import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import { isPresent } from '../../utils/helper';
import * as messages from '../../utils/messagesConstants';
import * as endpoints from '../../services/endpoints';
import { showSpinner, hideSpinner } from '../actions/spinner';
import {
  userLoginSuccess,
  userLoginFailure,
  userLogOutSuccess,
  userLogOutFailure,
  userResetPassFailure,
  userResetPassSuccess
} from '../actions/userAuth';
import { setLocalStorageTokens } from '../../services/utils/helper';
import { LOGIN_ROUTE } from '../../utils/routesNavigationConstants';
import { clearLocalStorage } from '../../services/utils/helper';
import { navigateTo } from '../../utils/history';
import { fetchUserInfo } from '../../redux/actions/userInfo';
import ApiModule from '../../services/ApiModule';

interface UserPayload {
  email: String;
  password: String;
}
interface ActionType {
  type: String;
  payload: UserPayload;
}

const fetchUserLoginCall = async (payload: UserPayload) => {
  var details = {
    username: payload.email,
    password: payload.password.trim()
  };

  const userStatus = await api.fetchLogin(details);

  return userStatus;
};

function* userLoginRequest(action: ActionType) {
  yield put(showSpinner());

  yield delay(1000);

  try {
    const userStatus = yield call(fetchUserLoginCall, action.payload);

    if (
      userStatus.success &&
      isPresent(userStatus.data.accessToken) &&
      isPresent(userStatus.data.refreshToken)
    ) {
      setLocalStorageTokens({
        accessToken: userStatus.data.accessToken,
        refreshToken: userStatus.data.refreshToken
      });

      yield put(userLoginSuccess());

      yield put(fetchUserInfo());

      toast.success(messages.USER_LOGIN_SUCCESS);

      // navigateTo(HOME_ROUTE);
    } else {
      toast.error(messages.USER_LOGIN_INVALID_CREDENTIALS_ERROR);
    }
  } catch (e) {
    yield put(userLoginFailure());

    toast.error(messages.USER_LOGIN_UNEXPECTED_ERROR);
  }

  yield put(hideSpinner());
}
function* userResetPassword(action: ActionType) {
  yield put(showSpinner());

  yield delay(1000);

  try {
    const response = yield ApiModule.updateData(
      endpoints.RESET_PASSWORD(action.payload.email),
      {},
      {},
      'POST'
    );

    if (response.success) {
      // setLocalStorageTokens({
      //   accessToken: userStatus.data.accessToken,
      //   refreshToken: userStatus.data.refreshToken
      // });

      yield put(userResetPassSuccess());

      // yield put(fetchUserInfo());

      toast.success('Reset Link Sent Successfully');

      // navigateTo(HOME_ROUTE);
    } else {
      toast.error(messages.USER_LOGIN_INVALID_CREDENTIALS_ERROR);
    }
  } catch (e) {
    yield put(userResetPassFailure());

    toast.error(messages.USER_LOGIN_UNEXPECTED_ERROR);
  }

  yield put(hideSpinner());
}

function* userLogOut() {
  try {
    clearLocalStorage();

    yield put(userLogOutSuccess());
    navigateTo(LOGIN_ROUTE);
  } catch (error) {
    yield put(userLogOutFailure());
  }
}

export default [
  takeLatest(types.USER_LOGIN_REQUEST, userLoginRequest),
  takeLatest(types.USER_LOG_OUT_REQUEST, userLogOut),
  takeLatest(types.USER_RESET_PASSWORD_REQUEST, userResetPassword)
];
