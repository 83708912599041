import React, { useMemo } from 'react';
import Rotate90DegreesCcwIcon from '@material-ui/icons/RotateLeft';
import { ZoomIn, ZoomOut } from '@material-ui/icons';
import Rotate90DegreesCcwIcon2 from '@material-ui/icons/RotateRight';
import { useWindowWidth } from '@react-hook/window-size';
import { Grid } from '@material-ui/core';

const RenderImageFuel = ({ el }: any) => {
  const onlyWidth = useWindowWidth();

  const mainPdfScaleOne = useMemo(() => {
    const currentWidth = onlyWidth;
    const startingWidth = 1600;
    const buffer = 200;
    const increaseRatio = 0.1;

    let ratio = 1;

    if (currentWidth < startingWidth) {
      return ratio;
    }

    for (let i = 1; i <= 20; i++) {
      if (currentWidth < startingWidth + i * buffer) {
        ratio = 1 + i * increaseRatio;
        break;
      }
    }

    return Number(ratio.toFixed(1));
  }, [onlyWidth]);

  const [pdfScaleOne, setPdfScaleOne] = React.useState(mainPdfScaleOne);
  const [rotationDegreeOne, setRotatioDegreeOne] = React.useState(0);
  const [index, setIndex] = React.useState(0);

  function handleClick(val: number) {
    setIndex(val);
  }

  const handleZoomIn = () => {
    setPdfScaleOne((prevScale) => Math.min(prevScale + 0.1, 2.0)); // Max scale is 2.0
  };

  const handleZoomOut = () => {
    setPdfScaleOne((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min scale is 0.5
  };

  return (
    <div
      style={{
        marginTop: '60px',
        backgroundColor: '#000',
        textAlign: 'center',
        height: '100%'
      }}>
      <Grid container>
        <Grid item xs={2} direction="column">
          <div
            style={{
              marginTop: '5px',
              display: 'flex',
              flexDirection: 'column'
            }}>
            {el.slice(0, el.length).map((item: any, currentIndex: number) => (
              <img
                key={currentIndex}
                onClick={() => handleClick(currentIndex)}
                style={{
                  width: '180px',
                  height: '180px',
                  objectFit: 'scale-down',
                  margin: '20px',
                  cursor: 'pointer',
                  border: index === currentIndex ? '3px solid lightblue' : ''
                }}
                src={item}
              />
            ))}
          </div>
        </Grid>
        <Grid item xs={9}>
          <div className="rotate-image-option">
            <Rotate90DegreesCcwIcon
              fontSize="large"
              style={{ color: 'gray', fontSize: '50px', marginRight: '10px' }}
              onClick={() => setRotatioDegreeOne(rotationDegreeOne - 90)}
            />
            <Rotate90DegreesCcwIcon2
              fontSize="large"
              style={{ color: 'gray', fontSize: '50px' }}
              onClick={() => setRotatioDegreeOne(rotationDegreeOne + 90)}
            />
            <div style={{ textAlign: 'center' }}>
              <ZoomIn
                fontSize="large"
                style={{ color: 'gray', marginRight: '5px' }}
                onClick={handleZoomIn}
              />
              <ZoomOut
                fontSize="large"
                style={{ color: 'gray' }}
                onClick={handleZoomOut}
              />
            </div>
          </div>
          <img
            style={{
              width: '400px',
              transform: `rotate(${rotationDegreeOne}deg) scale(${pdfScaleOne})`,
              marginTop: '220px',
              height: '400px',
              marginBottom: '200px',
              objectFit: 'scale-down'
            }}
            src={el[index]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default RenderImageFuel;
