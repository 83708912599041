import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import * as R from 'ramda';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import { getDetailsScreenSelectedFields } from '../utils/selectors';
import { showSpinner, hideSpinner } from '../actions/spinner';
import {
  fetchDetailScreenDataSuccess,
  fetchDetailScreenDataFailure,
  updateStatusDetailScreenDataSuccess,
  updateStatusDetailScreenDataFailure,
  fetchDetailScreenDataRequest,
  fetchExpenseTypesSuccess,
  fetchExpenseTypesFailure,
  fetchBookingsSuccess,
  fetchBookingsFailure,
  updateReceiptDetailScreenDataSuccess,
  updateReceiptDetailScreenDataFailure,
  setCommentWeeklyDetailsSuccess,
  setCommentWeeklyDetailsFailure
} from '../actions/detailsScreenData';
import { fetchUserDataCall, ActionType } from './weeklyScreenData';
import { filterUserData } from '../../components/WeeklyScreenTable/helper';
import { getWeekRangeString, getWeekRange } from '../../utils/helper';
import { fetchBookingFilters } from '../utils/sagaHelpers';
import { navigateTo } from '../../utils/history';
import StoreState from '../utils/interfaces';

export interface UpdateStatusUserDataActionType {
  type: String;
  payload: {
    userId: number;
    unlock: boolean;
  };
}

export interface UpdateReceiptDetailsUserDataActionType {
  type: String;
  payload: { updatedValues: {} };
}

function* setCommentWeeklyDetails(action: {
  type: String;
  payload: {
    userId: number;
    comment: string;
  };
}) {
  yield put(showSpinner());

  yield delay(1000);

  const {
    date,
    departmentFilter,
    statusFilter,
    firstNameFilter,
    lastNameFilter,
    supervisorFilter
    // comment
  } = yield select((state: StoreState) => {
    return {
      ...getDetailsScreenSelectedFields(state)
      // ...state.detailsScreenData
    };
  });

  try {
    const selectedDate = new Date(date);
    const weekRangeString = getWeekRangeString(selectedDate);
    const { userId, comment } = action.payload;
    console.log(action.payload, '@@action.payload@@');
    const response = yield api.updateData(
      endpoints.COMMENT_WEEKLY,
      {},
      {
        startDate: weekRangeString.from,
        endDate: weekRangeString.to,
        userId,
        comment
      }
    );

    if (response.success) {
      yield put(setCommentWeeklyDetailsSuccess());

      yield put(
        fetchDetailScreenDataRequest(
          date,
          departmentFilter,
          statusFilter,
          firstNameFilter,
          lastNameFilter,
          supervisorFilter
        )
      );
    } else {
      yield put(setCommentWeeklyDetailsFailure());
    }
  } catch (e) {
    yield put(setCommentWeeklyDetailsFailure());
  }

  yield put(hideSpinner());
}

function* updateReceiptDetailsUserData(
  action: UpdateReceiptDetailsUserDataActionType
) {
  yield put(showSpinner());

  yield delay(1000);

  let {
    date,
    departmentFilter,
    statusFilter,
    firstNameFilter,
    lastNameFilter,
    supervisorFilter
  } = yield select(getDetailsScreenSelectedFields);

  const { updatedValues } = action.payload;

  try {
    const status = yield api.updateData(endpoints.EXPENSE, {}, updatedValues);

    if (status.success) {
      yield put(updateReceiptDetailScreenDataSuccess());

      yield put(
        fetchDetailScreenDataRequest(
          date,
          departmentFilter,
          statusFilter,
          firstNameFilter,
          lastNameFilter,
          supervisorFilter
        )
      );
    } else {
      yield put(updateReceiptDetailScreenDataFailure());
    }
  } catch (e) {
    yield put(updateReceiptDetailScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* fetchBookings(action: any) {
  yield delay(1000);

  const { from: startDate, to: endDate } = getWeekRange(
    action.payload.selectedFirstDate
  );

  try {
    const bookingFiltersResponse = yield call(
      fetchBookingFilters,
      startDate,
      endDate
    );

    if (R.pathOr(false, ['success'], bookingFiltersResponse)) {
      yield put(
        fetchBookingsSuccess(
          R.pathOr([], ['data', 'bookingFilters'], bookingFiltersResponse)
        )
      );
    } else {
      yield put(fetchBookingsFailure());
    }
  } catch (e) {
    yield put(fetchBookingsFailure());
  }
}

function* fetchExpenseTypes() {
  yield delay(1000);

  try {
    const status = yield api.fetchResponse(endpoints.EXPENSE_TYPES(true));

    if (status.success) {
      yield put(fetchExpenseTypesSuccess(status.data));
    } else {
      yield put(fetchExpenseTypesFailure());
    }
  } catch (e) {
    yield put(fetchExpenseTypesFailure());
  }
}

function* updateStatusUserDataField(action: UpdateStatusUserDataActionType) {
  yield put(showSpinner());

  const { userId, unlock } = action.payload;

  try {
    const { date, dataArray }: any = yield select((state: StoreState) => {
      return {
        ...getDetailsScreenSelectedFields(state),
        dataArray: state.detailsScreenData.dataArray
      };
    });

    const updateStatusUserDataIndex = R.findIndex(R.propEq('id', userId))(
      dataArray
    );
    const updateStatusUserData = dataArray[updateStatusUserDataIndex];

    const currentStatus: string = R.pathOr(
      '',
      ['status'],
      updateStatusUserData
    );

    const isReviewed = currentStatus.toLowerCase() === 'reviewed';

    const selectedDate = new Date(date);
    const weekRangeString = getWeekRangeString(selectedDate);

    const body = {
      startDate: weekRangeString.from,
      endDate: weekRangeString.to,
      userId: Number(userId)
    };

    const response = yield api.updateData(
      unlock
        ? endpoints.WEEKLY_STATUS_CHANGE
        : isReviewed
        ? endpoints.WEEKLY_APPROVE
        : endpoints.WEEKLY_REVIEW,
      {},
      body
    );

    if (response.success) {
      yield put(updateStatusDetailScreenDataSuccess());

      dataArray[updateStatusUserDataIndex].status = response.data.status;

      yield put(fetchDetailScreenDataSuccess(dataArray));

      if (dataArray.length > 1 && !unlock) {
        let nextUserId = -1;

        if (updateStatusUserDataIndex < dataArray.length - 1) {
          nextUserId = R.pathOr(
            -1,
            [updateStatusUserDataIndex + 1, 'id'],
            dataArray
          );
        } else {
          nextUserId = R.pathOr(-1, [0, 'id'], dataArray);
        }

        if (nextUserId !== -1) {
          navigateTo('/details/' + nextUserId);
        }
      }
    } else {
      yield put(updateStatusDetailScreenDataFailure());
    }
  } catch (e) {
    yield put(updateStatusDetailScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* fetchDetailsScreenData(action: ActionType) {
  yield delay(1000);

  const {
    departmentFilter,
    statusFilter,
    supervisorFilter,
    date,
    firstNameFilter,
    lastNameFilter
  } = action.payload;

  try {
    const userStatus = yield call(fetchUserDataCall, date);
    if (userStatus.success) {
      const detailScreenFilteredData = filterUserData(
        userStatus.data.data,
        departmentFilter,
        statusFilter,
        firstNameFilter,
        lastNameFilter,
        supervisorFilter || -1
      );

      yield put(fetchDetailScreenDataSuccess(detailScreenFilteredData));
    } else {
      yield put(fetchDetailScreenDataFailure());
    }
  } catch (e) {
    yield put(fetchDetailScreenDataFailure());
  }
}

export default [
  takeLatest(types.FETCH_DETAILS_SCREEN_DATA_REQUEST, fetchDetailsScreenData),
  takeLatest(
    types.UPDATE_STATUS_DETAILS_SCREEN_DATA_REQUEST,
    updateStatusUserDataField
  ),
  takeLatest(types.FETCH_EXPENSE_TYPES_REQUEST, fetchExpenseTypes),
  takeLatest(types.FETCH_BOOKINGS_REQUEST, fetchBookings),
  takeLatest(
    types.UPDATE_RECEIPT_DETAILS_SCREEN_DATA_REQUEST,
    updateReceiptDetailsUserData
  ),
  takeLatest(types.SET_COMMENT_DETAILS_SCREEN_REQUEST, setCommentWeeklyDetails)
];
