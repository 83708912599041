import { put, takeLatest, all, delay } from 'redux-saga/effects';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as R from 'ramda';
import * as endpoints from '../../services/endpoints';
import {
  fetchUsersScreenDataRequest,
  fetchUsersScreenDataSuccess,
  fetchUsersScreenDataFailure,
  fetchUsersScreenTypesSuccess,
  fetchUsersScreenTypesFailure,
  addNewUserSuccess,
  addNewUserFailure,
  updateUserSuccess,
  updateUserFailure,
  userStatusChangeSuccess,
  userStatusChangeFailure,
  fetchUsersFirstNameType,
  fetchUsersLastNameType
} from '../actions/usersScreenData';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { isPresent } from '../../utils/helper';
import { navigateTo } from '../../utils/history';
import { toast } from 'react-toastify';
import { clearLocalStorage } from '../../services/utils/helper';
import { LOGIN_ROUTE } from '../../utils/routesNavigationConstants';

interface UserPayload {
  newUserData: {};
}
interface ActionType {
  type: String;
  payload: UserPayload;
}

function* updateUserStatus(action: any) {
  yield put(showSpinner());

  const { userId, isActive } = action.payload;
  console.log(userId, isActive, 'userBlock');
  try {
    const response = yield api.updateData(
      isActive ? endpoints.BLOCK_USER : endpoints.UNBLOCK_USER,
      {},
      {
        userId
      },
      'POST'
    );

    if (response.success) {
      yield put(userStatusChangeSuccess());

      yield put(fetchUsersScreenDataRequest());
    } else {
      yield put(userStatusChangeFailure());
    }
  } catch (e) {
    yield put(userStatusChangeFailure());
  }

  yield put(hideSpinner());
}

function* updateUser(action: any) {
  yield put(showSpinner());

  const { userId, updatedData } = action.payload;

  try {
    const response = yield api.updateData(
      endpoints.UPDATE_USER(userId, true),
      {},
      updatedData
    );

    if (response.success) {
      yield put(updateUserSuccess());
      yield put(fetchUsersScreenDataRequest());
    } else {
      yield put(updateUserFailure());
    }
  } catch (e) {
    yield put(updateUserFailure());
  }

  yield put(hideSpinner());
}

function* addNewUser(action: ActionType) {
  yield put(showSpinner());

  const body = action.payload.newUserData;

  try {
    const response = yield api.createNewUser([body]);
    console.log(response.data, 'responseFFFFFF');
    // console.log(response.json(),"From action")
    if (response.success) {
      yield put(addNewUserSuccess());

      yield put(fetchUsersScreenDataRequest());

      const userId = response.data[0].id;

      if (isPresent(userId)) {
        navigateTo(`/users/details/${userId}`);
      } else {
        navigateTo(`/users`);
      }
    } else {
      toast.error(response.data);
      yield put(addNewUserFailure());
    }
  } catch (e) {
    yield put(addNewUserFailure());
  }

  yield put(hideSpinner());
}

function* fetchUsersScreenTypes() {
  yield delay(1000);

  try {
    const [userTypeResponse, companiesResponse]: any = yield all([
      api.fetchResponse(endpoints.WEB_USER_TYPES(true)),
      api.fetchResponse(endpoints.WEB_COMPANY(true))
    ]);

    if (
      userTypeResponse.success === false ||
      userTypeResponse.data === 'Unauthorized' ||
      userTypeResponse.data === 'Access Denied'
    ) {
      clearLocalStorage();
      navigateTo(LOGIN_ROUTE);
      window.location.reload();
    }
    if (userTypeResponse.success && companiesResponse.success) {
      yield put(
        fetchUsersScreenTypesSuccess(
          userTypeResponse.data,
          companiesResponse.data
        )
      );
    } else {
      yield put(fetchUsersScreenTypesFailure());
    }
  } catch (err) {
    console.log(err, 'err');
    toast.error(err.error);
    yield put(fetchUsersScreenTypesFailure());
  }
}

function* fetchUsersScreenData() {
  yield delay(1000);

  try {
    const response = yield api.fetchResponse(endpoints.USERS(true));

    if (
      response.success === false ||
      response.data === 'Unauthorized' ||
      response.data === 'Access Denied'
    ) {
      clearLocalStorage();
      navigateTo(LOGIN_ROUTE);
      window.location.reload();
    }
    if (response.success) {
      yield put(fetchUsersScreenDataSuccess(response.data));
      let Data = R.uniq(response.data);

      const firstNameValue = Data.map((item: any) => {
        return {
          company: item.company,
          id: item.firstName,
          name: item.firstName
        };
      });

      const sortedValues = firstNameValue.sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      let newValues = [];
      let filtered = sortedValues.filter((item: any) => R.uniq(item.id));

      for (let i = 0; i < filtered.length; i++) {
        let firstNames = filtered[i].name;
        newValues.push(firstNames);
      }
      let res = Array.from(new Set(newValues));

      let userList = res.map((user: any) => {
        return {
          id: user,
          name: user
        };
      });

      yield put(fetchUsersFirstNameType(userList));

      //last name

      let DataLast = R.uniq(response.data);

      const lastNameValue = DataLast.map((items: any) => {
        return {
          company: items.company,
          id: items.lastName,
          name: items.lastName
        };
      });

      const sortedLastNameValues = lastNameValue.sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      let newLastValues = [];
      let filteredValue = sortedLastNameValues.filter((item: any) =>
        R.uniq(item.name)
      );

      for (let i = 0; i < filteredValue.length; i++) {
        let lastName = filteredValue[i].name.trim();
        newLastValues.push(lastName);
      }

      let resLastName = Array.from(new Set(newLastValues));

      let userListLastName = resLastName.map((names: any) => {
        return {
          id: names,
          name: names
        };
      });

      yield put(fetchUsersLastNameType(userListLastName));
    } else {
      yield put(fetchUsersScreenDataFailure());
    }
  } catch (err) {
    toast.error(err);
    yield put(fetchUsersScreenDataFailure());
  }
}

export default [
  takeLatest(types.FETCH_USERS_SCREEN_DATA_REQUEST, fetchUsersScreenData),
  takeLatest(types.FETCH_USERS_SCREEN_TYPES_REQUEST, fetchUsersScreenTypes),
  takeLatest(types.ADD_NEW_USER_REQUEST, addNewUser),
  takeLatest(types.UPDATE_USER_REQUEST, updateUser),
  takeLatest(types.USER_STATUS_CHANGE_REQUEST, updateUserStatus)
];
