import { put, takeLatest, all, select, delay } from 'redux-saga/effects';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import * as messages from '../../utils/messagesConstants';
import types from '../utils/actionTypes';
import ApiModule from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  fetchBookingsScreenDataRequest,
  fetchBookingsScreenDataFailure,
  fetchBookingsScreenDataSuccess,
  fetchBookingsScreenDataFieldsSuccess,
  fetchBookingsScreenDataFieldsFailure,
  addBookingsScreenDataSuccess,
  addBookingsScreenDataFailure,
  editBookingsScreenDataSuccess,
  editBookingsScreenDataFailure,
  validateBookingNumberSuccess,
  validateBookingNumberFailure
} from '../actions/bookingsScreenData';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { getDateStringForBooking, isPresent } from '../../utils/helper';
import { getBookingsFiltersValues } from '../utils/selectors';
import { navigateTo } from '../../utils/history';
import { isArrayOrSendEmpty } from '../../utils/helper';
import {
  fetchPilotFilters,
  fetchAmeFilters,
  fetchApprenticeFilters,
  fetchAircraftFilters,
  fetchClientUsersFilters,
  fetchApprovingCompanyFilters
} from '../utils/sagaHelpers';
import { clearLocalStorage } from '../../services/utils/helper';
import { LOGIN_ROUTE } from '../../utils/routesNavigationConstants';

interface FetchBookingsScreenDataActionType {
  type: String;
  payload: {
    startDate: Date;
    endDate: Date;
  };
}

interface FetchBookingsScreenDataFieldsActionType {
  type: String;
  payload: {
    customerId: number;
  };
}

interface AddBookingsScreenDataActionType {
  type: String;
  payload: {
    addBookingData: {};
  };
}

interface EditBookingsScreenDataActionType {
  type: String;
  payload: {
    editBookingData: {};
    newPassengers: {};
    newEquipments: {};
    deletedPassengers: {};
    deletedEquipments: {};
  };
}

interface ValidateBookingNumberActionType {
  type: String;
  payload: {
    bookingNumber: string;
  };
}

function* fetchBookingsScreenData(action: FetchBookingsScreenDataActionType) {
  yield delay(1000);

  try {
    const { startDate, endDate } = action.payload;

    const startDateString = getDateStringForBooking(new Date(startDate || ''));
    const endDateString = getDateStringForBooking(new Date(endDate || ''));

    console.log(startDateString, 'startDateString');
    console.log(endDateString, 'endDateString');
    const [
      responseBookings,
      responseCustomers,
      responseBase,
      responseEquipments,
      responsePilot,
      responseAme,
      responseApprentice,
      responseApprovingCompany,
      responseAricraft,
      responseClient
    ]: any = yield all([
      ApiModule.fetchResponse(
        endpoints.WEB_BOOKING_RANGE(startDateString, endDateString)
      ),
      ApiModule.fetchResponse(endpoints.WEB_CUSTOMERS),
      ApiModule.fetchResponse(endpoints.BASE),
      ApiModule.fetchResponse(endpoints.WEB_EQUIPMENTS),
      // ApiModule.fetchResponse(endpoints.GET_CLIENT_USERS),
      fetchPilotFilters(),
      fetchAmeFilters(),
      fetchApprenticeFilters(),
      fetchApprovingCompanyFilters(),
      fetchAircraftFilters(),
      fetchClientUsersFilters()
    ]);

    const bookings: [] = R.pathOr([], ['data'], responseBookings);
    const customers: [] = R.pathOr([], ['data'], responseCustomers);
    const bases: [] = R.pathOr([], ['data'], responseBase);
    const equipments: [] = R.pathOr([], ['data'], responseEquipments);
    // const clients: [] = R.pathOr([], ['data'], responseClient);

    console.log(responseBookings, 'responseBookings');
    yield put(
      fetchBookingsScreenDataSuccess(
        isArrayOrSendEmpty(bookings),
        isArrayOrSendEmpty(customers),
        isArrayOrSendEmpty(bases),
        isArrayOrSendEmpty(equipments),
        R.pathOr([], ['data', 'pilotFilters'], responsePilot),
        R.pathOr([], ['data', 'ameFilters'], responseAme),
        R.pathOr([], ['data', 'apprenticeFilters'], responseApprentice),
        R.pathOr([], ['data', 'aircraftFilters'], responseAricraft),
        R.pathOr([], ['data', 'clientFilters'], responseClient),

        R.pathOr(
          [],
          ['data', 'approvingCompanyFilters'],
          responseApprovingCompany
        )
      )
    );
  } catch (error) {
    yield put(fetchBookingsScreenDataFailure());
  }
}

function* fetchBookingsScreenDataFields(
  action: FetchBookingsScreenDataFieldsActionType
) {
  yield put(showSpinner());
  yield delay(1000);

  try {
    const { customerId } = action.payload;

    const [responseCustomerContacts, responsePassengers]: any = yield all([
      ApiModule.fetchResponse(endpoints.WEB_CUSTOMER_CONTACT(`${customerId}`)),
      ApiModule.fetchResponse(endpoints.WEB_PASSENGERS(`${customerId}`))
    ]);

    if (responseCustomerContacts.success && responsePassengers.success) {
      yield put(
        fetchBookingsScreenDataFieldsSuccess(
          responseCustomerContacts.data.noContent
            ? []
            : responseCustomerContacts.data,
          responsePassengers.data.noContent ? [] : responsePassengers.data
        )
      );
    } else {
      yield put(fetchBookingsScreenDataFieldsFailure());
    }
  } catch (error) {
    yield put(fetchBookingsScreenDataFieldsFailure());
  }

  yield put(hideSpinner());
}

function* addBookingsScreenData(action: AddBookingsScreenDataActionType) {
  yield put(showSpinner());
  yield delay(1000);

  try {
    const { addBookingData } = action.payload;

    const response = yield ApiModule.updateData(
      endpoints.WEB_BOOKING,
      {},
      addBookingData,
      'POST'
    );
    if (
      response.success === false &&
      response.data === 'Unauthorized' &&
      response.data === 'Access Denied'
    ) {
      clearLocalStorage();
      navigateTo(LOGIN_ROUTE);
      window.location.reload();
    }
    if (response.success) {
      yield put(addBookingsScreenDataSuccess());

      const id = R.path(['data', 'id'], response);

      if (isPresent(id)) {
        const { from, to } = yield select(getBookingsFiltersValues);

        yield put(fetchBookingsScreenDataRequest(from, to));

        navigateTo(`/bookings/details/${id}`);
      } else {
        navigateTo(`/bookings`);
      }
    } else {
      toast.error(response.data);
      console.log(response.data, 'error');
      yield put(addBookingsScreenDataFailure());
    }
  } catch (error) {
    yield put(addBookingsScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* editBookingsScreenData(action: EditBookingsScreenDataActionType) {
  yield put(showSpinner());
  yield delay(1000);

  try {
    const { editBookingData } = action.payload;

    const response = yield ApiModule.updateData(
      endpoints.WEB_BOOKING,
      {},
      editBookingData
    );

    if (
      response.success === false &&
      response.data === 'Unauthorized' &&
      response.data === 'Access Denied'
    ) {
      clearLocalStorage();
      navigateTo(LOGIN_ROUTE);
      window.location.reload();
    }

    if (response.success) {
      yield put(editBookingsScreenDataSuccess());

      const { from, to } = yield select(getBookingsFiltersValues);

      yield put(fetchBookingsScreenDataRequest(from, to));
    } else {
      toast.error(messages.BOOKING_EDIT_ERROR);

      yield put(editBookingsScreenDataFailure());
    }
  } catch (error) {
    yield put(editBookingsScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* validateBookingNumber(action: ValidateBookingNumberActionType) {
  yield delay(1000);

  try {
    const { bookingNumber } = action.payload;

    const response = yield ApiModule.fetchResponse(
      endpoints.VALIDATE_BOOKING_NUMBER(bookingNumber)
    );
    if (
      response.success === false ||
      response.data === 'Unauthorized' ||
      response.data === 'Access Denied'
    ) {
      clearLocalStorage();
      navigateTo(LOGIN_ROUTE);
      window.location.reload();
    }

    if (response.success && response.data.isValid) {
      yield put(validateBookingNumberSuccess());
    } else {
      yield put(validateBookingNumberFailure());
    }
  } catch (error) {
    yield put(validateBookingNumberFailure());
  }
}

export default [
  takeLatest(types.FETCH_BOOKINGS_SCREEN_DATA_REQUEST, fetchBookingsScreenData),
  takeLatest(
    types.FETCH_BOOKINGS_SCREEN_DATA_FIELDS_REQUEST,
    fetchBookingsScreenDataFields
  ),
  takeLatest(types.ADD_BOOKINGS_SCREEN_DATA_REQUEST, addBookingsScreenData),
  takeLatest(types.EDIT_BOOKINGS_SCREEN_DATA_REQUEST, editBookingsScreenData),
  takeLatest(types.VALIDATE_BOOKING_NUMBER_REQUEST, validateBookingNumber)
];
