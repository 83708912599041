import 'date-fns';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { isNilOrEmpty, isPresent, isValidDate } from '../../../utils/helper';

interface MaterialUIPickerProps {
  name: string;
  label: string;
  selectedDate: any;
  handleDateChange: (date: any, value?: string | null | undefined) => void;
}

export default function MaterialUIPicker({
  name,
  label,
  selectedDate,
  handleDateChange
}: MaterialUIPickerProps) {
  const initialDateTime = isPresent(selectedDate)
    ? moment(selectedDate)
    : null;
    // .utcOffset(0, true).format()
    console.log(initialDateTime,"initialDateTime")
  const [date, setDate] = useState<any>(initialDateTime);
  const [time, setTime] = useState<any>(initialDateTime);

  useEffect(() => {
    if (isValidDate(date)) {
      const dateTime = moment(
        selectedDate ? new Date(selectedDate) : new Date()
      );
      const dateLoc = moment(date);

      dateTime.set('year', dateLoc.get('year'));
      dateTime.set('month', dateLoc.get('month'));
      dateTime.set('date', dateLoc.get('date'));

      handleDateChange(dateTime.utcOffset(0, false).format());
      

    }
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    if (isValidDate(time)) {
      const dateTime = moment(
        selectedDate ? new Date(selectedDate) : new Date()
      );
      const dateLoc = moment(time);

      dateTime.set('hour', dateLoc.get('hour'));
      dateTime.set('minute', dateLoc.get('minute'));
      dateTime.set('second', dateLoc.get('second'));

      handleDateChange(dateTime.utcOffset(0, false).format());

    }
    // eslint-disable-next-line
  }, [time]);

  useEffect(() => {
    if (isNilOrEmpty(date) && isNilOrEmpty(time)) {
      handleDateChange(null);
    }
    // eslint-disable-next-line
  }, [date, time]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-between">
        <KeyboardDatePicker
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id={`${name}redux-form-date-picker-inline`}
          className="material-date-picker"
          label={`${label} Date`}
          value={date}
          onChange={setDate}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          clearable
          autoOk
        />

        <KeyboardTimePicker
          margin="normal"
          id={`${name}redux-form-time-picker-inline`}
          className="material-time-picker"
          variant="inline"
          ampm={false}
          label={`${label} Time`}
          value={time}
          onChange={setTime}
          KeyboardButtonProps={{
            'aria-label': 'change time'
          }}
          clearable
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
