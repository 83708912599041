import { put, takeLatest, call, delay } from 'redux-saga/effects';
import * as R from 'ramda';
import types from '../utils/actionTypes';
import {
  fetchInvoiceScreenAircraftFiltersSuccess,
  fetchInvoiceScreenAircraftFiltersFailure,
  fetchInvoiceScreenBookingFiltersSuccess,
  fetchInvoiceScreenBookingFiltersFailure,
  fetchInvoiceScreenClientFiltersSuccess,
  fetchInvoiceScreenClientFiltersFailure,
  fetchInvoiceScreenPilotFiltersSuccess,
  fetchInvoiceScreenPilotFiltersFailure
} from '../actions/invoiceScreenFilters';
import { showSpinner, hideSpinner } from '../actions/spinner';
import {
  fetchBookingFilters,
  fetchAircraftFilters,
  fetchCustomerFilters,
  fetchPilotFilters
} from '../utils/sagaHelpers';
import { clearLocalStorage } from '../../services/utils/helper';
import { navigateTo } from '../../utils/history';
import { LOGIN_ROUTE } from '../../utils/routesNavigationConstants';

interface FetchInvoiceScreenBookingFiltersActionType {
  type: String;
  payload: {
    startDate: Date;
    endDate: Date;
  };
}

function* fetchInvoiceScreenClientFilters() {
  yield put(showSpinner());

  yield delay(1000);

  const customerFiltersResponse = yield call(fetchCustomerFilters);

  if (
    customerFiltersResponse.success === false ||
    customerFiltersResponse.data === 'Unauthorized' ||
    customerFiltersResponse.data === 'Access Denied'
  ) {
    clearLocalStorage();
    navigateTo(LOGIN_ROUTE);
    window.location.reload();
  }

  if (R.pathOr(false, ['success'], customerFiltersResponse)) {
    yield put(
      fetchInvoiceScreenClientFiltersSuccess(
        R.pathOr([], ['data', 'customerFilters'], customerFiltersResponse)
      )
    );
  } else {
    yield put(fetchInvoiceScreenClientFiltersFailure());
  }

  yield put(hideSpinner());
}

function* fetchInvoiceScreenPilotFilters() {
  yield put(showSpinner());

  yield delay(1000);

  const pilotFiltersResponse = yield call(fetchPilotFilters);

  if (
    pilotFiltersResponse.success === false ||
    pilotFiltersResponse.data === 'Unauthorized' ||
    pilotFiltersResponse.data === 'Access Denied'
  ) {
    clearLocalStorage();
    navigateTo(LOGIN_ROUTE);
    window.location.reload();
  }

  if (R.pathOr(false, ['success'], pilotFiltersResponse)) {
    yield put(
      fetchInvoiceScreenPilotFiltersSuccess(
        R.pathOr([], ['data', 'pilotFilters'], pilotFiltersResponse)
      )
    );
  } else {
    yield put(fetchInvoiceScreenPilotFiltersFailure());
  }

  yield put(hideSpinner());
}

function* fetchInvoiceScreenBookingFilters(
  action: FetchInvoiceScreenBookingFiltersActionType
) {
  yield put(showSpinner());

  yield delay(1000);

  const { startDate, endDate } = action.payload;

  const bookingFiltersResponse = yield call(
    fetchBookingFilters,
    startDate,
    endDate
  );

  if (
    bookingFiltersResponse.success === false ||
    bookingFiltersResponse.data === 'Unauthorized' ||
    bookingFiltersResponse.data === 'Access Denied'
  ) {
    clearLocalStorage();
    navigateTo(LOGIN_ROUTE);
    window.location.reload();
  }

  if (R.pathOr(false, ['success'], bookingFiltersResponse)) {
    yield put(
      fetchInvoiceScreenBookingFiltersSuccess(
        R.pathOr([], ['data', 'bookingFilters'], bookingFiltersResponse)
      )
    );
  } else {
    yield put(fetchInvoiceScreenBookingFiltersFailure());
  }

  yield put(hideSpinner());
}

function* fetchInvoiceScreenAircraftFilters() {
  yield put(showSpinner());

  yield delay(1000);

  const aircraftFiltersResponse = yield call(fetchAircraftFilters);

  if (
    aircraftFiltersResponse.success === false ||
    aircraftFiltersResponse.data === 'Unauthorized' ||
    aircraftFiltersResponse.data === 'Access Denied'
  ) {
    clearLocalStorage();
    navigateTo(LOGIN_ROUTE);
    window.location.reload();
  }

  if (R.pathOr(false, ['success'], aircraftFiltersResponse)) {
    yield put(
      fetchInvoiceScreenAircraftFiltersSuccess(
        R.pathOr([], ['data', 'aircraftFilters'], aircraftFiltersResponse)
      )
    );
  } else {
    yield put(fetchInvoiceScreenAircraftFiltersFailure());
  }

  yield put(hideSpinner());
}

export default [
  takeLatest(
    types.FETCH_INVOICE_SCREEN_AIRCRAFT_FILTERS_REQUEST,
    fetchInvoiceScreenAircraftFilters
  ),
  takeLatest(
    types.FETCH_INVOICE_SCREEN_BOOKING_FILTERS_REQUEST,
    fetchInvoiceScreenBookingFilters
  ),
  takeLatest(
    types.FETCH_INVOICE_SCREEN_CLIENT_FILTERS_REQUEST,
    fetchInvoiceScreenClientFilters
  ),
  takeLatest(
    types.FETCH_INVOICE_SCREEN_PILOT_FILTERS_REQUEST,
    fetchInvoiceScreenPilotFilters
  )
];
