import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Chip
} from '@material-ui/core';
import { useStyles, ClientUsersRow, columns, filterUsersData } from './helper';
import { useSortHook } from '../../hooks/sortHooks';
import SharedTableHeader from '../shared/SharedTableHeader';
import { isNilOrEmpty } from '../../utils/helper';
import StoreState from '../../redux/utils/interfaces';
import { CLIENT_USERS_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { DEPARTMENT, BUSH_PAY, STATUS,FIRST_NAME,LAST_NAME, COMPANY } from '../../routes/ClientUsers/helper';

import './style.scss';

interface ClientUsersTableProps {
  usersData: ClientUsersRow[];
  // userTypes: [] | never[];
  goto: Function;
}

const UsersTable = ({ usersData, goto }: ClientUsersTableProps) => {
  const classes = useStyles();

  const { filterFormValues } = useSelector((state: StoreState) => ({
    filterFormValues: getFormValues(CLIENT_USERS_FILTERS_FORM)(state) || {}
  }));

  const department = R.pathOr(-1, [DEPARTMENT], filterFormValues);
  const bushPay = R.pathOr('all', [BUSH_PAY], filterFormValues);
  const status = R.pathOr('all', [STATUS], filterFormValues);
  const firstName = R.pathOr(-1, [FIRST_NAME], filterFormValues);
  const lastName = R.pathOr(-1, [LAST_NAME], filterFormValues);
  const company = R.pathOr(-1, [COMPANY], filterFormValues);

  const filteredUsersData = useMemo(() => {
    return filterUsersData(usersData, {
      department,
      bushPay,
      status,
      firstName,
      lastName,
      company
    });
  }, [department, bushPay, status,firstName, lastName,company,usersData]);

  const formatData = useMemo(() => {
    return filteredUsersData.map((row: ClientUsersRow) => {
      // const userTypeLabel = R.find(R.propEq('id', row.userType.id))(userTypes);
      var clientNames = "";
      const clients = R.pathOr([], ['clientUser'], row);
        clients.map((client: any) => {
          clientNames += client.client.name + ",";
        })
      return {
        id: row.id,
        employeeId: row.employeeId,
        firstName: row.firstName,
        lastName: row.lastName,
        username: row.username,
        // userTypeLabel: R.pathOr('-', ['name'], userTypeLabel),
        bushpayLabel: row.bushpay ? 'Yes' : 'No',
        company: row.company,
        phone: R.pathOr('-', ['phone'], row),
        license1: R.pathOr('-', ['license1'], row),
        status: isNilOrEmpty(row.deletedAt) ? 'Active' : 'Archived',
        client: clientNames
      };
    });
  }, [filteredUsersData]);

  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    dataArray: formatData,
    columnsData: columns
  });

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <SharedTableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedDataArray.map((row: any, index: number) => {
            return (
              <TableRow
                key={index}
                className="users-custom-body-row"
                onClick={() => goto(`/clientusers/details/${row.id}`)}>
                {/* <TableCell component="th" scope="row">
                  {row.employeeId}
                </TableCell> */}

                <TableCell component="th" scope="row" align="center">
                  {row.firstName}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.lastName}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.username}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.client}
                </TableCell>
                
                <TableCell component="th" scope="row" align="center">
                  <Chip size="medium" label={row.status} color={row.status ==='Active' ? 'secondary' : 'primary'}/>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;