import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import StoreState from '../../redux/utils/interfaces';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import {
  DEPARTMENT,
  BUSH_PAY,
  STATUS,
  filtersNotWorkingOptions,
  FIRST_NAME,
  LAST_NAME
} from '../../routes/Users/helper';
import { USERS_FILTERS_FORM } from '../../utils/reduxFormConstants';
import {
  initialFilterList,
  booleanOptions,
  statusOptions
} from '../../utils/helper';

const commonFilterPath = ['form', USERS_FILTERS_FORM, 'values'];
export const departmentFilterStatePath = commonFilterPath.concat(DEPARTMENT);
export const bushPayFilterStatePath = commonFilterPath.concat(BUSH_PAY);
export const statusFilterStatePath = commonFilterPath.concat(STATUS);
export const firstNameFilterStatePath = commonFilterPath.concat(FIRST_NAME);
export const lastNameFilterStatePath = commonFilterPath.concat(LAST_NAME);

export const DepartmentFilterComponent = () => {
  const { userTypes } = useSelector((state: StoreState) => ({
    userTypes: initialFilterList.concat(
      R.pathOr(
        filtersNotWorkingOptions,
        ['usersScreenData', 'userTypes'],
        state
      )
    )
  }));

  return (
    <Field
      name={DEPARTMENT}
      component={ReduxFormAutocomplete}
      label="Department"
      optionsList={userTypes}
      required
    />
  );
};

export const FirstNameFilterComponent = () => {
  const { UserfirstName } = useSelector((state: StoreState) => ({
    UserfirstName: initialFilterList.concat(
      R.pathOr(
        filtersNotWorkingOptions,
        ['usersScreenData', 'UserfirstName'],
        state
      )
    )
  }));

  return (
    <Field
      name={FIRST_NAME}
      component={ReduxFormAutocomplete}
      label="First Name"
      optionsList={UserfirstName}
      required
    />
  );
};

export const LastNameFilterComponent = () => {
  const { lastName } = useSelector((state: StoreState) => ({
    lastName: initialFilterList.concat(
      R.pathOr(filtersNotWorkingOptions, ['usersScreenData', 'lastName'], state)
    )
  }));

  return (
    <Field
      name={LAST_NAME}
      component={ReduxFormAutocomplete}
      label="Last Name"
      optionsList={lastName}
      required
    />
  );
};

export const BushPayFilterComponent = () => {
  return (
    <Field
      name={BUSH_PAY}
      component={ReduxFormAutocomplete}
      label="Bush Pay"
      optionsList={booleanOptions}
      required
    />
  );
};

export const StatusFilterComponent = () => {
  return (
    <Field
      name={STATUS}
      component={ReduxFormAutocomplete}
      label="Status"
      optionsList={statusOptions}
      required
    />
  );
};
