import React, { useState, useMemo } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import PdfRender from '../PdfRender';
import './styles.scss';

// interface PdfViewerProps {
//   pdfUrls: string[];
// }

const PdfViewer = ({ pdfUrls }: any) => {
  const onlyWidth = useWindowWidth();

  const [currentSelectedPdfIndex, setCurrentSelectedPdf] = useState(0);

  const currentSelectedPdf = pdfUrls[currentSelectedPdfIndex];

  const mainPdfScale = useMemo(() => {
    const currentWidth = onlyWidth;
    const startingWidth = 1600;
    const buffer = 200;
    const increaseRatio = 0.2;

    let ratio = 1.2;

    if (currentWidth < startingWidth) {
      return ratio;
    }

    for (let i = 1; i <= 20; i++) {
      if (currentWidth < startingWidth + i * buffer) {
        ratio = 1.2 + i * increaseRatio;
        break;
      }
    }

    return Number(ratio.toFixed(1));
  }, [onlyWidth]);

  console.log('Getting Flight reportPRF');
  return (
    <div className="pdf-viewer-container">
      <div className="pdf-options-container">
        {pdfUrls.map((pdfUrl: string, index: number) => {
          return (
            <div
              key={index}
              className={`pdf-option-wrapper ${
                currentSelectedPdfIndex === index
                  ? 'pdf-option-wrapper-active'
                  : ''
              }`}
              onClick={() => setCurrentSelectedPdf(index)}>
              <PdfRender pdf={'pdfUrl'} onlyFirstPage scale={0.2} />
            </div>
          );
        })}
      </div>
      <div className="current-selected-pdf-container">
        <PdfRender pdf={currentSelectedPdf} scale={mainPdfScale} />
      </div>
    </div>
  );
};

export default PdfViewer;
