export const FlightReportFromRange = (fromDate: Date) => {
  return {
    type: 'FLIGHT_REPORT_RANGE_UPDATE_FromDate',
    payload: {
      fromDate
    }
  };
};

export const FlightReportToRange = (to: Date) => {
  return {
    type: 'FLIGHT_REPORT_RANGE_UPDATE_ToDate',
    payload: {
      to
    }
  };
};
