import { put, takeLatest, select, call, delay } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  fetchFlightReportScreenDataSuccess,
  fetchFlightReportScreenDataFailure,
  fetchFlightReportScreenDataRequest,
  updateFlightReportScreenDataSuccess,
  updateFlightReportScreenDataFailure,
  exportFlightReportScreenDataSuccess,
  exportFlightReportScreenDataFailure,
  postFlightPortalCommentSuccess,
  postFlightPortalCommentFailure
} from '../actions/flightReportScreenData';
import { fetchCustomersScreenDataRequest } from '../actions/customersScreenData';
import { showSpinner, hideSpinner } from '../actions/spinner';
// import {
//   saveCopilotFilterSuccess,
//   saveAircraftFilterSuccess,
//   // setFlightReportStatusFilters,
//   saveFlightTypeFilterSuccess
// } from '../actions/flightReportScreenFilters';
import { FLIGHT_REPORT_FILTERS_FORM } from '../../utils/reduxFormConstants';
import StoreState from '../utils/interfaces';
import { getDateString, isPresent } from '../../utils/helper';
import { filterFlightReportScreenData } from '../../components/FlightReportTable/helper';
import { fetchAndDownload } from '../utils/sagaHelpers';
import {
  saveAircraftFilterSuccess,
  saveCopilotFilterSuccess,
  saveFlightStatusApprovedFilterSuccess,
  saveFlightTypeFilterSuccess
} from '../actions/flightReportScreenFilters';
// import { fetchBookingsScreenDataRequest } from '../actions/bookingsScreenData';
// import { DATA } from '../../utils/Data';
// import { clearLocalStorage } from '../../services/utils/helper';
// import { navigateTo } from '../../utils/history';
// import { LOGIN_ROUTE } from '../../utils/routesNavigationConstants';

interface FetchFlightReportScreenDataActionType {
  type?: String;
  payload: { startDate: Date; endDate: Date; filterFlag?: any };
}

interface UpdateFlightReportScreenDataActionType {
  type: String;
  payload: any;
}

function* updateFlightReportStatus(action: any) {
  yield put(showSpinner());

  yield delay(1000);

  const { isLocked, isLockedOnClientPortal, id } = action.payload;
  console.log(id, 'idxFlightReport');
  try {
    const fetchApi = (): any => {
      if (isLockedOnClientPortal) {
        return endpoints.FLIGHT_REPORT_LOCK;
      }
      if (isLocked) {
        return endpoints.FLIGHT_REPORT_UNLOCK;
      } else {
        return endpoints.FLIGHT_REPORT_WEB_LOCK;
      }
    };

    const invoiceScreenDataResponse = yield api.updateData(
      fetchApi(),
      {},
      { id },
      'POST'
    );

    console.log(invoiceScreenDataResponse, 'invoiceScreenDataResponse');
    if (invoiceScreenDataResponse.success) {
      yield put(updateFlightReportScreenDataSuccess());
      // const {
      //   formValues: { range }
      // } = yield select((state: StoreState) => {
      //   return {
      //     formValues: getFormValues(FLIGHT_REPORT_FILTERS_FORM)(state) || {}
      //   };
      // });

      const data = yield select((state: StoreState) => {
        return state.flightReportScreenData.flightReportData;
      });

      const index = data.findIndex((el: any) => el.id === id);

      data[index] = {
        ...data[index],
        isLocked: !data[index].isLocked
      };

      console.log(data, id, '@@data@@');
      // yield fetchFlightReportScreenData({
      //   payload: { startDate: range.from, endDate: range.to }
      // });
      const {
        formValues: { range }
      } = yield select((state: StoreState) => {
        return {
          formValues: getFormValues(FLIGHT_REPORT_FILTERS_FORM)(state) || {}
        };
      });

      yield put(fetchFlightReportScreenDataSuccess(data));
      yield put(fetchFlightReportScreenDataRequest(range.from, range.to));
      console.log(range.from, range.to, '@range@');
    } else {
      toast.error(invoiceScreenDataResponse.data);
      yield put(updateFlightReportScreenDataFailure());
    }
  } catch (e) {
    yield put(updateFlightReportScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* exportFlightReportScreenData(action: any) {
  yield put(showSpinner());

  yield delay(1000);

  const { formValues, flightReportData } = yield select((state: StoreState) => {
    return {
      formValues: getFormValues(FLIGHT_REPORT_FILTERS_FORM)(state) || {},
      flightReportData: state.flightReportScreenData.flightReportData
    };
  });
  const { from, to } = formValues.range;

  const filteredData = filterFlightReportScreenData(
    flightReportData,
    formValues
  );

  try {
    if (isPresent(filteredData)) {
      const body = filteredData.map((data: {}) => {
        return R.pathOr('', ['id'], data);
      });

      const response = yield call(
        fetchAndDownload,
        `${endpoints.FLIGHT_OPS_EXPORT}?${
          action.payload.withData ? 'withFormFields' : ''
        }`,
        body,
        `Flight Reports [${from.toDateString()} - ${to.toDateString()}]`,
        true,
        'Flight Report'
      );

      if (response.success) {
        yield put(exportFlightReportScreenDataSuccess());
      } else {
        yield put(exportFlightReportScreenDataFailure());
      }
    } else {
      toast.error('No Flight Reports available to download.');
      yield put(exportFlightReportScreenDataFailure());
    }
  } catch (e) {
    yield put(exportFlightReportScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* updateFlightReportScreenData(
  action: UpdateFlightReportScreenDataActionType
) {
  yield put(showSpinner());

  yield delay(1000);

  const { updateValues, isCustomerFlightReport } = action.payload;

  try {
    const invoiceScreenDataResponse = yield api.updateData(
      endpoints.FLIGHT_OPS,
      {},
      updateValues
    );

    if (invoiceScreenDataResponse.success) {
      yield put(updateFlightReportScreenDataSuccess());

      if (!isCustomerFlightReport) {
        const {
          formValues: { range }
        } = yield select((state: StoreState) => {
          return {
            formValues: getFormValues(FLIGHT_REPORT_FILTERS_FORM)(state) || {}
          };
        });

        yield put(fetchFlightReportScreenDataRequest(range.from, range.to));
      } else {
        yield put(fetchCustomersScreenDataRequest());
      }
    } else {
      yield put(updateFlightReportScreenDataFailure());
    }
  } catch (e) {
    yield put(updateFlightReportScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* fetchFlightReportScreenData(
  action: FetchFlightReportScreenDataActionType
) {
  // yield delay(300);

  const { startDate, endDate, filterFlag } = action.payload;

  const startDateString = getDateString(new Date(startDate));
  const endDateString = getDateString(new Date(endDate));

  console.log(filterFlag, '()()filterFlag()()');
  // const isDateValid =
  //   startDateString !== 'Invalid date' || endDateString !== 'Invalid date';

  console.log(action.payload, 'startDateString');
  try {
    const response = yield api.fetchResponse(
      endpoints.FLIGHT_OPS_RANGE(
        startDateString,
        endDateString,
        true,
        !filterFlag
      )
    );
    console.log(response, '***response***');

    // if (
    //   response.success === false ||
    //   response.data === 'Unauthorized' ||
    //   response.data === 'Access Denied'
    // ) {
    //   clearLocalStorage();
    //   navigateTo(LOGIN_ROUTE);
    //   window.location.reload();
    // }

    if (response.success) {
      const { data } = response.data;
      console.log(response, 'response');
      // yield put(fetchFlightReportScreenDataSuccess(response.data.data));
      yield put(fetchFlightReportScreenDataSuccess(data));

      const flightApprovedTypeList = response.data.data.map((types: any) => {
        return {
          id: types?.isApproved ? 'Approved' : 'Not Approved',
          name: types?.isApproved ? 'Approved' : 'Not Approved'
        };
      });
      console.log(flightApprovedTypeList, 'flightApprovedTypeList');
      yield put(saveFlightStatusApprovedFilterSuccess(flightApprovedTypeList));

      // yield put(
      //   fetchBookingsScreenDataRequest(new Date(startDate), new Date(endDate))
      // );
      const copilot = data.map((pilot: any) => {
        return {
          id: pilot.copilot?.id,
          name: pilot.copilot?.firstName + ' ' + pilot.copilot?.lastName
        };
      });

      const copilotTruthyValues = copilot.filter((item: any) => item.id);
      const unq = R.uniq(copilotTruthyValues);
      const sortedValues = unq.sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      yield put(saveCopilotFilterSuccess(sortedValues));

      const aircraftType = data.map((types: any) => {
        return {
          id: types.aircraft?.id,
          name: types.aircraft?.aircraftType
        };
      });
      const aircraftTypeValues = aircraftType.filter((item: any) =>
        item.name.trim()
      );

      // // var uniqueData = aircraftTypeValues.filter((v, i, a) =>{ return a.indexOf(v) === i});

      // // let newLastValues = [];

      // // for (let i = 0; i < aircraftType.length; i++) {
      // //   let aircraftTypeValueData = aircraftType[i].name.trim();
      // //   newLastValues.push(aircraftTypeValueData);
      // // }

      // // let resLastName = Array.from(new Set(newLastValues));

      // // console.log(aircraftTypeValues, 'aircraftTypeValues');

      // // let obj: any = {};
      // // for (let i = 0; i < aircraftTypeValues.length; i++) {
      // //   if (obj[aircraftTypeValues[i].name.trim()] === undefined) {
      // //     obj[aircraftTypeValues[i].id] = aircraftTypeValues[i].name.trim();

      // //     // obj[aircraftTypeValues[i].id] = aircraftTypeValues[i].name.trim();
      // //   }
      // // }
      // // console.log(obj, 'arrNew');

      // // let unique = { ...obj };

      // // let res = {};
      // // console.log(unique, 'asd');
      // // for (let key in unique) {
      // //   console.log(unique[key], 'uniqueName');
      // //   console.log(key, 'uniqueCal');
      // //   if (unique[key].trim() !== undefined) {
      // //     let copyObj = { ...obj };
      // //     console.log(delete unique[key], 'delete');
      // //     delete copyObj[key];
      // //     res[key] = copyObj[key];
      // //     console.log(unique, 'copyObj');
      // //   }
      // // }

      // // console.log(unique, 'unique');

      // // const aircraftunq = R.uniq(aircraftTypeValues);

      // // console.log(aircraftunq, 'aircraftunq');
      let newLastValues = [];
      for (let i = 0; i < aircraftTypeValues.length; i++) {
        let aircraftTypeData = aircraftTypeValues[i].name.trim();
        newLastValues.push(aircraftTypeData);
      }

      let resLastName = Array.from(new Set(newLastValues));

      let userListLastName = resLastName.map((names: any) => {
        return {
          id: names,
          name: names
        };
      });

      yield put(saveAircraftFilterSuccess(userListLastName));

      const flightTypeList = response.data.data.map((types: any) => {
        return {
          id: types?.flightType,
          name: types?.flightType
        };
      });

      const flightTypeValues = flightTypeList.filter((item: any) =>
        item.name.trim()
      );

      let newArr: any[] = [];

      for (let i = 0; i < flightTypeValues.length; i++) {
        if (newArr.indexOf(flightTypeValues[i]?.id) === -1) {
          newArr.push(flightTypeValues[i].id);
        }
      }

      let flightTypeValuesList = newArr.map((names: any) => {
        return {
          id: names,
          name: names
        };
      });

      yield put(saveFlightTypeFilterSuccess(flightTypeValuesList));

      // saveFlightStatusApprovedFilterSuccess

      // const { status } = response.data.metaData;

      // yield put(setFlightReportStatusFilters(status));
    } else {
      yield put(fetchFlightReportScreenDataFailure());
    }
  } catch (e) {
    yield put(fetchFlightReportScreenDataFailure());
  }
}

function* addClientPortalComments(action: any) {
  yield put(showSpinner());
  yield delay(1000);

  try {
    const response = yield api.updateData(
      endpoints.ADD_FLIGHT_PORTAL_COMMENTS,
      {},
      action.payload,
      'POST'
    );
    if (response.success) {
      const {
        formValues: { range }
      } = yield select((state: StoreState) => {
        return {
          formValues: getFormValues(FLIGHT_REPORT_FILTERS_FORM)(state) || {}
        };
      });
      yield put(fetchFlightReportScreenDataRequest(range.from, range.to));
      yield put(postFlightPortalCommentSuccess(response.data));
    } else {
      yield put(postFlightPortalCommentFailure());
    }
  } catch (e) {
    yield put(postFlightPortalCommentFailure());
  }
  yield put(hideSpinner());
}

function* updateFlightReportApprover(action: any) {
  yield put(showSpinner());
  const { id } = action.payload;
  try {
    const response = yield api.updateData(
      endpoints.UPDATE_FLIGHT_REPORT_APPROVE_STATUS,
      {},
      { flight_report_id: id },
      'POST'
    );
    if (response.success) {
      toast.success('Approved successfully');
      const flightReportData = yield select((state: StoreState) => {
        return state.flightReportScreenData.flightReportData;
      });

      const index = flightReportData.findIndex((el: any) => el.id === id);

      flightReportData[index] = {
        ...flightReportData[index],
        isApproved: !flightReportData[index].isApproved
      };
      console.log(flightReportData[index], '@@FlightReport@@');
      // const {
      //   formValues: { range }
      // } = yield select((state: StoreState) => {
      //   return {
      //     formValues: getFormValues(FLIGHT_REPORT_FILTERS_FORM)(state) || {}
      //   };
      // });
      yield put(fetchFlightReportScreenDataSuccess(flightReportData));
      // yield put(fetchFlightReportScreenDataRequest(range.from, range.to));
    } else {
      toast.error(response.data || 'Something went wrong');
    }
  } catch (e) {
    toast.error(e.error);
  }
  yield put(hideSpinner());
}

export default [
  takeLatest(
    types.FETCH_FLIGHT_REPORT_SCREEN_DATA_REQUEST,
    fetchFlightReportScreenData
  ),
  takeLatest(
    types.UPDATE_FLIGHT_REPORT_SCREEN_DATA_REQUEST,
    updateFlightReportScreenData
  ),
  takeLatest(
    types.EXPORT_FLIGHT_REPORT_SCREEN_DATA_REQUEST,
    exportFlightReportScreenData
  ),
  takeLatest(
    types.UPDATE_FLIGHT_REPORT_STATUS_REQUEST,
    updateFlightReportStatus
  ),
  takeLatest('ADD_FLIGHT_PORTAL_COMMENTS_REQUEST', addClientPortalComments),
  takeLatest('UPDATE_FLIGHT_REPORT_APPROVE_STATUS', updateFlightReportApprover)
];
