import { useMemo, useEffect, useCallback } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, initialize } from 'redux-form';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  fetchUsersScreenDataRequest,
  fetchUsersScreenTypesRequest,
  addNewUserRequest,
  updateUserRequest,
  userStatusChangeRequest
} from '../../redux/actions/usersScreenData';
import StoreState from '../../redux/utils/interfaces';
import {
  USERS_ADD_EDIT_FORM,
  USERS_FILTERS_FORM
} from '../../utils/reduxFormConstants';
import { isNilOrEmpty, isPresent } from '../../utils/helper';
import {
  USERS_DETAILS_ROUTE,
  ADD_USERS_ROUTE
} from '../../utils/routesNavigationConstants';
import { initialValues } from './helper';
// import { fetchUserInfo } from '../../redux/actions/userInfo';

interface CompanyProps {
  id: number;
  name: string;
}
interface UserTypeProps {
  company: CompanyProps;
  id: number;
  name: string;
}

export const useUsersHook = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { userId }: any = useParams();

  const isUserDetailsRouteMatch = isPresent(useRouteMatch(USERS_DETAILS_ROUTE));
  const isAddUserRouteMatch = isPresent(useRouteMatch(ADD_USERS_ROUTE));

  const goto = useCallback((route: string) => history.push(route), [history]);

  const {
    usersData,
    userTypes,
    isUsersDataLoading,
    formValues,
    userInformation,
    initialFormValues
  } = useSelector((state: StoreState) => {
    return {
      usersData: state.usersScreenData.usersData,
      userTypes: state.usersScreenData.userTypes,
      isUsersDataLoading:
        state.usersScreenData.isLoading || state.usersScreenData.isLoadingTypes,
      formValues: getFormValues(USERS_ADD_EDIT_FORM)(state),
      initialFormValues: R.pathOr(
        [],
        ['form', USERS_ADD_EDIT_FORM, 'initial'],
        state
      ),
      userInformation: state.userInformation
    };
  });
  console.log(usersData, 'usersData');
  const showLicenseField = [1, 2, 5, 6, 7, 28, 29].includes(
    R.pathOr(-1, ['userType'], formValues)
  );

  const currentUserData = useMemo(
    () => R.find(R.propEq('id', Number(userId)))(usersData) || {},
    [usersData, userId]
  );

  useEffect(() => {
    dispatch(fetchUsersScreenDataRequest());
    dispatch(fetchUsersScreenTypesRequest());
    dispatch(initialize(USERS_FILTERS_FORM, initialValues));
  }, [dispatch]);

  const getUserFormValues = useCallback(() => {
    const employeeId = R.pathOr('', ['employeeId'], formValues);
    const firstName = R.pathOr('', ['firstName'], formValues);
    const lastName = R.pathOr('', ['lastName'], formValues);
    const bushpay = R.pathOr(false, ['bushpay'], formValues);

    const userTypeId = R.pathOr(-1, ['userType'], formValues);
    const license_1 = R.pathOr('', ['license1'], formValues);
    const supervisorId = R.pathOr(-1, ['supervisor'], formValues);
    const isReviewingSupervisor = R.pathOr(
      false,
      ['isReviewingSupervisor'],
      formValues
    );

    const username = R.pathOr('', ['username'], formValues);
    const phone = R.pathOr('', ['phone'], formValues);
    const language = R.pathOr('', ['language'], formValues);
    const isApprovingSupervisor = R.pathOr(
      false,
      ['isApprovingSupervisor'],
      formValues
    );
    const isContractor = R.pathOr(false, ['isContractor'], formValues);
    const isLogbookMobileUser = R.pathOr(
      false,
      ['isLogbookMobileUser'],
      formValues
    );
    const isBillingWebpageAccess = R.pathOr(
      false,
      ['isBillingWebpageAccess'],
      formValues
    );

    const isUnlockAccess = R.pathOr(false, ['isUnlockAccess'], formValues);

    const isViewAdminAccess = R.pathOr(
      false,
      ['isViewAdminAccess'],
      formValues
    );
    const isApprover = R.pathOr(false, ['isApprover'], formValues);

    const isWeeklyWebpageAccess = R.pathOr(
      false,
      ['isWeeklyWebpageAccess'],
      formValues
    );
    // const isUnLockable = R.pathOr(
    const isJobsWebpageAccess = R.pathOr(
      false,
      ['isJobsWebpageAccess'],
      formValues
    );

    const isBookingWebpageAccess = R.pathOr(
      false,
      ['isBookingWebpageAccess'],
      formValues
    );
    const isFuelWebpageAccess = R.pathOr(
      false,
      ['isFuelWebpageAccess'],
      formValues
    );
    const isEmployeesWebpageAccess = R.pathOr(
      false,
      ['isEmployeesWebpageAccess'],
      formValues
    );
    const isAircraftWebpageAccess = R.pathOr(
      false,
      ['isAircraftWebpageAccess'],
      formValues
    );
    const isCustomersWebpageAccess = R.pathOr(
      false,
      ['isCustomersWebpageAccess'],
      formValues
    );
    const isFlightreportWebpageAccess = R.pathOr(
      false,
      ['isFlightreportWebpageAccess'],
      formValues
    );
    const isClientWebpageAccess = R.pathOr(
      false,
      ['isClientWebpageAccess'],
      formValues
    );
    const isLogbookWebpageUser = R.pathOr(
      false,
      ['isLogbookWebpageUser'],
      formValues
    );
    const notes = R.pathOr('', ['notes'], formValues);

    const userType = {};
    if (userTypeId !== -1) userType['id'] = userTypeId;
    const supervisor = {};
    if (supervisorId !== -1) supervisor['id'] = supervisorId;

    return {
      employeeId,
      firstName,
      lastName,
      bushpay,
      supervisorId,
      userType,
      ...(showLicenseField && { license_1 }),
      supervisor,
      isReviewingSupervisor,
      isUnlockAccess,
      username,
      phone,
      language,
      isViewAdminAccess,
      isApprover,
      isApprovingSupervisor,
      isBillingWebpageAccess,
      isClientWebpageAccess,
      isJobsWebpageAccess,
      isBookingWebpageAccess,
      isFuelWebpageAccess,
      isAircraftWebpageAccess,
      isCustomersWebpageAccess,
      isEmployeesWebpageAccess,
      isFlightreportWebpageAccess,
      isWeeklyWebpageAccess,
      isContractor,
      isLogbookWebpageUser,
      isLogbookMobileUser,
      company: {
        id: userInformation.companyId
      },

      notes
    };
  }, [formValues, userInformation.companyId, showLicenseField]);

  const saveButtonHandler = useCallback(() => {
    const userValues = getUserFormValues();

    if (isAddUserRouteMatch) {
      dispatch(addNewUserRequest(userValues));
    } else {
      dispatch(
        updateUserRequest(R.pathOr(-1, ['id'], currentUserData), userValues)
      );
      // dispatch(fetchUserInfo());
    }
  }, [dispatch, isAddUserRouteMatch, getUserFormValues, currentUserData]);

  const toggleUserStatusHandler = useCallback(() => {
    const isActive = isNilOrEmpty(
      R.pathOr(null, ['deletedAt'], currentUserData)
    );
    const userId = R.pathOr(-1, ['id'], currentUserData);

    dispatch(userStatusChangeRequest(userId, isActive));
  }, [dispatch, currentUserData]);

  const isSubmitDisabled = useMemo(() => {
    return R.equals(formValues, initialFormValues);
  }, [formValues, initialFormValues]);

  const filteredUserTypes = useMemo(() => {
    return userTypes.filter((userType: UserTypeProps) => {
      return (
        R.pathOr(-1, ['company', 'id'], userType) === userInformation.companyId
      );
    });
  }, [userTypes, userInformation.companyId]);

  const filteredUsers = useMemo(() => {
    return usersData.filter((user: any) => {
      return (
        R.pathOr(-1, ['company', 'id'], user) === userInformation.companyId
      );
    });
  }, [usersData, userInformation.companyId]);

  const supervisors = useMemo(() => {
    const supervisorsLocal = filteredUsers.filter((user: any) => {
      return user.isApprovingSupervisor || user.isReviewingSupervisor;
    });

    return supervisorsLocal.map((user: any) => {
      return {
        ...user,
        name: `${R.pathOr('', ['firstName'], user)} ${R.pathOr(
          '',
          ['lastName'],
          user
        )}`
      };
    });
  }, [filteredUsers]);

  // console.log('supervisors', supervisors);
  return {
    isUsersDataLoading,
    usersData: filteredUsers,
    supervisors,
    currentUserData,
    filteredUserTypes,
    isSubmitDisabled,
    isUserDetailsRouteMatch,
    isAddUserRouteMatch,
    showLicenseField,
    toggleUserStatusHandler,
    saveButtonHandler,
    goto
  };
};
