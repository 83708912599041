import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import ForwardBackwardNav from '../../components/shared/ForwardBackwardNav';
import ReceiptDetails from '../../components/ReceiptDetails';
import { RowData } from '../../redux/utils/interfaces';
import { getReceiptsData } from './helper';
import { useDispatch } from 'react-redux';
import { currentIndex } from '../../redux/actions/detailsScreenData';

interface ReceiptScreenProps {
  currentUserData: RowData;
  currentReceiptIndex: number;
  // setCurrentReceiptIndex: Function;
}

const ReceiptScreen = ({
  currentUserData,
  currentReceiptIndex
}: // setCurrentReceiptIndex
ReceiptScreenProps) => {
  const dispatch = useDispatch();

  const receiptsData = getReceiptsData(currentUserData);
  const currentReceiptData = receiptsData[currentReceiptIndex] || {};

  useEffect(() => {
    if (receiptsData?.length < currentReceiptIndex) {
      dispatch(currentIndex(currentReceiptIndex));
    }
  }, [receiptsData?.length, currentReceiptIndex, dispatch]);

  const handlePageChange = (changeIndex: number) =>
    dispatch(currentIndex(changeIndex));

  if (receiptsData.length <= 0) {
    return (
      <Typography
        variant="h4"
        gutterBottom
        className="no-receipts-available-text">
        No Receipt Data Available
      </Typography>
    );
  }

  return (
    <React.Fragment>
      <ForwardBackwardNav
        currentIndex={currentReceiptIndex}
        total={receiptsData?.length}
        handlePageChange={handlePageChange}
      />
      <Box py={2.5}>
        <ReceiptDetails
          currentReceiptIndex={currentReceiptIndex}
          currentReceiptData={currentReceiptData}
        />
      </Box>
    </React.Fragment>
  );
};

export default ReceiptScreen;
