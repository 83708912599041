// import React, { Fragment } from 'react';
// import { reduxForm } from 'redux-form';
// import * as R from 'ramda';
// import { Helmet } from 'react-helmet';
// import { Box } from '@material-ui/core';
// import HeaderContainer from '../../containers/HeaderContainer';
// import FlightReportPDFRender from './FlightReportPDFRender';
// import FlightReportSecondaryHeader from '../../components/FlightReportSecondaryHeader';
// import FlightReportTable from '../../components/FlightReportTable';
// import SetStatus from './SetStatus';
// import { FLIGHT_REPORT_NAV_KEY } from '../../utils/routesNavigationConstants';
// import { FLIGHT_REPORT_FILTERS_FORM } from '../../utils/reduxFormConstants';
// import { useFlightReportHook } from './flightReportHooks';
// import SharedSpinner from '../../components/shared/SharedSpinner';
// import './style.scss';
// const FlightReport = () => {
//   const {
//     currentSelectedStatus,
//     range,
//     formValues,
//     isSaveButtonDisabled,
//     flightReportScreenData,
//     currentFlightReportData,
//     isFlightReportPdfRoute,
//     setAllCurrentSelectedStatus,
//     setCurrentSelectedStatus,
//     setRange,
//     onSaveButtonClickHandler,
//     goto
//   } = useFlightReportHook();
//   return (
//     <React.Fragment>
//       <Helmet>
//         <title>Flight Reports</title>
//       </Helmet>
//       <HeaderContainer
//         secondaryHeader={
//           <FlightReportSecondaryHeader
//             range={range}
//             isSaveButtonDisabled={isSaveButtonDisabled}
//             isFlightReportPdfRoute={isFlightReportPdfRoute}
//             currentFlightReportData={currentFlightReportData}
//             setRange={setRange}
//             onSaveButtonClickHandler={onSaveButtonClickHandler}
//             goto={goto}
//           />
//         }
//         navKey={FLIGHT_REPORT_NAV_KEY}>
//         <Box py={2.5} className="flight-report-container">
//           {flightReportScreenData.isLoading ? (
//             <SharedSpinner />
//           ) : isFlightReportPdfRoute ? (
//             <FlightReportPDFRender
//               id={R.pathOr(
//                 '',
//                 ['flightReportData', 'id'],
//                 currentFlightReportData
//               )}
//             />
//           ) : (
//             <Fragment>
//               <SetStatus
//                 setAllCurrentSelectedStatus={setAllCurrentSelectedStatus}
//               />
//               <Box py={2.5}>
//                 <FlightReportTable
//                   formValues={formValues}
//                   flightReportScreenData={flightReportScreenData}
//                   currentSelectedStatus={currentSelectedStatus}
//                   setCurrentSelectedStatus={setCurrentSelectedStatus}
//                   goto={goto}
//                 />
//               </Box>
//             </Fragment>
//           )}
//         </Box>
//       </HeaderContainer>
//     </React.Fragment>
//   );
// };
// export default reduxForm({
//   form: FLIGHT_REPORT_FILTERS_FORM
// })(FlightReport);
import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { Helmet } from 'react-helmet';
import { Box, Paper, Typography } from '@material-ui/core';
import HeaderContainer from '../../containers/HeaderContainer';
import FlightReportPDFRender from './FlightReportPDFRender';
import FlightReportSecondaryHeader from '../../components/FlightReportSecondaryHeader';
import FlightReportTable from '../../components/FlightReportTable';
// import SetStatus from './SetStatus';
import { FLIGHT_REPORT_NAV_KEY } from '../../utils/routesNavigationConstants';
import { FLIGHT_REPORT_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { useFlightReportHook } from './flightReportHooks';
import SharedSpinner from '../../components/shared/SharedSpinner';
import './style.scss';
// import CommentsSection from './CommentsSection';
import FlightReportSummaryPDFRender from './FlightReportSummaryPDFRender';
import CommentsSection from './CommentsSection';
const FlightReport = () => {
  const {
    currentSelectedStatus,
    range,
    formValues,
    isSaveButtonDisabled,
    flightReportScreenData,
    flightReportIds,
    // currentFlightReportData,
    isFlightReportPdfRoute,
    // setAllCurrentSelectedStatus,
    setCurrentSelectedStatus,
    handleOnPostComment,
    setRange,
    onSaveButtonClickHandler,
    goto
    // handleOnPostComment
  } = useFlightReportHook();
  const [currentSelectedPdfIndex, setCurrentSelectedPdf] = useState(
    flightReportIds[0]
  );

  const [filterFlag, setFilterFlag] = useState(false);
  const [comments, setComments] = useState([]);
  console.log(flightReportScreenData, 'flightReportScreenDataOO');
  // useEffect(() => {
  //     dispatch(updateFlightFlag(filterFlag))
  // }, [filterFlag])
  const currentFlightReportData = useMemo(() => {
    const { flightReportData, isLoading } = flightReportScreenData;
    if (flightReportData === undefined) {
      return {
        isLoading,
        flightReportData: {}
      };
    }
    return {
      isLoading: isLoading,
      flightReportData: flightReportData.find(
        (flightReport: any) => flightReport?.id === currentSelectedPdfIndex
      )
    };
  }, [flightReportScreenData, currentSelectedPdfIndex]);
  useEffect(() => {
    setRange({ to: '', from: '' });
  }, []);

  // const data = [
  //   {
  //     id: 3,
  //     comment: "other comment's ",
  //     local_date: '2023-12-07T06:56:33.359Z',
  //     utc_date: '2023-12-07T06:56:33.000Z',
  //     user: '16',
  //     last_modified: '2023-12-07T06:56:33.376Z',
  //     flight_report: {
  //       id: '1ea624e0-936c-11ee-8786-857a988f882f'
  //     },
  //     username: 'R a'
  //   }
  // ];
  return (
    <React.Fragment>
      <Helmet>
        <title>Flight Reports</title>
      </Helmet>
      <HeaderContainer
        secondaryHeader={
          <FlightReportSecondaryHeader
            range={range}
            isSaveButtonDisabled={isSaveButtonDisabled}
            isFlightReportPdfRoute={isFlightReportPdfRoute}
            currentFlightReportData={currentFlightReportData}
            setRange={setRange}
            onSaveButtonClickHandler={onSaveButtonClickHandler}
            goto={goto}
            filterFlag={filterFlag}
            setFilterFlag={setFilterFlag}
          />
        }
        navKey={FLIGHT_REPORT_NAV_KEY}>
        <Box py={2.5} className="flight-report-container">
          {flightReportScreenData.isLoading ? (
            <SharedSpinner />
          ) : isFlightReportPdfRoute ? (
            //   <FlightReportPDFRender
            //     id={R.pathOr(
            //       '',
            //       ['flightReportData', 'id'],
            //       currentFlightReportData
            //     )}
            //   />
            // ){flightReportIds.length !== 0 ? (
            <Box>
              <FlightReportPDFRender
                flightReportIds={flightReportIds}
                currentSelectedPdfIndex={currentSelectedPdfIndex}
                setCurrentSelectedPdf={setCurrentSelectedPdf}
              />
              <FlightReportSummaryPDFRender
                flightReportIds={flightReportIds}
                currentSelectedPdfIndex={currentSelectedPdfIndex}
                setCurrentSelectedPdf={setCurrentSelectedPdf}
                handleOnPostComment={handleOnPostComment}
                currentFlightReportData={currentFlightReportData}
                setComments={setComments}
              />
              <Paper elevation={2} className="comments-container">
                <Typography variant="h6">Comments</Typography>
                <CommentsSection
                  data={comments}
                  handleOnPostComment={handleOnPostComment}
                  flightReportIDx={
                    currentFlightReportData?.flightReportData?.id
                  }
                />
              </Paper>
            </Box>
          ) : (
            <Fragment>
              {/* <SetStatus
                setAllCurrentSelectedStatus={setAllCurrentSelectedStatus}
              /> */}
              <Box py={2.5}>
                <FlightReportTable
                  formValues={formValues}
                  flightReportScreenData={flightReportScreenData}
                  currentSelectedStatus={currentSelectedStatus}
                  setCurrentSelectedStatus={setCurrentSelectedStatus}
                  currentSelectedPdfIndex={currentSelectedPdfIndex}
                  setCurrentSelectedPdf={setCurrentSelectedPdf}
                  goto={goto}
                  range={range}
                  filterFlag={filterFlag}
                  setFilterFlag={setFilterFlag}
                  setRange={setRange}
                />
              </Box>
            </Fragment>
          )}
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};
export default reduxForm({
  form: FLIGHT_REPORT_FILTERS_FORM
})(FlightReport);
