// import React, { useMemo, useCallback } from 'react';
// import { useDispatch } from 'react-redux';
// import * as R from 'ramda';
// import { Box } from '@material-ui/core';
// import SaveIcon from '@material-ui/icons/SaveOutlined';
// import SaveAltIcon from '@material-ui/icons/SaveAltOutlined';
// import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
// import UnlockIcon from '@material-ui/icons/LockOpen';
// import LockIcon from '@material-ui/icons/Lock';
// import Breadcrumbs from '../../components/shared/Breadcrumbs';
// import CalendarInputRange from '../CalendarInputRange';
// import Status from '../shared/Status';
// import {
//   exportFlightReportScreenDataRequest,
//   updateFlightReportStatusRequest
// } from '../../redux/actions/flightReportScreenData';
// import CustomSpeedDial from '../shared/CustomSpeedDial';

// import './style.scss';

// interface RangeProps {
//   to: Date;
//   from: Date;
// }

// interface FlightReportSecondaryHeaderProps {
//   range: RangeProps;
//   isSaveButtonDisabled: boolean;
//   isFlightReportPdfRoute: boolean;
//   currentFlightReportData: any;
//   setRange: Function;
//   onSaveButtonClickHandler: Function;
//   goto: Function;
// }

// const FlightReportSecondaryHeader = ({
//   range,
//   isSaveButtonDisabled,
//   isFlightReportPdfRoute,
//   currentFlightReportData,
//   setRange,
//   onSaveButtonClickHandler,
//   goto
// }: FlightReportSecondaryHeaderProps) => {
//   const dispatch = useDispatch();

//   const isLocked = R.pathOr(
//     true,
//     ['flightReportData', 'isLocked'],
//     currentFlightReportData
//   );

//   const isLockedOnClientPortal = R.pathOr(
//     false,
//     ['flightReportData', 'isForClientPortal'],
//     currentFlightReportData
//   );
//   const id = R.pathOr('', ['flightReportData', 'id'], currentFlightReportData);

//   const breadcrumbDataArray = useMemo(() => {
//     const breadcrumbDataArrayLocal = [
//       {
//         id: 'flight_report_breadcrum',
//         label: 'Flight Reports',
//         onClick: isFlightReportPdfRoute ? () => goto('/flightReport') : () => {}
//       }
//     ];

//     if (isFlightReportPdfRoute) {
//       breadcrumbDataArrayLocal.push({
//         id: 'flight_report_pdf_breadcrum',
//         label: currentFlightReportData.isLoading
//           ? 'Loading...'
//           : currentFlightReportData.flightReportData.flightReport,
//         onClick: () => {}
//       });
//     }

//     return breadcrumbDataArrayLocal;
//   }, [goto, isFlightReportPdfRoute, currentFlightReportData]);

//   const handleCloudDownloadButtonClick = useCallback(
//     (withData) => {
//       dispatch(exportFlightReportScreenDataRequest(withData));
//     },
//     [dispatch]
//   );

//   const actions = useMemo(() => {
//     if (isFlightReportPdfRoute) {
//       return [
//         {
//           icon: isLocked ? UnlockIcon : LockIcon,
//           name: isLocked ? 'Unlock' : 'Lock',
//           onClick: () => {
//             dispatch(updateFlightReportStatusRequest(isLocked, false, id));
//           },
//           showToast: false
//         },
//         {
//           icon: isLockedOnClientPortal ? UnlockIcon : LockIcon,
//           name: isLockedOnClientPortal
//             ? 'Unregister from Client Portal'
//             : 'Not Registered on Client Portal',
//           onClick: () => {
//             isLockedOnClientPortal &&
//               dispatch(
//                 updateFlightReportStatusRequest(
//                   false,
//                   isLockedOnClientPortal,
//                   id
//                 )
//               );
//           },
//           showToast: false,
//           disabled: !isLockedOnClientPortal
//         }
//       ];
//     }

//     return [
//       {
//         icon: SaveIcon,
//         name: 'Save',
//         disabled: isSaveButtonDisabled,
//         onClick: onSaveButtonClickHandler,
//         showToast: false
//       },
//       {
//         icon: SaveAltIcon,
//         name: 'Download',
//         onClick: () => handleCloudDownloadButtonClick(false)
//       },
//       {
//         icon: CloudDownloadOutlinedIcon,
//         name: 'Download with Data',
//         onClick: () => handleCloudDownloadButtonClick(true)
//       }
//     ];
//   }, [
//     dispatch,
//     id,
//     isLocked,
//     isSaveButtonDisabled,
//     isFlightReportPdfRoute,
//     onSaveButtonClickHandler,
//     isLockedOnClientPortal,
//     handleCloudDownloadButtonClick
//   ]);

//   return (
//     <React.Fragment>
//       <div className="dropdown-container-flight-report">
//         <Box mr={5}>
//           <Breadcrumbs breadcrumbDataArray={breadcrumbDataArray} />
//         </Box>

//         {isFlightReportPdfRoute ? (
//           <Box mx={2}>
//             <Status
//               status={R.pathOr(
//                 '',
//                 ['flightReportData', 'status', 'name'],
//                 currentFlightReportData
//               )}
//             />
//           </Box>
//         ) : (
//           <CalendarInputRange
//             range={range}
//             setRange={setRange}
//             showOffFilter={false}
//           />
//         )}
//       </div>

//       <CustomSpeedDial actions={actions} />
//     </React.Fragment>
//   );
// };

// export default FlightReportSecondaryHeader;

import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { Box, Chip } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import SaveAltIcon from '@material-ui/icons/SaveAltOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
// import UnlockIcon from '@material-ui/icons/LockOpen';
// import LockIcon from '@material-ui/icons/Lock';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
// import CalendarInputRangeFilghtReport from '../CalendarInputRangeFilghtReport';
// import Status from '../shared/Status';
import {
  FlightReportApproveStatusRequest,
  exportFlightReportScreenDataRequest,
  updateFlightReportStatusRequest
} from '../../redux/actions/flightReportScreenData';
import CustomSpeedDial from '../shared/CustomSpeedDial';
import UnlockIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';

import './style.scss';
import ForwardToInboxOutlinedIcon from '@material-ui/icons/EmailOutlined';
// import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import DoneIcon from '@material-ui/icons/Done';
import { numberOrEmpty } from '../../utils/helper';
import { sendClientPortalFlightReportRequest } from '../../redux/actions/clientPortalScreenData';
import CalendarInputRangeFlightReport from '../CalendarInputRangeFilghtReport';

// interface RangeProps {
//   to: Date;
//   from: Date;
// }

// interface FlightReportSecondaryHeaderProps {
//   range: RangeProps;
//   isSaveButtonDisabled: boolean;
//   isFlightReportPdfRoute: boolean;
//   currentFlightReportData: any;
//   setRange: Function;
//   onSaveButtonClickHandler: Function;
//   goto: Function;
//   setFilterFlag: Function;
//   filterFlag: boolean;
// }

const FlightReportSecondaryHeader = ({
  range,
  isSaveButtonDisabled,
  isFlightReportPdfRoute,
  currentFlightReportData,
  setRange,
  onSaveButtonClickHandler,
  goto,
  setFilterFlag,
  filterFlag
}: any) => {
  const dispatch = useDispatch();

  const isApproved = R.pathOr(
    true,
    ['flightReportData', 'isApproved'],
    currentFlightReportData
  );

  const { userInformation } = useSelector((state: any) => {
    return {
      userInformation: state.userInformation
    };
  });

  const isLocked = R.pathOr(
    true,
    ['flightReportData', 'isLocked'],
    currentFlightReportData
  );

  console.log(currentFlightReportData, 'currentFlightReportData');
  // const isForClientPortal = R.pathOr(
  //   true,
  //   ['flightReportData', 'isForClientPortal'],
  //   currentFlightReportData
  // );
  // const isLockedOnClientPortal = R.pathOr(
  //   false,
  //   ['flightReportData', 'isForClientPortal'],
  //   currentFlightReportData
  // );
  const id = R.pathOr('', ['flightReportData', 'id'], currentFlightReportData);

  const breadcrumbDataArray = useMemo(() => {
    const breadcrumbDataArrayLocal = [
      {
        id: 'flight_report_breadcrum',
        label: 'Flight Reports',
        onClick: isFlightReportPdfRoute ? () => goto('/flightReport') : () => {}
      }
    ];

    if (isFlightReportPdfRoute) {
      breadcrumbDataArrayLocal.push({
        id: 'flight_report_pdf_breadcrum',
        label: currentFlightReportData?.isLoading
          ? 'Loading...'
          : currentFlightReportData?.flightReportData?.flightReport,
        onClick: () => {}
      });
    }

    return breadcrumbDataArrayLocal;
  }, [goto, isFlightReportPdfRoute, currentFlightReportData]);

  const handleCloudDownloadButtonClick = useCallback(
    (withData) => {
      dispatch(exportFlightReportScreenDataRequest(withData));
    },
    [dispatch]
  );

  const actions = useMemo(() => {
    if (isFlightReportPdfRoute) {
      return [
        userInformation?.isApprover
          ? {
              icon: currentFlightReportData?.flightReportData.isApproved
                ? 'DoneIcon'
                : DoneIcon,
              name: currentFlightReportData?.flightReportData ? '' : '',
              disabled: currentFlightReportData?.flightReportData?.isApproved,
              disabledMsg: 'Approved',
              onClick: () => {
                dispatch(FlightReportApproveStatusRequest(id));
              },
              showToast: false
            }
          : {
              icon: '' ? 'DoneIcon' : 'DoneIcon',
              name: 'currentFlightReportData?.flightReportData' ? '' : '',
              disabled: currentFlightReportData?.flightReportData?.isApproved,
              disabledMsg: 'Approved',
              onClick: () => {},
              showToast: false
            },

        userInformation.isUnlockAccess
          ? {
              icon: isLocked ? UnlockIcon : LockIcon,
              name: isLocked ? 'Unlock' : 'Lock',
              onClick: () => {
                dispatch(updateFlightReportStatusRequest(isLocked, false, id));
              },
              showToast: false
            }
          : {
              icon: '' ? 'Grant access' : 'LockIcon',
              name: 'isLocked' ? 'Grant access UnLock' : 'Lock',
              onClick: () => {},
              showToast: false,
              disabled: !userInformation?.isUnlockAccess
            },

        {
          icon: ForwardToInboxOutlinedIcon,
          name: 'Send Flight Report to Email',
          disabled: false,
          onClick: () => {
            dispatch(
              sendClientPortalFlightReportRequest(
                currentFlightReportData?.flightReportData?.id
              )
            );
          },
          showToast: false
        }
      ];
    }

    return [
      {
        icon: SaveIcon,
        name: 'Save',
        disabled: isSaveButtonDisabled,
        onClick: onSaveButtonClickHandler,
        showToast: false
      },
      {
        icon: SaveAltIcon,
        name: 'Download',
        onClick: () => handleCloudDownloadButtonClick(false)
      },
      {
        icon: CloudDownloadOutlinedIcon,
        name: 'Download with Data',
        onClick: () => handleCloudDownloadButtonClick(true)
      }
    ];
  }, [
    dispatch,
    id,
    isApproved,
    currentFlightReportData?.flightReportData?.isApproved,
    isSaveButtonDisabled,
    isFlightReportPdfRoute,
    onSaveButtonClickHandler,
    currentFlightReportData,
    handleCloudDownloadButtonClick
  ]);

  return (
    <React.Fragment>
      <div className="dropdown-container-flight-report">
        <Box mr={5}>
          <Breadcrumbs breadcrumbDataArray={breadcrumbDataArray} />
        </Box>
        {isFlightReportPdfRoute ? (
          <Box mx={4}>
            <span className="hours-text">
              {numberOrEmpty(
                R.pathOr(
                  '',
                  ['flightReportData', 'revenue'],
                  currentFlightReportData
                ),
                1
              )}{' '}
              Hours
            </span>
            <span
              style={{
                margin: '8px'
              }}>
              Approved Status :
              <Chip
                label={
                  currentFlightReportData?.flightReportData?.isApproved
                    ? 'APPROVED'
                    : 'NOT APPROVED'
                }
                style={{
                  color: '#fff',
                  padding: '6px',
                  margin: '5px',
                  background: currentFlightReportData?.flightReportData
                    ?.isApproved
                    ? ' #8edb29'
                    : '#e57676'
                }}
              />
              {/* <Status
                status={
                  currentFlightReportData?.flightReportData
                    ?.flightReportApprovalStatus
                }
              /> */}
            </span>

            <span
              style={{
                margin: '8px'
              }}>
              Mobile Status :
              <Chip
                label={currentFlightReportData?.flightReportData?.flightReportStatus?.toUpperCase()}
                style={{
                  color: '#fff',
                  padding: '6px',
                  margin: '5px',
                  background:
                    currentFlightReportData?.flightReportData?.flightReportStatus?.toUpperCase() ===
                    'READY TO SIGN'
                      ? '#f8a309'
                      : currentFlightReportData?.flightReportData?.flightReportStatus?.toUpperCase() ===
                        'NOT LOCKED'
                      ? '#e57676'
                      : '#8edb29'
                }}
              />
              {/* Mobile Status :
              <Status
                status={
                  currentFlightReportData?.flightReportData?.flightReportStatus
                }
              /> */}
            </span>
          </Box>
        ) : (
          <CalendarInputRangeFlightReport
            range={range}
            setRange={setRange}
            showOffFilter={true}
            filterFlag={filterFlag}
            setFilterFlag={setFilterFlag}
          />
        )}
      </div>

      <CustomSpeedDial actions={actions} />
    </React.Fragment>
  );
};

export default FlightReportSecondaryHeader;
