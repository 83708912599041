import React from 'react';
import './style.css';
const Privacy = () => {
  return (
    <>
      <div
        data-custom-class="body"
        style={{ maxWidth: '700px', margin: '0 auto' }}>
        <div style={{ marginTop: '80px' }}>
          <strong>
            <span style={{ fontSize: 24 }}>
              <span data-custom-class="title">PRIVACY NOTICE</span>
            </span>
          </strong>
        </div>
        <div>
          <br />
        </div>
        <div>
          <div style={{ color: 'rgb(127, 127, 127)' }}>
            <strong>
              <span style={{ fontSize: 16 }}>
                <div data-custom-class="subtitle"></div>
              </span>
            </strong>
          </div>
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: '1.0' }}>
          <div style={{ color: 'rgb(127, 127, 127)' }}>
            <div style={{ color: 'rgb(89, 89, 89)', fontSize: 17 }}>
              This privacy notice for AirOps as describes how and why we might
              collect, store, use, and/or <p className="block-component" />
              share "<strong>process</strong>" your information when you use our{' '}
              <strong>Services</strong>", such as when you:
            </div>
          </div>
          <span style={{ fontSize: 17 }}>
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <span data-custom-class="body_text">
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <p className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 17, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 17, color: 'rgb(89, 89, 89)' }}>
                <div data-custom-class="body_text">
                  Visit our website at{' '}
                  <a
                    href="https://portal.airops.ca"
                    target="_blank"
                    data-custom-class="link">
                    https://portal.airops.ca
                  </a>
                  <p className="block-component" />
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          {/* <span style={{ color: 'rgb(89, 89, 89)' }}>
                            <p className="statement-end-if-in-editor">
                              , or any website of ours that links to this
                              privacy notice
                            </p>
                          </span> */}
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </span>
            </span>
          </li>
        </ul>
        <div>
          <p className="block-component">
            <span style={{ fontSize: 15 }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: 'rgb(127, 127, 127)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <span data-custom-class="body_text">
                        <p className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span data-custom-class="body_text">
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      <p className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            {/* <li style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    Engage with us in other related ways, including any sales,
                    marketing, or events
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: 'rgb(89, 89, 89)' }}>
                              <p className="statement-end-if-in-editor" />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li> */}
          </ul>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span data-custom-class="body_text">
                  <strong>Questions or concerns?&nbsp;</strong>
                  <p className="block-component" />
                  Reading this privacy notice will help you understand your
                  privacy rights and choices. If you have any questions or
                  concerns, please contact us at{' '}
                  <a
                    href="mailto:pierre.marcoux@pballard.com"
                    target="_blank"
                    data-custom-class="link">
                    pierre.marcoux@pballard.com
                  </a>
                  <p className="question"></p>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <strong>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
              </span>
            </strong>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>
                    This summary provides key points from our privacy notice,
                    but you can find out more details about any of these topics
                    by clicking the link following each key point or by using
                    our table of contents below to find the section you are
                    looking for. You can also click&nbsp;
                  </em>
                </strong>
              </span>
            </span>
            <a data-custom-class="link" href="#toc">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>
                    <em>here</em>
                  </strong>
                </span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>&nbsp;to go directly to our table of contents.</em>
                </strong>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>What personal information do we process?</strong>
                <p className="block-component" />
                When we create your account we store your Name, Email, Phone
                version
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  Do we process any sensitive personal information?
                </strong>{' '}
                <p className="block-component" />
                We do not process sensitive personal information.
                {/* <p className="else-block" /> */}
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  Do we receive any information from third parties?
                </strong>{' '}
                <p className="block-component" />
                We do not receive any information from third parties.
                {/* <p className="else-block" /> */}
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do we process your information?</strong>
                <p className="block-component" />
                We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud
                prevention, and to comply with law.
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  In what situations and with which parties do we share personal
                  information?
                </strong>
                <p className="block-component" />
                We may share information in specific situations and with
                specific third parties.
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do we keep your information safe?</strong>
                <p className="statement-end-if-in-editor" /> We have{' '}
                organizationaland technical processes and procedures in place to
                protect your personal information.
              </span>
            </span>
          </div>

          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div id="toc" style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(0, 0, 0)' }}>
                  <strong>
                    <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                  </strong>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#infocollect">
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  1. WHAT INFORMATION DO WE COLLECT?
                  <p className="block-component" />
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#infouse">
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                  <p className="block-component" />
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#legalbases">
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                      INFORMATION?
                      <p className="block-component" />
                    </span>
                  </span>
                  <p className="statement-end-if-in-editor" />
                </span>
              </a>
            </span>
          </div>
          {/* 
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#cookies">
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </span>
              </a>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <p className="statement-end-if-in-editor" />
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <span style={{ color: 'rgb(89, 89, 89)' }}>
                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                          <p className="block-component" />
                        </span>
                      </span>
                      <p className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div> */}
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#intltransfers">
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  4. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                </span>
              </a>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <p className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#inforetain">
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  5. HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
              </a>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <span style={{ color: 'rgb(89, 89, 89)' }}>
                        <p className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#infosafe">
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </span>
              </a>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <p className="statement-end-if-in-editor" />
                      <p className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#infominors">
                {/* <span style={{ color: 'rgb(89, 89, 89)' }}>
                  9. DO WE COLLECT INFORMATION FROM MINORS?
                </span> */}
              </a>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <p className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              {/* <span style={{ color: 'rgb(89, 89, 89)' }}>
                <a data-custom-class="link" href="#privacyrights">
                  10. WHAT ARE YOUR PRIVACY RIGHTS?
                </a>
              </span> */}
            </span>
          </div>
          {/* <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#DNT">
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  7. CONTROLS FOR DO-NOT-TRACK FEATURES
                </span>
              </a>
            </span>
          </div> */}
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#caresidents">
                {/* <span style={{ color: 'rgb(89, 89, 89)' }}>
                  12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span> */}
              </a>
            </span>
            <p className="block-component">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text" />
              </span>
            </p>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#policyupdates">
                <span style={{ color: 'rgb(89, 89, 89)' }}>
                  7. DO WE MAKE UPDATES TO THIS NOTICE?
                  <p className="block-component" />
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <a data-custom-class="link" href="#contact">
              <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                8. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                <p className="block-component" />
              </span>
            </a>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <a data-custom-class="link" href="#request">
              <span style={{ color: 'rgb(89, 89, 89)' }}>
                9. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </span>
            </a>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div id="infocollect" style={{ lineHeight: '1.5' }}>
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                  <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                    <span id="control" style={{ color: 'rgb(0, 0, 0)' }}>
                      <strong>
                        <span data-custom-class="heading_1">
                          1. WHAT INFORMATION DO WE COLLECT?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div id="personalinfo" style={{ lineHeight: '1.5' }}>
            <span
              data-custom-class="heading_2"
              style={{ color: 'rgb(0, 0, 0)' }}>
              <span style={{ fontSize: 15 }}>
                <strong>Personal information you disclose to us</strong>
              </span>
            </span>
          </div>
          <div>
            <div>
              <br />
            </div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                      <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:</em>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                      <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>&nbsp;</em>
                          </strong>
                          <em>
                            We collect personal information that you voluntarily
                            provide to us. To Create your Account{' '}
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          {/* <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  We collect personal information that you voluntarily provide
                  to us. To Create your Account{' '}
                </span>
              </span>
            </span>
          </div> */}

          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  <strong>Personal Information Provided by You.</strong> The
                  personal information we collect is the following:
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <p className="forloop-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <p className="question">Name</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <p className="forloop-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          {/* <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <p className="question">Phone Number</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul> */}
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <p className="forloop-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <p className="question">Email Address</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <p className="forloop-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <p className="question">Phone Version</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <p className="forloop-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul></ul>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <p className="forloop-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>

          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <p className="forloop-component" />
                    </span>
                    <span data-custom-class="body_text">
                      <p className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div id="sensitiveinfo" style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>Sensitive Information.</strong>{' '}
                {/* <p className="block-component" />
                We do not process sensitive information. */}
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  All personal information that you provide to us must be true,
                  complete, and accurate, and you must notify us of any changes
                  to such personal information.
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  <p className="block-component" />
                </span>
                <span data-custom-class="body_text">
                  <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <p className="statement-end-if-in-editor">
                            <p className="block-component" />
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <p className="block-component" />
              </span>
            </span>
          </div>
          <div id="infouse" style={{ lineHeight: '1.5' }}>
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                  <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                    <span id="control" style={{ color: 'rgb(0, 0, 0)' }}>
                      <strong>
                        <span data-custom-class="heading_1">
                          2. HOW DO WE PROCESS YOUR INFORMATION?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <div style={{ lineHeight: '1.5' }}>
              <br />
            </div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                      <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:&nbsp;</em>
                          </strong>
                          <em>
                            We process your information to provide, improve, and
                            administer our Services, communicate with you, for
                            security and fraud prevention, and to comply with
                            law.
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  <strong>We process your personal information</strong>
                  <p className="block-component" />
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <strong>
                      To facilitate account creation and authentication and
                      otherwise manage user accounts.&nbsp;
                    </strong>
                    We may process your information so you can create and log in
                    to your account, as well as keep your account in working
                    order.
                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                      <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: 'rgb(89, 89, 89)' }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                                    <span data-custom-class="body_text">
                                      <p className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  <p className="block-component" />
                </span>
              </span>
            </span>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <p className="block-component" />
                  </span>
                </span>
              </span>
              <div style={{ lineHeight: '1.5' }}>
                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                  <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      <p className="block-component" />
                    </span>
                  </span>
                </span>
                <div style={{ lineHeight: '1.5' }}>
                  <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                      <span data-custom-class="body_text">
                        <p className="block-component" />
                      </span>
                    </span>
                  </span>
                  <div style={{ lineHeight: '1.5' }}>
                    <p className="block-component">
                      <span style={{ fontSize: 15 }} />
                    </p>
                    <div style={{ lineHeight: '1.5' }}>
                      <p className="block-component">
                        <span style={{ fontSize: 15 }} />
                      </p>
                      <div style={{ lineHeight: '1.5' }}>
                        <p className="block-component">
                          <span style={{ fontSize: 15 }} />
                        </p>
                        <div style={{ lineHeight: '1.5' }}>
                          <p className="block-component">
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text" />
                            </span>
                          </p>
                          <p style={{ fontSize: 15, lineHeight: '1.5' }}>
                            <p className="block-component">
                              <span style={{ fontSize: 15 }} />
                            </p>
                          </p>
                          <p style={{ fontSize: 15, lineHeight: '1.5' }}>
                            <p className="block-component">
                              <span style={{ fontSize: 15 }} />
                            </p>
                          </p>
                          <p style={{ fontSize: 15, lineHeight: '1.5' }}>
                            <p className="block-component" />
                          </p>
                          <p style={{ fontSize: 15, lineHeight: '1.5' }}>
                            <p className="block-component" />
                          </p>
                          <div style={{ lineHeight: '1.5' }}>
                            <p className="block-component">
                              <span style={{ fontSize: 15 }} />
                            </p>
                            <div style={{ lineHeight: '1.5' }}>
                              <p className="block-component">
                                <span style={{ fontSize: 15 }} />
                              </p>
                              <div style={{ lineHeight: '1.5' }}>
                                <p className="block-component">
                                  <span style={{ fontSize: 15 }} />
                                </p>
                                <div style={{ lineHeight: '1.5' }}>
                                  <span style={{ fontSize: 15 }}>
                                    <p className="block-component">
                                      <span data-custom-class="body_text" />
                                    </p>
                                  </span>
                                  <div style={{ lineHeight: '1.5' }}>
                                    <p className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </p>
                                    <div style={{ lineHeight: '1.5' }}>
                                      <p className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </p>
                                      <div style={{ lineHeight: '1.5' }}>
                                        <p className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </p>
                                        <div style={{ lineHeight: '1.5' }}>
                                          <p className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </p>
                                          <div style={{ lineHeight: '1.5' }}>
                                            <p className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </p>
                                            <div style={{ lineHeight: '1.5' }}>
                                              <p className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </p>
                                              <div
                                                style={{ lineHeight: '1.5' }}>
                                                <p className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </p>
                                                <div
                                                  style={{ lineHeight: '1.5' }}>
                                                  <p className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}>
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </p>
                                                  <div
                                                    style={{
                                                      lineHeight: '1.5'
                                                    }}>
                                                    <p className="block-component">
                                                      <span
                                                        style={{
                                                          fontSize: 15
                                                        }}>
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </p>
                                                    <div
                                                      style={{
                                                        lineHeight: '1.5'
                                                      }}>
                                                      <p className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}>
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </p>
                                                      <div
                                                        style={{
                                                          lineHeight: '1.5'
                                                        }}>
                                                        <p className="block-component">
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}>
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                        </p>
                                                        <div
                                                          style={{
                                                            lineHeight: '1.5'
                                                          }}>
                                                          <p className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}>
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </p>
                                                          <p className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}>
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </p>
                                                          <p className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}>
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </p>
                                                          <p className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}>
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </p>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: '1.5'
                                                          }}>
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="legalbases"
                                                          style={{
                                                            lineHeight: '1.5'
                                                          }}>
                                                          <strong>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}>
                                                              <span data-custom-class="heading_1">
                                                                3. WHAT LEGAL
                                                                BASES DO WE RELY
                                                                ON TO PROCESS
                                                                YOUR
                                                                INFORMATION?
                                                                <p className="block-component" />
                                                              </span>
                                                            </span>
                                                          </strong>
                                                        </div>

                                                        <div
                                                          style={{
                                                            lineHeight: '1.5'
                                                          }}>
                                                          <em>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}>
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  In
                                                                  Short:&nbsp;
                                                                </strong>
                                                                We only process
                                                                your personal
                                                                information when
                                                                we believe it is
                                                                necessary and we
                                                                have a valid
                                                                legal reason
                                                                (i.e., legal
                                                                basis) to do so
                                                                under applicable
                                                                law, like with
                                                                your consent, to
                                                                comply with
                                                                laws, to provide
                                                                you with
                                                                services to
                                                                enter into or{' '}
                                                                fulfill our
                                                                contractual
                                                                obligations, to
                                                                protect your
                                                                rights, or to{' '}
                                                                fulfill our
                                                                legitimate
                                                                business
                                                                interests. We
                                                                may process your
                                                                information if
                                                                you have given
                                                                us specific
                                                                permission.
                                                              </span>
                                                            </span>
                                                          </em>
                                                        </div>

                                                        {/* <div
                                                          style={{
                                                            lineHeight: '1.5'
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}>
                                                              We may process
                                                              your information
                                                              if you have given
                                                              us specific
                                                              permission
                                                            </span>
                                                          </span>
                                                        </div> */}

                                                        <div
                                                          id="whoshare"
                                                          style={{
                                                            lineHeight: '1.5'
                                                          }}>
                                                          <span
                                                            style={{
                                                              color:
                                                                'rgb(127, 127, 127)'
                                                            }}>
                                                            <span
                                                              style={{
                                                                color:
                                                                  'rgb(89, 89, 89)',
                                                                fontSize: 15
                                                              }}>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    'rgb(89, 89, 89)'
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      'rgb(89, 89, 89)'
                                                                  }}>
                                                                  <span
                                                                    id="control"
                                                                    style={{
                                                                      color:
                                                                        'rgb(0, 0, 0)'
                                                                    }}>
                                                                    <strong></strong>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        {/* <div
                                                          style={{
                                                            lineHeight: '1.5'
                                                          }}>
                                                          <br />
                                                        </div> */}

                                                        <div
                                                          style={{
                                                            lineHeight: '1.5'
                                                          }}>
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}></span>
                                                        </div>

                                                        <div
                                                          style={{
                                                            lineHeight: '1.5'
                                                          }}>
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}>
                                                            <span data-custom-class="body_text">
                                                              <p className="block-component" />
                                                            </span>
                                                          </span>
                                                          <div
                                                            style={{
                                                              lineHeight: '1.5'
                                                            }}>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}>
                                                              <p className="block-component">
                                                                <span data-custom-class="body_text" />
                                                              </p>
                                                            </span>
                                                            <div
                                                              style={{
                                                                lineHeight:
                                                                  '1.5'
                                                              }}>
                                                              <p className="block-component">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}>
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                              </p>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    '1.5'
                                                                }}>
                                                                <p className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}>
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </p>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      '1.5'
                                                                  }}>
                                                                  <p className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}>
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </p>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    {/* <p className="block-component">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}>
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                    </p> */}
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          'rgb(89, 89, 89)',
                                                                        fontSize: 15
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              'rgb(89, 89, 89)'
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(89, 89, 89)'
                                                                              }}>
                                                                              <p className="block-component">
                                                                                <span data-custom-class="heading_1" />
                                                                              </p>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  {/* <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div> */}
                                                                  <div
                                                                    id="cookies"
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          'rgb(127, 127, 127)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            'rgb(89, 89, 89)',
                                                                          fontSize: 15
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              'rgb(89, 89, 89)'
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  'rgb(0, 0, 0)'
                                                                              }}>
                                                                              {/* <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  4.
                                                                                  DO
                                                                                  WE
                                                                                  USE
                                                                                  COOKIES
                                                                                  AND
                                                                                  OTHER
                                                                                  TRACKING
                                                                                  TECHNOLOGIES?
                                                                                </span>
                                                                              </strong> */}
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>

                                                                  {/* <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            &nbsp;We
                                                                            may
                                                                            use
                                                                            cookies
                                                                            and
                                                                            other
                                                                            tracking
                                                                            technologies
                                                                            to
                                                                            collect
                                                                            and
                                                                            store
                                                                            your
                                                                            information.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div> */}
                                                                  {/* <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div> */}
                                                                  {/* <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          We may
                                                                          use
                                                                          cookies
                                                                          and
                                                                          similar
                                                                          tracking
                                                                          technologies
                                                                          (like
                                                                          web
                                                                          beacons
                                                                          and
                                                                          pixels)
                                                                          to
                                                                          access
                                                                          or
                                                                          store
                                                                          information.
                                                                          Specific
                                                                          information
                                                                          about
                                                                          how we
                                                                          use
                                                                          such
                                                                          technologies
                                                                          and
                                                                          how
                                                                          you
                                                                          can
                                                                          refuse
                                                                          certain
                                                                          cookies
                                                                          is set
                                                                          out in
                                                                          our
                                                                          Cookie
                                                                          Notice
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                'rgb(89, 89, 89)',
                                                                              fontSize: 15
                                                                            }}>
                                                                            <span data-custom-class="body_text">
                                                                              <p className="block-component" />
                                                                              .
                                                                            </span>
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                                fontSize: 15
                                                                              }}>
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}>
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      'rgb(89, 89, 89)'
                                                                                  }}>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15
                                                                                    }}>
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(89, 89, 89)'
                                                                                      }}>
                                                                                      <span data-custom-class="body_text">
                                                                                        <p className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                'rgb(89, 89, 89)',
                                                                              fontSize: 15
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                                fontSize: 15
                                                                              }}>
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                  fontSize: 15
                                                                                }}>
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}>
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        'rgb(89, 89, 89)'
                                                                                    }}>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}>
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            'rgb(89, 89, 89)'
                                                                                        }}>
                                                                                        <span data-custom-class="body_text">
                                                                                          <p className="block-component" />
                                                                                        </span>
                                                                                        <p className="block-component">
                                                                                          <span data-custom-class="body_text">
                                                                                            <p className="block-component" />
                                                                                          </span>
                                                                                        </p>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div> */}
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="intltransfers"
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          'rgb(127, 127, 127)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            'rgb(89, 89, 89)',
                                                                          fontSize: 15
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              'rgb(89, 89, 89)'
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  'rgb(0, 0, 0)'
                                                                              }}>
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  4.
                                                                                  IS
                                                                                  YOUR
                                                                                  INFORMATION
                                                                                  TRANSFERRED
                                                                                  INTERNATIONALLY?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            We
                                                                            may
                                                                            transfer,
                                                                            store,
                                                                            and
                                                                            process
                                                                            your
                                                                            information
                                                                            in
                                                                            countries
                                                                            other
                                                                            than
                                                                            your
                                                                            own.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          Our
                                                                          servers
                                                                          are
                                                                          located
                                                                          in the
                                                                          Cloud
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(89, 89, 89)'
                                                                              }}>
                                                                              <span data-custom-class="body_text">
                                                                                <p className="forloop-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)'
                                                                              }}></span>
                                                                          </span>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)'
                                                                              }}></span>
                                                                            &nbsp;
                                                                          </span>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(89, 89, 89)'
                                                                              }}>
                                                                              <span data-custom-class="body_text">
                                                                                <p className="forloop-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)'
                                                                              }}></span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>

                                                                  <div
                                                                    id="inforetain"
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          'rgb(127, 127, 127)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            'rgb(89, 89, 89)',
                                                                          fontSize: 15
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              'rgb(89, 89, 89)'
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  'rgb(0, 0, 0)'
                                                                              }}>
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  5.
                                                                                  HOW
                                                                                  LONG
                                                                                  DO
                                                                                  WE
                                                                                  KEEP
                                                                                  YOUR
                                                                                  INFORMATION?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            We
                                                                            keep
                                                                            your
                                                                            information
                                                                            for
                                                                            as
                                                                            long
                                                                            as
                                                                            necessary
                                                                            to{' '}
                                                                            fulfill
                                                                            the
                                                                            purposes
                                                                            outlined
                                                                            in
                                                                            this
                                                                            privacy
                                                                            notice
                                                                            unless
                                                                            otherwise
                                                                            required
                                                                            by
                                                                            law.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}></span>
                                                                    </span>
                                                                  </div>

                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}></span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="infosafe"
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          'rgb(127, 127, 127)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            'rgb(89, 89, 89)',
                                                                          fontSize: 15
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              'rgb(89, 89, 89)'
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  'rgb(0, 0, 0)'
                                                                              }}>
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  6.
                                                                                  HOW
                                                                                  DO
                                                                                  WE
                                                                                  KEEP
                                                                                  YOUR
                                                                                  INFORMATION
                                                                                  SAFE?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            We
                                                                            aim
                                                                            to
                                                                            protect
                                                                            your
                                                                            personal
                                                                            information
                                                                            through
                                                                            a
                                                                            system
                                                                            of
                                                                            organizational{' '}
                                                                            and
                                                                            technical
                                                                            security
                                                                            measures.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          have
                                                                          implemented
                                                                          appropriate
                                                                          and
                                                                          reasonable
                                                                          technical
                                                                          and{' '}
                                                                          organizational{' '}
                                                                          security
                                                                          measures
                                                                          designed
                                                                          to
                                                                          protect
                                                                          the
                                                                          security
                                                                          of any
                                                                          personal
                                                                          information
                                                                          we
                                                                          process.
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <p className="statement-end-if-in-editor" />
                                                                          </span>
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                'rgb(89, 89, 89)',
                                                                              fontSize: 15
                                                                            }}>
                                                                            <span data-custom-class="body_text">
                                                                              <p className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>

                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <em>
                                                                            &nbsp;
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(89, 89, 89)'
                                                                              }}>
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <em>
                                                                                    <p className="block-component" />
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>

                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}></span>
                                                                    </span>
                                                                    <a
                                                                      data-custom-class="link"
                                                                      href="#contact">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}></span>
                                                                    </a>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}></span>
                                                                    </span>
                                                                  </div>

                                                                  {/* <div
                                                                    id="DNT"
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          'rgb(127, 127, 127)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            'rgb(89, 89, 89)',
                                                                          fontSize: 15
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              'rgb(89, 89, 89)'
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  'rgb(0, 0, 0)'
                                                                              }}>
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  7.
                                                                                  CONTROLS
                                                                                  FOR
                                                                                  DO-NOT-TRACK
                                                                                  FEATURES
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div> */}
                                                                  {/* <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          Most
                                                                          web
                                                                          browsers
                                                                          and
                                                                          some
                                                                          mobile
                                                                          operating
                                                                          systems
                                                                          and
                                                                          mobile
                                                                          applications
                                                                          include
                                                                          a
                                                                          Do-Not-Track
                                                                          ("DNT")
                                                                          feature
                                                                          or
                                                                          setting
                                                                          you
                                                                          can
                                                                          activate
                                                                          to
                                                                          signal
                                                                          your
                                                                          privacy
                                                                          preference
                                                                          not to
                                                                          have
                                                                          data
                                                                          about
                                                                          your
                                                                          online
                                                                          browsing
                                                                          activities
                                                                          monitored
                                                                          and
                                                                          collected.
                                                                          At
                                                                          this
                                                                          stage
                                                                          no
                                                                          uniform
                                                                          technology
                                                                          standard
                                                                          for{' '}
                                                                          <p className="block-component" />
                                                                          recognizing
                                                                          <p className="statement-end-if-in-editor" />{' '}
                                                                          and
                                                                          implementing
                                                                          DNT
                                                                          signals
                                                                          has
                                                                          been{' '}
                                                                          <p className="block-component" />
                                                                          finalized
                                                                          <p className="statement-end-if-in-editor" />
                                                                          . As
                                                                          such,
                                                                          we do
                                                                          not
                                                                          currently
                                                                          respond
                                                                          to DNT
                                                                          browser
                                                                          signals
                                                                          or any
                                                                          other
                                                                          mechanism
                                                                          that
                                                                          automatically
                                                                          communicates
                                                                          your
                                                                          choice
                                                                          not to
                                                                          be
                                                                          tracked
                                                                          online.
                                                                          If a
                                                                          standard
                                                                          for
                                                                          online
                                                                          tracking
                                                                          is
                                                                          adopted
                                                                          that
                                                                          we
                                                                          must
                                                                          follow
                                                                          in the
                                                                          future,
                                                                          we
                                                                          will
                                                                          inform
                                                                          you
                                                                          about
                                                                          that
                                                                          practice
                                                                          in a
                                                                          revised
                                                                          version
                                                                          of
                                                                          this
                                                                          privacy
                                                                          notice.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div> */}

                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}></span>
                                                                    </span>
                                                                  </div>

                                                                  <div
                                                                    id="policyupdates"
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          'rgb(127, 127, 127)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            'rgb(89, 89, 89)',
                                                                          fontSize: 15
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              'rgb(89, 89, 89)'
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  'rgb(0, 0, 0)'
                                                                              }}>
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  7.
                                                                                  DO
                                                                                  WE
                                                                                  MAKE
                                                                                  UPDATES
                                                                                  TO
                                                                                  THIS
                                                                                  NOTICE?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <em>
                                                                            <strong>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </strong>
                                                                            Yes,
                                                                            we
                                                                            will
                                                                            update
                                                                            this
                                                                            notice
                                                                            as
                                                                            necessary
                                                                            to
                                                                            stay
                                                                            compliant
                                                                            with
                                                                            relevant
                                                                            laws.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          We may
                                                                          update
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          from
                                                                          time
                                                                          to
                                                                          time.
                                                                          The
                                                                          updated
                                                                          version
                                                                          will
                                                                          be
                                                                          indicated
                                                                          by an
                                                                          updated{' '}
                                                                          "Revised".
                                                                          <p className="statement-end-if-in-editor" />{' '}
                                                                          Date
                                                                          and
                                                                          the
                                                                          updated
                                                                          version
                                                                          will
                                                                          be
                                                                          effective
                                                                          as
                                                                          soon
                                                                          as it
                                                                          is
                                                                          accessible.
                                                                          If we
                                                                          make
                                                                          material
                                                                          changes
                                                                          to
                                                                          this
                                                                          privacy
                                                                          notice,
                                                                          we may
                                                                          notify
                                                                          you
                                                                          either
                                                                          by
                                                                          prominently
                                                                          posting
                                                                          a
                                                                          notice
                                                                          of
                                                                          such
                                                                          changes
                                                                          or by
                                                                          directly
                                                                          sending
                                                                          you a
                                                                          notification.
                                                                          We
                                                                          encourage
                                                                          you to
                                                                          review
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          frequently
                                                                          to be
                                                                          informed
                                                                          of how
                                                                          we are
                                                                          protecting
                                                                          your
                                                                          information.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="contact"
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          'rgb(127, 127, 127)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            'rgb(89, 89, 89)',
                                                                          fontSize: 15
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              'rgb(89, 89, 89)'
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  'rgb(0, 0, 0)'
                                                                              }}>
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  8.
                                                                                  HOW
                                                                                  CAN
                                                                                  YOU
                                                                                  CONTACT
                                                                                  US
                                                                                  ABOUT
                                                                                  THIS
                                                                                  NOTICE?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          If you
                                                                          have
                                                                          questions
                                                                          or
                                                                          comments
                                                                          about
                                                                          this
                                                                          notice,
                                                                          you
                                                                          may{' '}
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                'rgb(89, 89, 89)',
                                                                              fontSize: 15
                                                                            }}>
                                                                            <span data-custom-class="body_text">
                                                                              email
                                                                              us
                                                                              at{' '}
                                                                              <a
                                                                                href="mailto:pierre.marcoux@pballard.com"
                                                                                target="_blank"
                                                                                data-custom-class="link">
                                                                                pierre.marcoux@pballard.com
                                                                              </a>
                                                                            </span>
                                                                          </span>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)'
                                                                              }}></span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>

                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(89, 89, 89)'
                                                                              }}>
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)'
                                                                                }}></span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}>
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              'rgb(89, 89, 89)'
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}>
                                                                            <p className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}>
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              'rgb(89, 89, 89)'
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}>
                                                                            <p className="block-component" />
                                                                            <p className="block-component" />
                                                                            <p className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              'rgb(89, 89, 89)'
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}>
                                                                            <p className="statement-end-if-in-editor" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <p className="statement-end-if-in-editor">
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)'
                                                                                }}>
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}>
                                                                                  <span data-custom-class="body_text">
                                                                                    <p className="block-component">
                                                                                      <p className="block-component" />
                                                                                    </p>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </p>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                'rgb(89, 89, 89)',
                                                                              fontSize: 15
                                                                            }}>
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}>
                                                                              <span data-custom-class="body_text">
                                                                                <p className="statement-end-if-in-editor">
                                                                                  <p className="block-component" />
                                                                                </p>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="request"
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          'rgb(127, 127, 127)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            'rgb(89, 89, 89)',
                                                                          fontSize: 15
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              'rgb(89, 89, 89)'
                                                                          }}>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                'rgb(89, 89, 89)'
                                                                            }}>
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  'rgb(0, 0, 0)'
                                                                              }}>
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  9.
                                                                                  HOW
                                                                                  CAN
                                                                                  YOU
                                                                                  REVIEW,
                                                                                  UPDATE,
                                                                                  OR
                                                                                  DELETE
                                                                                  THE
                                                                                  DATA
                                                                                  WE
                                                                                  COLLECT
                                                                                  FROM
                                                                                  YOU?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        '1.5'
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          'rgb(89, 89, 89)'
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            'rgb(89, 89, 89)'
                                                                        }}>
                                                                        <span data-custom-class="body_text">
                                                                          <p className="block-component" />
                                                                          Based
                                                                          on the
                                                                          applicable
                                                                          laws
                                                                          of
                                                                          your
                                                                          country,
                                                                          you
                                                                          may
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          request
                                                                          access
                                                                          to the
                                                                          personal
                                                                          information
                                                                          we
                                                                          collect
                                                                          from
                                                                          you,
                                                                          change
                                                                          that
                                                                          information,
                                                                          or
                                                                          delete
                                                                          it.{' '}
                                                                          <p className="else-block" />
                                                                          To
                                                                          request
                                                                          to
                                                                          review,
                                                                          update,
                                                                          or
                                                                          delete
                                                                          your
                                                                          personal
                                                                          information,
                                                                          please
                                                                          send
                                                                          an
                                                                          email
                                                                          to{' '}
                                                                          <a
                                                                            href="mailto:pierre.marcoux@pballard.com"
                                                                            target="_blank"
                                                                            data-custom-class="link">
                                                                            pierre.marcoux@pballard.com
                                                                          </a>
                                                                        </span>
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              'rgb(48, 48, 241)'
                                                                          }}>
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}>
                                                                              <a
                                                                                data-custom-class="link"
                                                                                href="https://app.termly.io/notify/a360a694-729a-43aa-a7c8-0714d295ee72"
                                                                                rel="noopener noreferrer"
                                                                                target="_blank">
                                                                                here
                                                                              </a>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <p className="block-component">
                                                                          <span data-custom-class="body_text" />
                                                                        </p>
                                                                      </span>
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      .
                                                                    </span>
                                                                  </div>
                                                                  <style
                                                                    dangerouslySetInnerHTML={{
                                                                      __html:
                                                                        '\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    '
                                                                    }}
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Privacy;
