import { put, takeLatest, call, delay } from 'redux-saga/effects';
import * as R from 'ramda';
import types from '../utils/actionTypes';
import { fetchCustomerFilters } from '../utils/sagaHelpers';
import {
  fetchBookingsScreenFiltersSuccess,
  fetchBookingsScreenFiltersFailure
} from '../actions/bookingsScreenFilters';
import { showSpinner, hideSpinner } from '../actions/spinner';
// import { clearLocalStorage } from '../../services/utils/helper';
// import { navigateTo } from '../../utils/history';
// import { LOGIN_ROUTE } from '../../utils/routesNavigationConstants';

function* fetchBookingsScreenFilters() {
  yield put(showSpinner());

  yield delay(1000);

  try {
    const customerFiltersResponse = yield call(fetchCustomerFilters);

    // if (
    //   customerFiltersResponse.success === false ||
    //   customerFiltersResponse.data === 'Unauthorized' ||
    //   customerFiltersResponse.data === 'Access Denied'
    // ) {
    //   clearLocalStorage();
    //   navigateTo(LOGIN_ROUTE);
    //   window.location.reload();
    // }
    yield put(
      fetchBookingsScreenFiltersSuccess(
        R.pathOr([], ['data', 'customerFilters'], customerFiltersResponse)
      )
    );
  } catch (e) {
    yield put(fetchBookingsScreenFiltersFailure());
  }

  yield put(hideSpinner());
}

export default [
  takeLatest(
    types.FETCH_BOOKINGS_SCREEN_FILTERS_REQUEST,
    fetchBookingsScreenFilters
  )
];
