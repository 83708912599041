// import React, { useState, useEffect, useCallback } from 'react';
// import { Typography } from '@material-ui/core';
// import PdfRender from '../../components/PdfRender';
// import api from '../../services/ApiModule';
// import { isNilOrEmpty, isPresent } from '../../utils/helper';

// interface FlightReportPDFRenderProps {
//   id: string;
// }

// const FlightReportPDFRender = ({ id }: FlightReportPDFRenderProps) => {
//   const [pdf, setPdf] = useState('');
//   const [isLoading, setIsLoading] = useState(false);

//   const fetchPdf = useCallback(async () => {
//     if (isPresent(id)) {
//       setIsLoading(true);
//       const response = await api.fetchFlightReportReceipt(id);

//       if (response.success) {
//         if (response.data.noContent) {
//           setPdf('');
//         } else {
//           const url = URL.createObjectURL(response.data);
//           setPdf(url);
//         }
//       }
//       setIsLoading(false);
//     }
//   }, [id]);

//   useEffect(() => {
//     fetchPdf();
//   }, [fetchPdf]);

//   if (isNilOrEmpty(id)) {
//     return (
//       <Typography
//         variant="h4"
//         gutterBottom
//         className="no-receipts-available-text">
//         No Flight Report Available
//       </Typography>
//     );
//   }

//   return <PdfRender pdf={pdf} isLoading={isLoading} />;
// };

// export default FlightReportPDFRender;

import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import PdfViewer from './PdfViewer';
import api from '../../services/ApiModule';
import { isNilOrEmpty } from '../../utils/helper';

// interface FlightReportPDFRenderProps {
//   id: string;
//   flightReportIds: string[];
// }

const FlightReportPDFRender = ({
  id,
  flightReportIds,
  currentSelectedPdfIndex,
  setCurrentSelectedPdf
}: any) => {
  const pdfInit: any = [];
  const [pdf, setPdf] = useState(pdfInit);
  const [isLoading, setIsLoading] = useState(false);

  // import {DATA} from '../../utils/Data'

  const fetchPdf = useCallback(async () => {
    setIsLoading(true);
    // get flightReportId from flightReportIds with currentSelectedPdfIndex
    // const flightReportId = flightReportIds.find((id: string) => id===currentSelectedPdfIndex);
    const response = await api.fetchFlightReportReceipt(
      currentSelectedPdfIndex
    );
    let data: string[] = [];
    if (response.success && !response.data.noContent) {
      const url = URL.createObjectURL(response.data);
      data.push(url);
    }

    setPdf(data);

    setIsLoading(false);
  }, [currentSelectedPdfIndex]);

  useEffect(() => {
    fetchPdf();
  }, [currentSelectedPdfIndex]);

  return isLoading ? (
    <Typography
      variant="h4"
      gutterBottom
      className="no-receipts-available-text">
      Loading...
    </Typography>
  ) : isNilOrEmpty(pdf) ? (
    <Typography
      variant="h4"
      gutterBottom
      className="no-receipts-available-text">
      No Flight Report Available
    </Typography>
  ) : flightReportIds.length !== 0 ? (
    <React.Fragment>
      <PdfViewer
        pdfUrls={pdf}
        flightReportIds={flightReportIds}
        currentSelectedPdfIndex={currentSelectedPdfIndex}
        setCurrentSelectedPdf={setCurrentSelectedPdf}
      />
    </React.Fragment>
  ) : null;
};

export default FlightReportPDFRender;
