// ----------------------------------------- ROUTES -----------------------------------------
export const ROOT_ROUTE = '/';

export const LOGIN_ROUTE = '/login';

export const LOGBOOK_ROUTE = '/logbook';

export const LOGBOOK_DETAILS_ROUTE = '/logbook/details/:id';

export const HOME_ROUTE = '/home';

export const PRIVACY_POLICY = '/privacypolicy';
// SUB ROUTES OF HOME ROUTE START ----->
export const DETAILS_ROUTE = '/details/:userId';
export const RECEIPT_ROUTE = '/details/:userId/receipt';
export const WORK_HOURS_DETAILS = '/details/:userId/workHoursDetails';
// SUB ROUTES OF HOME ROUTE END XXXXXXXX

//WEEKLY_PER_EMPLOYEE_ROUTE FOR RECEIPT_ROUTE and WEEKLY_PER_EMPLOYEE_ROUTE
// export const WEEKLY_PER_DETAILS_ROUTE = '/weklydetails/:userId';
export const WEEKLY_PER_EMPLOYEE_ROUTE = '/WeeklyPerEmployee';

export const WEEKLY_PER_EMPLOYEE_RECEIPT_ROUTE =
  '/WeeklyPerEmployee/:userId/receipt';
export const WEEKLY_PER_EMPLOYEE_WORK_HOURS_DETAILS =
  '/WeeklyPerEmployee/:userId/workHoursDetails';
export const INVOICE_ROUTE = '/invoice';

export const FUEL_EXPENSE_ROUTE = '/fuelExpense';

export const ENGINEER_JOB_REVIEW_ROUTE = '/engineerJobReview';

export const FLIGHT_REPORT_ROUTE = '/flightReport';
export const CLIENT_PORTAL_ROUTE = '/clientportal';

export const HELP_ROUTE = '/help';
// SUB ROUTES OF FLIGHT REPORT ROUTE START ----->
export const FLIGHT_REPORT_PDF_ROUTE = '/flightReport/:reportId';
// SUB ROUTES OF FLIGHT REPORT ROUTE END XXXXXXXX

// SUB ROUTES OF CLIENT PORTALROUTE START ----->
export const CLIENT_PORTAL_FLIGHT_REPORT_PDF_ROUTE =
  '/clientportal/flightreport';
// SUB ROUTES OF CLIENT PORTAL ROUTE END XXXXXXXX

export const CUSTOMERS_ROUTE = '/customers';
// SUB ROUTES OF CUSTOMERS ROUTE START ----->
export const CUSTOMERS_DETAILS_ROUTE = '/customers/details/:customerId';
export const ADD_CUSTOMER_ROUTE = '/customers/add';
export const CUSTOMERS_FLIGHT_REPORTS_ROUTE =
  '/customers/flightReports/:customerId';
// SUB ROUTES OF CUSTOMERS ROUTE END XXXXXXXX

export const USERS_ROUTE = '/users';
// SUB ROUTES OF USERS ROUTE START ----->
export const USERS_DETAILS_ROUTE = '/users/details/:userId';
export const ADD_USERS_ROUTE = '/users/add';
// SUB ROUTES OF USERS ROUTE END XXXXXXXX

export const CLIENT_USERS_ROUTE = '/clientusers';
// SUB ROUTES OF USERS ROUTE START ----->
export const CLIENT_USERS_DETAILS_ROUTE = '/clientusers/details/:userId';
export const ADD_CLIENT_USERS_ROUTE = '/clientusers/add';
// SUB ROUTES OF USERS ROUTE END XXXXXXXX

export const BOOKINGS_ROUTE = '/bookings';
// SUB ROUTES OF BOOKINGS ROUTE START ----->
export const BOOKINGS_DETAILS_ROUTE = '/bookings/details/:bookingsId';
export const ADD_BOOKINGS_ROUTE = '/bookings/add';
// SUB ROUTES OF BOOKINGS ROUTE END XXXXXXXX

// export const SUPERVISORS_ROUTE = '/supervisor';

export const AIRCRAFT_ROUTE = '/aircraft';
// SUB ROUTES OF AIRCRAFT ROUTE START ----->
export const AIRCRAFT_DETAILS_ROUTE = '/aircraft/details/:aircraftId';
export const ADD_AIRCRAFT_ROUTE = '/aircraft/add';
// SUB ROUTES OF AIRCRAFT ROUTE END XXXXXXXX

// ----------------------------------------- NAVIGATION KEYS -----------------------------------------
export const HOME_NAV_KEY = 'home';
export const INVOICE_NAV_KEY = 'invoice';
export const FUEL_EXPENSE_NAV_KEY = 'fuelExpense';
export const ENGINEER_JOB_REVIEW_NAV_KEY = 'engineerJobReview';
export const FLIGHT_REPORT_NAV_KEY = 'flightReport';
export const CLIENT_PORTAL_NAV_KEY = 'clientPortal';
export const CUSTOMERS_NAV_KEY = 'customers';
export const USERS_NAV_KEY = 'users';
export const CLIENT_USERS_NAV_KEY = 'clientUsers';
export const BOOKINGS_NAV_KEY = 'bookings';
export const AIRCRAFT_NAV_KEY = 'aircraft';
export const SUPERVISORS_NAV_KEY = 'supervisors';

export const WEEKLY_PER_NAV_KEY = 'weekly-per-employee';

export const LOGBOOK_NAV_KEY = 'logBook';
export const HELP_NAV_KEY = 'help';

// ----------------------------------------- NAVIGATION OBJECT FOR HEADER -----------------------------------------

export interface OptionsProps {
  gotoRoute: string;
  key: string;
  name: string;
}

export interface NavigationArrayProps {
  key: string;
  name: string;
  options: OptionsProps[];
}
export const initialNavigationArray: NavigationArrayProps[] = [
  {
    key: 'flightOps',
    name: 'Flight Ops',
    options: [
      {
        gotoRoute: HOME_ROUTE,
        key: HOME_NAV_KEY,
        name: 'Weekly'
      }

      // {
      //   gotoRoute: WEEKLY_PER_EMPLOYEE_ROUTE,
      //   key: WEEKLY_PER_NAV_KEY,
      //   name: 'weekly Per employee'
      // }
    ]
  },

  {
    key: 'maintenance',
    name: 'Maintenance',
    options: []
  },
  {
    key: 'accounting',
    name: 'Accounting',
    options: []
  },
  {
    key: 'resources',
    name: 'Resources',
    options: []
  },
  {
    key: 'logbook',
    name: 'LogBooks',
    options: []
  }
];
export const navigationArray: NavigationArrayProps[] = [
  {
    key: 'flightOps',
    name: 'Flight Ops',
    options: [
      {
        gotoRoute: HOME_ROUTE,
        key: HOME_NAV_KEY,
        name: 'Weekly'
      },
      {
        gotoRoute: FLIGHT_REPORT_ROUTE,
        key: FLIGHT_REPORT_NAV_KEY,
        name: 'Flight Report'
      },
      {
        gotoRoute: BOOKINGS_ROUTE,
        key: BOOKINGS_NAV_KEY,
        name: 'Booking'
      },
      {
        gotoRoute: WEEKLY_PER_EMPLOYEE_ROUTE,
        key: WEEKLY_PER_NAV_KEY,
        name: 'WeeklyPerEmployee'
      }
    ]
  },
  {
    key: 'maintenance',
    name: 'Maintenance',
    options: [
      {
        gotoRoute: ENGINEER_JOB_REVIEW_ROUTE,
        key: ENGINEER_JOB_REVIEW_NAV_KEY,
        name: 'Jobs'
      }
    ]
  },
  {
    key: 'accounting',
    name: 'Accounting',
    options: [
      {
        gotoRoute: INVOICE_ROUTE,
        key: INVOICE_NAV_KEY,
        name: 'Billing'
      },
      {
        gotoRoute: FUEL_EXPENSE_ROUTE,
        key: FUEL_EXPENSE_NAV_KEY,
        name: 'Fuel'
      }
    ]
  },
  {
    key: 'resources',
    name: 'Resources',
    options: [
      {
        gotoRoute: CUSTOMERS_ROUTE,
        key: CUSTOMERS_NAV_KEY,
        name: 'Customers'
      },
      {
        gotoRoute: USERS_ROUTE,
        key: USERS_NAV_KEY,
        name: 'Employees'
      },

      {
        gotoRoute: AIRCRAFT_ROUTE,
        key: AIRCRAFT_NAV_KEY,
        name: 'Aircraft'
      },
      // {
      //   gotoRoute: SUPERVISORS_ROUTE,
      //   key: SUPERVISORS_NAV_KEY,
      //   name: 'Supervisor'
      // },
      {
        gotoRoute: CLIENT_USERS_ROUTE,
        key: CLIENT_USERS_NAV_KEY,
        name: 'ClientUsers'
      }
    ]
  },
  {
    key: 'clientUsers',
    name: 'Client Users',
    options: [
      {
        gotoRoute: CLIENT_USERS_ROUTE,
        key: CLIENT_USERS_NAV_KEY,
        name: 'ClientUsers'
      }
    ]
  }

  //! For testing Client portal uncomment this
  // {
  //   key: 'clientPortal',
  //   name: 'Client Portal',
  //   options: [
  //     {
  //       gotoRoute: CLIENT_PORTAL_ROUTE,
  //       key: CLIENT_PORTAL_NAV_KEY,
  //       name: 'ClientPortal'
  //     }
  //   ]
  // },
];
export const clientPortalRoutes = [
  {
    key: 'clientPortal',
    name: 'Client Portal',
    options: [
      {
        gotoRoute: CLIENT_PORTAL_ROUTE,
        key: CLIENT_PORTAL_NAV_KEY,
        name: 'ClientPortal'
      }
    ]
  }
];

// export const navigationArrayForReviewers: NavigationArrayProps[] = [
//   {
//     key: 'clientPortal',
//     name: 'Client Users',
//     options: [
//       {
//         gotoRoute: CLIENT_USERS_ROUTE,
//         key: CLIENT_USERS_NAV_KEY,
//         name: 'ClientUsers'
//       }
//     ]
//   },
//   {
//     key: 'clientUsers',
//     name: 'Client Users',
//     options: [
//       {
//         gotoRoute: CLIENT_USERS_ROUTE,
//         key: CLIENT_USERS_NAV_KEY,
//         name: 'ClientUsers'
//       }
//     ]
//   },

//   //! For testing Client portal uncomment this
//   // {
//   //   key: 'clientPortal',
//   //   name: 'Client Portal',
//   //   options: [
//   //     {
//   //       gotoRoute: CLIENT_PORTAL_ROUTE,
//   //       key: CLIENT_PORTAL_NAV_KEY,
//   //       name: 'ClientPortal'
//   //     }
//   //   ]
//   // },
// ];
// export const clientPortalRoutes = [
//   {
//     key: 'clientPortal',
//     name: 'Client Portal',
//     options: [
//       {
//         gotoRoute: CLIENT_PORTAL_ROUTE,
//         key: CLIENT_PORTAL_NAV_KEY,
//         name: 'ClientPortal'
//       }
//     ]
//   }
// ]

export const navigationArrayForReviewers: NavigationArrayProps[] = [
  {
    key: 'clientPortal',
    name: 'Client Users',
    options: [
      {
        gotoRoute: CLIENT_USERS_ROUTE,
        key: CLIENT_USERS_NAV_KEY,
        name: 'ClientUsers'
      }
    ]
  },
  {
    key: 'logbook',
    name: 'LogBooks',
    options: [
      {
        gotoRoute: LOGBOOK_ROUTE,
        key: LOGBOOK_NAV_KEY,
        name: 'LogBooks'
      }
    ]
  }
];

export const navigationArrayForUsers: any = {
  gotoRoute: HOME_ROUTE,
  key: HOME_NAV_KEY,
  name: 'Weekly'
};

export const navigationArrayForUserss: any = {
  gotoRoute: WEEKLY_PER_EMPLOYEE_ROUTE,
  key: WEEKLY_PER_NAV_KEY,
  name: 'Weekly Per Employee'
};

export const navigationArrayForClientWebpageUser: any = {
  gotoRoute: CLIENT_USERS_ROUTE,
  key: CLIENT_USERS_NAV_KEY,
  name: 'ClientUsers'
};

export const navigationArrayForLogBookAccess: any = {
  gotoRoute: LOGBOOK_ROUTE,
  key: LOGBOOK_NAV_KEY,
  name: 'LogBooks'
};
export const navigationArrayForJobsAccess: any = {
  gotoRoute: ENGINEER_JOB_REVIEW_ROUTE,
  key: ENGINEER_JOB_REVIEW_NAV_KEY,
  name: 'Jobs'
};
export const navigationArrayForEmployeeAccess: any = {
  gotoRoute: USERS_ROUTE,
  key: USERS_NAV_KEY,
  name: 'Employees'
};
// gotoRoute: SUPERVISORS_ROUTE,
// key: SUPERVISORS_NAV_KEY,
// name: 'Supervisor'
// export const navigationArrayForSupervisorsAccess: any = {
//   gotoRoute: SUPERVISORS_ROUTE,
//   key: SUPERVISORS_NAV_KEY,
//   name: 'Supervisor'
// };
export const navigationArrayForAircraftAccess: any = {
  gotoRoute: AIRCRAFT_ROUTE,
  key: AIRCRAFT_NAV_KEY,
  name: 'Aircraft'
};
export const navigationArrayForCustomersAccess: any = {
  gotoRoute: CUSTOMERS_ROUTE,
  key: CUSTOMERS_NAV_KEY,
  name: 'Customers'
};

export const navigationArrayForBillingAccess: any = {
  gotoRoute: INVOICE_ROUTE,
  key: INVOICE_NAV_KEY,
  name: 'Billing'
};
export const navigationArrayForFuelAccess: any = {
  gotoRoute: FUEL_EXPENSE_ROUTE,
  key: FUEL_EXPENSE_NAV_KEY,
  name: 'Fuel'
};
export const navigationArrayForBookingAccess: any = {
  gotoRoute: BOOKINGS_ROUTE,
  key: BOOKINGS_NAV_KEY,
  name: 'Booking'
};

export const navigationArrayForFlightreportAccess: any = {
  gotoRoute: FLIGHT_REPORT_ROUTE,
  key: FLIGHT_REPORT_NAV_KEY,
  name: 'Flight Report'
};
// export const navigationArrayForUsers: NavigationArrayProps[] = [
//   {
//     key: 'flightOps',
//     name: 'Flight Ops',
//     options: [
//       {
//         gotoRoute: HOME_ROUTE,
//         key: HOME_NAV_KEY,
//         name: 'Weekly'
//       }
//     ]
//   },
// ];
// export const navigationArrayForLogBookAccess: NavigationArrayProps =
//   {
//     key: 'logbook',
//     name: 'LogBook',
//     options: [
//       {
//         gotoRoute: LOGBOOK_ROUTE,
//         key: LOGBOOK_NAV_KEY,
//         name: 'LogBooks'
//       }
//     ]
//   }
// export const navigationArrayForJobsAccess: NavigationArrayProps =
//   {
//     key: 'maintenance',
//     name: 'Maintenance',
//     options: [
//       {
//         gotoRoute: ENGINEER_JOB_REVIEW_ROUTE,
//         key: ENGINEER_JOB_REVIEW_NAV_KEY,
//         name: 'Jobs'
//       }
//     ]
//   }
// export const navigationArrayForEmployeeAccess: NavigationArrayProps =
//   {
//     key: 'resources',
//     name: 'Resources',
//     options: [
//       {
//         gotoRoute: USERS_ROUTE,
//         key: USERS_NAV_KEY,
//         name: 'Employees'
//       }
//     ]
//   }
// export const navigationArrayForAircraftAccess: NavigationArrayProps =
//   {
//     key: 'resources',
//     name: 'Resources',
//     options: [
//       {
//         gotoRoute: AIRCRAFT_ROUTE,
//         key: AIRCRAFT_NAV_KEY,
//         name: 'Aircraft'
//       }
//     ]
//   }
// export const navigationArrayForCustomersAccess: NavigationArrayProps =
//   {
//     key: 'resources',
//     name: 'Resources',
//     options: [
//       {
//         gotoRoute: CUSTOMERS_ROUTE,
//         key: CUSTOMERS_NAV_KEY,
//         name: 'Customers'
//       }
//     ]
//   }
// export const navigationArrayForBillingAccess: NavigationArrayProps =
//   {
//     key: 'accounting',
//     name: 'Accounting',
//     options: [
//       {
//         gotoRoute: INVOICE_ROUTE,
//         key: INVOICE_NAV_KEY,
//         name: 'Billing'
//       }
//     ]
//   }
// export const navigationArrayForFuelAccess: NavigationArrayProps =
//   {
//     key: 'accounting',
//     name: 'Accounting',
//     options: [
//       {
//         gotoRoute: FUEL_EXPENSE_ROUTE,
//         key: FUEL_EXPENSE_NAV_KEY,
//         name: 'Fuel'
//       }
//     ]
//   }
// export const navigationArrayForBookingAccess: NavigationArrayProps =
//   {
//     key: 'flightOps',
//     name: 'Flight Ops',
//     options: [
//       {
//         gotoRoute: BOOKINGS_ROUTE,
//         key: BOOKINGS_NAV_KEY,
//         name: 'Booking'
//       }
//     ]
//   }
// export const navigationArrayForFlightreportAccess: NavigationArrayProps =
//   {
//     key: 'flightOps',
//     name: 'Flight Ops',
//     options: [
//       {
//         gotoRoute: FLIGHT_REPORT_ROUTE,
//         key: FLIGHT_REPORT_NAV_KEY,
//         name: 'Flight Report'
//       }
//     ]
//   }

const commonRoutes: string[] = [
  'Login',
  'Landing',
  'Home',
  'privacypolicy',
  'Details',
  'Help',
  'Details',
  'Help',
  'WeeklyPerEmployee'
];

export const routesToRenderForUser: string[] = commonRoutes;

export const routesToRenderForApprover: string[] = [...commonRoutes];

export const routesToRenderForLogBook: string[] = [
  ...routesToRenderForUser,
  'LogBooks',
  'LogBookDetails'
];
