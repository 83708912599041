import { ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: any = {
  isLoading: true,
  id: -1,
  firstName: '',
  lastName: '',
  companyId: -1,
  companyName: '',
  email: '',
  phone: '',
  language: '',
  license1: '',
  license2: '',
  isApprovingSupervisor: false,
  isReviewingSupervisor: false,
  isLogbookMobileUser: false,
  isLogbookWebpageUser: false,
  isJobsWebpageAccess: false,
  isCustomersWebpageAccess: false,
  isEmployeesWebpageAccess: false,
  isAircraftWebpageAccess: false,
  isFuelWebpageAccess: false,
  isFlightreportWebpageAccess: false,
  isBookingWebpageAccess: false,
  isBillingWebpageAccess: false,
  isClientWebpageAccess: false,
  isWeeklyWebpageAccess: false,
  isClientUser: false,
  isUnlockAccess: false,
  isViewAdminAccess: false,
  isApprover: false,
  canReviewUserIds: []
};

const userInformation = (state: any = initialState, action: ActionType) => {
  console.log(action.payload, 'action.payload');
  switch (action.type) {
    case types.FETCH_USER_INFO_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };
    }

    case types.FETCH_USER_INFO_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case types.FETCH_USER_INFO_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }

    default:
      return state;
  }
};

export default userInformation;
