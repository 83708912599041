import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core';
import { useStyles, UsersRow, columns, filterUsersData } from './helper';
import { useSortHook } from '../../hooks/sortHooks';
import SharedTableHeader from '../shared/SharedTableHeader';
import { isNilOrEmpty } from '../../utils/helper';
import StoreState from '../../redux/utils/interfaces';
import { USERS_FILTERS_FORM } from '../../utils/reduxFormConstants';
import {
  DEPARTMENT,
  BUSH_PAY,
  STATUS,
  FIRST_NAME,
  LAST_NAME
} from '../../routes/Users/helper';

import './style.scss';

interface UsersTableProps {
  usersData: UsersRow[];
  userTypes: [] | never[];
  goto: Function;
}

const UsersTable = ({ usersData, userTypes, goto }: UsersTableProps) => {
  const classes = useStyles();

  const { filterFormValues } = useSelector((state: StoreState) => ({
    filterFormValues: getFormValues(USERS_FILTERS_FORM)(state) || {}
  }));
  console.log(filterFormValues, 'filterFormValues');

  const department = R.pathOr(-1, [DEPARTMENT], filterFormValues);
  const bushPay = R.pathOr('all', [BUSH_PAY], filterFormValues);
  const status = R.pathOr('all', [STATUS], filterFormValues);
  const firstName = R.pathOr(-1, [FIRST_NAME], filterFormValues);
  const lastName = R.pathOr(-1, [LAST_NAME], filterFormValues);
  const filteredUsersData = useMemo(() => {
    return filterUsersData(usersData, {
      department,
      firstName,
      bushPay,
      lastName,
      status
    });
  }, [department, firstName, bushPay, status, lastName, usersData]);

  const formatData = useMemo(() => {
    return filteredUsersData.map((row: UsersRow) => {
      const userTypeLabel = R.find(R.propEq('id', row.userType.id))(userTypes);

      return {
        id: row.id,
        employeeId: row.employeeId,
        firstName: row.firstName,
        lastName: row.lastName,
        username: row.username,
        userTypeLabel: R.pathOr('-', ['name'], userTypeLabel),
        bushpayLabel: row.bushpay ? 'Yes' : 'No',
        phone: R.pathOr('-', ['phone'], row),
        license1: R.pathOr('-', ['license1'], row),
        status: isNilOrEmpty(row.deletedAt) ? 'Active' : 'Archived'
      };
    });
  }, [filteredUsersData, userTypes]);

  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    dataArray: formatData,
    columnsData: columns
  });
  {
    console.log('User Detail');
  }
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <SharedTableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedDataArray.map((row: any, index: number) => {
            return (
              <TableRow
                key={index}
                className="users-custom-body-row"
                onClick={() => goto(`/users/details/${row.id}`)}>
                <TableCell component="th" scope="row">
                  {row.employeeId}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.firstName}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.lastName}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.username}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.phone}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.userTypeLabel}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.license1}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.bushpayLabel}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.status}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
