import { DetailsScreenSelectedFields, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';
import { initialFilterList, statusFilterList } from '../../utils/helper';
import { getWeekRange } from '../../utils/helper';

const initialState: DetailsScreenSelectedFields = {
  departmentFilter: initialFilterList[0].id,
  statusFilter: statusFilterList[0].value,
  firstNameFilter: -1,
  lastNameFilter: -1,
  supervisorFilter: -1,
  selectedFirstDate: getWeekRange(new Date()).from
};

const detailsScreenSelectedFields = (
  state: DetailsScreenSelectedFields = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case 'UPDATED_SELECTED_FIRST_DATE':
      return {
        ...state,
        selectedFirstDate: action.payload.value
      };

    case types.SET_DETAILS_SCREEN_SELECTED_FIELDS:
      return {
        ...state,
        departmentFilter: action.payload.departmentFilter,
        statusFilter: action.payload.statusFilter,
        firstNameFilter: -1,
        lastNameFilter: -1,
        supervisorFilter: -1,
        selectedFirstDate: action.payload.selectedFirstDate
      };

    case types.RESET_DETAILS_SCREEN_SELECTED_FIELDS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default detailsScreenSelectedFields;
