import { all } from 'redux-saga/effects';

import userAuth from './userAuth';
// import refreshTokens from './refreshTokens';
import userInfo from './userInfo';
import weeklyScreenData from './weeklyScreenData';
import detailsScreenData from './detailsScreenData';
import invoiceScreenFilters from './invoiceScreenFilters';
import invoiceScreenData from './invoiceScreenData';
import pdfExport from './pdfExport';
import fuelExpenseScreenFilters from './fuelExpenseScreenFilters';
import fuelExpenseScreenData from './fuelExpenseScreenData';
import engineerJobReviewScreenFilters from './engineerJobReviewScreenFilters';
import engineerJobReviewScreenData from './engineerJobReviewScreenData';
import flightReportScreenFilters from './flightReportScreenFilters';
import flightReportScreenData from './flightReportScreenData';
import customersScreenData from './customersScreenData';
import usersScreenData from './usersScreenData';
import clientUsersScreenData from './clientUsersScreenData';
import bookingsScreenData from './bookingsScreenData';
import bookingsScreenFilters from './bookingsScreenFilters';
import aircraftScreenData from './aircraftScreenData';
import logBookScreenData from './logBookScreenData';
import customerFlightReport from './customerFlightReport';
import clientPortalScreenData from './clientPortalScreenData';

export const tasks = [
  ...userAuth,
  // ...refreshTokens,
  ...userInfo,
  ...weeklyScreenData,
  ...detailsScreenData,
  ...invoiceScreenFilters,
  ...invoiceScreenData,
  ...pdfExport,
  ...fuelExpenseScreenFilters,
  ...fuelExpenseScreenData,
  ...engineerJobReviewScreenFilters,
  ...engineerJobReviewScreenData,
  ...flightReportScreenFilters,
  ...flightReportScreenData,
  ...customersScreenData,
  ...usersScreenData,
  ...clientUsersScreenData,
  ...bookingsScreenData,
  ...bookingsScreenFilters,
  ...aircraftScreenData,
  ...logBookScreenData,
  ...customerFlightReport,
  ...clientPortalScreenData
];

const rootSaga = function* rootSaga() {
  yield all(tasks);
};

export default rootSaga;
