import React, { useState, useEffect, useMemo, useCallback } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import ForwardBackwardNav from '../../components/shared/ForwardBackwardNav';
import WorkHoursDetailsTable from '../../components/WorkHoursDetailsTable';
import PilotFlightReportPDFRender from '../../components/PilotFlightReportPDFRender';
import { RowData } from '../../redux/utils/interfaces';
import { isPresent, isNilOrEmpty } from '../../utils/helper';

interface WorkHoursDetailsScreenProps {
  currentUserData: RowData;
}

const WorkHoursDetailsScreen = ({
  currentUserData
}: WorkHoursDetailsScreenProps) => {
  const [currentDayIndex, setCurrentDayIndexIndex] = useState(0);

  const dailies = useMemo(
    () => R.pathOr([], ['dailies'], currentUserData),
    [currentUserData]
  );

  const workHoursDetailsArray = useMemo(
    () =>
      dailies.map((daily: any) => {
        return {
          workHoursDescription: daily.workHoursDescription,
          flightReportIds: daily.flightReportIds,
          date: daily.date
        };
      }),
    [dailies]
  );

  const flatworkHoursDescriptionArray = useMemo(() => {
    const newArr: any = [];

    workHoursDetailsArray.forEach((workHoursDetail: any) => {
      (workHoursDetail.workHoursDescription || []).forEach((element: any) => {
        newArr.push({ ...element, date: workHoursDetail.date });
      });
    });

    return newArr;
  }, [workHoursDetailsArray]);

  useEffect(() => {
    setCurrentDayIndexIndex(0);
  }, [workHoursDetailsArray]);

  const handlePageChange = useCallback(
    (changeIndex: number) => setCurrentDayIndexIndex(changeIndex),
    [setCurrentDayIndexIndex]
  );

  console.log(currentDayIndex, '@currentDayIndex@');
  const workHourDetails = useMemo(
    () => workHoursDetailsArray[currentDayIndex],
    [workHoursDetailsArray, currentDayIndex]
  );

  if (workHoursDetailsArray.length <= 0 || isNilOrEmpty(workHourDetails)) {
    return (
      <Typography
        variant="h4"
        gutterBottom
        className="no-receipts-available-text">
        No Work Hours Details Available
      </Typography>
    );
  }

  if (
    isPresent(currentUserData) &&
    !(
      currentUserData.isEngineer ||
      currentUserData.isApprentice ||
      currentUserData.isPilot ||
      currentUserData.isAdmin
    )
  ) {
    return <Redirect to={`/details/${currentUserData.id}`} />;
  }

  return (
    <React.Fragment>
      {currentUserData.isPilot && (
        <ForwardBackwardNav
          currentIndex={currentDayIndex}
          total={workHoursDetailsArray.length}
          handlePageChange={handlePageChange}
          customLabel={
            workHourDetails &&
            moment(workHourDetails.date).format('dddd MMM DD, YYYY')
          }
        />
      )}

      {currentUserData.isPilot && (
        <Box py={2.5}>
          <PilotFlightReportPDFRender
            flightReportIds={workHourDetails.flightReportIds}
            currentDayIndex={currentDayIndex}
          />
        </Box>
      )}

      {(currentUserData.isEngineer ||
        currentUserData.isApprentice ||
        currentUserData.isAdmin) && (
        <Box py={2.5}>
          <WorkHoursDetailsTable
            currentDayDetails={flatworkHoursDescriptionArray}
            isEngineer={
              currentUserData.isEngineer || currentUserData.isApprentice
            }
          />
        </Box>
      )}
    </React.Fragment>
  );
};

export default WorkHoursDetailsScreen;
