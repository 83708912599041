import { put, takeLatest, call, delay } from 'redux-saga/effects';
import * as R from 'ramda';
import types from '../utils/actionTypes';
import {
  fetchFuelExpenseScreenAircraftFiltersSuccess,
  fetchFuelExpenseScreenAircraftFiltersFailure,
  fetchFuelExpenseScreenBookingFiltersSuccess,
  fetchFuelExpenseScreenBookingFiltersFailure
} from '../actions/fuelExpenseScreenFilters';
import { showSpinner, hideSpinner } from '../actions/spinner';
import {
  fetchBookingFilters,
  fetchAircraftFilters
} from '../utils/sagaHelpers';
import { clearLocalStorage } from '../../services/utils/helper';
import { LOGIN_ROUTE } from '../../utils/routesNavigationConstants';
import { navigateTo } from '../../utils/history';

interface FetchFuelExpenseScreenBookingFiltersActionType {
  type: String;
  payload: { startDate: Date; endDate: Date };
}

function* fetchFuelExpenseScreenBookingFilters(
  action: FetchFuelExpenseScreenBookingFiltersActionType
) {
  yield put(showSpinner());

  yield delay(1000);

  const { startDate, endDate } = action.payload;

  const bookingFiltersResponse = yield call(
    fetchBookingFilters,
    startDate,
    endDate
  );

  if (R.pathOr(false, ['success'], bookingFiltersResponse)) {
    yield put(
      fetchFuelExpenseScreenBookingFiltersSuccess(
        R.pathOr([], ['data', 'bookingFilters'], bookingFiltersResponse)
      )
    );
  } else {
    yield put(fetchFuelExpenseScreenBookingFiltersFailure());
  }

  yield put(hideSpinner());
}

function* fetchFuelExpenseScreenAircraftFilters() {
  yield put(showSpinner());

  yield delay(1000);

  const aircraftFiltersResponse = yield call(fetchAircraftFilters);

  if (
    aircraftFiltersResponse.success === false ||
    aircraftFiltersResponse.data === 'Unauthorized' ||
    aircraftFiltersResponse.data === 'Access Denied'
  ) {
    clearLocalStorage();
    navigateTo(LOGIN_ROUTE);
    window.location.reload();
  }
  if (R.pathOr(false, ['success'], aircraftFiltersResponse)) {
    yield put(
      fetchFuelExpenseScreenAircraftFiltersSuccess(
        R.pathOr([], ['data', 'aircraftFilters'], aircraftFiltersResponse)
      )
    );
  } else {
    yield put(fetchFuelExpenseScreenAircraftFiltersFailure());
  }

  yield put(hideSpinner());
}

export default [
  takeLatest(
    types.FETCH_FUEL_EXPENSE_SCREEN_AIRCRAFT_FILTERS_REQUEST,
    fetchFuelExpenseScreenAircraftFilters
  ),
  takeLatest(
    types.FETCH_FUEL_EXPENSE_SCREEN_BOOKING_FILTERS_REQUEST,
    fetchFuelExpenseScreenBookingFilters
  )
];
