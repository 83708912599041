// import React, { useState, useEffect, useCallback } from 'react';
// import { Typography } from '@material-ui/core';
// import PdfRender from '../../components/PdfRender';
// import api from '../../services/ApiModule';
// import { isNilOrEmpty, isPresent } from '../../utils/helper';

// interface FlightReportPDFRenderProps {
//   id: string;
// }

// const FlightReportPDFRender = ({ id }: FlightReportPDFRenderProps) => {
//   const [pdf, setPdf] = useState('');
//   const [isLoading, setIsLoading] = useState(false);

//   const fetchPdf = useCallback(async () => {
//     if (isPresent(id)) {
//       setIsLoading(true);
//       const response = await api.fetchFlightReportReceipt(id);

//       if (response.success) {
//         if (response.data.noContent) {
//           setPdf('');
//         } else {
//           const url = URL.createObjectURL(response.data);
//           setPdf(url);
//         }
//       }
//       setIsLoading(false);
//     }
//   }, [id]);

//   useEffect(() => {
//     fetchPdf();
//   }, [fetchPdf]);

//   if (isNilOrEmpty(id)) {
//     return (
//       <Typography
//         variant="h4"
//         gutterBottom
//         className="no-receipts-available-text">
//         No Flight Report Available
//       </Typography>
//     );
//   }

//   return <PdfRender pdf={pdf} isLoading={isLoading} />;
// };

// export default FlightReportPDFRender;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Typography } from '@material-ui/core';
// import PdfViewer from './PdfViewer';
// import { useFlightReportHook } from './flightReportHooks';
import api from '../../services/ApiModule';
// import { isNilOrEmpty } from '../../utils/helper';
// import CommentsSection from './CommentsSection';
import { useWindowWidth } from '@react-hook/window-size';
// import Rotate90DegreesCcwIcon from '@material-ui/icons/RotateLeft';
// import Rotate90DegreesCcwIcon2 from '@material-ui/icons/RotateRight';
// import ZoomIn from '@material-ui/icons/ZoomIn';
// import ZoomOut from '@material-ui/icons/ZoomOut';
import RenderImageFuel from './RenderImageFuel';
// import { useFlightReportHook } from './flightReportHooks';
// import CommentsSection from './CommentsSection';

// import FlightReport from '.';
// import PdfWithoutNavViewer from './PdfWithoutNavViewer';

// interface FlightReportPDFRenderProps {
//   id: string;
//   flightReportIds: string[];
// }

const FlightReportSummaryPDFRender = ({
  id,
  flightReportIds,
  currentSelectedPdfIndex,
  setCurrentSelectedPdf,
  currentFlightReportData,
  handleOnPostComment,
  setComments
}: any) => {
  const onlyWidth = useWindowWidth();

  // const { handleOnPostComment } = useFlightReportHook();
  // const { handleOnPostComment } = useFlightReportHook();
  const reportInit: any = [];
  const fuelInit: any = [];
  //   const [pdf, setPdf] = useState(pdfInit);
  const imageHolder: any = [];
  const summaryImage: any = [];
  const [report, setReport] = useState(reportInit);
  const [fuelImage, setImage] = useState(fuelInit);
  // const [fuelImage2, setImage2] = useState(fuelInit);
  // const [fuelImage3, setImage3] = useState(fuelInit);
  // const [fuelImage4, setImage4] = useState(fuelInit);
  // const [rotationDegreeOne, setRotatioDegreeOne] = React.useState(0);
  // const [rotationDegreeTwo, setRotatioDegreeTwo] = React.useState(0);
  // const [rotationDegreeThree, SetRotationDegreeThree] = React.useState(0);
  // const [rotationDegreeFour, SetRotationDegreeFour] = React.useState(0);

  const [isLoading, setIsLoading] = useState(false);

  // import {DATA} from '../../utils/Data'

  // const mainPdfScaleOne = useMemo(() => {
  //   const currentWidth = onlyWidth;
  //   const startingWidth = 1600;
  //   const buffer = 200;
  //   const increaseRatio = 0.1;

  //   let ratio = 1;

  //   if (currentWidth < startingWidth) {
  //     return ratio;
  //   }

  //   for (let i = 1; i <= 20; i++) {
  //     if (currentWidth < startingWidth + i * buffer) {
  //       ratio = 1 + i * increaseRatio;
  //       break;
  //     }
  //   }

  //   return Number(ratio.toFixed(1));
  //   // return 1.0;
  // }, [onlyWidth]);

  // const mainPdfScaleTwo = useMemo(() => {
  //   const currentWidth = onlyWidth;
  //   const startingWidth = 1600;
  //   const buffer = 200;
  //   const increaseRatio = 0.1;

  //   let ratio = 1;

  //   if (currentWidth < startingWidth) {
  //     return ratio;
  //   }

  //   for (let i = 1; i <= 20; i++) {
  //     if (currentWidth < startingWidth + i * buffer) {
  //       ratio = 1 + i * increaseRatio;
  //       break;
  //     }
  //   }

  //   return Number(ratio.toFixed(1));
  //   // return 1.0;
  // }, [onlyWidth]);

  const mainPdfScaleThree = useMemo(() => {
    const currentWidth = onlyWidth;
    const startingWidth = 1600;
    const buffer = 200;
    const increaseRatio = 0.1;

    let ratio = 1;

    if (currentWidth < startingWidth) {
      return ratio;
    }

    for (let i = 1; i <= 20; i++) {
      if (currentWidth < startingWidth + i * buffer) {
        ratio = 1 + i * increaseRatio;
        break;
      }
    }

    return Number(ratio.toFixed(1));
    // return 1.0;
  }, [onlyWidth]);

  // const mainPdfScaleFour = useMemo(() => {
  //   const currentWidth = onlyWidth;
  //   const startingWidth = 1600;
  //   const buffer = 200;
  //   const increaseRatio = 0.1;

  //   let ratio = 1;

  //   if (currentWidth < startingWidth) {
  //     return ratio;
  //   }

  //   for (let i = 1; i <= 20; i++) {
  //     if (currentWidth < startingWidth + i * buffer) {
  //       ratio = 1 + i * increaseRatio;
  //       break;
  //     }
  //   }

  //   return Number(ratio.toFixed(1));
  //   // return 1.0;
  // }, [onlyWidth]);

  // console.log(rotationDegreeOne, '!rotationDegreeOne');
  // const [pdfScaleOne, setPdfScaleOne] = React.useState(mainPdfScaleOne);
  // const [pdfScaleTwo, setPdfScaleTwo] = React.useState(mainPdfScaleTwo);
  const [pdfScaleThree, setPdfScaleThree] = React.useState(mainPdfScaleThree);
  // const [pdfScaleFour, setPdfScaleFour] = React.useState(mainPdfScaleFour);
  // const [fuelIndex, setFuelIndex] = React.useState(0);
  console.log(pdfScaleThree, setPdfScaleThree, 'pdfScaleThree');
  const fetchPdf = useCallback(async () => {
    setIsLoading(true);
    // get flightReportId from flightReportIds with currentSelectedPdfIndex
    // const flightReportId = flightReportIds.find((id: string) => id===currentSelectedPdfIndex);

    // setPdf(data);

    const response2 = await api.fetchFlightReportInfo(currentSelectedPdfIndex);

    if (response2.success) {
      setReport(response2.data.summary_images);
      setImage(response2.data.fuel_images);
      setComments(response2?.data?.flight_report_comments);
      // setImage2(response2.data.fuel_images[1]);
      // setImage3(response2.data.fuel_images[2]);
      console.log(response2.data, 'response2.data');
    }

    console.log(response2.data.fuel_images[1], 'summary_images');
    console.log(fuelImage.summary_images, 'fuelImagecloser');
    setIsLoading(false);
  }, [currentSelectedPdfIndex]);

  useEffect(() => {
    fetchPdf();
  }, [fetchPdf]);

  imageRender();
  summaryImageRender();
  function imageRender() {
    for (let i = 0; i < fuelImage.length; i++) {
      imageHolder.push(
        `https://airops-microservice.herokuapp.com/reporting/image?image_url=${fuelImage[i]}`
      );
    }
  }
  function summaryImageRender() {
    for (let i = 0; i < report.length; i++) {
      summaryImage.push(
        `https://airops-microservice.herokuapp.com/reporting/image?image_url=${report[i]}`
      );
    }
  }

  // report

  // const imageTwo = `https://airops-microservice.herokuapp.com/reporting/image?image_url=${fuelImage}`;
  // const imageThree = `https://airops-microservice.herokuapp.com/reporting/image?image_url=${fuelImage2}`;
  // const imageFour = `https://airops-microservice.herokuapp.com/reporting/image?image_url=${fuelImage3}`;

  // const imageFive = `https://airops-microservice.herokuapp.com/reporting/image?image_url=${fuelImage4}`;
  // console.log(imageFive, '@imageThree@');

  // console.log(pdfScaleOne, '@pdfScaleOne@');

  console.log(summaryImage, '@summaryImage@');
  console.log(imageHolder[0], '@imageHolder@');

  if (isLoading) {
    return (
      <Typography
        variant="h4"
        gutterBottom
        className="no-receipts-available-text">
        Loading Images....
      </Typography>
    );
  }

  if (flightReportIds.length === 0) {
    return (
      <Typography
        variant="h4"
        gutterBottom
        className="no-receipts-available-text">
        No Flight Report Available
      </Typography>
    );
  }

  return (
    <React.Fragment>
      {summaryImage.length > 0 && (
        <div style={{ textAlign: 'center' }}>
          <h4>SUMMARY IMAGE</h4>
          <RenderImageFuel el={summaryImage} />
        </div>
      )}

      {imageHolder.length > 0 && (
        <div style={{ textAlign: 'center' }}>
          <h4>FUEL IMAGE</h4>
          <RenderImageFuel el={imageHolder} />
        </div>
      )}
    </React.Fragment>
  );
};

export default FlightReportSummaryPDFRender;
