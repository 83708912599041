/**
 * Moulds the failure response
 */

import * as R from 'ramda';

//  import generateMessage from './message';
// import { toast } from 'react-toastify';

import { Response } from 'node-fetch';

interface ApiError {
  code: number;
  success: boolean;
  data: { error: Error };
}

const failureResponse = async (res: Response) => {
  const statusObject = {
    status: res.status,
    statusText: res.statusText
  };

  let error: ApiError = {
    code: 0,
    data: { error: { name: '', message: '' } },
    success: false
  };

  error.code = R.pathOr(500, ['status'], res);

  const response = await res.json();

  if (
    (error.code === 401 || error.code === 400) &&
    (response.hasOwnProperty('errorMessage') ||
      response.hasOwnProperty('message'))
  ) {
    error.code = R.pathOr(error.code, ['errorCode'], response);
    error.data.error.message = R.pathOr(
      R.pathOr('Something went wrong!', ['message'], response),
      ['errorMessage'],
      response
    );

    return {
      success: false,
      data: error,
      ...statusObject
    };
  }
  //  error.data.error.message = await generateMessage(error.code);

  return {
    success: false,
    data: response.error.toString(),
    ...statusObject
  };
};

export default failureResponse;
