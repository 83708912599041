import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
// import { parseISO } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
// import moment from 'moment';
// import MomentUtils from '@date-io/moment';
// import moment from 'moment';

interface MaterialUIPickersProps {
  selectedDate: any;
  disabled?: boolean;
  id?: string;
  label?: string;
  format?: string;
  maxDate?: Date;
  minDate?: Date;
  variant?: 'dialog' | 'inline' | 'static';
  disableToolbar?: boolean;
  autoCloseAfterSelection?: boolean;
  keyboardIcon?: any;
  rifmFormatter?: any;
  handleDateChange: (date: any, value?: string | null | undefined) => void;
}

function MaterialUIPickers({
  selectedDate = new Date(),
  disabled,
  id = 'date-picker-dialog',
  label = 'Date',
  format = 'dd MMMM, yyyy',
  maxDate,
  minDate,
  variant = 'dialog',
  disableToolbar = false,
  autoCloseAfterSelection = true,
  keyboardIcon,
  handleDateChange
}: MaterialUIPickersProps) {
  // const selectedValue = new Date(selectedDate);
  // selectedValue.setUTCHours(15);
  // console.log(selectedValue, 'date-picker-dialog');
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        keyboardIcon={keyboardIcon}
        autoOk={autoCloseAfterSelection}
        id={id}
        disableToolbar={disableToolbar}
        variant={variant}
        label={label}
        format={format}
        value={selectedDate}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  );
}

export default MaterialUIPickers;
