import React, { useEffect } from 'react';
import DatePicker from '../shared/DatePicker';
// import FilterListIcon from '@material-ui/icons/FilterList';
import FilterAltIcon from '../../assets/filter.png';
import NoFilterAltIcon from '../../assets/nofilter.png';
import { useSelector, useDispatch } from 'react-redux';
import './styles.scss';
import { change } from 'redux-form';
import { FLIGHT_REPORT_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { fetchFlightReportScreenDataRequest } from '../../redux/actions/flightReportScreenData';
import { fetchFlightReportBookingFiltersRequest } from '../../redux/actions/flightReportScreenFilters';
import {
  FlightReportFromRange,
  FlightReportToRange
} from '../../redux/actions/FlightReportRangeData';
import { UpdateRangeFilter } from '../../redux/actions/FlightReportRangeUpdate';
// import { getMonthStartToCurrentDateRange } from '../../utils/helper';
// import StoreState from '../../redux/utils/interfaces';

interface RangeProps {
  to: Date;
  from: Date;
}
interface CalendarInputRangeProps {
  range: RangeProps | any;
  setRange: Function;
  showOffFilter: boolean;
  filterFlag: boolean;
  setFilterFlag: Function;
}

const CalendarInputRangeFlightReport = ({
  range,
  setRange,
  showOffFilter,
  // filterFlag,
  setFilterFlag
}: CalendarInputRangeProps) => {
  // const { to, from } = range;
  const dispatch = useDispatch();
  // const [isValidRange, toggleValidRange] = React.useState(false);
  // const [isValidRange, toggleValidRange] = React.useState(
  //   to !== '' && from !== ''
  // );

  // const [fromDate, setFromDate] = useState(
  //   getMonthStartToCurrentDateRange().from
  // );
  // const [toDate, setToDate] = useState(getMonthStartToCurrentDateRange().to);

  // const { values } = useSelector((state: any) => {
  //   return {
  //     values: state.form?.flightReportFiltersForm?.values?.range
  //   };
  // });
  // console.log(values.from , '@@values@@');

  const { fromDate, toDate } = useSelector((state: any) => {
    return state.FlightReportRangeData;
  });
  const filterFlag = useSelector((state: any) => {
    return state.FlightReportRangeUpdate;
  });

  console.log(filterFlag, '@@filterFlag@@');
  useEffect(() => {
    dispatch(
      fetchFlightReportScreenDataRequest(
        fromDate,
        toDate,
        filterFlag.rangeFilter
      )
    );
    dispatch(fetchFlightReportBookingFiltersRequest(fromDate, toDate));
  }, [fromDate, toDate, filterFlag.rangeFilter]);

  // console.log(fromDate, to, 'initialRange1');

  useEffect(() => {
    if (filterFlag.rangeFilter) {
      setRange({
        to: '',
        from: ''
      });
      // dispatch(
      //   change(
      //     FLIGHT_REPORT_FILTERS_FORM,
      //     'statusFiltersApproved',
      //     'Not Approved'
      //   )
      // );
      dispatch(UpdateRangeFilter(filterFlag.rangeFilter));
      setFilterFlag(filterFlag.rangeFilter);
    }
  }, []);

  // React.useCallback(() => {
  //   if (to !== '' && from !== '') {
  //     toggleValidRange(true);
  //   } else {
  //     toggleValidRange(false);
  //   }
  // }, [to, from]);

  const handleSelectDateFrom = (date: Date) => {
    // setFromDate(new Date(date));
    dispatch(FlightReportFromRange(new Date(date)));
    const obj = {
      ...range,
      from: new Date(date)
    };
    setRange(obj);
  };

  const handleSelectDateTo = (date: Date) => {
    // setToDate(new Date(date));
    dispatch(FlightReportToRange(new Date(date)));
    setRange({
      ...range,
      to: new Date(date)
    });
  };

  const handelOnFilterToggle = () => {
    if (filterFlag.rangeFilter) {
      setFilterFlag(false);
      dispatch(UpdateRangeFilter(false));
      setRange({
        to: '',
        from: ''
      });
      dispatch(
        change(
          FLIGHT_REPORT_FILTERS_FORM,
          'statusFiltersApproved',
          'Not Approved'
        )
      );
    } else {
      setFilterFlag(true);
      dispatch(UpdateRangeFilter(true));
      // setRange({
      //   to: initialRange?.to,
      //   from: initialRange?.from
      // });
    }
  };

  return (
    <div className="calendar-input-range">
      <DatePicker
        id="calendar-input-range-from"
        variant="inline"
        disableToolbar
        label="From"
        format="MMM d yyyy"
        selectedDate={fromDate}
        maxDate={toDate}
        handleDateChange={handleSelectDateFrom}
        disabled={!filterFlag.rangeFilter}
      />
      <DatePicker
        id="calendar-input-range-to"
        variant="inline"
        disableToolbar
        label="To"
        format="MMM d yyyy"
        minDate={fromDate}
        selectedDate={toDate}
        handleDateChange={handleSelectDateTo}
        disabled={!filterFlag.rangeFilter}
      />
      {showOffFilter ? (
        <div className="date-filter" onClick={handelOnFilterToggle}>
          {filterFlag.rangeFilter ? (
            <img
              src={NoFilterAltIcon}
              alt="nofilter"
              className="data-filter-icon"
            />
          ) : (
            <img
              src={FilterAltIcon}
              alt="filter"
              className="data-filter-icon"
            />
          )}
        </div>
      ) : null}
    </div>
  );
};
export default CalendarInputRangeFlightReport;
