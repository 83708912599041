import React, { useMemo } from 'react';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core';
import {
  useStyles,
  columnsEngineer,
  columnsAdmin,
  EngineerJobReviewRow
} from './helper';

import './style.scss';
import { useSortHook } from '../../hooks/sortHooks';
import SharedTableHeader from '../shared/SharedTableHeader';

interface WorkHoursDetailsTableProps {
  currentDayDetails: [];
  isEngineer: Boolean;
}

interface TableRowProps {
  row: EngineerJobReviewRow;
}

const TableRowEngineer = ({ row }: TableRowProps) => {
  let newDate = moment.utc(row.date).format('YYYY-MM-DD');
  // const selectedValue = new Date(newDate);
  // selectedValue.setUTCHours(1);
  return (
    <TableRow className="work-hours-details-custom-body-row">
      <TableCell component="th" scope="row" align="left">
        {newDate}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row.aircraft}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row.booking}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row.client}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row.flightReport}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row.workReport}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row.workType}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row.description}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row.comments}
      </TableCell>
      <TableCell component="th" scope="row" align="right">
        {row.hours}
      </TableCell>
      <TableCell component="th" scope="row" align="right">
        {row.flightPay}
      </TableCell>
    </TableRow>
  );
};

const TableRowAdmin = ({ row }: TableRowProps) => {
  let newDate = moment.utc(row.date).format('YYYY-MM-DD');
  return (
    <TableRow className="work-hours-details-custom-body-row">
      <TableCell component="th" scope="row" align="left">
        {/* {moment(row.date).format('YYYY-MM-DD')} */}
        {newDate}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row.workType}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.comments}
      </TableCell>
      <TableCell component="th" scope="row" align="right">
        {row.hours}
      </TableCell>
    </TableRow>
  );
};

const WorkHoursDetailsTable = ({
  currentDayDetails,
  isEngineer
}: WorkHoursDetailsTableProps) => {
  const classes = useStyles();

  const { hoursTotal, flightPayTotal } = useMemo(() => {
    let hoursTotalLocal = 0;
    let flightPayTotalLocal = 0;

    currentDayDetails.forEach((row: EngineerJobReviewRow) => {
      hoursTotalLocal = Number(hoursTotalLocal) + Number(row.hours);
      flightPayTotalLocal = Number(flightPayTotalLocal) + Number(row.flightPay);
    });

    return {
      hoursTotal: hoursTotalLocal.toFixed(2),
      flightPayTotal: flightPayTotalLocal.toFixed(1)
    };
  }, [currentDayDetails]);

  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    dataArray: currentDayDetails,
    columnsData: isEngineer ? columnsEngineer : columnsAdmin,
    initialSortById: 'date'
  });

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <SharedTableHeader
          columns={isEngineer ? columnsEngineer : columnsAdmin}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedDataArray.map((row: EngineerJobReviewRow, index: number) => {
            return isEngineer ? (
              <TableRowEngineer key={index} row={row} />
            ) : (
              <TableRowAdmin key={index} row={row} />
            );
          })}

          {isEngineer ? (
            <TableRow className="work-hours-details-custom-body-row work-hours-details-total-row">
              <TableCell component="th" scope="row">
                TOTAL
              </TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row" align="right">
                {hoursTotal}
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                {flightPayTotal}
              </TableCell>
            </TableRow>
          ) : (
            <TableRow className="work-hours-details-custom-body-row work-hours-details-total-row">
              <TableCell component="th" scope="row">
                TOTAL
              </TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row" align="right">
                {hoursTotal}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WorkHoursDetailsTable;
