import { UsersScreenDataProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: UsersScreenDataProps = {
  isLoading: false,
  isLoadingTypes: false,
  usersData: [],
  userTypes: [],
  UserfirstName: [],
  companies: []
};

const usersScreenData = (
  state: UsersScreenDataProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_USERS_SCREEN_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.FETCH_USERS_SCREEN_DATA_SUCCESS:
      return {
        ...state,
        usersData: action.payload.usersData,
        isLoading: false
      };

    case 'FETCH_USERS_FIRST_NAME':
      return {
        ...state,
        UserfirstName: action.payload,
        isLoadingTypes: false
      };

    case 'FETCH_USERS_LAST_NAME':
      return {
        ...state,
        lastName: action.payload,
        isLoadingTypes: false
      };

    case types.FETCH_USERS_SCREEN_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case types.FETCH_USERS_SCREEN_TYPES_REQUEST:
      return {
        ...state,
        isLoadingTypes: true
      };

    case types.FETCH_USERS_SCREEN_TYPES_SUCCESS:
      return {
        ...state,
        userTypes: action.payload.userTypes,
        companies: action.payload.companies,
        isLoadingTypes: false
      };

    case types.FETCH_USERS_SCREEN_TYPES_FAILURE:
      return {
        ...state,
        isLoadingTypes: false
      };

    default:
      return state;
  }
};

export default usersScreenData;
