import * as R from 'ramda';
import moment, { Moment } from 'moment';

export const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty]);
export const isPresent = R.complement(isNilOrEmpty);
export function isValidDate(d: any) {
  return !isNaN(d) && d instanceof Date;
}
export const isArrayOrSendEmpty = (dataArray: any) => {
  if (R.is(Array, dataArray)) {
    return dataArray;
  }

  return [];
};
export const numberOrEmpty = (
  value: number | string | null,
  decimalPlace: number
) => {
  const parsedValue = parseFloat(`${value}`);

  if (R.is(Number, parsedValue) && `${parsedValue}` !== 'NaN') {
    return Number(value).toFixed(decimalPlace);
  }

  return '';
};

export const removeNullOrEmptyKeys = (object: any) =>
  R.filter((value: any) => !(isNilOrEmpty(value) || value === -1), object);

const sortFunctionNumberAscend = (prop: string[]) =>
  R.ascend(R.compose(R.pathOr(0, prop)));
const sortFunctionNumberDescend = (prop: string[]) =>
  R.descend(R.compose(R.pathOr(0, prop)));

const sortFunctionStringAscend = (prop: string[]) =>
  R.ascend(R.compose(R.toLower, R.pathOr('', prop)));
const sortFunctionStringDescend = (prop: string[]) =>
  R.descend(R.compose(R.toLower, R.pathOr('', prop)));

export const sortArrayByProp = (
  arrayToSort: any[],
  propArray: string[][],
  {
    order,
    isNumberSort
  }: {
    order?: 'asc' | 'desc';
    isNumberSort?: boolean;
  } = {
    order: 'asc',
    isNumberSort: false
  }
) => {
  const sortFuncArray: any[] = [];

  propArray.forEach((props: string[]) => {
    if (isNumberSort && order === 'asc') {
      sortFuncArray.push(sortFunctionNumberAscend(props));
    } else if (isNumberSort && order === 'desc') {
      sortFuncArray.push(sortFunctionNumberDescend(props));
    } else if (order === 'asc') {
      sortFuncArray.push(sortFunctionStringAscend(props));
    } else {
      sortFuncArray.push(sortFunctionStringDescend(props));
    }
  });

  const sortedArrayFunction = R.sortWith(sortFuncArray);

  const sortedArray: any[] = sortedArrayFunction(arrayToSort);

  return sortedArray;
};

export const sortArrayBasedOnColumn = (
  userDataArray: any[],
  order: 'asc' | 'desc',
  orderBy: string,
  columnsData: any[]
) => {
  const filterOption: any = R.find(R.propEq('id', orderBy))(columnsData);

  if (isNilOrEmpty(filterOption)) {
    return userDataArray;
  }

  return sortArrayByProp(userDataArray, filterOption.sortingProps, {
    isNumberSort: filterOption.isNumber,
    order
  });
};

export const changeToDateStartForBooking = (date: Moment | Date) =>
  new Date(`${moment(date).hour(0).minute(0).second(0).milliseconds(0)}`);

export const getDateStringForBooking = (date: Moment | Date) =>
  moment(changeToDateStartForBooking(date))
    .hour(23)
    .minute(59)
    .second(59)
    .milliseconds(0)
    .toISOString();

export const changeToDateStart = (date: Moment | Date) =>
  new Date(`${moment(date).hour(0).minute(0).second(0).milliseconds(0)}`);

export const getDateString = (date: Moment | Date) =>
  moment(changeToDateStart(date)).format('YYYY-MM-DDTHH:mm:ss');
// moment(changeToDateStart(date)).local().format('YYYY-MM-DDTHH:mm:ss');

export const getWeekDays = (weekStart: Date) => {
  const days = [];
  for (let i = 0; i < 7; i += 1) {
    days.push(changeToDateStart(moment(weekStart).add(i, 'days').toDate()));
  }
  return days;
};

export const getWeekRange = (date: Date = new Date()) => {
  return {
    from: changeToDateStart(moment(date).startOf('week').toDate()),
    to: changeToDateStart(moment(date).endOf('week').toDate())
  };
};

export const getWeekDaysString = (weekStart: Date) => {
  const days = [];
  for (let i = 0; i < 7; i += 1) {
    days.push(
      getDateString(
        changeToDateStart(moment(weekStart).add(i, 'days').toDate())
      )
    );
  }
  return days;
};

export const getWeekRangeString = (date: Date) => {
  const startDay = getDateString(
    changeToDateStart(moment(date).startOf('week').toDate())
  );

  const endDay = getDateString(
    changeToDateStart(moment(date).endOf('week').toDate())
  );

  return {
    from: startDay,
    to: endDay
  };
};

export const getMonthStartToCurrentDateRange = (date: Date = new Date()) => {
  return {
    from: changeToDateStart(moment(date).startOf('month').toDate()),
    to: changeToDateStart(moment(date).toDate())
  };
};

export const initialFilterList = [{ id: -1, name: 'All' }];

export const statusFilterList = [
  { label: 'All', value: 'all' },
  { label: 'Approved', value: 'approved' },
  { label: 'Reviewed', value: 'reviewed' },
  { label: 'Pending', value: 'pending' },
  { label: 'Missing', value: 'missing' }
];

export const getNumberUpto2Decimals = (value: number = 0) => {
  return Number(value).toFixed(2);
};

export const paymentMethodsList = [
  { id: 'userPaid', name: 'userPaid' },
  { id: 'companyCard', name: 'companyCard' },
  { id: 'companyAccount', name: 'companyAccount' },
  { id: 'provided', name: 'provided' }
];

export const fuelTypeList = [
  { id: 'bulkBowser', name: 'bulkBowser' },
  { id: 'drum', name: 'drum' }
];

export const fuelMeasureOptions = [
  { name: 'Gallons', id: 'gallons' },
  { name: 'Pounds', id: 'lbs' },
  { name: 'Percentage', id: 'percentage' }
];

export const fuelMeasureFilterOptions = [
  { name: 'All', id: 'all' },
  ...fuelMeasureOptions
];

export const booleanOptions: any = [
  { name: 'All', id: 'all' },
  { name: 'Yes', id: true },
  { name: 'No', id: false }
];

export const statusOptions = [
  { name: 'All', id: 'all' },
  { name: 'Active', id: 'active' },
  { name: 'Archived', id: 'archived' }
];

export const languageOptions = [
  {
    id: 'fr',
    name: 'French'
  },
  {
    id: 'en',
    name: 'English'
  }
];

export interface BookingsProps {
  id: number;
  name: string;
  bookingNumber: number;
}
