import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { Box } from '@material-ui/core';
// import LaunchIcon from '@material-ui/icons/LaunchOutlined';
//import SaveAltIcon from '@material-ui/icons/SaveAltOutlined';
// import UnlockIcon from '@material-ui/icons/LockOpen';
// import LockIcon from '@material-ui/icons/Lock';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import CalendarInputRange from '../CalendarInputRange';
import Status from '../shared/Status';
// import {
//   exportFlightReportScreenDataRequest
//   // updateFlightReportStatusRequest
// } from '../../redux/actions/flightReportScreenData';
import CustomSpeedDial from '../shared/CustomSpeedDial';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import ForwardToInboxOutlinedIcon from '@material-ui/icons/EmailOutlined';
import './style.scss';
import {
  sendClientPortalFlightReportRequest,
  updateClientPortalFlightReportStatusRequest
} from '../../redux/actions/clientPortalScreenData';
import { numberOrEmpty } from '../../utils/helper';

interface RangeProps {
  to: Date;
  from: Date;
}

interface FlightReportSecondaryHeaderProps {
  range: RangeProps;
  isSaveButtonDisabled: boolean;
  isFlightReportPdfRoute: boolean;
  currentFlightReportData: any;
  setRange: Function;
  onSaveButtonClickHandler: Function;
  goto: Function;
  flightReportIds: any;
  // currentSelectedPdfIndex: number;
  // setCurrentSelectedPdf: Function;
}

const FlightReportSecondaryHeader = ({
  range,
  isSaveButtonDisabled,
  isFlightReportPdfRoute,
  currentFlightReportData,
  setRange,
  onSaveButtonClickHandler,
  goto,
  flightReportIds
}: FlightReportSecondaryHeaderProps) => {
  const dispatch = useDispatch();

  const isLocked = R.pathOr(
    true,
    ['flightReportData', 'isLocked'],
    currentFlightReportData
  );
  const isForClientPortal = R.pathOr(
    true,
    ['flightReportData', 'isForClientPortal'],
    currentFlightReportData
  );

  const id = R.pathOr('', ['flightReportData', 'id'], currentFlightReportData);

  const breadcrumbDataArray = useMemo(() => {
    const breadcrumbDataArrayLocal = [
      {
        id: 'flight_report_breadcrum',
        label: 'Client Portal',
        onClick: isFlightReportPdfRoute ? () => goto('/clientportal') : () => {}
      }
    ];

    if (isFlightReportPdfRoute && flightReportIds.length !== 0) {
      breadcrumbDataArrayLocal.push(
        {
          id: 'flight_report_text_breadcrum',
          label: 'Flight Report',
          onClick: () => {}
        },
        {
          id: 'flight_report_pdf_breadcrum',
          label: currentFlightReportData.isLoading
            ? 'Loading...'
            : currentFlightReportData?.flightReportData?.flightReport,
          onClick: () => {}
        }
      );
    }

    return breadcrumbDataArrayLocal;
  }, [goto, isFlightReportPdfRoute, currentFlightReportData]);

  // const handleCloudDownloadButtonClick = useCallback(
  //   () => dispatch(exportFlightReportScreenDataRequest(false)),
  //   [dispatch]
  // );
  // const handleOnClickOpenInNew = useCallback(() => {
  //   if (isReceiptPage) window.open(receiptLink, '_blank');
  //   if (isWorkHoursDetailsPage) window.open(workHoursDetailsLink, '_blank');
  // }, [
  //   isReceiptPage,
  //   isWorkHoursDetailsPage,
  //   receiptLink,
  //   workHoursDetailsLink
  // ]);

  const actions = useMemo(() => {
    if (isFlightReportPdfRoute && flightReportIds.length !== 0) {
      return [
        {
          icon: isLocked ? DoneAllIcon : DoneIcon,
          name: isLocked ? 'Approved' : 'Approve',
          disabled: isLocked || !isForClientPortal,
          disabledMsg: 'Flight Report is not locked by pilot',
          onClick: () => {
            dispatch(updateClientPortalFlightReportStatusRequest(isLocked, id));
          },
          showToast: false
        },
        {
          icon: ForwardToInboxOutlinedIcon,
          name: 'Send Flight Report to Email',
          disabled: false,
          onClick: () => {
            dispatch(
              sendClientPortalFlightReportRequest(
                currentFlightReportData.flightReportData.id
              )
            );
          },
          showToast: false
        }
      ];
    }

    return [
      // {
      //   icon: SaveIcon,
      //   name: 'Save',
      //   disabled: isSaveButtonDisabled,
      //   onClick: onSaveButtonClickHandler,
      //   showToast: false
      // }
      // {
      //   icon: LaunchIcon,
      //   name: 'Open',
      //   onClick: () => {
      //     console.log('open');
      //   }
      // }
      // {
      //   icon: SaveAltIcon,
      //   name: 'Download',
      //   onClick: handleCloudDownloadButtonClick
      // }
    ];
  }, [
    dispatch,
    id,
    isLocked,
    //isSaveButtonDisabled,
    isFlightReportPdfRoute,
    //onSaveButtonClickHandler,
    //handleCloudDownloadButtonClick,
    currentFlightReportData,
    isForClientPortal
  ]);

  return (
    <React.Fragment>
      <div className="dropdown-container-flight-report">
        <Box mr={1}>
          <Breadcrumbs breadcrumbDataArray={breadcrumbDataArray} />
        </Box>

        {isFlightReportPdfRoute && flightReportIds.length !== 0 ? (
          <>
            <Box mx={2}>
              <span className="hours-text">
                {numberOrEmpty(
                  R.pathOr(
                    '',
                    ['flightReportData', 'revenue'],
                    currentFlightReportData
                  ),
                  1
                )}{' '}
                Hours
              </span>
              <Status
                status={R.pathOr(
                  '',
                  ['flightReportData', 'flightStatus'],
                  currentFlightReportData
                )}
              />
            </Box>
          </>
        ) : (
          <CalendarInputRange
            range={range}
            setRange={setRange}
            showOffFilter={true}
          />
        )}
      </div>

      <CustomSpeedDial actions={actions} />
    </React.Fragment>
  );
};

export default FlightReportSecondaryHeader;
