import { ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';
// import { useParams } from 'react-router-dom';
// import { useSelector } from 'react-redux';

const initialState: any = {
  isLoading: true,
  bookingsData: [],
  customers: [],
  requestingPersons: [],
  bases: [],
  equipments: [],
  passengers: [],
  pilot: [],
  ame: [],
  apprentice: [],
  aircraft: [],
  approvingClient: [],
  approvingCompany: [],
  bookingNumberIsValid: true,
  bookingNumberIsLoading: false,
  isApprovalNeeded: []
};

// const { bookingsId }: any = useParams();

// const { bookingsData } = useSelector((state: any) => ({
//   bookingsData: state.bookingsScreenData.bookingsData
// }));

// const getCurrentBookingData = () => {
//   return bookingsData.find((el: { id: string }) => el.id === bookingsId);
// };

// console.log(getCurrentBookingData(), 'BookingID');
const bookingsScreenData = (state: any = initialState, action: ActionType) => {
  switch (action.type) {
    case types.FETCH_BOOKINGS_SCREEN_DATA_SUCCESS:
      console.log(action.payload, 'action.payload');
      return {
        ...state,
        bookingsData: action.payload.bookingsData,
        customers: action.payload.customers,
        bases: action.payload.bases,
        equipments: action.payload.equipments,
        pilot: action.payload.pilot,
        ame: action.payload.ame,
        approvingClient: action.payload.approvingClient,
        apprentice: action.payload.apprentice,
        aircraft: action.payload.aircraft,
        approvingCompany: action.payload.approvingCompany,
        isLoading: false,
        isApprovalNeeded: action.payload.bookingsData.map((el: any) => ({
          id: el.id,
          value: el.isApprovalNeeded
        }))
      };
    case 'UPDATE_IS_APPROVAL_NEEDED':
      const currentIndex = state.isApprovalNeeded.findIndex(
        (el: { id: string }) => el.id === action.payload.id
      );
      let cloneIsApprovel = [...state.isApprovalNeeded];
      cloneIsApprovel[currentIndex].value = action.payload.flag;
      return {
        ...state,
        isApprovalNeeded: cloneIsApprovel
      };
    case types.FETCH_BOOKINGS_SCREEN_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case types.FETCH_BOOKINGS_SCREEN_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.FETCH_BOOKINGS_SCREEN_DATA_FIELDS_SUCCESS:
      return {
        ...state,
        requestingPersons: action.payload.requestingPersons,
        passengers: action.payload.passengers
      };

    case types.VALIDATE_BOOKING_NUMBER_REQUEST:
      return {
        ...state,
        bookingNumberIsLoading: true
      };

    case types.VALIDATE_BOOKING_NUMBER_SUCCESS:
      return {
        ...state,
        bookingNumberIsValid: true,
        bookingNumberIsLoading: false
      };

    case types.VALIDATE_BOOKING_NUMBER_FAILURE:
      return {
        ...state,
        bookingNumberIsValid: false,
        bookingNumberIsLoading: false
      };

    case types.FETCH_BOOKINGS_SCREEN_DATA_FIELDS_FAILURE:
    case types.FETCH_BOOKINGS_SCREEN_DATA_FIELDS_REQUEST:
    default:
      return state;
  }
};

export default bookingsScreenData;
