import { DetailsScreenData } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: DetailsScreenData = {
  isLoading: true,
  dataArray: [],
  expenseTypes: [],
  bookings: [],
  comment: '',
  currentIndex: 0
};

const detailsScreenData = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.FETCH_DETAILS_SCREEN_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.FETCH_DETAILS_SCREEN_DATA_SUCCESS:
      return {
        ...state,
        dataArray: action.payload.dataArray,
        isLoading: false
      };

    case types.FETCH_DETAILS_SCREEN_DATA_FAILURE:
      return {
        ...state,
        dataArray: [],
        isLoading: false
      };

    case types.RESET_DETAILS_SCREEN_DATA:
      return initialState;

    case types.FETCH_EXPENSE_TYPES_SUCCESS:
      return {
        ...state,
        expenseTypes: action.payload.expenseTypes
      };

    case types.FETCH_EXPENSE_TYPES_FAILURE:
      return {
        ...state,
        expenseTypes: []
      };

    case types.FETCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: action.payload.bookings
      };

    case types.FETCH_BOOKINGS_FAILURE:
      return {
        ...state,
        bookings: []
      };

    case 'currentIndex':
      return {
        ...state,
        currentIndex: action.payload.currentIndex
      };

    case 'SAVE_COMMENT':
      return {
        ...state,
        comment: action.payload
      };
    default:
      return state;
  }
};

export default detailsScreenData;
