export const DEPARTMENT = 'department';
export const BUSH_PAY = 'bushPays';
export const STATUS = 'status';
export const LAST_NAME = 'lastName';
export const FIRST_NAME = 'firstName';
export const EMPLOYEE_ID = 'employeeId';

export const initialValues = {
  [DEPARTMENT]: -1,
  [LAST_NAME]: -1,
  [BUSH_PAY]: 'all',
  [STATUS]: 'all',
  [FIRST_NAME]: -1,
  [EMPLOYEE_ID]: 'all'
};

export const filtersNotWorkingOptions = [
  { name: 'Filter not working', id: -2 }
];
