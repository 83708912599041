import { FlightReportScreenDataProps, ActionType } from '../utils/interfaces';
import { getMonthStartToCurrentDateRange } from '../../utils/helper';

const initialState: any = {
  fromDate: getMonthStartToCurrentDateRange().from,
  toDate: getMonthStartToCurrentDateRange().to
};

const FlightReportRangeData = (
  state: FlightReportScreenDataProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case 'FLIGHT_REPORT_RANGE_UPDATE_FromDate':
      return {
        ...state,
        fromDate: action.payload.fromDate
      };
    case 'FLIGHT_REPORT_RANGE_UPDATE_ToDate':
      return {
        ...state,
        toDate: action.payload.to
      };
    default:
      return state;
  }
};

export default FlightReportRangeData;
