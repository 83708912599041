import React from 'react';
// import * as R from 'ramda';

// import { useSelector } from 'react-redux';
import ForwardBackwardNav from '../../components/shared/ForwardBackwardNav';
import DetailsScreenTable from '../../components/DetailsScreenTable';
import { RowData, ExpenseTypes } from '../../redux/utils/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import {
  // saveComment,
  setCommentWeeklyDetailsRequest
} from '../../redux/actions/detailsScreenData';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Box,
  Typography,
  Button
} from '@material-ui/core';
import moment from 'moment';

interface DetailsScreenProps {
  currentUserIndex: number;
  length: number;
  handlePageChange: (changeIndex: number) => void;
  currentUserData: RowData;
  selectedFirstDate: Date;
  expenseTypes: ExpenseTypes[];
  userId: any;
}

const DetailsScreen = ({
  currentUserIndex,
  length,
  handlePageChange,
  currentUserData,
  selectedFirstDate,
  expenseTypes,
  userId
}: DetailsScreenProps) => {
  const dispatch = useDispatch();
  // const managerComment = R.pathOr('', ['managerComment'], currentUserData);
  const { dataArray } = useSelector((state: any) => {
    return {
      ...state.detailsScreenData
    };
  });
  const [comment, setCommentText] = React.useState('');
  const commentList = dataArray.find((item: any) => item.id === userId);

  console.log(commentList?.managerComment, 'dataArray');
  // const [comment, setComment] = React.useState('');

  const handleCommentChange = (event: any) => {
    event.stopPropagation();
    setCommentText(event.target.value);
    // dispatch(saveComment(event.target.value));
  };

  return (
    <React.Fragment>
      <ForwardBackwardNav
        currentIndex={currentUserIndex}
        total={length}
        handlePageChange={handlePageChange}
      />

      <Box py={2.5}>
        <DetailsScreenTable
          currentUserData={currentUserData}
          selectedFirstDate={selectedFirstDate}
          expenseTypes={expenseTypes}
        />
      </Box>

      {commentList?.managerComment.length ? (
        <Box
          py={2.5}
          style={{ background: ' #fff', boxShadow: '2px 2px 2px #FFF' }}>
          <List>
            <h3>Comments</h3>
            {commentList?.managerComment.map((comment: any, index: any) => {
              return (
                <div key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        <span>
                          <Typography
                            component="span"
                            className="comment-massage">
                            <span className="comment-timestamp">
                              {comment.commentingManagerName}
                            </span>
                          </Typography>

                          <Typography
                            component="span"
                            className="comment-massage">
                            <span className="comment-timestamp">
                              {moment(comment.lastModified)
                                .local()
                                .format('YYYY-MM-DD HH:mm A')}
                            </span>
                          </Typography>
                        </span>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            //sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            //color="text.primary"
                          >
                            {comment?.managerComment}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider component="li" />
                </div>
              );
            })}
          </List>
        </Box>
      ) : (
        ''
      )}

      <Box py={2.5}>
        <TextField
          fullWidth
          label="Comments"
          variant="outlined"
          multiline
          rows={3}
          rowsMax={3}
          value={comment}
          onChange={(e) => handleCommentChange(e)}
        />
      </Box>
      <div className="comment-submit-button">
        <Button
          disabled={comment.length === 0}
          variant="contained"
          color="primary"
          onClick={() =>
            dispatch(setCommentWeeklyDetailsRequest({ userId, comment }))
          }>
          Post comment
        </Button>
      </div>
    </React.Fragment>
  );
};

export default DetailsScreen;
