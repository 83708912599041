import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@material-ui/core';
import HeaderContainer from '../../containers/HeaderContainer';
import DetailsScreenSecondaryHeader from '../../components/DetailsScreenSecondaryHeaders';
import DetailsScreen from './DetailsScreen';
import ReceiptScreen from './ReceiptScreen';
import WorkHoursDetailsScreen from './WorkHoursDetailsScreen';
import SharedSpinner from '../../components/shared/SharedSpinner';
import { isNilOrEmpty } from '../../utils/helper';
import { HOME_NAV_KEY } from '../../utils/routesNavigationConstants';
import { useDetailScreenHook } from './hooks';
import { useSelector, useDispatch } from 'react-redux';
import { currentIndex } from '../../redux/actions/detailsScreenData';
import { useParams } from 'react-router-dom';

const Details = () => {
  const {
    isLoading,
    dataArray,
    currentUserIndex,
    isReceiptPage,
    isWorkHoursDetailsPage,
    currentUserData,
    selectedFirstDate,
    expenseTypes,
    pageTitle,
    handlePageChange
  } = useDetailScreenHook();

  // detailsScreenData

  const params = useParams();

  const dispatch = useDispatch();

  const currentReceiptIndex = useSelector((state: any) => {
    return state.detailsScreenData.currentIndex;
  });

  const { userId }: any = params;

  useEffect(() => {
    dispatch(currentIndex(0));
  }, [userId]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <HeaderContainer
        secondaryHeader={
          <DetailsScreenSecondaryHeader
            currentUserData={currentUserData}
            isReceiptPage={isReceiptPage}
            isWorkHoursDetailsPage={isWorkHoursDetailsPage}
            dataArray={dataArray}
            isLoading={isLoading}
            selectedFirstDate={selectedFirstDate}
          />
        }
        navKey={HOME_NAV_KEY}>
        <Box py={3.5}>
          {isLoading ? (
            <SharedSpinner />
          ) : isNilOrEmpty(dataArray) ? (
            <Typography
              variant="h4"
              gutterBottom
              className="no-receipts-available-text">
              Go to weekly screen or refresh the app.
            </Typography>
          ) : (
            <Fragment>
              {isReceiptPage && (
                <ReceiptScreen
                  currentUserData={currentUserData}
                  currentReceiptIndex={currentReceiptIndex}
                />
              )}
              {isWorkHoursDetailsPage && (
                <WorkHoursDetailsScreen currentUserData={currentUserData} />
              )}
              {!(isReceiptPage || isWorkHoursDetailsPage) && (
                <DetailsScreen
                  currentUserIndex={currentUserIndex}
                  length={dataArray.length}
                  handlePageChange={handlePageChange}
                  currentUserData={currentUserData}
                  selectedFirstDate={selectedFirstDate}
                  expenseTypes={expenseTypes}
                  userId={userId}
                />
              )}
            </Fragment>
          )}
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default Details;
