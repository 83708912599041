const syncActions: String[] = [
  'SHOW_SPINNER',
  'HIDE_SPINNER',
  'SET_SELECTED_DAYS',
  'SET_DEPARTMENT_FILTER',
  'SET_STATUS_FILTER',
  'SET_FIRST_NAME_FILTER',
  'SET_LAST_NAME_FILTER',
  'SET_SUPERVISOR',
  'RESET_DETAILS_SCREEN_DATA',
  'RESET_LOGBOOK_SCREEN_DATA',
  'SET_DETAILS_SCREEN_SELECTED_FIELDS',
  'RESET_DETAILS_SCREEN_SELECTED_FIELDS',
  'SET_INVOICE_SCREEN_INVOICE_NUMBER_FILTERS',
  'SET_FUEL_EXPENSE_SCREEN_VENDOR_FILTERS',
  'SET_FUEL_EXPENSE_SCREEN_DOCUMENT_HASH_FILTERS',
  'SET_FUEL_EXPENSE_SCREEN_INVOICE_NUMBER_FILTERS',
  'SET_ENGINEER_JOB_REVIEW_EMPLOYEE_FILTERS',
  'SET_ENGINEER_JOB_REVIEW_WORK_REPORT_FILTERS',
  'SET_ENGINEER_JOB_REVIEW_WORK_DONE_FILTERS',
  'SET_FLIGHT_REPORT_STATUS_FILTERS',
  'SET_AIRCRAFT_SCREEN_FILTERS',
  'SET_COMMENT',
  'SET_CLIENT_PORTALFLIGHT_REPORT_STATUS_FILTERS'
];

export default syncActions;
