// import * as R from 'ramda';
import { useSelector } from 'react-redux';
// import StoreState from '../../redux/utils/interfaces';
import { isNilOrEmpty } from '../../utils/helper';
import { NavigationArrayProps } from '../../utils/routesNavigationConstants';

export const useFetchUserInfo = () => {
  const {
    companyName,
    isApprovingSupervisor,
    isReviewingSupervisor,
    isLogbookWebpageUser,
    isJobsWebpageAccess,
    isAircraftWebpageAccess,
    isBillingWebpageAccess,
    isBookingWebpageAccess,
    isCustomersWebpageAccess,
    isEmployeesWebpageAccess,
    isFlightreportWebpageAccess,
    isFuelWebpageAccess,
    isClientWebpageAccess,
    isWeeklyWebpageAccess,
    isApproverAccess,
    isViewAdminAccess,
    isUnlockAccess,
    isClientUser
  } = useSelector((state: any) => {
    return {
      companyName: state.userInformation.companyName,
      companyId: state.userInformation.companyId,
      isApprovingSupervisor: state.userInformation.isApprovingSupervisor,
      isReviewingSupervisor: state.userInformation.isReviewingSupervisor,
      isLogbookWebpageUser: state.userInformation.isLogbookWebpageUser,
      isJobsWebpageAccess: state.userInformation.isJobsWebpageAccess,
      isCustomersWebpageAccess: state.userInformation.isCustomersWebpageAccess,
      isBillingWebpageAccess: state.userInformation.isBillingWebpageAccess,
      isBookingWebpageAccess: state.userInformation.isBookingWebpageAccess,
      isWeeklyWebpageAccess: state.userInformation.isWeeklyWebpageAccess,
      isViewAdminAccess: state.userInformation.isViewAdminAccess,
      isUnlockAccess: state.userInformation.isUnlockAccess,
      isApproverAccess: state.userInformation.isApprover,
      isFlightreportWebpageAccess:
        state.userInformation.isFlightreportWebpageAccess,
      isAircraftWebpageAccess: state.userInformation.isAircraftWebpageAccess,
      isFuelWebpageAccess: state.userInformation.isFuelWebpageAccess,
      isEmployeesWebpageAccess: state.userInformation.isEmployeesWebpageAccess,
      isClientWebpageAccess: state.userInformation.isClientWebpageAccess,
      isClientUser: state.userInformation.isClientUser
    };
  });

  console.log(isUnlockAccess, 'isUnlockAccess');
  return {
    companyName,
    isApprovingSupervisor,
    isReviewingSupervisor,
    isLogbookWebpageUser,
    isJobsWebpageAccess,
    isWeeklyWebpageAccess,
    isAircraftWebpageAccess,
    isBillingWebpageAccess,
    isBookingWebpageAccess,
    isViewAdminAccess,
    isUnlockAccess,
    isApproverAccess,
    isCustomersWebpageAccess,
    isEmployeesWebpageAccess,
    isFlightreportWebpageAccess,
    isFuelWebpageAccess,
    isClientWebpageAccess,
    isClientUser
  };
};
export const getCalculatedNavigationArray = (arr: NavigationArrayProps[]) => {
  const isOptionsEmpty = (nav: { options: any }) => !isNilOrEmpty(nav.options);
  return arr.filter(isOptionsEmpty);
};
