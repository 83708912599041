import request from '../http/request';
import humps from 'humps';
import { clearLocalStorage } from '../utils/helper';
import * as endpoint from '../../services/endpoints';
import { LOGIN_ROUTE } from '../../utils/routesNavigationConstants';
import { navigateTo } from '../../utils/history';
// import { toast } from 'react-toastify';

// console.log('authorizationTokenBase64Encoded', authorizationTokenBase64Encoded);
export const fetchLogin = async (body: {}) => {
  const method = 'POST';
  const url = endpoint.AUTH_TOKEN(true);

  const header = {
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    Accept: '*/*'
  };

  const response = await request(url, method, header, body);

  // if (
  //   response.success === false ||
  //   response.data === 'Unauthorized' ||
  //   response.data === 'Access Denied'
  // ) {
  //   clearLocalStorage();
  //   navigateTo(LOGIN_ROUTE);
  //   window.location.reload();
  // }
  console.log(response, 'responseToken');
  const camelizeResponse = humps.camelizeKeys(response);
  // console.log('authorizationLogin', authorizationTokenBase64Encoded);
  return camelizeResponse;
};

// export const fetchNewTokens = async () => {
//   const method = 'POST';
//   const url = endpoint.AUTH_TOKEN_REFRESH;

//   const { refreshToken } = getLocalStorageTokens();

//   const header = {
//     Authorization: `Bearer ${refreshToken}`,
//     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
//   };
//   const response = await request(url, method, header);

//   if (
//     response.success === false ||
//     response.data === 'Unauthorized' ||
//     response.data === 'Access Denied'
//   ) {
//     clearLocalStorage();
//     navigateTo(LOGIN_ROUTE);
//     window.location.reload();
//   }

//   const camelizeResponse = humps.camelizeKeys(response);
//   console.log(camelizeResponse, 'Refresher');

//   return camelizeResponse;
// };

export const createNewUser = async (body: any) => {
  const method = 'POST';
  const url = endpoint.REGISTER;

  const header = {
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    Accept: '*/*'
  };

  const response = await request(url, method, header, body);

  // console.log(response, 'responce****');
  if (
    response.success === false &&
    response.data === 'Unauthorized'
    // response.success === false ||
    // response.data === 'Unauthorized' ||
    // response.data === 'Access Denied'
  ) {
    clearLocalStorage();
    navigateTo(LOGIN_ROUTE);
    window.location.reload();
  }

  const camelizeResponse = humps.camelizeKeys(response);

  return camelizeResponse;
};
export const createNewClientUser = async (body: {}) => {
  const method = 'POST';
  const url = endpoint.CLIENT_REGISTER;

  const header = {};

  const response = await request(url, method, header, body, false);

  if (response.success === false && response.data === 'Unauthorized') {
    clearLocalStorage();
    navigateTo(LOGIN_ROUTE);
    window.location.reload();
  }

  const camelizeResponse = humps.camelizeKeys(response);

  return camelizeResponse;
};

export const fetchMe = async () => {
  const method = 'GET';
  const url = endpoint.ME;

  const response = await request(url, method);

  const camelizeResponse = humps.camelizeKeys(response);

  if (
    response.success === false ||
    response.data === 'Unauthorized' ||
    response.data === 'Access Denied'
  ) {
    clearLocalStorage();
    navigateTo(LOGIN_ROUTE);
    window.location.reload();
  }

  return camelizeResponse;
};
