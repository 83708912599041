import types from '../utils/actionTypes';

export const fetchUsersScreenDataRequest = () => {
  return {
    type: types.FETCH_USERS_SCREEN_DATA_REQUEST
  };
};

export const fetchUsersScreenDataSuccess = (usersData: []) => {
  return {
    type: types.FETCH_USERS_SCREEN_DATA_SUCCESS,
    payload: { usersData }
  };
};

export const fetchUsersScreenDataFailure = () => {
  return {
    type: types.FETCH_USERS_SCREEN_DATA_FAILURE
  };
};

export const fetchUsersScreenTypesRequest = () => {
  return {
    type: types.FETCH_USERS_SCREEN_TYPES_REQUEST
  };
};

export const fetchUsersScreenTypesSuccess = (userTypes: [], companies: []) => {
  console.log(userTypes, companies, '###res');
  return {
    type: types.FETCH_USERS_SCREEN_TYPES_SUCCESS,
    payload: { userTypes, companies }
  };
};

export const fetchUsersFirstNameType = (firstName: any) => {
  return {
    type: 'FETCH_USERS_FIRST_NAME',
    payload: firstName
  };
};

export const fetchUsersLastNameType = (lastName: any) => {
  return {
    type: 'FETCH_USERS_LAST_NAME',
    payload: lastName
  };
};
export const fetchUsersScreenTypesFailure = () => {
  return {
    type: types.FETCH_USERS_SCREEN_TYPES_FAILURE
  };
};

export const addNewUserRequest = (newUserData: {}) => {
  return {
    type: types.ADD_NEW_USER_REQUEST,
    payload: {
      newUserData
    }
  };
};

export const addNewUserSuccess = () => {
  return {
    type: types.ADD_NEW_USER_SUCCESS
  };
};

export const addNewUserFailure = () => {
  return {
    type: types.ADD_NEW_USER_FAILURE
  };
};

export const updateUserRequest = (userId: number, updatedData: {}) => {
  return {
    type: types.UPDATE_USER_REQUEST,
    payload: {
      userId,
      updatedData
    }
  };
};

export const updateUserSuccess = () => {
  return {
    type: types.UPDATE_USER_SUCCESS
  };
};

export const updateUserFailure = () => {
  return {
    type: types.UPDATE_USER_FAILURE
  };
};

export const userStatusChangeRequest = (userId: number, isActive: boolean) => {
  return {
    type: types.USER_STATUS_CHANGE_REQUEST,
    payload: {
      userId,
      isActive
    }
  };
};

export const userStatusChangeSuccess = () => {
  return {
    type: types.USER_STATUS_CHANGE_SUCCESS
  };
};

export const userStatusChangeFailure = () => {
  return {
    type: types.USER_STATUS_CHANGE_FAILURE
  };
};
