import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { isNilOrEmpty } from '../../../utils/helper';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import './style.scss';

interface PdfRenderProps {
  isLoading?: boolean;
  onlyFirstPage?: boolean;
  pdf: string;
  scale?: number;
  rotationDegree?: number;
  activePageIndex?: number;
  totalPages: number;
  setTotalPages: any;
}

const PdfRender = ({
  isLoading = false,
  onlyFirstPage = false,
  pdf,
  scale = 1.6,
  rotationDegree = 0,
  activePageIndex = 0,
  totalPages,
  setTotalPages
}: PdfRenderProps) => {
  // const [totalPages, setTotalPages] = useState(null);
  console.log(pdf, 'pdfSetBackOne');

  return (
    <div className="flight-report-container">
      {isLoading ? (
        <Typography
          variant="h4"
          gutterBottom
          className="no-receipts-available-text">
          Loading...
        </Typography>
      ) : isNilOrEmpty(pdf) ? (
        <Typography
          variant="h4"
          gutterBottom
          className="no-receipts-available-text">
          No Flight Report Available
        </Typography>
      ) : (
        <>
          <Box>
            <Document
              file={pdf}
              onLoadSuccess={({ numPages }: any) => setTotalPages(numPages)}>
              {/* {onlyFirstPage ? (
              <Page pageNumber={1} scale={scale} />
            ) : (
              <Fragment>
                {[...Array(totalPages)].map((e, i) => (
                    <Page
                      key={i}
                      className={`pdfPage-${i + 1}`}
                      pageNumber={i + 1}
                      scale={scale}
                      rotate={rotationDegree}
                    />
                ))}
              </Fragment>
            )} */}
              <Page
                pageNumber={activePageIndex + 1}
                scale={scale}
                rotate={rotationDegree}
              />
            </Document>
          </Box>
        </>
      )}
    </div>
  );
};

export default PdfRender;
