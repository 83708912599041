import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, getFormInitialValues, change } from 'redux-form';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from '../../components/shared/DatePicker';
import { BOOKINGS_ADD_EDIT_FORM } from '../../utils/reduxFormConstants';
import StoreState from '../../redux/utils/interfaces';
import { isPresent } from '../../utils/helper';
import {
  validateBookingNumberRequest,
  validateBookingNumberSuccess
} from '../../redux/actions/bookingsScreenData';

interface EditBookingExtraFieldsProps {
  enteredByName: String;
  dateEntered: String;
  isAddBookingsRouteMatch: boolean;
}

const EditBookingExtraFields = ({
  enteredByName,
  dateEntered,
  isAddBookingsRouteMatch
}: EditBookingExtraFieldsProps) => {
  const dispatch = useDispatch();

  const {
    formValuesBookingsDetails,
    initialFormValuesBookingsDetails,
    currentUserName,
    bookingNumberIsLoading,
    bookingNumberIsValid
  } = useSelector((state: StoreState) => ({
    formValuesBookingsDetails:
      getFormValues(BOOKINGS_ADD_EDIT_FORM)(state) || {},
    initialFormValuesBookingsDetails:
      getFormInitialValues(BOOKINGS_ADD_EDIT_FORM)(state) || {},
    currentUserName:
      `${state.userInformation.firstName} ${state.userInformation.lastName}`.trim(),
    bookingNumberIsLoading: state.bookingsScreenData.bookingNumberIsLoading,
    bookingNumberIsValid: state.bookingsScreenData.bookingNumberIsValid
  }));

  const updateEditFormField = useCallback(
    (key: string, value: any) =>
      dispatch(change(BOOKINGS_ADD_EDIT_FORM, key, value)),
    [dispatch]
  );

  const isDefault = R.pathOr(false, ['isDefault'], formValuesBookingsDetails);
  const bookingNumber = R.pathOr(
    '',
    ['bookingNumber'],
    formValuesBookingsDetails
  );
  const initialBookingNumber = R.pathOr(
    '',
    ['bookingNumber'],
    initialFormValuesBookingsDetails
  );

  useEffect(() => {
    if (`${initialBookingNumber}` === `${bookingNumber}`) {
      dispatch(validateBookingNumberSuccess());
    } else {
      dispatch(validateBookingNumberRequest(bookingNumber));
    }
  }, [dispatch, initialBookingNumber, bookingNumber]);

  return (
    <div className="edit-booking-extra-fields">
      <div className="edit-booking-extra-fields-booking-number">
        <TextField
          className="standard-basic"
          type="number"
          label="Booking Number"
          error={!bookingNumberIsValid}
          helperText={!bookingNumberIsValid && 'Booking Number Not Valid!'}
          disabled={isAddBookingsRouteMatch}
          value={bookingNumber}
          onChange={(event) => {
            updateEditFormField('bookingNumber', event.target.value);
          }}
        />

        {bookingNumberIsLoading && <CircularProgress size={20} />}
      </div>

      <TextField
        className="standard-basic"
        type="text"
        disabled
        label="Entered By"
        value={isPresent(enteredByName) ? enteredByName : currentUserName}
      />

      <DatePicker
        label="Date Entered"
        selectedDate={
          isPresent(dateEntered) ? new Date(`${dateEntered}`) : new Date()
        }
        handleDateChange={() => {}}
        disabled
        keyboardIcon={<div></div>}
      />

      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isDefault}
            onChange={() => {
              updateEditFormField('isDefault', !isDefault);
            }}
          />
        }
        label="Default"
      />
    </div>
  );
};

export default EditBookingExtraFields;
