// USER RELATED ENDPOINTS ---------------->
// export const AUTH_TOKEN = 'auth/token';
export const AUTH_TOKEN = (isWebLogin: boolean) =>
  `auth/token?isWebLogin=${isWebLogin}`;

export const AUTH_TOKEN_REFRESH = 'auth/refresh';
export const REGISTER = 'register';
export const CLIENT_REGISTER = 'register/clientuser';
export const ME = 'me';
export const RESET_PASSWORD = (email: String) =>
  `reset_password?username=${email}`;

// USER RELATED ENDPOINTS <----------------

// export const AIRCRAFT = (isWebClient: Boolean) =>
//   `aircraft?isWebClient=${isWebClient}`;

export const WEB_USER_TYPES = (isWebClient: Boolean) =>
  `web/user_types?isWebClient=${isWebClient}`;

// WEB USER TYPES ENDPOINTS
export const USER_TYPES = 'user_types'; // USER TYPES ENDPOINTS
export const WORK_TYPES = 'work_type'; // WORK TYPES ENDPOINTS

export const WEB_COMPANY = (isWebClient: Boolean) =>
  `web/company?isWebClient=${isWebClient}`; // COMPANIES LIST ENDPOINTS

// USERS LISTS ENDPOINTS
export const USERS = (isWebClient: Boolean) =>
  `users?isWebClient=${isWebClient}`;

// 'expense_type'
export const CLIENT_USERS = 'clientusers'; // CLIENT USERS LISTS ENDPOINTS
export const EXPENSE_TYPES = (isWebClient: Boolean) =>
  `expense_type?isWebClient=${isWebClient}`; // EXPENSE TYPES LISTS ENDPOINTS
export const EXPENSE = 'expense'; // EXPENSE LIST ENDPOINTS
export const WEB_CUSTOMERS = 'web/customers'; // WEB CUSTOMERS LIST ENDPOINTS
export const BASE = 'base'; // BASE LIST ENDPOINTS
export const WEB_EQUIPMENTS = 'web/equipments'; // WEB EQUIPMENTS LIST ENDPOINTS

export const APPROVING_COMPANY = 'approvingCompany';
// WEEKLY DATA OF CURRENT USER ENDPOINTS ---------------->
export const WEEKLY_MANAGE = (startDate: string, endDate: string) =>
  `weekly/manage?start_date=${startDate}&end_date=${endDate}`;
// WEEKLY DATA OF CURRENT USER ENDPOINTS <----------------

// EXPORT ENDPOINTS ---------------->
export const EXPORT_ACCPAC_REPORT = (startDate: string, endDate: string) =>
  `report/accpac?start_date=${startDate}&end_date=${endDate}`;

export const EXPORT_WEEKLY_REPORT = 'weekly/report';
export const EXPORT_WEEKLY_RECEIPT = 'weekly/receipt';
export const EXPORT_SUMMARY_REPORT_IMAGES = 'weekly/flightreport_images';
export const EXPORT_REFUEL_IMAGE = 'image_export';
// EXPORT ENDPOINTS <----------------

// FUNCTIONS FOR THESE ENDPOINTS ALREADY EXISTS IN sagaHelpers.ts ---------------->

//
export const AIRCRAFT = (isWebClient: Boolean) =>
  `aircraft?isWebClient=${isWebClient}`;
export const APPRENTICE = 'apprentice';
export const AME = 'ame';
export const PILOT = 'pilot';
export const CUSTOMERS = (isWebClient: Boolean) =>
  `customers?isWebClient=${isWebClient}`;
export const WEB_BOOKING_RANGE = (startDate: string, endDate: string) =>
  `web/booking?start_date=${startDate}&end_date=${endDate}`;
// FUNCTIONS FOR THESE ENDPOINTS ALREADY EXISTS IN sagaHelpers.ts <----------------

// BILLINGS ENDPOINTS ---------------->

export const BILLING_RANGE = (
  isWebClient: Boolean,
  startDate: string,
  endDate: string
) =>
  `billing?start_date=${startDate}&end_date=${endDate}&isWebClient=${isWebClient}`;

export const BILLING = 'billing';
// BILLINGS ENDPOINTS <----------------

// FUEL ENDPOINTS ---------------->
export const FUEL_RANGE = (
  startDate: string,
  endDate: string,
  isWebClient: Boolean
) =>
  `fuel_validation?start_date=${startDate}&end_date=${endDate}&isWebClient=${isWebClient}`;
export const FUEL = 'fuel_validation';
// FUEL ENDPOINTS <----------------

// LOG BOOK ENDPOINTS ---------------->
export const LOGBOOK_RANGE = (startDate: string, endDate: string) =>
  `web/logbook?from=${startDate}&to=${endDate}`;
export const LOGBOOK = 'web/logbook';
// LOG BOOK ENDPOINTS <----------------

// FLIGHT REPORT ENDPOINTS ---------------->
export const FLIGHT_OPS_RANGE = (
  startDate: string,
  endDate: string,
  isWebClient: boolean,
  isDefaultView: boolean
) =>
  `flight_ops?start_date=${startDate}&end_date=${endDate}&isWebClient=${isWebClient}&isDefaultView=${isDefaultView}`;
export const FLIGHT_OPS = 'flight_ops';
export const FLIGHT_OPS_EXPORT = 'flight_report_export';
export const FLIGHT_REPORT_UNLOCK = 'unlock_flight_report';
export const FLIGHT_REPORT_WEB_LOCK = 'web/lock_flight_report';
export const FLIGHT_REPORT_LOCK = 'unregister_client_portal';
// export const FLIGHT_REPORT_INFO = 'flight_report_info';

export const CLIENT_PORTAL_FLIGHT_REPORT_LOCK =
  'web/client_portal_lock_flight_report';
export const SEND_FLIGHT_REPORT_EMAIL = 'flight_report_pdf_email';
// FLIGHT REPORT ENDPOINTS <----------------

// ENGINEER JOB REVIEW ENDPOINTS ---------------->
export const ENGINEER_JOB_REVIEW_RANGE = (
  startDate: string,
  endDate: string,
  isWebClient: Boolean
) =>
  `engineering_job?start_date=${startDate}&end_date=${endDate}&isWebClient=${isWebClient}`;
// ENGINEER JOB REVIEW ENDPOINTS <----------------

// WEEKLY DETAILS ENDPOINTS ---------------->
export const WEEKLY_APPROVE = 'weekly/approve';
export const WEEKLY_REVIEW = 'weekly/review';
export const WEEKLY_STATUS_CHANGE = 'weekly/status_change';
export const COMMENT_WEEKLY = 'comment_weekly';
// WEEKLY DETAILS ENDPOINTS <----------------

// CUSTOMERS ENDPOINTS ---------------->
export const CUSTOMER_DETAILS = 'customer_detail';
export const CUSTOMER_CONTACT = 'customer_contact';
export const CUSTOMER_FLIGHT_REPORT = (customerId: String) =>
  `customer/${customerId}`;
export const PASSENGERS = 'passengers';
// CUSTOMERS ENDPOINTS <----------------

// BOOKINGS ENDPOINTS ---------------->
export const WEB_CUSTOMER_CONTACT = (customerId: string) =>
  `web/customer_contact/${customerId}`;
export const WEB_PASSENGERS = (customerId: string) =>
  `web/passengers/${customerId}`;
export const WEB_BOOKING = 'web/booking';
export const VALIDATE_BOOKING_NUMBER = (bookingNumber: string) =>
  `validate_booking_number?booking_number=${bookingNumber}`;
// BOOKINGS ENDPOINTS <----------------

// AIRCRAFT ENDPOINTS ---------------->
export const WEB_AIRCRAFT = 'web/aircraft';
export const AIRCRAFT_OFFICIAL_TYPES = 'aircraft_official_type';
export const ARCHIVE_AIRCRAFT = 'archive_aircraft';
export const UNARCHIVE_AIRCRAFT = 'unarchive_aircraft';
// AIRCRAFT ENDPOINTS <----------------

// USERS ENDPOINTS ---------------->
export const UPDATE_USER = (userId: string, isWebClient: Boolean) =>
  `users/${userId}?isWebClient=${isWebClient}`;

// (isWebClient: Boolean) =>
//   `users?isWebClient=${isWebClient}`

export const BLOCK_USER = 'block_user';
export const UNBLOCK_USER = 'unblock_user';
// USERS ENDPOINTS <----------------

// CLIENT USERS ENDPOINTS -------------->
export const UPDATE_CLIENT_USER = (userId: string) => `clientusers/${userId}`;
export const GET_CLIENT_USERS = 'clientusers';
export const GET_CLIENT_USER = (id: number) => `clientusers/${id}`;
// CLIENT USERS ENDPOINTS <--------------

// CLIENT PORTAL ENDPOINTS -------------->
export const CLIENT_PORTAL_RANGE = (startDate: string, endDate: string) =>
  `client_portal?start_date=${startDate}&end_date=${endDate}`;
export const CLIENT_PORTAL_REPORTS = 'client_portal';
export const ADD_CLIENT_PORTAL_COMMENTS = 'client_portal_comment';
export const ADD_FLIGHT_PORTAL_COMMENTS = 'flight_report_comment';
export const UPDATE_FLIGHT_REPORT_APPROVE_STATUS = 'flight_report/approve';
// CLIENT PORTAL ENDPOINTS <--------------
