import React, { useMemo } from 'react';
import * as R from 'ramda';
import { reduxForm } from 'redux-form';
import { Helmet } from 'react-helmet';
import { Box, Paper } from '@material-ui/core';
import HeaderContainer from '../../containers/HeaderContainer';
import ClientUsersSecondaryHeader from '../../components/ClientUsersSecondaryHeader';
import ClientUsersTable from '../../components/ClientUsersTable';
import { CLIENT_USERS_NAV_KEY } from '../../utils/routesNavigationConstants';
import { useUsersHook } from './hooks';
import SharedSpinner from '../../components/shared/SharedSpinner';
import UsersDetailsHeader from './UsersDetailsHeader';
import UserForm from './UserForm';
import { isNilOrEmpty } from '../../utils/helper';
import { CLIENT_USERS_FILTERS_FORM } from '../../utils/reduxFormConstants';

import './styles.scss';

const Users = () => {
  const {
    isUsersDataLoading,
    usersData,
    supervisors,
    currentUserData,
    clients,
    isSubmitDisabled,
    isUserDetailsRouteMatch,
    isAddUserRouteMatch,
    showLicenseField,
    saveButtonHandler,
    goto,
    toggleUserStatusHandler
  } = useUsersHook();

  const initialValues = useMemo(() => {
    const approvingClientUsers = R.pathOr([], ['clientUser'], currentUserData);
    let approvingClients: any = [];
    approvingClientUsers.forEach((clientUser: any) => {
      approvingClients.push({
        ...clientUser.client,
        isApprover: clientUser.isApprover,
        isViewer: clientUser.isViewer,
        clientUserId:clientUser.id
      });
    });
    return {
      firstName: R.pathOr('', ['firstName'], currentUserData),
      lastName: R.pathOr('', ['lastName'], currentUserData),
      userType: R.pathOr(-1, ['userType', 'id'], currentUserData),
      approvingClient: approvingClients,
      isApprovingClient: R.pathOr(
        false,
        ['clientUser', '0', 'isApprover'],
        currentUserData
      ),
      isReviewingClient: R.pathOr(
        false,
        ['clientUser', '0', 'isViewer'],
        currentUserData
      ),
      username: R.pathOr('', ['username'], currentUserData),
      language: R.pathOr('', ['language'], currentUserData)
    };
  }, [currentUserData]);
  

  return (
    <React.Fragment>
      <Helmet>
        <title>Client Users</title>
      </Helmet>

      <HeaderContainer
        secondaryHeader={
          <ClientUsersSecondaryHeader
            currentUserName={`${R.pathOr(
              '',
              ['firstName'],
              currentUserData
            )} ${R.pathOr('', ['lastName'], currentUserData)}`}
            isActive={isNilOrEmpty(
              R.pathOr(null, ['deletedAt'], currentUserData)
            )}
            isAddUserRouteMatch={isAddUserRouteMatch}
            isUserDetailsRouteMatch={isUserDetailsRouteMatch}
            isSubmitDisabled={isSubmitDisabled}
            saveButtonHandler={saveButtonHandler}
            toggleUserStatusHandler={toggleUserStatusHandler}
            goto={goto}
          />
        }
        navKey={CLIENT_USERS_NAV_KEY}>
        <Box py={2.5} className="user-route-container">
          {isUsersDataLoading ? (
            <SharedSpinner />
          ) : isAddUserRouteMatch || isUserDetailsRouteMatch ? (
            <Paper>
              <UsersDetailsHeader
                isUserDetailsRouteMatch={isUserDetailsRouteMatch}
                isAddUserRouteMatch={isAddUserRouteMatch}
                currentUserId={R.pathOr(-1, ['id'], currentUserData)}
                goto={goto}
              />

              <div className="users-details-table-body">
                <UserForm
                  initialValues={initialValues}
                  clients={clients}
                  supervisors={supervisors}
                  showLicenseField={showLicenseField}
                  // isActive={isNilOrEmpty(
                  //   R.pathOr(null, ['deletedAt'], currentUserData)
                  // )}
                />
              </div>
            </Paper>
          ) : (
            <ClientUsersTable
              usersData={usersData}
              // userTypes={filteredUserTypes}
              goto={goto}
            />
          )}
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default reduxForm({
  form: CLIENT_USERS_FILTERS_FORM
})(Users);
