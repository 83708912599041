import { call, put, takeLatest, delay } from 'redux-saga/effects';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
// import userStatusData from '../../utils/weeklyManageData.json'
import {
  fetchWeeklyScreenDataSuccess,
  fetchWeeklyScreenDataFailure,
  fetchDepartmentFiltersSuccess,
  fetchDepartmentFiltersFailure
} from '../actions/weeklyScreenData';
import { getWeekRangeString } from '../../utils/helper';
import { clearLocalStorage } from '../../services/utils/helper';
import { LOGIN_ROUTE } from '../../utils/routesNavigationConstants';
import { navigateTo } from '../../utils/history';

export interface ActionType {
  type: String;
  payload: {
    date: Date;
    departmentFilter: number;
    statusFilter: string;
    firstNameFilter: number;
    lastNameFilter: number;
    supervisorFilter: any;
  };
}

export const fetchUserDataCall = async (date: Date) => {
  const selectedDate = new Date(date);
  const weekRangeString = getWeekRangeString(selectedDate);

  const userStates = await api.fetchResponse(
    endpoints.WEEKLY_MANAGE(weekRangeString.from, weekRangeString.to)
  );
  return userStates;
};

function* fetchWeeklyScreenData(action: ActionType) {
  yield delay(1000);

  try {
    const userStatus = yield call(fetchUserDataCall, action.payload.date);

    if (
      userStatus.success === false ||
      userStatus.data === 'Unauthorized' ||
      userStatus.data === 'Access Denied'
    ) {
      clearLocalStorage();
      navigateTo(LOGIN_ROUTE);
      window.location.reload();
    }

    if (userStatus.success) {
      yield put(
        fetchWeeklyScreenDataSuccess(
          userStatus.data.data,
          userStatus.data.metaData
        )
      );
    } else {
      yield put(fetchWeeklyScreenDataFailure());
    }
  } catch (e) {
    yield put(fetchWeeklyScreenDataFailure());
  }
}

function* fetchDepartmentFilters() {
  yield delay(1000);

  try {
    const userStatus = yield api.fetchResponse(endpoints.USER_TYPES);

    if (
      userStatus.success === false ||
      userStatus.data === 'Unauthorized' ||
      userStatus.data === 'Access Denied'
    ) {
      clearLocalStorage();
      navigateTo(LOGIN_ROUTE);
      window.location.reload();
    }
    if (userStatus.success) {
      yield put(fetchDepartmentFiltersSuccess(userStatus.data));
    } else {
      yield put(fetchDepartmentFiltersFailure());
    }
  } catch (e) {
    yield put(fetchDepartmentFiltersFailure());
  }
}

export default [
  takeLatest(types.FETCH_WEEKLY_SCREEN_DATA_REQUEST, fetchWeeklyScreenData),
  takeLatest(types.FETCH_DEPARTMENT_FILTERS_REQUEST, fetchDepartmentFilters)
];
