import React from 'react';
import * as R from 'ramda';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import Paper from '@material-ui/core/Paper';
import { isNilOrEmpty } from '../../utils/helper';
import {
  useStyles,
  DetailsScreenTableProps,
  getColumnTitles,
  getCalculatedDetailsDataArray
} from './helper';

import './style.scss';

const DetailsWeekPreScreenTable = ({
  currentUserData,
  selectedFirstDate,
  expenseTypes
}: DetailsScreenTableProps) => {
  const classes = useStyles();
  console.log(currentUserData, 'currentUserData--user');
  if (isNilOrEmpty(currentUserData)) {
    return null;
  }

  var expenseTypesArray = R.map(
    (data) =>
      R.assoc(
        'isExpensesField',
        true,
        R.omit(['lastModified', 'company'], data)
      ),
    expenseTypes
  );

  expenseTypesArray = R.map(
    (data) => R.assoc('className', 'details-custom-body-row-green-bg', data),
    expenseTypesArray
  );

  const columns = getColumnTitles(selectedFirstDate);
  const customerDetailsArray = getCalculatedDetailsDataArray(
    currentUserData,
    selectedFirstDate,
    expenseTypesArray
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <TableHeader columns={columns} />

        <TableBody customerDetailsArray={customerDetailsArray} />
      </Table>
    </TableContainer>
  );
};

export default DetailsWeekPreScreenTable;
