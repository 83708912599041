import {
  FlightReportScreenFiltersProps,
  ActionType
} from '../utils/interfaces';
import types from '../utils/actionTypes';
import { initialFilterList } from '../../utils/helper';

const statusFiltersArray = [
  ...initialFilterList,
  { id: 1, name: 'SIGNED ON MOBILE' },
  { id: 2, name: 'READY ON SIGNED' }
];
const statusApprovedFiltersArray = [
  ...initialFilterList,
  { id: 1, name: 'Approved' },
  { id: 2, name: 'Not Approved' }
];

// saveFlightStatusApprovedFilterSuccess

// const statusFiltersArray = [
//   ...initialFilterList,
//   { id: 1, name: 'Closed' },
//   { id: 2, name: 'Ready To Bill' },
//   { id: 3, name: 'In Progress' }
// ];

const initialState: any = {
  bookingFiltersArray: initialFilterList,
  customerFiltersArray: initialFilterList,
  aircraftFiltersArray: initialFilterList,
  pilotFiltersArray: initialFilterList,
  copilotFiltersArray: initialFilterList,
  aircraftTypeFiltersArray: initialFilterList,
  ameFiltersArray: initialFilterList,
  statusFiltersArray: statusFiltersArray,
  statusApprovedFiltersArray: statusApprovedFiltersArray,
  flightTypFiltersArray: initialFilterList,
  invoiceFiltersArray: initialFilterList,
  approvedByFiltersArray: initialFilterList
};

const flightReportScreenFilters = (
  state: FlightReportScreenFiltersProps = initialState,
  action: ActionType
) => {
  // console.log(approvedStatusFiltersArray, '!statusApprovedFiltersArray!');
  switch (action.type) {
    case types.FETCH_FLIGHT_REPORT_FILTERS_SUCCESS:
      const customerFiltersArray = initialFilterList.concat(
        action.payload.customerFiltersArray
      );
      const aircraftFiltersArray = initialFilterList.concat(
        action.payload.aircraftFiltersArray
      );
      console.log(aircraftFiltersArray, '!!aircraftFiltersArray!!');
      const pilotFiltersArray = initialFilterList.concat(
        action.payload.pilotFiltersArray
      );

      // case 'saveFlightStatusApprovedFilterSuccess':
      //   const statusApprovedFiltersArray = initialFilterList.concat(
      //     action.payload
      //   );

      const ameFiltersArray = initialFilterList.concat(
        action.payload.ameFiltersArray
      );
      const invoiceFiltersArray = initialFilterList.concat(
        action.payload.invoiceFiltersArray
      );

      const approvedByFiltersArray = initialFilterList.concat(
        action.payload.approvedByFiltersArray
      );

      const statusTrackFiltersArray = initialFilterList.concat(
        action.payload.statusTrackFiltersArray
      );

      return {
        ...state,
        customerFiltersArray,
        aircraftFiltersArray,
        pilotFiltersArray,
        ameFiltersArray,
        invoiceFiltersArray,
        statusTrackFiltersArray,
        approvedByFiltersArray
      };

    case 'SAVE_COPILOT_FILTER_SUCCESS': {
      return {
        ...state,
        copilotFiltersArray: initialFilterList.concat(action.payload)
      };
    }

    case 'SAVE_flight_Type_Filters_SUCCESS': {
      return {
        ...state,
        flightTypFiltersArray: initialFilterList.concat(action.payload)
      };
    }

    case 'SAVE_AIRCRAFT_FILTER_SUCCESS': {
      return {
        ...state,
        aircraftTypeFiltersArray: initialFilterList.concat(action.payload)
      };
    }
    case types.FETCH_FLIGHT_REPORT_FILTERS_FAILURE:
      return state;

    case types.FETCH_FLIGHT_REPORT_BOOKING_FILTERS_SUCCESS:
      return {
        ...state,
        bookingFiltersArray: initialFilterList.concat(
          action.payload.bookingFiltersArray
        )
      };

    case types.FETCH_FLIGHT_REPORT_BOOKING_FILTERS_FAILURE:
      return state;

    case types.SET_FLIGHT_REPORT_STATUS_FILTERS:
      const statusFiltersArray = initialFilterList.concat(
        action.payload.statusFiltersArray
      );

      const statusApprovedFiltersArray = initialFilterList.concat(
        action.payload
      );

      return {
        ...state,
        statusApprovedFiltersArray,
        statusFiltersArray
      };

    default:
      return state;
  }
};

export default flightReportScreenFilters;
