import types from '../utils/actionTypes';
export const fetchUserInfo = () => {
  return {
    type: types.FETCH_USER_INFO_REQUEST,
    payload: {}
  };
};

export const userInfoSuccess = (data: {}) => {
  console.log(data, '::database::');
  return {
    type: types.FETCH_USER_INFO_SUCCESS,
    payload: { ...data }
  };
};

export const userInfoFailure = () => {
  return {
    type: types.FETCH_USER_INFO_FAILURE
  };
};
