import React, { useState } from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import NavigationIcon from '@material-ui/icons/Navigation';
import { toast } from 'react-toastify';
import { isNilOrEmpty, isPresent } from '../../../utils/helper';

import './styles.scss';

interface ActionsProps {
  name: string | number | undefined;
  icon: any;
  disabled?: boolean;
  disabledMsg?: String;
  showToast?: boolean;
  onClick: Function;
}

// interface CustomSpeedDialProps {
//   actions: any[];
// }

const CustomSpeedDial = ({ actions }: any) => {
  const [openSpeedDial, setOpenSpeedDial] = useState(true);

  if (isNilOrEmpty(actions)) {
    return null;
  }

  return (
    <SpeedDial
      ariaLabel="SpeedDial tooltip"
      className="custom-speed-dial"
      icon={<NavigationIcon className="speed-dial-icon" />}
      onOpen={() => setOpenSpeedDial(true)}
      onClose={() => setOpenSpeedDial(true)}
      open={openSpeedDial}
      direction="left">
      {actions.map(
        ({
          name,
          icon: ActionIcon,
          disabled = false,
          disabledMsg = '',
          showToast = true,
          onClick
        }: ActionsProps) => {
          return (
            <SpeedDialAction
              key={name}
              icon={<ActionIcon color={disabled ? 'disabled' : 'primary'} />}
              tooltipTitle={name}
              tooltipPlacement="top"
              // tooltipOpen
              onClick={() => {
                if (disabled) {
                  showToast &&
                    toast.error(
                      isPresent(disabledMsg)
                        ? disabledMsg
                        : `${name} Action Disabled`
                    );
                } else {
                  setOpenSpeedDial(true);
                  onClick();
                }
              }}
            />
          );
        }
      )}
    </SpeedDial>
  );
};

export default CustomSpeedDial;
