import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import {
  aircraftFilterStatePath,
  bookingFilterStatePath,
  vendorFilterStatePath,
  documentHashStatePath,
  invoiceNumberFilterStatePath,
  AircraftFilterComponent,
  BookingFilterComponent,
  VendorFilterComponent,
  DocumentHashFilterComponent,
  InvoiceNumberFilterComponent,
  PilotFilterComponent,
  pilotFilterStatePath,
  FlightReportFilterComponent,
  flightReportFilterStatePath,
  FuelTypeFilterComponent,
  PaymentFilterComponent,
  paymentFilterStatePath,
  fuelFilterStatePath
} from './FiltersComponents';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

interface AircraftProps {
  id: number;
  registration: string;
}
interface BookingProps {
  id: number;
  name: string;
  bookingNumber: number;
}

export interface FuelExpenseRow {
  id: string;
  date: string;
  aircraft: AircraftProps;
  booking: BookingProps;
  flightReport: string;
  vendor: string;
  quantity: string;
  amount: string;
  document: string;
  type: string;
  paymentMethod: string;
  vendorInvoiceNumber: string;
  receiptUri: string[];
  refuelId: string;
  userName: string;
}

export const columns: Column[] = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 120,
    align: 'center',
    sortingProps: [['date']]
  },
  {
    id: 'aircraft',
    label: 'Aircraft',
    minWidth: 90,
    align: 'center',
    sortingProps: [['aircraft', 'registration']],
    filterComponent: AircraftFilterComponent,
    filterStatePath: aircraftFilterStatePath
  },
  {
    id: 'booking',
    label: 'Booking',
    minWidth: 90,
    align: 'center',
    sortingProps: [['booking', 'bookingNumber']],
    filterComponent: BookingFilterComponent,
    filterStatePath: bookingFilterStatePath,
    isNumber: true
  },
  {
    id: 'flightReport',
    label: 'Flight report',
    minWidth: 120,
    align: 'center',
    sortingProps: [['flightReport']],
    filterComponent: FlightReportFilterComponent,
    filterStatePath: flightReportFilterStatePath
  },
  {
    id: 'vendor',
    label: 'Vendor',
    minWidth: 110,
    align: 'center',
    sortingProps: [['vendor']],
    filterComponent: VendorFilterComponent,
    filterStatePath: vendorFilterStatePath
  },
  {
    id: 'pilot',
    label: 'Pilot',
    minWidth: 110,
    align: 'center',
    sortingProps: [['pilot']],
    filterComponent: PilotFilterComponent,
    filterStatePath: pilotFilterStatePath
  },
  {
    id: 'quantity',
    label: 'Quantity',
    minWidth: 90,
    align: 'center',
    sortingProps: [['quantity']]
  },
  {
    id: 'amount',
    label: '$',
    minWidth: 90,
    align: 'center',
    sortingProps: [['amount']]
  },
  {
    id: 'documentHash',
    label: 'Document #',
    minWidth: 110,
    align: 'center',
    sortingProps: [['document']],
    filterComponent: DocumentHashFilterComponent,
    filterStatePath: documentHashStatePath
  },
  {
    id: 'type',
    label: 'Type',
    minWidth: 90,
    align: 'center',
    sortingProps: [['type']],
    filterComponent: FuelTypeFilterComponent,
    filterStatePath: fuelFilterStatePath
  },
  {
    id: 'paymentMethod',
    label: 'Payment Method',
    minWidth: 90,
    align: 'center',
    sortingProps: [['paymentMethod']],
    filterComponent: PaymentFilterComponent,
    filterStatePath: paymentFilterStatePath
  },
  {
    id: 'invoiceNumber',
    label: 'Vendor Invoice Number',
    minWidth: 110,
    align: 'center',
    sortingProps: [['vendorInvoiceNumber']],
    filterComponent: InvoiceNumberFilterComponent,
    filterStatePath: invoiceNumberFilterStatePath
  }
];

export interface FiltersProps {
  aircraftFilters: number;
  bookingFilters: number;
  vendorFilters: number;
  pilotFilters: number;
  documentHashFilters: number;
  invoiceNumberFilters: any;
  flightReportFilters: number;
  fuelTypeFilters: number;
  paymentTypeFilters: number;
}

const isAllAircraftFilters = R.propEq('aircraftFilters', -1);
const isAllBookingFilters = R.propEq('bookingFilters', -1);
const isAllVendorFilters = R.propEq('vendorFilters', -1);
const isAllPilotFilters = R.propEq('pilotFilters', -1);
const isAllDocumentHashFilters = R.propEq('documentHashFilters', -1);
const isAllInvoiceNumberFilters = R.propEq('invoiceNumberFilters', -1);
const isAllFlightReportFilters = R.propEq('flightReportFilters', -1);
const isAllFuelFilters = R.propEq('fuelTypeFilters', -1);
const isAllPaymentFilters = R.propEq('paymentTypeFilters', -1);

const isAllFiltersAppliedEvery = R.allPass([
  isAllAircraftFilters,
  isAllBookingFilters,
  isAllVendorFilters,
  isAllPilotFilters,
  isAllDocumentHashFilters,
  isAllInvoiceNumberFilters,
  isAllFlightReportFilters,
  isAllFuelFilters,
  isAllPaymentFilters
]);

export const filterFuelExpenseScreenData = (
  fuelExpenseScreenData: FuelExpenseRow[],
  filters: FiltersProps
) => {
  if (isAllFiltersAppliedEvery(filters)) {
    return fuelExpenseScreenData;
  }

  const {
    aircraftFilters,
    bookingFilters,
    vendorFilters,
    pilotFilters,
    documentHashFilters,
    invoiceNumberFilters,
    flightReportFilters,
    fuelTypeFilters,
    paymentTypeFilters
  } = filters;

  let filteredDataArray = fuelExpenseScreenData;

  if (!isAllAircraftFilters({ aircraftFilters })) {
    const isFilterWithAircraft = (obj: {}) =>
      R.equals(R.path(['aircraft', 'id'], obj), aircraftFilters);

    filteredDataArray = R.filter(isFilterWithAircraft, filteredDataArray);
  }

  if (!isAllBookingFilters({ bookingFilters })) {
    const isFilterWithBooking = (obj: {}) =>
      R.equals(R.path(['booking', 'id'], obj), bookingFilters);

    filteredDataArray = R.filter(isFilterWithBooking, filteredDataArray);
  }

  if (!isAllVendorFilters({ vendorFilters })) {
    const isFilterWithVendor = (obj: {}) =>
      R.equals(
        R.toLower(R.pathOr('', ['vendor'], obj)),
        R.toLower(`${vendorFilters}`)
      );

    filteredDataArray = R.filter(isFilterWithVendor, filteredDataArray);
  }

  if (!isAllPilotFilters({ pilotFilters })) {
    const isFilterWithPilot = (obj: {}) =>
      R.equals(
        R.toLower(R.pathOr('', ['userName'], obj)),
        R.toLower(`${pilotFilters}`)
      );

    filteredDataArray = R.filter(isFilterWithPilot, filteredDataArray);
  }

  if (!isAllFuelFilters({ fuelTypeFilters })) {
    const isFilterWithFuel = (obj: {}) =>
      R.equals(R.path(['type'], obj), fuelTypeFilters);

    filteredDataArray = R.filter(isFilterWithFuel, filteredDataArray);
  }

  if (!isAllPaymentFilters({ paymentTypeFilters })) {
    const isFilterWithPayment = (obj: {}) =>
      R.equals(R.path(['paymentMethod'], obj), paymentTypeFilters);

    filteredDataArray = R.filter(isFilterWithPayment, filteredDataArray);
  }

  if (!isAllFlightReportFilters({ flightReportFilters })) {
    const isFilterWithFlightReport = (obj: {}) =>
      R.equals(R.path(['flightReport'], obj), flightReportFilters);

    filteredDataArray = R.filter(isFilterWithFlightReport, filteredDataArray);
  }

  if (!isAllDocumentHashFilters({ documentHashFilters })) {
    const isFilterWithDocumentHash = (obj: {}) =>
      R.equals(
        R.toLower(R.pathOr('', ['document'], obj)),
        R.toLower(`${documentHashFilters}`)
      );

    filteredDataArray = R.filter(isFilterWithDocumentHash, filteredDataArray);
  }

  if (!isAllInvoiceNumberFilters({ invoiceNumberFilters })) {
    const isFilterWithInvoiceNumber = (obj: {}) =>
      R.equals(
        R.toLower(R.pathOr('', ['vendorInvoiceNumber'], obj)),
        R.toLower(`${invoiceNumberFilters}`)
      );

    filteredDataArray = R.filter(isFilterWithInvoiceNumber, filteredDataArray);
  }

  return filteredDataArray;
};
