import { getMonthStartToCurrentDateRange } from '../../utils/helper';

export interface RangeProps {
  to: Date;
  from: Date;
}
// export const STATUS_FILTERS = 'statusFilters';
export const RANGE = 'range';
export const BOOKING_FILTERS = 'bookingFilters';
export const CUSTOMER_FILTERS = 'customerFilters';
export const AIRCRAFT_FILTERS = 'aircraftFilters';
export const PILOT_FILTERS = 'pilotFilters';
export const AME_FILTERS = 'ameFilters';
export const STATUS_FILTERS = 'statusFilters';
export const STATUS_FILTERS_APPROVED = 'statusFiltersApproved';
export const COPILOT_FILTERS = 'copilotFilters';
export const AIRCRAFT_TYPE_FILTERS = 'aircraftTypeFilters';
export const FLIGHT_TYPE = 'flightTypeFilters';
export const INVOICE_NUMBER_FILTERS = 'invoiceNumberFilters';
export const STATUS_TRACK_FILTERS = 'statusTrackFiltersArray';
export const APPROVED_BY = 'approvedFilters';
export const flightreportsFliters = 'flightreportsFliters';
export const apprenticesFilters = 'filtersApprentices';
export const commentFilters = 'commentFilters';
export const IS_BILLING = 'isBilling';
export const initialValues = {
  range: getMonthStartToCurrentDateRange(),
  [AIRCRAFT_TYPE_FILTERS]: -1,
  [BOOKING_FILTERS]: -1,
  [CUSTOMER_FILTERS]: -1,
  [AIRCRAFT_FILTERS]: -1,
  [PILOT_FILTERS]: -1,
  [COPILOT_FILTERS]: -1,
  [AME_FILTERS]: -1,
  [flightreportsFliters]: -1,
  [apprenticesFilters]: -1,
  // [STATUS_FILTERS]: -1,
  [STATUS_FILTERS]: -1,
  [STATUS_FILTERS_APPROVED]: -1,
  [commentFilters]: -1,
  [APPROVED_BY]: -1,
  [INVOICE_NUMBER_FILTERS]: -1,
  [FLIGHT_TYPE]: -1
};

export const filtersNotWorkingOptions = [
  { label: 'All', value: -1 },
  { label: 'Filter not working', value: -2 }
];

export const statusFilters = [
  { id: 2, name: 'Ready To Bill' },
  { id: 3, name: 'In Progress' }
];
