/**
 * Makes the request with the created configuration
 */

import * as R from 'ramda';
import { kernel, serverDownResponse } from '../response';
import fetch, { RequestInit } from 'node-fetch';
import {
  getEncodedBody,
  getLocalStorageTokens,
  getStringifyBody
} from '../../utils/helper';
import * as endpoint from '../../../services/endpoints';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || '';

const request = async (
  url: string,
  method: string,
  apiHeader: { [key: string]: string } = {},
  body: {} = {},
  decamelizeKeys: boolean = true
) => {
  const { accessToken } = getLocalStorageTokens();
  // Default headers
  const headers: { [key: string]: string } = {
    Accept: '*/*',
    'Content-Type': 'application/json',
    //Not sending the Authorization token header in /auth/token , auth/refresh Apis
    ...(url != endpoint.AUTH_TOKEN(true) &&
      url != endpoint.AUTH_TOKEN_REFRESH &&
      accessToken != '' && { Authorization: `Bearer ${accessToken}` })
  }; //;charset=UTF-8
  let options: RequestInit = { method: '', headers: {} };

  options.method = method;
  options.timeout = 10000;

  // Combining headers
  options.headers = {
    ...headers,
    ...apiHeader
  };

  if (R.includes(method, ['POST', 'PATCH', 'PUT', 'DELETE'])) {
    if (
      options.headers['Content-Type'] ===
      'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      const encodedBody = getEncodedBody(body);
      options.body = encodedBody;
    } else {
      const stringifyBody = getStringifyBody(body, decamelizeKeys);
      options.body = stringifyBody;
    }
  }

  // Returns after adding request and kernel filteration

  return fetch(BASE_URL + url, options)
    .then(kernel)
    .catch(serverDownResponse);
};

export default request;
