import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import {
  StatusFilterComponent,
  DepartmentFilterComponent,
  statusFilterStatePath,
  departmentFilterStatePath,
  FirstNameFilterComponent,
  firstNameFilterStatePath,
  LastNameFilterComponent,
  lastNameFilterStatePath,
  supervisorFilterStatePath,
  SuperVisorFilterComponent
} from './FiltersComponents';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id:
    | 'firstName'
    | 'lastName'
    | 'department'
    | 'total'
    | 'status'
    | 'approvedBy'
    | 'reviewedBy'
    | 'supervisor'
    | 'missingReceiptsWithImages';
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

export interface DepartmentFilterProps {
  id: number;
  name: string;
}

export interface Row {
  id: string;
  firstName: string;
  lastName: string;
  department: DepartmentFilterProps;
  total: number;
  status: string;
  approvedBy: string;
  reviewedBy: string;

  // supervisor: string;

  dailies: [];
}

export const columns: Column[] = [
  {
    id: 'firstName',
    label: 'First Name',
    minWidth: 170,
    align: 'center',
    sortingProps: [['firstName']],
    filterComponent: FirstNameFilterComponent,
    filterStatePath: firstNameFilterStatePath
  },
  {
    id: 'lastName',
    label: 'Last Name',
    minWidth: 170,
    align: 'center',
    sortingProps: [['lastName']],
    filterComponent: LastNameFilterComponent,
    filterStatePath: lastNameFilterStatePath
  },
  {
    id: 'department',
    label: 'Department',
    minWidth: 170,
    align: 'center',
    sortingProps: [['department', 'name']],
    filterComponent: DepartmentFilterComponent,
    filterStatePath: departmentFilterStatePath
  },
  {
    id: 'total',
    label: 'Total',
    minWidth: 100,
    align: 'center',
    isNumber: true,
    sortingProps: [['total']]
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 200,
    align: 'center',
    sortingProps: [['status']],
    filterComponent: StatusFilterComponent,
    filterStatePath: statusFilterStatePath
  },

  {
    id: 'supervisor',
    label: 'Supervisor',
    minWidth: 200,
    align: 'center',
    sortingProps: [['supervisor']],
    filterComponent: SuperVisorFilterComponent,
    filterStatePath: supervisorFilterStatePath
  },
  {
    id: 'missingReceiptsWithImages',
    label: 'Missing Receipts',
    minWidth: 200,
    align: 'center',
    sortingProps: [['missingReceiptsWithImages']]
    // filterComponent: StatusFilterComponent,
    // filterStatePath: statusFilterStatePath
  },

  {
    id: 'reviewedBy',
    label: 'Reviewed By',
    minWidth: 170,
    align: 'center',
    sortingProps: [['reviewedBy']]
  },
  {
    id: 'approvedBy',
    label: 'Approved By',
    minWidth: 170,
    align: 'center',
    sortingProps: [['approvedBy']]
  }
];

export const filterUserData = (
  userDataArray: Row[],
  departmentFilter: number,
  statusFilter: string,
  firstNameFilter: number,
  lastNameFilter: number,
  supervisorFilter: any
) => {
  let filterUserDataArray = userDataArray;

  console.log(filterUserDataArray, 'filterUserDataArray');
  if (departmentFilter !== -1) {
    filterUserDataArray = R.filter(
      (userData: Row) => userData.department.id === departmentFilter,
      filterUserDataArray
    );
  }

  if (firstNameFilter !== -1) {
    filterUserDataArray = R.filter(
      (userData: Row) => userData.id === firstNameFilter?.toString(),
      filterUserDataArray
    );
  }

  if (supervisorFilter !== -1) {
    console.log(supervisorFilter, 'idx');
    filterUserDataArray = R.filter(
      (userData: Row) => userData.id === supervisorFilter?.toString(),
      filterUserDataArray
    );
  }

  console.log(supervisorFilter, 'supervisorFilter');

  if (lastNameFilter !== -1) {
    filterUserDataArray = R.filter(
      (userData: Row) => userData.id === lastNameFilter?.toString(),
      filterUserDataArray
    );
  }

  if (statusFilter !== 'all') {
    filterUserDataArray = R.filter(
      (userData: Row) => (userData.status || 'missing') === statusFilter,
      filterUserDataArray
    );
  }

  return filterUserDataArray;
};

export const missingReceiptsWithImagesCount = (row: Row) => {
  const excludeExpenseTypes = [
    2, 3, 4, 5, 6, 7, 11, 12, 13, 30, 31, 32, 33, 34, 35, 39, 40, 41
  ];
  let missingReceiptsWithImages = 0;
  row.dailies.map((weekly: any) => {
    if (weekly.expenses.details.length > 0) {
      weekly.expenses.details.map((exp: any) => {
        if (excludeExpenseTypes.indexOf(exp.expenseType.id) === -1) {
          if (exp.receiptUri === null || exp.receiptUri?.length === 0) {
            missingReceiptsWithImages += 1;
          }
        }
      });
    }
  });
  return missingReceiptsWithImages;
};
