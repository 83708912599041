import types from '../utils/actionTypes';

export const setDetailsScreenSelectedFields = (
  departmentFilter: number,
  statusFilter: string,
  selectedFirstDate: Date
) => {
  return {
    type: types.SET_DETAILS_SCREEN_SELECTED_FIELDS,
    payload: {
      departmentFilter,
      statusFilter,
      selectedFirstDate
    }
  };
};

export const resetDetailsScreenSelectedFields = () => {
  return {
    type: types.RESET_DETAILS_SCREEN_SELECTED_FIELDS
  };
};

export const updateSelectedFirstDate = (value: any) => {
  return { type: 'UPDATED_SELECTED_FIRST_DATE', payload: { value } };
};
