import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import {
  bookingFilterStatePath,
  customerFilterStatePath,
  aircraftFilterStatePath,
  pilotFilterStatePath,
  ameFilterStatePath,
  statusFilterStatePath,
  BookingFilterComponent,
  CustomerFilterComponent,
  AircraftFilterComponent,
  PilotFilterComponent,
  COPilotFilterComponent,
  copilotFilterStatePath,
  AmeFilterComponent,
  StatusFilterComponent,
  aircraftTypeFilterStatePath,
  AircraftTypeFilterComponent,
  flightTypeFilterStatePath,
  FlightTypeFilterComponent,
  InvoiceNumberFilterComponent,
  InvoiceFilterStatePath,
  ApprovedByFilterComponent,
  ApprovedByFilterStatePath,
  StatusNewFilterComponent,
  statusApprovedFilterPath,
  FlightReportFilterComponent,
  flightReportFilterStatePath,
  CommentFilterComponent,
  CommentFilterStatePath,
  ApprenticeFilterComponent,
  ApprenticeFilterStatePath
} from './FiltersComponents';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

export const columns: Column[] = [
  {
    id: 'Status',
    label: 'Approval status',
    minWidth: 50,
    align: 'center',
    sortingProps: [['statusFiltersApproved']],
    filterComponent: StatusNewFilterComponent,
    filterStatePath: statusApprovedFilterPath
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 50,
    align: 'center',
    sortingProps: [['date']]
  },

  {
    id: 'aircraft',
    label: 'Aircraft',
    minWidth: 50,
    align: 'center',
    sortingProps: [['aircraft', 'registration']],
    filterComponent: AircraftFilterComponent,
    filterStatePath: aircraftFilterStatePath
  },
  {
    id: 'booking',
    label: 'Booking',
    minWidth: 50,
    align: 'center',
    isNumber: true,
    sortingProps: [['booking', 'bookingNumber']],
    filterComponent: BookingFilterComponent,
    filterStatePath: bookingFilterStatePath
  },
  {
    id: 'customer',
    label: 'Customer',
    minWidth: 90,
    align: 'center',
    sortingProps: [['customer', 'name']],
    filterComponent: CustomerFilterComponent,
    filterStatePath: customerFilterStatePath
  },

  {
    id: 'flightTypeFilters',
    label: 'Flight Type',
    minWidth: 50,
    align: 'center',
    sortingProps: [['aircraft', 'flightType']],
    filterComponent: FlightTypeFilterComponent,
    filterStatePath: flightTypeFilterStatePath
  },
  {
    id: 'flightReport',
    label: 'Flight report',
    minWidth: 50,
    align: 'center',
    sortingProps: [['flightReport']],
    filterComponent: FlightReportFilterComponent,
    filterStatePath: flightReportFilterStatePath
  },

  // {
  //   id: 'aircraftType',
  //   label: 'Aircraft Type',
  //   minWidth: 90,
  //   align: 'center',
  //   sortingProps: [['aircraftType']],
  //   filterStatePath: aircraftTypeFilterStatePath,
  //   filterComponent: AircraftTypeFilterComponent
  // },
  {
    id: 'aircraftType',
    label: 'AC Type',
    minWidth: 50,
    align: 'center',
    sortingProps: [['aircraftType']],
    filterStatePath: aircraftTypeFilterStatePath,
    filterComponent: AircraftTypeFilterComponent
  },
  {
    id: 'pilot',
    label: 'Pilot',
    minWidth: 50,
    align: 'center',
    sortingProps: [
      ['pilot', 'firstName'],
      ['pilot', 'lastName']
    ],
    filterComponent: PilotFilterComponent,
    filterStatePath: pilotFilterStatePath
  },

  {
    id: 'copilot',
    label: 'Co Pilot',
    minWidth: 90,
    align: 'center',
    sortingProps: [
      ['copilot', 'firstName'],
      ['copilot', 'lastName']
    ],
    filterComponent: COPilotFilterComponent,
    filterStatePath: copilotFilterStatePath
  },
  {
    id: 'ame',
    label: 'AME',
    minWidth: 50,
    align: 'center',
    sortingProps: [
      ['ame', 'firstName'],
      ['ame', 'lastName']
    ],
    filterComponent: AmeFilterComponent,
    filterStatePath: ameFilterStatePath
  },
  {
    id: 'apprentice',
    label: 'Apprentice',
    minWidth: 50,
    align: 'center',
    isNumber: true,
    sortingProps: [['apprentice']],
    filterComponent: ApprenticeFilterComponent,
    filterStatePath: ApprenticeFilterStatePath
  },
  {
    id: 'revenue',
    label: 'Rev Hrs',
    minWidth: 50,
    align: 'center',
    isNumber: true,
    sortingProps: [['revenue']]
  },
  {
    id: 'nonRevenue',
    label: 'Non-rev Hrs',
    minWidth: 50,
    align: 'center',
    isNumber: true,
    sortingProps: [['nonRevenue']]
  },
  {
    id: 'airTime',
    label: 'Air Time',
    minWidth: 50,
    align: 'center',
    isNumber: true,
    sortingProps: [['airTime']]
  },
  // {
  //   id: 'difference',
  //   label: 'AT/FT %',
  //   minWidth: 50,
  //   align: 'center',
  //   isNumber: true,
  //   sortingProps: [['difference']]
  // },
  {
    id: 'invoiceNumbers',
    label: 'Invoice Number',
    minWidth: 50,
    align: 'center',
    isNumber: true,
    sortingProps: [['invoiceNumbers']],
    filterComponent: InvoiceNumberFilterComponent,
    filterStatePath: InvoiceFilterStatePath
  },

  // {
  //   id: 'Readytobill',
  //   label: 'Ready to bill',
  //   minWidth: 50,
  //   align: 'center',
  //   sortingProps: [['Readytobill']]
  // },

  {
    id: 'approvedBy',
    label: 'Approved by',
    minWidth: 50,
    align: 'center',
    sortingProps: [['approvedBy']],
    filterComponent: ApprovedByFilterComponent,
    filterStatePath: ApprovedByFilterStatePath
  },

  {
    id: 'comments',
    label: 'Comments',
    minWidth: 50,
    align: 'center',
    sortingProps: [['comments']],
    filterComponent: CommentFilterComponent,
    filterStatePath: CommentFilterStatePath
  },

  {
    id: 'status',
    label: 'Mobile status',
    minWidth: 50,
    align: 'center',
    sortingProps: [['status', 'name']],
    filterComponent: StatusFilterComponent,
    filterStatePath: statusFilterStatePath
  }
  // {
  //   id: 'status',
  //   label: 'status',
  //   minWidth: 50,
  //   align: 'center',
  //   sortingProps: [['status', 'name']],
  //   filterComponent: StatusFilterComponent,
  //   filterStatePath: statusFilterStatePath
  // }
];
// statusFilters
interface AircraftProps {
  aircraftType(
    aircraftType: any,
    arg1: string
  ):
    | string
    | number
    | boolean
    | {}
    | import('react').ReactElement<
        any,
        string | import('react').JSXElementConstructor<any>
      >
    | import('react').ReactNodeArray
    | import('react').ReactPortal
    | null
    | undefined;
  firstName: string;
  aircraft_type: string;
  id: number;
  registration: string;
}

interface BookingProps {
  id: number;
  name: string;
  bookingNumber: number;
}

interface CustomerProps {
  id: number;
  name: string;
}

interface PilotProps {
  id: number;
  firstName: string;
  lastName: string;
}

interface AmeProps {
  id: number;
  firstName: string;
  lastName: string;
}

interface StatusProps {
  id: number;
  name: string;
}

export interface FlightReportRow {
  id: string;
  date: string;
  booking: BookingProps;
  customer: CustomerProps;
  flightReport: number;
  aircraft: AircraftProps;
  flightReportFilters: any;
  pilot: PilotProps;
  aircraftTypeFilters: any;
  copilot: any;
  ame: AmeProps;
  status: StatusProps;
  apprenticesFilters: number;
  statusFiltersApproved: any;
  flightType: any;
  revenue: number;
  nonRevenue: number;
  airTime: number;
  commentfilters: number;
  difference: number;
}
// apprenticesFilters = 'filtersApprentices';
const isAllBookingFilters = R.propEq('bookingFilters', -1);
const isAllapprenticesFilters = R.propEq('apprenticesFilters', -1);

const isAllCustomerFilters = R.propEq('customerFilters', -1);
const isAllAircraftFilters = R.propEq('aircraftFilters', -1);
const isAllPilotFilters = R.propEq('pilotFilters', -1);
const isAllcoPilotFilters = R.propEq('copilotFilters', -1);
const isAllAmeFilters = R.propEq('ameFilters', -1);
const isAllStatusFilters = R.propEq('statusFilters', -1);
const isAllStatusApprovedFilters = R.propEq('statusFiltersApproved', -1);
const isAllCommentFilters = R.propEq('commentFilters', -1);
const isAllFlightReportFilters = R.propEq('flightreportsFliters', -1);
const isAllAircraftTypeFilters = R.propEq('aircraftTypeFilters', -1);
const isAllAircraftFlightTypeFilters = R.propEq('flightTypeFilters', -1);
const isAllApprovedFlightFilters = R.propEq('approvedFilters', -1);
const isAllInvoiceNumberFilters = R.propEq('invoiceNumberFilters', -1);
const isAllFiltersAppliedEvery = R.allPass([
  isAllBookingFilters,
  isAllCustomerFilters,
  isAllAircraftFilters,
  isAllPilotFilters,
  isAllAmeFilters,
  isAllStatusFilters,
  isAllStatusApprovedFilters,
  isAllcoPilotFilters,
  isAllAircraftTypeFilters,
  isAllFlightReportFilters,
  isAllAircraftFlightTypeFilters,
  isAllapprenticesFilters,
  isAllCommentFilters,
  isAllApprovedFlightFilters,
  isAllInvoiceNumberFilters
]);

export const filterFlightReportScreenData = (
  flightReportScreenData: FlightReportRow[],
  filters: {}
) => {
  if (isAllFiltersAppliedEvery(filters)) {
    return flightReportScreenData;
  }
  const flightreportsFliters = R.pathOr(-1, ['flightreportsFliters'], filters);
  const commentFilters = R.pathOr(-1, ['commentFilters'], filters);
  const apprenticesFilters = R.pathOr(-1, ['filtersApprentices'], filters);
  const bookingFilters = R.pathOr(-1, ['bookingFilters'], filters);
  const customerFilters = R.pathOr(-1, ['customerFilters'], filters);
  const aircraftFilters = R.pathOr(-1, ['aircraftFilters'], filters);
  const pilotFilters = R.pathOr(-1, ['pilotFilters'], filters);
  const copilotFilters = R.pathOr(-1, ['copilotFilters'], filters);
  const ameFilters = R.pathOr(-1, ['ameFilters'], filters);
  const statusFilters = R.pathOr(-1, ['statusFilters'], filters);
  const aircraftTypeFilters = R.pathOr(-1, ['aircraftTypeFilters'], filters);
  const statusFiltersApproved = R.pathOr(
    -1,
    ['statusFiltersApproved'],
    filters
  );
  const flightTypeFilters = R.pathOr(-1, ['flightTypeFilters'], filters);
  const approvedFilters = R.pathOr(-1, ['approvedFilters'], filters);
  const invoiceNumberFilters = R.pathOr(-1, ['invoiceNumberFilters'], filters);

  let filteredDataArray = flightReportScreenData;

  if (!isAllBookingFilters({ bookingFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['booking', 'id'], obj), bookingFilters);
    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllCommentFilters({ commentFilters })) {
    let newFilterArray = filteredDataArray.filter(
      (el: any) => el.commentCount == commentFilters
    );
    filteredDataArray = newFilterArray;
  }

  if (!isAllapprenticesFilters({ apprenticesFilters })) {
    let newFilterArray = filteredDataArray.filter((el: any) => {
      let name = el.apprentice.firstName + ' ' + el.apprentice.lastName;
      return name == String(apprenticesFilters);
    });
    filteredDataArray = newFilterArray;
  }

  if (!isAllFlightReportFilters({ flightreportsFliters })) {
    let newFilterArray = filteredDataArray.filter(
      (el: any) => el.flightReport == flightreportsFliters
    );
    filteredDataArray = newFilterArray;
  }

  if (!isAllInvoiceNumberFilters({ invoiceNumberFilters })) {
    let newFilterArray = filteredDataArray.filter(
      (el: any) => el.invoiceNumber == invoiceNumberFilters
    );
    filteredDataArray = newFilterArray;
  }

  if (!isAllApprovedFlightFilters({ approvedFilters })) {
    let newFilterArray = filteredDataArray.filter(
      (el: any) => el.approverName == approvedFilters
    );
    filteredDataArray = newFilterArray;
  }

  if (!isAllAircraftFlightTypeFilters({ flightTypeFilters })) {
    let newFilterArray = filteredDataArray.filter(
      (el: any) => el.flightType == flightTypeFilters
    );
    filteredDataArray = newFilterArray;
  }

  // flightReportStatus
  if (!isAllStatusFilters({ statusFilters })) {
    console.log(statusFilters, 'statusFilters');
    let newFilterArray = filteredDataArray.filter(
      (el: any) => el.flightReportStatus == statusFilters
    );
    filteredDataArray = newFilterArray;
  }

  if (!isAllStatusApprovedFilters({ statusFiltersApproved })) {
    let newFilterArray = filteredDataArray.filter((el: any) => {
      return String(statusFiltersApproved) === 'Approved'
        ? el.isApproved === true
        : el.isApproved === false;
    });
    filteredDataArray = newFilterArray;
  }
  if (!isAllAircraftTypeFilters({ aircraftTypeFilters })) {
    let newFilterArray = filteredDataArray.filter(
      (el: any) => el.aircraft?.aircraftType === aircraftTypeFilters
    );

    // const isFilter = (obj: {}) =>
    //   R.equals(R.path(['aircraft', 'id'], obj), aircraftTypeFilters);
    filteredDataArray = newFilterArray;
  }

  if (!isAllCustomerFilters({ customerFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['customer', 'id'], obj), customerFilters);
    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllAircraftFilters({ aircraftFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['aircraft', 'id'], obj), aircraftFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllcoPilotFilters({ copilotFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['copilot', 'id'], obj), copilotFilters);
    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllPilotFilters({ pilotFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['pilot', 'id'], obj), pilotFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllAmeFilters({ ameFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['ame', 'id'], obj), ameFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  return filteredDataArray;
};

export const getFlightReportScreenDataTotal = (
  flightReportScreenData: FlightReportRow[]
) => {
  let revenue = 0;
  let nonRevenue = 0;
  let airTime = 0;
  let difference = 0;
  let differenceCount = 0;

  if (flightReportScreenData === undefined) {
    return {
      revenue,
      nonRevenue,
      airTime,
      difference
    };
  }
  flightReportScreenData.forEach(
    (flightReportScreenRowData: FlightReportRow) => {
      revenue = Number(revenue) + Number(flightReportScreenRowData.revenue);
      nonRevenue =
        Number(nonRevenue) + Number(flightReportScreenRowData.nonRevenue);
      airTime = Number(airTime) + Number(flightReportScreenRowData.airTime);
      difference =
        Number(difference) + Number(flightReportScreenRowData.difference);

      const value = parseFloat(`${flightReportScreenRowData.difference}`);

      if (R.is(Number, value) && `${value}` !== 'NaN') {
        differenceCount = differenceCount + 1;
      }
    }
  );

  if (differenceCount !== 0) {
    difference = parseFloat(`${difference}`) / differenceCount;
  }

  return {
    revenue,
    nonRevenue,
    airTime,
    difference
  };
};
