import types from '../utils/actionTypes';
import { Row } from '../../components/WeeklyScreenTable/helper';

export const fetchDetailScreenDataRequest = (
  date: Date,
  departmentFilter: number,
  statusFilter: string,
  firstNameFilter: number,
  lastNameFilter: number,
  supervisorFilter: any
) => {
  return {
    type: types.FETCH_DETAILS_SCREEN_DATA_REQUEST,
    payload: {
      date,
      departmentFilter,
      statusFilter,
      firstNameFilter,
      lastNameFilter,
      supervisorFilter
    }
  };
};

export const fetchDetailScreenDataSuccess = (data: Row[]) => {
  return {
    type: types.FETCH_DETAILS_SCREEN_DATA_SUCCESS,
    payload: { dataArray: data }
  };
};

export const fetchDetailScreenDataFailure = () => {
  return {
    type: types.FETCH_DETAILS_SCREEN_DATA_FAILURE
  };
};

export const resetDetailScreenData = () => {
  return {
    type: types.RESET_DETAILS_SCREEN_DATA
  };
};

export const updateStatusDetailScreenDataRequest = (
  userId: string,
  unlock: boolean = false
) => {
  return {
    type: types.UPDATE_STATUS_DETAILS_SCREEN_DATA_REQUEST,
    payload: {
      userId,
      unlock
    }
  };
};

export const updateStatusDetailScreenDataSuccess = () => {
  return {
    type: types.UPDATE_STATUS_DETAILS_SCREEN_DATA_SUCCESS
  };
};

export const updateStatusDetailScreenDataFailure = () => {
  return {
    type: types.UPDATE_STATUS_DETAILS_SCREEN_DATA_FAILURE
  };
};

export const fetchExpenseTypesRequest = () => {
  return {
    type: types.FETCH_EXPENSE_TYPES_REQUEST
  };
};

export const fetchExpenseTypesSuccess = (expenseTypes: []) => {
  return {
    type: types.FETCH_EXPENSE_TYPES_SUCCESS,
    payload: {
      expenseTypes: expenseTypes
    }
  };
};

export const fetchExpenseTypesFailure = () => {
  return {
    type: types.FETCH_EXPENSE_TYPES_FAILURE
  };
};

export const fetchBookingsRequest = (selectedFirstDate: Date) => {
  return {
    type: types.FETCH_BOOKINGS_REQUEST,
    payload: { selectedFirstDate }
  };
};

export const fetchBookingsSuccess = (bookings: []) => {
  return {
    type: types.FETCH_BOOKINGS_SUCCESS,
    payload: { bookings: bookings }
  };
};

export const fetchBookingsFailure = () => {
  return {
    type: types.FETCH_BOOKINGS_FAILURE
  };
};

export const updateReceiptDetailScreenDataRequest = (updatedValues: {}) => {
  return {
    type: types.UPDATE_RECEIPT_DETAILS_SCREEN_DATA_REQUEST,
    payload: {
      updatedValues
    }
  };
};

export const updateReceiptDetailScreenDataSuccess = () => {
  return {
    type: types.UPDATE_RECEIPT_DETAILS_SCREEN_DATA_SUCCESS
  };
};

export const updateReceiptDetailScreenDataFailure = () => {
  return {
    type: types.UPDATE_RECEIPT_DETAILS_SCREEN_DATA_FAILURE
  };
};

export const currentIndex = (currentIndex: any) => {
  return {
    type: 'currentIndex',
    payload: { currentIndex }
  };
};

export const setCommentWeeklyDetailsRequest = (payload: {}) => {
  return {
    type: types.SET_COMMENT_DETAILS_SCREEN_REQUEST,
    payload
  };
};

export const setCommentWeeklyDetailsSuccess = () => {
  return {
    type: types.SET_COMMENT_DETAILS_SCREEN_SUCCESS
  };
};

export const setCommentWeeklyDetailsFailure = () => {
  return {
    type: types.SET_COMMENT_DETAILS_SCREEN_FAILURE
  };
};

export const saveComment = (payload: string) => {
  return {
    type: 'SAVE_COMMENT',
    payload
  };
};
