import { FlightReportScreenDataProps, ActionType } from '../utils/interfaces';

const initialState: any = {
  rangeFilter: false
};
// FLIGHT_REPORT_RANGE_UPDATE
const FlightReportRangeUpdate = (
  state: FlightReportScreenDataProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case 'FLIGHT_REPORT_RANGE_UPDATE':
      return {
        ...state,
        rangeFilter: action.payload.rangeFilter
      };
    default:
      return state;
  }
};

export default FlightReportRangeUpdate;
