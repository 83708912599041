import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Status from '../shared/Status';
import StoreState from '../../redux/utils/interfaces';
import { fetchWeeklyScreenDataRequest } from '../../redux/actions/weeklyScreenData';
import { fetchDepartmentFiltersRequest } from '../../redux/actions/weeklyScreenData';
import { setDetailsScreenSelectedFields } from '../../redux/actions/detailsScreenSelectedFields';
import {
  filterUserData,
  useStyles,
  columns,
  missingReceiptsWithImagesCount
} from './helper';
import { isPresent } from '../../utils/helper';
import SharedSpinner from '../shared/SharedSpinner';
import SharedTableHeader from '../shared/SharedTableHeader';
import { useSortHook } from '../../hooks/sortHooks';

import './style.scss';

function WeeklyScreenTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const {
    isLoading,
    userDataArray,
    selectedFirstDate,
    departmentFilter,
    statusFilter,
    firstNameFilter,
    lastNameFilter,
    supervisorFilter
  } = useSelector((state: StoreState) => {
    return {
      isLoading: state.weeklyScreenData.isLoading,
      userDataArray: state.weeklyScreenData.userDataArray,
      selectedFirstDate: state.weeklyScreenFilters.selectedFirstDate,
      departmentFilter: state.weeklyScreenFilters.departmentFilter,
      statusFilter: state.weeklyScreenFilters.statusFilter,
      firstNameFilter: state.weeklyScreenFilters.firstNameFilter,
      lastNameFilter: state.weeklyScreenFilters.lastNameFilter,
      supervisorFilter: state.weeklyScreenFilters.supervisorFilter
    };
  });

  const filteredUserDataArray = useMemo(
    () =>
      filterUserData(
        userDataArray,
        departmentFilter,
        statusFilter,
        firstNameFilter,
        lastNameFilter,
        supervisorFilter
      ),
    [
      userDataArray,
      departmentFilter,
      statusFilter,
      firstNameFilter,
      lastNameFilter,
      supervisorFilter
    ]
  );
  console.log(supervisorFilter, '@#@#');
  const {
    order,
    orderBy,
    sortedDataArray: sortedUserDataArray,
    handleRequestSort
  } = useSortHook({
    dataArray: filteredUserDataArray,
    columnsData: columns
  });

  const handleClickUser = useCallback(
    (userId: string) => {
      dispatch(
        setDetailsScreenSelectedFields(
          departmentFilter,
          statusFilter,
          selectedFirstDate
        )
      );

      history.push(`/details/${userId}`);
    },
    [
      dispatch,
      history,
      supervisorFilter,
      departmentFilter,
      selectedFirstDate,
      statusFilter
    ]
  );

  useEffect(() => {
    dispatch(fetchWeeklyScreenDataRequest(selectedFirstDate));
  }, [selectedFirstDate, dispatch]);

  useEffect(() => {
    dispatch(fetchDepartmentFiltersRequest());
  }, [dispatch]);

  if (isLoading) {
    return <SharedSpinner />;
  }
  console.log(selectedFirstDate, 'weeklyselectedFirstDate');
  return (
    <TableContainer component={Paper} className="weekly-table-container">
      <Table className={classes.table} aria-label="custom pagination table">
        <SharedTableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />
        {console.log(sortedUserDataArray, 'sortedUserDataArray)')}

        {isPresent(sortedUserDataArray) && (
          <TableBody>
            {sortedUserDataArray.map((row: any, index: number) => {
              const status = row.status || 'missing';
              const missingReceiptsWithImages =
                missingReceiptsWithImagesCount(row);
              return (
                <TableRow
                  key={`${row.id}_${index}`}
                  onClick={() => handleClickUser(row.id)}
                  className="custom-body-row">
                  <TableCell component="th" scope="row" align="center">
                    {row.firstName}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.lastName}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.department.name}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.total.toFixed(2)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <Status status={status} />
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.supervisorName}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {missingReceiptsWithImages !== 0
                      ? missingReceiptsWithImages
                      : ''}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.reviewedBy}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.approvedBy}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default WeeklyScreenTable;
