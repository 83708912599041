import React, { useMemo } from 'react';
import * as R from 'ramda';
import { reduxForm } from 'redux-form';
import { Helmet } from 'react-helmet';
import { Box, Paper } from '@material-ui/core';
import HeaderContainer from '../../containers/HeaderContainer';
import UsersSecondaryHeader from '../../components/UsersSecondaryHeader';
import UsersTable from '../../components/UsersTable';
import { USERS_NAV_KEY } from '../../utils/routesNavigationConstants';
import { useUsersHook } from './hooks';
import SharedSpinner from '../../components/shared/SharedSpinner';
import UsersDetailsHeader from './UsersDetailsHeader';
import UserForm from './UserForm';
import { isNilOrEmpty } from '../../utils/helper';
import { USERS_FILTERS_FORM } from '../../utils/reduxFormConstants';

import './styles.scss';

const Users = () => {
  const {
    isUsersDataLoading,
    usersData,
    supervisors,
    currentUserData,
    filteredUserTypes,
    isSubmitDisabled,
    isUserDetailsRouteMatch,
    isAddUserRouteMatch,
    showLicenseField,
    saveButtonHandler,
    goto,
    toggleUserStatusHandler
  } = useUsersHook();

  const initialValues = useMemo(() => {
    return {
      employeeId: R.pathOr('', ['employeeId'], currentUserData),
      firstName: R.pathOr('', ['firstName'], currentUserData),
      lastName: R.pathOr('', ['lastName'], currentUserData),
      bushpay: R.pathOr(false, ['bushpay'], currentUserData),
      userType: R.pathOr(-1, ['userType', 'id'], currentUserData),
      license1: R.pathOr('', ['license1'], currentUserData),
      supervisor: R.pathOr(-1, ['supervisor', 'id'], currentUserData),
      isReviewingSupervisor: R.pathOr(
        false,
        ['isReviewingSupervisor'],
        currentUserData
      ),

      isWeeklyWebpageAccess: R.pathOr(
        false,
        ['isWeeklyWebpageAccess'],
        currentUserData
      ),
      isLogbookMobileUser: R.pathOr(
        false,
        ['isLogbookMobileUser'],
        currentUserData
      ),
      isLogbookWebpageUser: R.pathOr(
        false,
        ['isLogbookWebpageUser'],
        currentUserData
      ),
      isJobsWebpageAccess: R.pathOr(
        false,
        ['isJobsWebpageAccess'],
        currentUserData
      ),
      isCustomersWebpageAccess: R.pathOr(
        false,
        ['isCustomersWebpageAccess'],
        currentUserData
      ),
      isEmployeesWebpageAccess: R.pathOr(
        false,
        ['isEmployeesWebpageAccess'],
        currentUserData
      ),
      isAircraftWebpageAccess: R.pathOr(
        false,
        ['isAircraftWebpageAccess'],
        currentUserData
      ),
      isFuelWebpageAccess: R.pathOr(
        false,
        ['isFuelWebpageAccess'],
        currentUserData
      ),
      isFlightreportWebpageAccess: R.pathOr(
        false,
        ['isFlightreportWebpageAccess'],
        currentUserData
      ),
      isBookingWebpageAccess: R.pathOr(
        false,
        ['isBookingWebpageAccess'],
        currentUserData
      ),
      isBillingWebpageAccess: R.pathOr(
        false,
        ['isBillingWebpageAccess'],
        currentUserData
      ),
      username: R.pathOr('', ['username'], currentUserData),
      phone: R.pathOr('', ['phone'], currentUserData),
      language: R.pathOr('', ['language'], currentUserData),
      isApprovingSupervisor: R.pathOr(
        false,
        ['isApprovingSupervisor'],
        currentUserData
      ),
      isClientWebpageAccess: R.pathOr(
        false,
        ['isClientWebpageAccess'],
        currentUserData
      ),
      isUnlockAccess: R.pathOr(false, ['isUnlockAccess'], currentUserData),
      isContractor: R.pathOr(false, ['isContractor'], currentUserData),
      isApprover: R.pathOr(false, ['isApprover'], currentUserData),
      isViewAdminAccess: R.pathOr(
        false,
        ['isViewAdminAccess'],
        currentUserData
      ),

      notes: R.pathOr('', ['notes'], currentUserData)
    };
  }, [currentUserData]);

  {
    console.log(currentUserData, 'currentUserData');
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Users</title>
      </Helmet>

      <HeaderContainer
        secondaryHeader={
          <UsersSecondaryHeader
            currentUserName={`${R.pathOr(
              '',
              ['firstName'],
              currentUserData
            )} ${R.pathOr('', ['lastName'], currentUserData)}`}
            isActive={isNilOrEmpty(
              R.pathOr(null, ['deletedAt'], currentUserData)
            )}
            isAddUserRouteMatch={isAddUserRouteMatch}
            isUserDetailsRouteMatch={isUserDetailsRouteMatch}
            isSubmitDisabled={isSubmitDisabled}
            saveButtonHandler={saveButtonHandler}
            toggleUserStatusHandler={toggleUserStatusHandler}
            goto={goto}
          />
        }
        navKey={USERS_NAV_KEY}>
        <Box py={2.5} className="user-route-container">
          {isUsersDataLoading ? (
            <SharedSpinner />
          ) : isAddUserRouteMatch || isUserDetailsRouteMatch ? (
            <Paper>
              <UsersDetailsHeader
                isUserDetailsRouteMatch={isUserDetailsRouteMatch}
                isAddUserRouteMatch={isAddUserRouteMatch}
                currentUserId={R.pathOr(-1, ['id'], currentUserData)}
                goto={goto}
              />

              <div className="users-details-table-body">
                <UserForm
                  initialValues={initialValues}
                  userTypes={filteredUserTypes}
                  supervisors={supervisors}
                  showLicenseField={showLicenseField}
                  currentUserData={currentUserData}
                  isActive={isNilOrEmpty(
                    R.pathOr(null, ['deletedAt'], currentUserData)
                  )}
                />
              </div>
            </Paper>
          ) : (
            <UsersTable
              usersData={usersData}
              userTypes={filteredUserTypes}
              goto={goto}
            />
          )}
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default reduxForm({
  form: USERS_FILTERS_FORM
})(Users);
