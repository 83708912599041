import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button
} from '@material-ui/core';
import React from 'react';
import {
  //reduxForm,
  Field
  //InjectedFormProps,
  // initialize
} from 'redux-form';
import moment from 'moment';
import ReduxFormInput from '../../components/shared/ReduxFormInput';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import StoreState from '../../redux/utils/interfaces';
import { FLIGHT_REPORT_FILTERS_FORM } from '../../utils/reduxFormConstants';
// import { useSelector } from 'react-redux';
// import { CLIENT_PORTAL_FILTERS_FORM } from '../../utils/reduxFormConstants';
// import * as R from 'ramda';
// import StoreState from '../../redux/utils/interfaces';
// handleonPostComment

function CommentsSection({ data, handleOnPostComment, flightReportIDx }: any) {
  console.log(flightReportIDx, 'flightReportIDx');
  const { userInformation } = useSelector((state: StoreState) => {
    return {
      userInformation: state.userInformation
    };
  });

  const { formValues } = useSelector((state: StoreState) => {
    return {
      formValues: R.pathOr(
        '',
        ['form', FLIGHT_REPORT_FILTERS_FORM, 'values', 'comment'],
        state
      )
    };
  });

  console.log(data.flightReportData, 'data.flightReportDataß');
  return (
    <>
      <List>
        {data.map((comment: any, index: any) => {
          console.log(comment, 'comments');
          return (
            <div key={index}>
              <ListItem alignItems="flex-start">
                {/* <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                  </ListItemAvatar> */}
                <ListItemText
                  primary={
                    <span style={{ display: 'flex' }}>
                      <Typography component="span" className="comment-massage">
                        <span className="comment-timestamp">
                          {moment(comment.utc_date)
                            .local()
                            .format('YYYY-MM-DD HH:mm A')}
                        </span>
                      </Typography>
                    </span>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        //sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        //color="text.primary"
                      >
                        {comment.comment}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider component="li" />
            </div>
          );
        })}
      </List>
      <div className="comment-textarea">
        <Field
          name="comment"
          component={ReduxFormInput}
          minRows={250}
          label="Enter a Comment"
          variant="outlined"
          multiline
        />
      </div>
      <div className="comment-submit-button">
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            handleOnPostComment(formValues, flightReportIDx, userInformation.id)
          }>
          Post comment
        </Button>
      </div>
    </>
  );
}

export default CommentsSection;
