import { FlightReportScreenDataProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: FlightReportScreenDataProps = {
  isLoading: true,
  flightReportData: []
};

const flightReportScreenData = (
  state: FlightReportScreenDataProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_FLIGHT_REPORT_SCREEN_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    // case 'UPDATE_BILLING':
    //   if (action.payload.id === 'allSelected') {
    //     return {
    //       ...state,
    //       flightReportData: state.flightReportData.map((item: any) => ({
    //         ...item,
    //         ReadyToBill: action.payload.checked
    //       }))
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       flightReportData: state.flightReportData.map((data: { id: string }) =>
    //         data.id === action.payload.id
    //           ? { ...data, ReadyToBill: action.payload.checked }
    //           : data
    //       )
    //     };
    //   }

    case types.FETCH_FLIGHT_REPORT_SCREEN_DATA_SUCCESS:
      return {
        ...state,
        flightReportData: action.payload.flightReportData,
        isLoading: false
      };

    case types.FETCH_FLIGHT_REPORT_SCREEN_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};

export default flightReportScreenData;
