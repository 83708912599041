import React, { useEffect, useCallback, useMemo } from 'react';
// import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import * as R from 'ramda';
import Chip from '@material-ui/core/Chip';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core';
import SharedSpinner from '../shared/SharedSpinner';
// import Dropdown from '../shared/Dropdown';
import { FlightReportScreenDataProps } from '../../redux/utils/interfaces';
import {
  useStyles,
  columns,
  FlightReportRow,
  filterFlightReportScreenData,
  getFlightReportScreenDataTotal
} from './helper';
// import { statusFilters } from '../../routes/FlightReport/helper';
import { isPresent, numberOrEmpty } from '../../utils/helper';
import SharedTableHeader from '../shared/SharedTableHeader';
import { useSortHook } from '../../hooks/sortHooks';
// import { DATA } from '../../utils/Data.js';
import './style.scss';
// import { useDispatch } from 'react-redux';
// import { updateisBillingFlightReport } from '../../redux/actions/flightReportScreenData';

interface FlightReportTableProps {
  formValues: {};
  flightReportScreenData: FlightReportScreenDataProps;
  currentSelectedStatus: boolean | object | any;
  setCurrentSelectedStatus: Function;

  setCurrentSelectedPdf: any;
  currentSelectedPdfIndex: any;
  goto: Function;
  filterFlag: boolean;
  range: any;
  setFilterFlag: any;
  setRange: Function;
}

const FlightReportTable = ({
  formValues,
  flightReportScreenData,
  currentSelectedStatus,
  setCurrentSelectedStatus,
  setCurrentSelectedPdf,
  goto,
  filterFlag,
  setFilterFlag,
  range,
  setRange
}: FlightReportTableProps) => {
  const classes = useStyles();
  const { isLoading, flightReportData } = flightReportScreenData;
  // const [filteredColumns, setFilteredColumns] = React.useState([
  //   ...columns.map((column) => {
  //     return R.omit(['filterStatePath', 'filterComponent'], column);
  //   })
  // ]);

  // useEffect(() => {
  //   console.log('UPDATE COLS');
  //   let columnsData = [...columns];
  //   if (range.to === '' || range.from === '') {
  //     columnsData = columns.map((column) => {
  //       return R.omit(['filterStatePath', 'filterComponent'], column);
  //     });
  //   }
  //   setFilteredColumns(columnsData);
  // }, [range.to, range.from]);

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   let customSelectedStatus = {};

  //   flightReportData.forEach((row: FlightReportRow) => {
  //     if (Number(row.status.id) !== 1) {
  //       customSelectedStatus[`${row.id}`] = row.status.id;
  //     }
  //   });

  //   setCurrentSelectedStatus(customSelectedStatus);
  // }, [flightReportData, setCurrentSelectedStatus]);

  // useEffect(() => {

  // }, [dispatch]);
  useEffect(() => {
    let customSelectedStatus = {};

    flightReportData.forEach((row: any) => {
      if (Number(row.Status) !== 1) {
        customSelectedStatus[`${row.id}`] = row.Status;
      }
    });

    setCurrentSelectedStatus(customSelectedStatus);
  }, [flightReportData, setCurrentSelectedStatus]);

  // const handleRowStatusValueChange = useCallback(
  //   (value: unknown, id: string) => {
  //     let customSelectedStatus = { ...currentSelectedStatus };
  //     customSelectedStatus[`${id}`] = value;
  //     setCurrentSelectedStatus(customSelectedStatus);
  //   },
  //   [currentSelectedStatus, setCurrentSelectedStatus]
  // );

  // const handelOnClickRow = useCallback(
  //   (row: FlightReportRow) => {
  //     goto(`/flightReport/${row.id}`);
  //   },
  //   [goto]
  // );

  const handelOnClickRow = useCallback(
    (row: FlightReportRow, index: number) => {
      // const flightReprotIndex = R.indexOf({id:row.id},flightReportData)
      setCurrentSelectedPdf(row.id);
      goto(`/flightReport/${row.id}`);
    },
    [goto]
  );

  const filteredFlightReportData = useMemo(
    () => filterFlightReportScreenData(flightReportData, formValues),
    [flightReportData, formValues]
  );

  console.log(filteredFlightReportData, 'filteredFlightReportData');

  const totals = useMemo(
    () => getFlightReportScreenDataTotal(filteredFlightReportData),
    [filteredFlightReportData]
  );
  // console.log(filteredFlightReportData, 'filteredFlightReportData');
  let { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    columnsData: columns,
    dataArray: filteredFlightReportData,
    initialSortById: 'date'
  });

  // let newsortedDataArray = React.useMemo(() => {
  //   if (filterFlag === false) {
  //     return sortedDataArray.filter((el) => el.isApproved === false);
  //   } else {
  //     return sortedDataArray;
  //   }
  // }, [filterFlag, sortedDataArray]);

  console.log('Filter Approved');

  console.log(sortedDataArray, 'newsortedDataArray');
  // const handleChange = (e: { target: { checked: any; name?: any } }) => {
  //   const { name, checked } = e.target;
  //   if (name === 'allSelected') {
  //     dispatch(updateisBillingFlightReport(name, checked));
  //   } else {
  //     sortedDataArray.map((item) =>
  //       item.id === name
  //         ? dispatch(updateisBillingFlightReport(item.id, checked))
  //         : item.id
  //     );
  //   }
  // };

  useEffect(() => {
    console.log(filteredColumns, 'columns');
  }, []);
  const filteredColumns = useMemo(() => {
    const columnsData = [...columns];
    const removedFilteredColumns = columns.map((column) => {
      return R.omit(['filterStatePath', 'filterComponent'], column);
    });

    if (!filterFlag) {
      return removedFilteredColumns;
    }

    // if (range.to === '' || range.from === '') {
    //   return removedFilteredColumns;
    // }
    return columnsData;
  }, [range.to, range.from, filterFlag]);

  console.log(filteredColumns, filterFlag, range, 'filteredColumns');
  // get unique date count from filteredFlightReportData
  const uniqueDateCount = useMemo(() => {
    if (filteredFlightReportData) {
      return filteredFlightReportData.map((row: any) => row.date);
    }
    return [];
  }, [filteredFlightReportData]);

  if (isLoading) {
    return <SharedSpinner />;
  }

  return (
    <div style={{ position: 'absolute' }}>
      {/* ReadyToBill */}
      <TableContainer component={Paper}>
        {/* {DATA.length > 1 && (
          <input
            type="checkbox"
            onChange={handleChange}
            name="allSelected"
            checked={
              DATA.filter((item) => item.ReadyToBill !== true).length < 1
            }
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: '60px',
              right: '24.3%',
              opacity: 1,
              zIndex: 1,
              fontSize: '40px !important'
            }}
          />
        )} */}
        <Table
          style={{ fontSize: '10px' }}
          className={classes.table}
          aria-label="custom pagination table">
          <SharedTableHeader
            order={order}
            orderBy={orderBy}
            columns={filteredColumns}
            handleRequestSort={handleRequestSort}
          />

          {/* {console.log(sortedDataArray, '@@sortedDataArray@@')} */}

          <TableBody>
            {sortedDataArray?.map((row: any, index: any) => {
              // const copilot =
              //   row.copilot?.firstName + '' + row.copilot?.lastName;
              // console.log(copilot.length, 'copilot');
              return (
                <>
                  <TableRow
                    key={row.id}
                    className="flight-report-custom-body-row"
                    onClick={() => handelOnClickRow(row, index)}>
                    <TableCell component="th" scope="row" align="left">
                      <Chip
                        label={row.isApproved ? 'Approved' : 'Not Approved'}
                        style={{
                          background: !row.isApproved ? '#e57676' : '#90c648',
                          color: '#ffff',
                          width: '110px'
                        }}
                      />
                    </TableCell>

                    <TableCell component="th" scope="row" align="left">
                      {row ? moment.utc(row?.date).format('MMM D YYYY') : ''}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {row.aircraft?.registration}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {row?.booking?.bookingNumber}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {row?.customer?.name}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {row.flightType}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {row.flightReport}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {row.aircraft?.aircraftType}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {row.pilot?.firstName[0] + '.' + row.pilot?.lastName}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {row.copilot?.firstName
                        ? row.copilot?.firstName[0] +
                          '.' +
                          row.copilot?.lastName
                        : ''}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {row.ame?.firstName
                        ? row.ame?.firstName[0] + '.' + row.ame?.lastName
                        : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.apprentice?.firstName
                        ? row.apprentice?.firstName[0] +
                          '.' +
                          row.apprentice?.lastName
                        : ''}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {numberOrEmpty(row.revenue, 1)}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {numberOrEmpty(row.nonRevenue, 1)}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {row.airTime}
                    </TableCell>
                    {/* <TableCell component="th" scope="row" align="center">
                      {numberOrEmpty(row.airTime, 1)}
                    </TableCell> */}

                    {/* <TableCell component="th" scope="row" align="center">
                      {row.difference}
                    </TableCell> */}

                    {/* <TableCell component="th" scope="row" align="center">
                      {isPresent(numberOrEmpty(row.difference, 1)) &&
                        `${numberOrEmpty(row.difference, 1)}%`}
                    </TableCell> */}

                    <TableCell component="th" scope="row" align="center">
                      {row.invoiceNumber}
                    </TableCell>

                    {/* <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      onClick={(e) => e.stopPropagation()}
                      style={{ cursor: 'pointer', opacity: 100 }}>
                      <input
                        type="checkbox"
                        name={row.id}
                        checked={row?.ReadyToBill || false}
                        style={{
                          cursor: 'pointer',
                          opacity: 100,
                          position: 'relative',
                          fontSize: '30px'
                        }}
                        onChange={handleChange}
                      />
                    </TableCell> */}

                    <TableCell component="th" scope="row" align="center">
                      {row?.approverName?.length
                        ? row.approverName?.split(' ')[0][0] +
                          '.' +
                          row?.approverName?.split(' ')[1]
                        : ''}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {String(row?.commentCount)}
                    </TableCell>

                    <TableCell component="th" scope="row" align="left">
                      <Chip
                        label={row.flightReportStatus}
                        style={{
                          background:
                            row.flightReportStatus?.toUpperCase() ===
                            'READY TO SIGN'
                              ? '#f8a309'
                              : row.flightReportStatus?.toUpperCase() ===
                                'NOT LOCKED'
                              ? '#e57676'
                              : '#8edb29',
                          color: '#ffff',
                          width: '110px'
                        }}
                      />
                    </TableCell>

                    {/* //row.pilot.signatureUri,'signatureUri' */}

                    {/* <TableCell component="th" scope="row" align="center">
                    <Dropdown
                      label="Set Status"
                      variant="outlined"
                      customClassNames="status-input-table"
                      withLabel={false}
                      value={currentRowStatusValue}
                      disabled={isDropdownDisabled}
                      optionsList={
                        isDropdownDisabled
                          ? [{ id: 1, name: 'Closed' }]
                          : statusFilters
                      }
                      handleChange={(
                        event: React.ChangeEvent<{
                          value: unknown;
                        }>
                      ) => {
                        if (!isDropdownDisabled) {
                          const { value } = event.target;
                          handleRowStatusValueChange(value, row.id);
                        }
                      }}
                    />
                  </TableCell> */}
                  </TableRow>
                </>
              );
            })}
            <TableRow className="totals-row-flight-report">
              <TableCell component="th" scope="row" align="left">
                Totals
              </TableCell>
              {numberOrEmpty(totals.revenue / flightReportData.length, 1) ? (
                <TableCell component="th" scope="row" colSpan={2}>
                  {R.uniq(uniqueDateCount).length} days and{' '}
                  {numberOrEmpty(
                    totals.revenue / R.uniq(uniqueDateCount).length,
                    1
                  )}{' '}
                  flight hours averaged per day
                </TableCell>
              ) : (
                <>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell component="th" scope="row"></TableCell>
                </>
              )}
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>

              <TableCell component="th" scope="row" align="center">
                {numberOrEmpty(totals.revenue, 1)}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {numberOrEmpty(totals.nonRevenue, 1)}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {numberOrEmpty(totals.airTime, 1)}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {isPresent(numberOrEmpty(totals.difference, 1)) &&
                  `${numberOrEmpty(totals.difference, 1)}%`}
              </TableCell>

              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default FlightReportTable;
