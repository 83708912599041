import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import api from '../../services/ApiModule';
import PdfViewer from './PdfViewer';
import { isNilOrEmpty } from '../../utils/helper';

interface PilotFlightReportPDFRenderProps {
  flightReportIds: string[];
  currentSelectedPdfIndex: string;
  setCurrentSelectedPdf: Function;
}

const PilotFlightReportPDFRender = ({
  flightReportIds,
  currentSelectedPdfIndex,
  setCurrentSelectedPdf
}: PilotFlightReportPDFRenderProps) => {
  const pdfInit: string[] = [];
  const [pdf, setPdf] = useState(pdfInit);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPdf = useCallback(async () => {
    setIsLoading(true);
    // get flightReportId from flightReportIds with currentSelectedPdfIndex
    // const flightReportId = flightReportIds.find((id: string) => id===currentSelectedPdfIndex);
    const response = await api.fetchFlightReportReceipt(
      currentSelectedPdfIndex
    );
    let data: string[] = [];
    if (response.success && !response.data.noContent) {
      const url = URL.createObjectURL(response.data);
      data.push(url);
    }
    setPdf(data);

    setIsLoading(false);
  }, [currentSelectedPdfIndex]);

  useEffect(() => {
    fetchPdf();
  }, [fetchPdf, currentSelectedPdfIndex]);
  
  return isLoading ? (
    <Typography
      variant="h4"
      gutterBottom
      className="no-receipts-available-text">
      Loading...
    </Typography>
  ) : isNilOrEmpty(pdf) ? (
    <Typography
      variant="h4"
      gutterBottom
      className="no-receipts-available-text">
      No Flight Report Available
    </Typography>
  ) : flightReportIds.length !== 0 ? (
    <React.Fragment>
      <PdfViewer
        pdfUrls={pdf}
        flightReportIds={flightReportIds}
        currentSelectedPdfIndex={currentSelectedPdfIndex}
        setCurrentSelectedPdf={setCurrentSelectedPdf}
      />
    </React.Fragment>
  ) : null;
};

export default PilotFlightReportPDFRender;
