import types from '../utils/actionTypes';

export const fetchFlightReportScreenDataRequest = (
  startDate: Date,
  endDate: Date,
  filterFlag?: boolean
) => {
  return {
    type: types.FETCH_FLIGHT_REPORT_SCREEN_DATA_REQUEST,
    payload: {
      startDate,
      endDate,
      filterFlag
    }
  };
};

export const fetchFlightReportScreenDataSuccess = (flightReportData: any) => {
  return {
    type: types.FETCH_FLIGHT_REPORT_SCREEN_DATA_SUCCESS,
    payload: { flightReportData }
  };
};

export const fetchFlightReportScreenDataFailure = () => {
  return {
    type: types.FETCH_FLIGHT_REPORT_SCREEN_DATA_FAILURE
  };
};

export const updateFlightReportScreenDataRequest = (
  updateValues: {}[],
  isCustomerFlightReport = false
) => {
  return {
    type: types.UPDATE_FLIGHT_REPORT_SCREEN_DATA_REQUEST,
    payload: {
      updateValues,
      isCustomerFlightReport
    }
  };
};

export const updateFlightReportScreenDataSuccess = () => {
  return {
    type: types.UPDATE_FLIGHT_REPORT_SCREEN_DATA_SUCCESS
  };
};

export const updateFlightReportScreenDataFailure = () => {
  return {
    type: types.UPDATE_FLIGHT_REPORT_SCREEN_DATA_FAILURE
  };
};

export const exportFlightReportScreenDataRequest = (withData: false) => {
  return {
    type: types.EXPORT_FLIGHT_REPORT_SCREEN_DATA_REQUEST,
    payload: { withData: withData }
  };
};

export const exportFlightReportScreenDataSuccess = () => {
  return {
    type: types.EXPORT_FLIGHT_REPORT_SCREEN_DATA_SUCCESS
  };
};

export const exportFlightReportScreenDataFailure = () => {
  return {
    type: types.EXPORT_FLIGHT_REPORT_SCREEN_DATA_FAILURE
  };
};
// yield put(fetchFlightReportScreenDataRequest(range.from, range.to));

export const updateFlightReportStatusRequest = (
  isLocked: any,
  isLockedOnClientPortal: boolean,
  id: string
) => {
  return {
    type: types.UPDATE_FLIGHT_REPORT_STATUS_REQUEST,
    payload: {
      isLocked,
      isLockedOnClientPortal,
      id
    }
  };
};

export const updateFlightReportStatusSuccess = () => {
  return {
    type: types.UPDATE_FLIGHT_REPORT_STATUS_SUCCESS
  };
};

export const updateFlightReportStatusFailure = () => {
  return {
    type: types.UPDATE_FLIGHT_REPORT_STATUS_FAILURE
  };
};

export const updateisBillingFlightReport = (id: string, checked: any) => {
  return {
    type: 'UPDATE_BILLING',
    payload: {
      id,
      checked
    }
  };
};

export const postFlightPortalCommentRequest = (data: any) => {
  return {
    type: 'ADD_FLIGHT_PORTAL_COMMENTS_REQUEST',
    payload: data
  };
};
export const postFlightPortalCommentSuccess = (commentData: any) => {
  return {
    type: 'ADD_FLIGHT_PORTAL_COMMENTS_SUCCESS',
    payload: { commentData }
  };
};
export const postFlightPortalCommentFailure = () => {
  return {
    type: 'ADD_FLIGHT_PORTAL_COMMENTS_FAILURE'
  };
};

export const FlightReportApproveStatusRequest = (id: string) => {
  return {
    type: 'UPDATE_FLIGHT_REPORT_APPROVE_STATUS',
    payload: {
      id
    }
  };
};
