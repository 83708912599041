import React, { useMemo } from 'react';
import AddIcon from '@material-ui/icons/AddOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import UnarchiveIcon from '@material-ui/icons/UnarchiveOutlined';
import Breadcrumbs from '../shared/Breadcrumbs';
import CustomSpeedDial from '../shared/CustomSpeedDial';
import Status from '../shared/Status';

import './style.scss';
import {
  ADD_AIRCRAFT_ROUTE,
  AIRCRAFT_ROUTE
} from '../../utils/routesNavigationConstants';
import { Box } from '@material-ui/core';

interface AircraftSecondaryHeaderProps {
  currentAircraftRegistration: string;
  isActive: boolean;
  isAircraftDetailsRouteMatch: boolean;
  isAddAircraftRouteMatch: boolean;
  isSubmitDisabled: boolean;
  saveButtonHandler: Function;
  toggleAircraftStatusHandler: Function;
  goto: Function;
}

const AircraftSecondaryHeader = ({
  currentAircraftRegistration,
  isActive,
  isAircraftDetailsRouteMatch,
  isAddAircraftRouteMatch,
  isSubmitDisabled,
  saveButtonHandler,
  toggleAircraftStatusHandler,
  goto
}: AircraftSecondaryHeaderProps) => {
  const breadcrumbDataArray = useMemo(() => {
    const breadcrumbDataArrayLocal = [
      {
        id: 'aircraft_breadcrum',
        label: 'Aircraft',
        onClick: () =>
          (isAddAircraftRouteMatch || isAircraftDetailsRouteMatch) &&
          goto(AIRCRAFT_ROUTE)
      }
    ];

    if (isAddAircraftRouteMatch) {
      breadcrumbDataArrayLocal.push({
        id: 'add_aircraft_breadcrum_name',
        label: 'Add Aircraft',
        onClick: () => {}
      });
    } else if (isAircraftDetailsRouteMatch) {
      breadcrumbDataArrayLocal.push({
        id: 'aircraft_breadcrum_name',
        label: currentAircraftRegistration,
        onClick: () => {}
      });
    }

    return breadcrumbDataArrayLocal;
  }, [
    goto,
    currentAircraftRegistration,
    isAddAircraftRouteMatch,
    isAircraftDetailsRouteMatch
  ]);

  const actions = useMemo(() => {
    if (isAddAircraftRouteMatch || isAircraftDetailsRouteMatch) {
      const localAction: any = [
        {
          icon: SaveIcon,
          name: 'Save',
          onClick: saveButtonHandler,
          disabled: isSubmitDisabled,
          showToast: false
        }
      ];

      if (isAircraftDetailsRouteMatch) {
        localAction.push({
          icon: isActive ? ArchiveIcon : UnarchiveIcon,
          name: isActive ? 'Archive' : 'Unarchive',
          onClick: toggleAircraftStatusHandler
        });
      }

      return localAction;
    }

    return [
      {
        icon: AddIcon,
        name: 'Add New',
        onClick: () => goto(ADD_AIRCRAFT_ROUTE)
      }
    ];
  }, [
    goto,
    saveButtonHandler,
    toggleAircraftStatusHandler,
    isAddAircraftRouteMatch,
    isAircraftDetailsRouteMatch,
    isSubmitDisabled,
    isActive
  ]);

  return (
    <React.Fragment>
      <div className="breadcrumbs-container-aircraft">
        <Box mr={5}>
          <Breadcrumbs breadcrumbDataArray={breadcrumbDataArray} />
        </Box>

        {isAircraftDetailsRouteMatch && (
          <Box>
            <Status status={isActive ? 'ACTIVE' : 'ARCHIVED'} />
          </Box>
        )}
      </div>

      <div className="aircraft-actions-buttons-container">
        <CustomSpeedDial actions={actions} />
      </div>
    </React.Fragment>
  );
};

export default AircraftSecondaryHeader;