import React, { useEffect } from 'react';
// import * as R from 'ramda';
import { Box, TextField } from '@material-ui/core';
import StoreState, {
  RowData,
  ExpenseTypes
} from '../../redux/utils/interfaces';
// import { setComment } from '../../redux/actions/detailsScreenData';
import DetailsWeekPreScreenTable from '../../components/DetailsWeekPreScreenTable';
import CalendarSelectorWeekly from '../../components/WeeklyPerEmployeeScreenSecondaryHeader/CalendarSelectorWeekly';
import {
  fetchWeeklyScreenDataRequest,
  // fetchDepartmentFiltersRequest, ,
  fetchWeeklyCurrentData
} from '../../redux/actions/weeklyScreenData';
import { useDispatch, useSelector } from 'react-redux';

interface DetailsScreenProps {
  currentUserIndex: number;
  length: number;
  handlePageChange: (changeIndex: number) => void;
  currentUserData: RowData;
  selectedFirstDate: Date;
  expenseTypes: ExpenseTypes[];
}

const DetailsScreen = ({
  currentUserIndex,
  length,
  handlePageChange,
  currentUserData,
  // selectedFirstDate,
  expenseTypes
}: DetailsScreenProps) => {
  const dispatch = useDispatch();

  const { selectedFirstDate, usersData, currentUser } = useSelector(
    (state: StoreState) => {
      return {
        currentUser: state.weeklyScreenData.currentUser,
        selectedFirstDate: state.weeklyScreenFilters.selectedFirstDate,
        usersData: state.weeklyScreenData.userDataArray
      };
    }
  );

  // const {
  //   comment,
  //   userInformation: { canReviewUserIds, isApprovingSupervisor }
  // } = useSelector((state: StoreState) => {
  //   return {
  //     comment: state.detailsScreenData.comment,
  //     userInformation: state.userInformation
  //   };
  // });

  // const isCommentFieldEditable =
  //   isApprovingSupervisor ||
  //   canReviewUserIds.includes(Number(currentUserData.id));

  // const managerComment = R.pathOr('', ['managerComment'], currentUserData);

  // const handleCommentChange = useCallback(
  //   (event: any) => {
  //     event.stopPropagation();
  //     dispatch(setComment(event.target.value));
  //   },
  //   [dispatch]
  // );

  const getUserData = () => {
    // return Object.keys(currentUser).length ? currentUser : usersData[0];
    return currentUser.id ? currentUser : usersData[0];
  };
  const [selectedUser, setSelectedUser] = React.useState<any>(
    getUserData() || {}
  );

  const handleChange = (e: any) => {
    e.stopPropagation();
    const user = usersData.find(
      (item) =>
        item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase() ===
        e.target.value.toLowerCase()
    );
    setSelectedUser(user);
  };

  useEffect(() => {
    const user = usersData.find(
      (item) =>
        item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase() ===
        selectedUser.firstName.toLowerCase() +
          ' ' +
          selectedUser.lastName.toLowerCase()
    );
    return setSelectedUser(user);
  }, [selectedUser.firstName, usersData]);

  console.log(selectedUser, '***selectedUser***');
  useEffect(() => {
    dispatch(fetchWeeklyCurrentData(selectedUser));
  }, [selectedUser, selectedFirstDate]);

  useEffect(() => {
    // console.log('WeeklyDATa3', selectedFirstDate, selectedUser);
    dispatch(fetchWeeklyScreenDataRequest(selectedFirstDate));
  }, [selectedFirstDate, dispatch]);

  // useEffect(() => {
  //   dispatch(fetchDepartmentFiltersRequest());
  // }, [selectedUser, currentUser, selectedFirstDate, dispatch]);

  console.log(selectedUser, currentUser, usersData, 'selectedUser');
  return (
    <React.Fragment>
      <div style={{ display: 'flex', marginTop: '17px' }}>
        <TextField
          style={{ marginRight: '50px' }}
          select
          value={selectedUser.firstName + ' ' + selectedUser.lastName}
          onChange={handleChange}
          SelectProps={{
            native: true
          }}
          variant="standard">
          {usersData
            .sort((a: RowData, b: RowData) => {
              let nameA =
                a.firstName.toLowerCase() + ' ' + b.lastName.toLowerCase();
              let nameB =
                b.firstName.toLowerCase() + ' ' + a.lastName.toLowerCase();

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
            .map((option: any) => (
              <option
                key={option.id}
                value={option.firstName + ' ' + option.lastName}>
                {option.firstName + ' ' + option.lastName}
              </option>
            ))}
        </TextField>

        <CalendarSelectorWeekly />
        <br />
        <br />
        <br />
      </div>

      {/* <div style={{ padding: 15, position: 'relative', zIndex: -1 }}>
        <TextField
          fullWidth
          label="Comments"
          variant="outlined"
          multiline
          minRows={3}
          maxRows={3}
          value={comment}
          onChange={handleCommentChange}
          //  disabled={!isCommentFieldEditable}
          disabled={isCommentFieldEditable}
        />
      </div> */}
      <Box py={2.5}>
        <DetailsWeekPreScreenTable
          currentUserData={selectedUser}
          selectedFirstDate={selectedFirstDate}
          expenseTypes={expenseTypes}
        />
      </Box>
    </React.Fragment>
  );
};

export default DetailsScreen;
