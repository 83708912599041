import React, { Fragment, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { isNilOrEmpty } from '../../utils/helper';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import './style.scss';

interface PdfRenderProps {
  isLoading?: boolean;
  onlyFirstPage?: boolean;
  pdf: string;
  scale?: number;
  rotationDegree?: number;
  activePageIndex?: number;
  setActivePageIndex?: Function;
}

const PdfRender = ({
  isLoading = false,
  onlyFirstPage = false,
  pdf,
  scale = 1.6,
  rotationDegree = 0,
  activePageIndex = 0,
  setActivePageIndex
}: // setActivePageIndex
PdfRenderProps) => {
  const [totalPages, setTotalPages] = useState(null);

  console.log(totalPages, '@@numPages@@');
  return (
    <div className="flight-report-container">
      {isLoading ? (
        <Typography
          variant="h4"
          gutterBottom
          className="no-receipts-available-text">
          Loading...
        </Typography>
      ) : isNilOrEmpty(pdf) ? (
        <Typography
          variant="h4"
          gutterBottom
          className="no-receipts-available-text">
          No Flight Report Available
        </Typography>
      ) : (
        <Box>
          <p>SIDE BAR IS COMING WIT</p>
          <Document
            file={pdf}
            onLoadSuccess={({ numPages }: any) => setTotalPages(numPages)}>
            {onlyFirstPage ? (
              <Page pageNumber={1} scale={scale} />
            ) : (
              <Fragment>
                {[...Array(totalPages)].map((e, i) => (
                  <div
                    onClick={() => {
                      if (setActivePageIndex) setActivePageIndex(i);
                    }}
                    style={{ opacity: activePageIndex === i ? '1' : '.7' }}>
                    <Page
                      key={i}
                      className={`pdfPage-${i + 1}`}
                      pageNumber={i + 1}
                      scale={scale}
                      rotate={rotationDegree}
                    />
                  </div>
                ))}
              </Fragment>
            )}
          </Document>
        </Box>
      )}
    </div>
  );
};

export default PdfRender;
