import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FilterAltIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      fill="white"
      width="48px"
      height="48px">
      <path d="M0,0h24 M24,24H0" fill="none" />
      <path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
      <path d="M0,0h24v24H0V0z" fill="none" />
    </SvgIcon>
  );
};

export default FilterAltIcon;
