import { put, takeLatest, delay } from 'redux-saga/effects';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import { userInfoSuccess, userInfoFailure } from '../actions/userInfo';
import { navigateTo } from '../../utils/history';
import {
  HOME_ROUTE,
  CLIENT_PORTAL_ROUTE,
  LOGIN_ROUTE
} from '../../utils/routesNavigationConstants';
import { toast } from 'react-toastify';
import { clearLocalStorage } from '../../services/utils/helper';
function* fetchUserInformation() {
  yield delay(1000);

  try {
    const userInfoStatus = yield api.fetchMe();
    console.log(userInfoStatus, 'userInfoStatus');

    if (
      userInfoStatus.success === false ||
      userInfoStatus.data === 'Unauthorized' ||
      userInfoStatus.data === 'Access Denied'
    ) {
      clearLocalStorage();
      navigateTo(LOGIN_ROUTE);
      window.location.reload();
    }

    const {
      id,
      firstName,
      company: { id: companyId, name: companyName },
      lastName,
      username: email,
      phone,
      language,
      license1,
      license2,
      isApprovingSupervisor,
      isReviewingSupervisor,
      isLogbookMobileUser,
      isLogbookWebpageUser,
      isJobsWebpageAccess,
      isCustomersWebpageAccess,
      isBillingWebpageAccess,
      isFlightreportWebpageAccess,
      isFuelWebpageAccess,
      isEmployeesWebpageAccess,
      isAircraftWebpageAccess,
      isBookingWebpageAccess,
      isClientUser,
      isClientWebpageAccess,
      isWeeklyWebpageAccess,
      isUnlockAccess,
      isViewAdminAccess,
      isApprover,
      canReviewUserIds = []
    } = userInfoStatus.data;

    const meInfo = {
      id,
      firstName,
      lastName,
      email,
      companyId,
      companyName,
      phone,
      language,
      license1,
      license2,
      isApprovingSupervisor,
      isReviewingSupervisor,
      isLogbookMobileUser,
      isLogbookWebpageUser,
      isJobsWebpageAccess,
      isCustomersWebpageAccess,
      isBillingWebpageAccess,
      isFlightreportWebpageAccess,
      isFuelWebpageAccess,
      isWeeklyWebpageAccess,
      isEmployeesWebpageAccess,
      isAircraftWebpageAccess,
      isBookingWebpageAccess,
      canReviewUserIds,
      isClientUser,
      isClientWebpageAccess,
      isUnlockAccess,
      isViewAdminAccess,
      isApprover
    };

    if (userInfoStatus.success) {
      yield put(userInfoSuccess(meInfo));
      if (userInfoStatus.data.isClientUser) {
        navigateTo(CLIENT_PORTAL_ROUTE);
      } else {
        navigateTo(HOME_ROUTE);
      }
    } else {
      yield put(userInfoFailure());
    }
  } catch (err) {
    console.log(err, 'err');
    toast.error(err);
    yield put(userInfoFailure());
  }
}

export default [
  takeLatest(types.FETCH_USER_INFO_REQUEST, fetchUserInformation)
];
