import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { statusFilterList } from '../../utils/helper';
import StoreState from '../../redux/utils/interfaces';
import {
  setDepartmentFilter,
  setFirstNameFilter,
  setLastNameFilter,
  setStatusFilter,
  setSupervisorFilter
} from '../../redux/actions/weeklyScreenFilters';
import { Row } from './helper';
// import { Field } from 'redux-form';
// import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';

export const statusFilterStatePath = ['weeklyScreenFilters', 'statusFilter'];
export const departmentFilterStatePath = [
  'weeklyScreenFilters',
  'departmentFilter'
];
export const firstNameFilterStatePath = [
  'weeklyScreenFilters',
  'firstNameFilter'
];
export const lastNameFilterStatePath = [
  'weeklyScreenFilters',
  'lastNameFilter'
];

export const supervisorFilterStatePath = [
  'weeklyScreenFilters',
  'supervisorFilter'
];

export const FirstNameFilterComponent = () => {
  const dispatch = useDispatch();
  const { weeklyScreenData } = useSelector((state: StoreState) => {
    return {
      weeklyScreenData: R.pathOr(
        [],
        ['weeklyScreenData', 'userDataArray'],
        state
      )
    };
  });
  const firstNameFiltersArray = useMemo(() => {
    let weeklyData: any = weeklyScreenData;

    const names = new Set<{ id: number; name: string }>();

    weeklyData.forEach(
      (data: { id: string; first_name: string }, index: string) => {
        names.add({ id: parseInt(data.id), name: data.first_name });
      }
    );

    let firstNameFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    names.forEach((data, key) => {
      firstNameFiltersArray.push({ id: data.id, name: data.name });
    });
    return R.uniq(firstNameFiltersArray);
  }, [weeklyScreenData]);
  const handleChangeName = (event: any, value: any) => {
    dispatch(setFirstNameFilter(value.id || -1));
  };
  const { firstNameFilter }: any = useSelector((state: StoreState) => ({
    firstNameFilter: R.pathOr(-1, firstNameFilterStatePath, state)
  }));

  return (
    <Autocomplete
      id="firstname-filter"
      value={firstNameFilter}
      options={firstNameFiltersArray}
      disableClearable
      getOptionLabel={(value: any) => {
        if (R.is(Number, value)) {
          const selectedOption = firstNameFiltersArray.find((option: any) => {
            return option.id === value;
          });

          return R.pathOr('', ['name'], selectedOption);
        }

        return R.pathOr('', ['name'], value);
      }}
      getOptionSelected={(option, value) => option['id'] === value}
      onChange={handleChangeName}
      renderInput={(params) => <TextField {...params} label="First Name" />}
    />
  );
};

export const LastNameFilterComponent = () => {
  const dispatch = useDispatch();
  const { weeklyScreenData } = useSelector((state: StoreState) => {
    return {
      weeklyScreenData: R.pathOr(
        [],
        ['weeklyScreenData', 'userDataArray'],
        state
      )
    };
  });
  const lastNameFiltersArray = useMemo(() => {
    let weeklyData: Row[] = weeklyScreenData;

    const names = new Set<{ id: number; name: string }>();

    weeklyData.forEach((data, index) => {
      names.add({ id: parseInt(data.id), name: data.lastName });
    });

    let lastNameFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    names.forEach((data, key) => {
      lastNameFiltersArray.push({ id: data.id, name: data.name });
    });

    return R.uniq(lastNameFiltersArray);
  }, [weeklyScreenData]);
  const handleChangeName = (event: any, value: any) => {
    dispatch(setLastNameFilter(value.id || -1));
  };
  const { lastNameFilter }: any = useSelector((state: StoreState) => ({
    lastNameFilter: R.pathOr(-1, lastNameFilterStatePath, state)
  }));

  return (
    <Autocomplete
      id="lastname-filter"
      value={lastNameFilter}
      options={lastNameFiltersArray}
      disableClearable
      getOptionLabel={(value: any) => {
        if (R.is(Number, value)) {
          const selectedOption = lastNameFiltersArray.find((option: any) => {
            return option.id === value;
          });

          return R.pathOr('', ['name'], selectedOption);
        }

        return R.pathOr('', ['name'], value);
      }}
      getOptionSelected={(option, value) => option['id'] === value}
      onChange={handleChangeName}
      renderInput={(params) => <TextField {...params} label="Last Name" />}
    />
  );
};

export const StatusFilterComponent = () => {
  const dispatch = useDispatch();

  const { statusFilter }: any = useSelector((state: StoreState) => ({
    statusFilter: R.pathOr('all', statusFilterStatePath, state)
  }));

  const handleChangeStatus = (event: any, value: any) => {
    dispatch(setStatusFilter(value.value || 'all'));
  };

  return (
    <Autocomplete
      id="department-filter"
      value={statusFilter}
      options={statusFilterList}
      disableClearable
      getOptionLabel={(value: any) => {
        if (R.is(String, value)) {
          const selectedOption = statusFilterList.find((option: any) => {
            return option.value === value;
          });

          return R.pathOr('', ['label'], selectedOption);
        }

        return R.pathOr('', ['label'], value);
      }}
      getOptionSelected={(option, value) =>
        R.pathOr('', ['value'], option) === value
      }
      onChange={handleChangeStatus}
      renderInput={(params) => <TextField {...params} label="Status" />}
    />
  );
};

export const DepartmentFilterComponent = () => {
  const dispatch = useDispatch();

  const { departmentFilter, departmentFiltersArray }: any = useSelector(
    (state: StoreState) => {
      return {
        departmentFilter: R.pathOr(-1, departmentFilterStatePath, state),
        departmentFiltersArray: state.weeklyScreenData.departmentFiltersArray
      };
    }
  );

  const handleChangeDepartment = (event: any, value: any) => {
    dispatch(setDepartmentFilter(value.id || -1));
  };

  return (
    <Autocomplete
      id="department-filter"
      value={departmentFilter}
      options={departmentFiltersArray}
      disableClearable
      getOptionLabel={(value: any) => {
        if (R.is(Number, value)) {
          const selectedOption = departmentFiltersArray.find((option: any) => {
            return option.id === value;
          });

          return R.pathOr('', ['name'], selectedOption);
        }

        return R.pathOr('', ['name'], value);
      }}
      getOptionSelected={(option, value) =>
        R.pathOr('', ['id'], option).toString() === value?.toString()
      }
      onChange={handleChangeDepartment}
      renderInput={(params) => <TextField {...params} label="Department" />}
    />
  );
};

export const SuperVisorFilterComponent = () => {
  const dispatch = useDispatch();
  const { weeklyScreenData } = useSelector((state: StoreState) => {
    return {
      weeklyScreenData: R.pathOr(
        [],
        ['weeklyScreenData', 'userDataArray'],
        state
      )
    };
  });
  const supervisorFiltersArray = useMemo(() => {
    let weeklyData: any = weeklyScreenData;

    const supervisors = new Set<{ id: number; name: string }>();

    weeklyData.forEach(
      (data: { id: string; supervisorName: string }, index: string) => {
        supervisors.add({ id: parseInt(data.id), name: data.supervisorName });
      }
    );

    let supervisorFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    supervisors.forEach((data, key) => {
      supervisorFiltersArray.push({ id: data.id, name: data.name });
    });

    return supervisorFiltersArray;
  }, [weeklyScreenData]);

  const handleChangeName = (event: any, value: any) => {
    dispatch(setSupervisorFilter(value.id || -1));
  };
  const { supervisorFilter }: any = useSelector((state: StoreState) => ({
    supervisorFilter: R.pathOr(-1, supervisorFilterStatePath, state)
  }));

  const map: {} = {};

  for (let i = 0; i < supervisorFiltersArray.length; i++) {
    if (map[supervisorFiltersArray[i].name] === undefined) {
      map[supervisorFiltersArray[i].name] = supervisorFiltersArray[i].id;
    }
  }

  const supervisorFiltersArrayNew = Object.entries(map).map(([key, value]) => {
    return {
      id: value,
      name: key
    };
  });

  console.log(supervisorFilter, 'supervisorFilter');
  return (
    <Autocomplete
      id="supervisor-filter"
      value={supervisorFilter}
      options={supervisorFiltersArrayNew}
      disableClearable
      getOptionLabel={(value: any) => {
        if (R.is(String, value)) {
          const selectedOption = supervisorFiltersArray.find((option: any) => {
            return option.id === value;
          });

          return R.pathOr('', ['name'], selectedOption);
        }

        return R.pathOr('', ['name'], value);
      }}
      getOptionSelected={(option, value) => option['id'] === value}
      onChange={handleChangeName}
      renderInput={(params) => <TextField {...params} label="Supervisor" />}
    />
  );
};
