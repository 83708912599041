import React, { useEffect, useCallback, useState } from 'react';
import moment from 'moment';
import * as R from 'ramda';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core';
import { useStyles, columns, CustomersFlightReportRow } from './helper';

import Dropdown from '../shared/Dropdown';
import { statusFilters } from '../../routes/FlightReport/helper';
import SharedDialog from '../shared/SharedDialog';
import FlightReportPDFRender from '../../routes/FlightReport/FlightReportPDFRender';
import { useSortHook } from '../../hooks/sortHooks';
import SharedTableHeader from '../shared/SharedTableHeader';

// interface FlightReportsTableProps {
//   flightReports: CustomersFlightReportRow[];
//   currentSelectedStatus: any;
//   setCurrentSelectedStatus: Function;
// }

const FlightReportsTable = ({
  flightReports,
  currentSelectedStatus,
  setCurrentSelectedStatus
}: any) => {
  const classes = useStyles();
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const [currentPdfUserId, setCurrentPdfUserId] = useState('');

  useEffect(() => {
    let customSelectedStatus = {};

    flightReports.forEach((row: CustomersFlightReportRow) => {
      if (Number(row.status.id) !== 1) {
        customSelectedStatus[`${row.id}`] = row.status.id;
      }
    });

    setCurrentSelectedStatus(customSelectedStatus);
  }, [flightReports, setCurrentSelectedStatus]);

  const handleRowStatusValueChange = useCallback(
    (value: unknown, id: string) => {
      let customSelectedStatus = { ...currentSelectedStatus };
      customSelectedStatus[`${id}`] = value;
      setCurrentSelectedStatus(customSelectedStatus);
    },
    [currentSelectedStatus, setCurrentSelectedStatus]
  );

  const handelOnClickRow = useCallback((row: CustomersFlightReportRow) => {
    setCurrentPdfUserId(row.id);
    setIsPdfViewerOpen(true);
  }, []);

  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    initialSortById: 'date',
    columnsData: columns,
    dataArray: flightReports
  });

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <SharedTableHeader
            columns={columns}
            order={order}
            orderBy={orderBy}
            secondaryVariant
            handleRequestSort={handleRequestSort}
          />

          <TableBody>
            {sortedDataArray.map(
              (row: CustomersFlightReportRow, index: number) => {
                const currentRowStatusValue = R.pathOr(
                  '1',
                  [],
                  currentSelectedStatus[`${row.id}`]
                );
                const isDropdownDisabled = Number(currentRowStatusValue) === 1;

                return (
                  <TableRow
                    key={index}
                    className="flight-report-custom-body-row"
                    onClick={() => handelOnClickRow(row)}>
                    <TableCell component="th" scope="row">
                      {moment.utc(row.date).format('MMM D YYYY')}
                    </TableCell>

                    <TableCell component="th" scope="row" align={'left'}>
                      {row.flightReport}
                    </TableCell>

                    <TableCell component="th" scope="row" align={'left'}>
                      {`${row.booking.bookingNumber} - ${row.booking.name}`}
                    </TableCell>

                    <TableCell component="th" scope="row" align={'left'}>
                      {row.aircraft.registration}
                    </TableCell>

                    <TableCell component="th" scope="row" align={'left'}>
                      {`${row.pilot.firstName || ''} ${
                        row.pilot.lastName || ''
                      }`}
                    </TableCell>

                    <TableCell component="th" scope="row" align={'left'}>
                      {`${row.ame.firstName || ''} ${row.ame.lastName || ''}`}
                    </TableCell>

                    <TableCell component="th" scope="row" align={'right'}>
                      {row.revenue.toFixed(1)}
                    </TableCell>

                    <TableCell component="th" scope="row" align={'right'}>
                      {row.nonRevenue.toFixed(1)}
                    </TableCell>

                    <TableCell component="th" scope="row" align={'center'}>
                      <Dropdown
                        label="Set Status"
                        variant="outlined"
                        customClassNames="status-input-table"
                        withLabel={false}
                        value={currentRowStatusValue}
                        disabled={isDropdownDisabled}
                        optionsList={
                          isDropdownDisabled
                            ? [{ id: 1, name: 'Closed' }]
                            : statusFilters
                        }
                        handleChange={(
                          event: React.ChangeEvent<{
                            value: unknown;
                          }>
                        ) => {
                          if (!isDropdownDisabled) {
                            const { value } = event.target;
                            handleRowStatusValueChange(value, row.id);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <SharedDialog
        id="fuel-receipt-dialog"
        keepMounted
        maxWidth={false}
        open={isPdfViewerOpen}
        dialogueTitle="Flight Report PDF"
        cancelButtonText="Close"
        dialogContent={
          <div className="customers-flight-report-pdf-container">
            <FlightReportPDFRender id={currentPdfUserId} />
          </div>
        }
        handleCancel={() => setIsPdfViewerOpen(false)}
      />
    </React.Fragment>
  );
};

export default FlightReportsTable;
