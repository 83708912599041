import types from '../utils/actionTypes';

export const fetchWeeklyScreenDataRequest = (date: Date) => {
  return {
    type: types.FETCH_WEEKLY_SCREEN_DATA_REQUEST,
    payload: { date }
  };
};

export const fetchWeeklyScreenDataSuccess = (data: any, metaData: {}) => {
  return {
    type: types.FETCH_WEEKLY_SCREEN_DATA_SUCCESS,
    payload: { data, metaData }
  };
};

export const fetchWeeklyCurrentData = (data: {}) => {
  return {
    type: 'FETCH_WEEKLY_CURRENT_DATA',
    payload: { data }
  };
};

export const fetchWeeklyScreenDataFailure = () => {
  return {
    type: types.FETCH_WEEKLY_SCREEN_DATA_FAILURE
  };
};

export const fetchDepartmentFiltersRequest = () => {
  return {
    type: types.FETCH_DEPARTMENT_FILTERS_REQUEST
  };
};

export const fetchDepartmentFiltersSuccess = (departmentFiltersArray: []) => {
  return {
    type: types.FETCH_DEPARTMENT_FILTERS_SUCCESS,
    payload: { value: departmentFiltersArray }
  };
};

export const fetchDepartmentFiltersFailure = () => {
  return {
    type: types.FETCH_DEPARTMENT_FILTERS_FAILURE
  };
};
