import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import api from '../../services/ApiModule';
import PdfViewer from './PdfViewer';
import { isNilOrEmpty } from '../../utils/helper';

interface PilotFlightReportPDFRenderProps {
  flightReportIds: string[];
  currentDayIndex: any;
}

const PilotFlightReportPDFRender = ({
  flightReportIds,
  currentDayIndex
}: PilotFlightReportPDFRenderProps) => {
  const pdfInit: string[] = [];
  const [pdf, setPdf] = useState(pdfInit);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPdf = useCallback(async () => {
    setIsLoading(true);
    const responsePromises = flightReportIds.map((reportId: string) => {
      return api.fetchFlightReportReceipt(reportId);
    });

    const values = await Promise.all(responsePromises);

    const data = values.map((response) => {
      if (response.success && !response.data.noContent) {
        const url = URL.createObjectURL(response.data);
        return url;
      } else {
        return '';
      }
    });

    setPdf(data);

    setIsLoading(false);
  }, [flightReportIds]);

  useEffect(() => {
    fetchPdf();
  }, [currentDayIndex]);

  console.log(currentDayIndex, '@currentDayIndex@');
  // fetchFlightReportInfo
  return isLoading ? (
    <Typography
      variant="h4"
      gutterBottom
      className="no-receipts-available-text">
      Loading...
    </Typography>
  ) : isNilOrEmpty(pdf) ? (
    <Typography
      variant="h4"
      gutterBottom
      className="no-receipts-available-text">
      No Flight Report Available
    </Typography>
  ) : (
    <React.Fragment>
      <PdfViewer pdfUrls={pdf} />
    </React.Fragment>
  );
};

export default PilotFlightReportPDFRender;
