import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import StoreState from '../../redux/utils/interfaces';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import {
  AIRCRAFT_FILTERS,
  BOOKING_FILTERS,
  CUSTOMER_FILTERS,
  PILOT_FILTERS,
  AME_FILTERS,
  INVOICE_NUMBER_FILTERS,
  STATUS_TRACK_FILTERS,
  STATUS_FILTERS,
  COPILOT_FILTERS,
  AIRCRAFT_TYPE_FILTERS,
  FLIGHT_TYPE,
  APPROVED_BY,
  filtersNotWorkingOptions,
  flightreportsFliters,
  STATUS_FILTERS_APPROVED,
  commentFilters,
  apprenticesFilters
} from '../../routes/FlightReport/helper';
import { FLIGHT_REPORT_FILTERS_FORM } from '../../utils/reduxFormConstants';

const commonFilterPath = ['form', FLIGHT_REPORT_FILTERS_FORM, 'values'];

console.log([...commonFilterPath], 'commonFilterPath');

export const ApprenticeFilterStatePath = [
  ...commonFilterPath,
  apprenticesFilters
];
export const bookingFilterStatePath = [...commonFilterPath, BOOKING_FILTERS];

export const customerFilterStatePath = [...commonFilterPath, CUSTOMER_FILTERS];
export const aircraftFilterStatePath = [...commonFilterPath, AIRCRAFT_FILTERS];
export const pilotFilterStatePath = [...commonFilterPath, PILOT_FILTERS];
export const ameFilterStatePath = [...commonFilterPath, AME_FILTERS];
export const statusFilterStatePath = [...commonFilterPath, STATUS_FILTERS];
export const copilotFilterStatePath = [...commonFilterPath, COPILOT_FILTERS];
export const CommentFilterStatePath = [...commonFilterPath, commentFilters];
export const statusApprovedFilterPath = [
  ...commonFilterPath,
  STATUS_FILTERS_APPROVED
];
export const aircraftTypeFilterStatePath = [
  ...commonFilterPath,
  AIRCRAFT_TYPE_FILTERS
];

export const StatusTrackFiltersStatePath = [
  ...commonFilterPath,
  STATUS_TRACK_FILTERS
];
export const InvoiceFilterStatePath = [
  ...commonFilterPath,
  INVOICE_NUMBER_FILTERS
];
export const ApprovedByFilterStatePath = [...commonFilterPath, APPROVED_BY];

export const flightTypeFilterStatePath = [...commonFilterPath, FLIGHT_TYPE];
export const flightReportFilterStatePath = [
  ...commonFilterPath,
  flightreportsFliters
];
export const BookingFilterComponent = () => {
  console.log('tried and time waits for none');
  const { flightReportScreenData } = useSelector((state: StoreState) => ({
    flightReportScreenData: state.flightReportScreenData.flightReportData
  }));

  const bookingFiltersArray = useMemo(() => {
    let ApprovedByData: any[] = flightReportScreenData;

    const ApprovedBy = new Set<{ id: number | string; name: string }>();

    ApprovedByData.forEach((data: any, index: any) => {
      if (data.booking.bookingNumber)
        ApprovedBy.add({
          id: data.booking.id,
          name: String(data.booking.bookingNumber)
        });
    });

    let ApprovedByFiltersArray: { id: string | any; name: any }[] = [
      { id: -1, name: 'All' }
    ];

    ApprovedBy.forEach((data: any, key) => {
      ApprovedByFiltersArray.push({
        id: data.id,
        name: data.name
      });
    });

    return R.uniq(ApprovedByFiltersArray);
  }, [flightReportScreenData]);

  const sortedArr = bookingFiltersArray.sort((a: any, b: any) => {
    if (a.name > b.name) {
      return 1;
    } else {
      return -1;
    }
  });

  return (
    <Field
      name={BOOKING_FILTERS}
      component={ReduxFormAutocomplete}
      label="Booking"
      optionsList={sortedArr}
      required
    />
  );
};

export const CustomerFilterComponent = () => {
  // const { customerFiltersArray } = useSelector((state: StoreState) => ({
  //   customerFiltersArray: R.pathOr(
  //     filtersNotWorkingOptions,
  //     ['flightReportScreenFilters', 'customerFiltersArray'],
  //     state
  //   )
  // }));

  const { flightReportScreenData } = useSelector((state: StoreState) => ({
    flightReportScreenData: state.flightReportScreenData.flightReportData
  }));

  const customerFiltersArrayNew = useMemo(() => {
    let ApprovedByData: any[] = flightReportScreenData;

    const ApprovedBy = new Set<{ id: number | string; name: string }>();

    ApprovedByData.forEach((data: any, index: any) => {
      if (data.customer.name)
        ApprovedBy.add({ id: data.customer.id, name: data.customer.name });
    });

    let ApprovedByFiltersArray: { id: string | any; name: any }[] = [
      { id: -1, name: 'All' }
    ];

    ApprovedBy.forEach((data: any, key) => {
      ApprovedByFiltersArray.push({
        id: data.id,
        name: data.name
      });
    });

    return R.uniq(ApprovedByFiltersArray);
  }, [flightReportScreenData]);
  const sortedArr = customerFiltersArrayNew.sort((a: any, b: any) => {
    if (a.name > b.name) {
      return 1;
    } else {
      return -1;
    }
  });

  return (
    <Field
      name={CUSTOMER_FILTERS}
      component={ReduxFormAutocomplete}
      label="Customer"
      optionsList={sortedArr}
      required
    />
  );
};

export const AircraftFilterComponent = () => {
  const { flightReportScreenData } = useSelector((state: StoreState) => ({
    flightReportScreenData: state.flightReportScreenData.flightReportData
  }));
  const aircraftFiltersArray = useMemo(() => {
    let ApprovedByData: any[] = flightReportScreenData;

    const ApprovedBy = new Set<{ id: number | string; name: string }>();

    ApprovedByData.forEach((data: any, index: any) => {
      if (data.aircraft?.registration)
        ApprovedBy.add({
          id: data.aircraft?.id,
          name: data.aircraft?.registration
        });
    });

    let ApprovedByFiltersArray: { id: string | any; name: any }[] = [
      { id: -1, name: 'All' }
    ];

    ApprovedBy.forEach((data: any, key) => {
      ApprovedByFiltersArray.push({
        id: data.id,
        name: data.name
      });
    });

    return R.uniq(ApprovedByFiltersArray);
  }, [flightReportScreenData]);

  const sortedArr = aircraftFiltersArray.sort((a: any, b: any) => {
    if (a.name > b.name) {
      return 1;
    } else {
      return -1;
    }
  });

  const result: any[] = [];
  for (let i = 0; i < sortedArr.length; i++) {
    let val = sortedArr[i];
    let nameExit = result.find((el) => el?.name === val.name);
    if (nameExit?.name !== val.name) {
      result.push({
        id: val.id,
        name: val.name
      });
    } else {
      let findElm = sortedArr.findIndex((elm) => elm.name === val.name);
      if (findElm !== -1) {
        result.splice(findElm, 1);
      }
    }
  }
  console.log(result, '@@result@@');

  return (
    <Field
      name={AIRCRAFT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Aircraft"
      optionsList={result}
      required
    />
  );
};

export const AircraftTypeFilterComponent = () => {
  const { aircraftTypeFiltersArray } = useSelector((state: StoreState) => ({
    aircraftTypeFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['flightReportScreenFilters', 'aircraftTypeFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={AIRCRAFT_TYPE_FILTERS}
      component={ReduxFormAutocomplete}
      label="Aircraft Type"
      optionsList={aircraftTypeFiltersArray}
      required
    />
  );
};

export const COPilotFilterComponent = () => {
  const { copilotFiltersArray } = useSelector((state: StoreState) => ({
    copilotFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['flightReportScreenFilters', 'copilotFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={COPILOT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Co Pilot"
      optionsList={copilotFiltersArray}
      required
    />
  );
};

export const FlightTypeFilterComponent = () => {
  const { flightTypFiltersArray } = useSelector((state: StoreState) => ({
    flightTypFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['flightReportScreenFilters', 'flightTypFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={FLIGHT_TYPE}
      component={ReduxFormAutocomplete}
      label="Flight Type"
      optionsList={flightTypFiltersArray}
      required
    />
  );
};

export const PilotFilterComponent = () => {
  const { flightReportScreenData } = useSelector((state: StoreState) => ({
    flightReportScreenData: state.flightReportScreenData.flightReportData
  }));

  const pilotFiltersArray = useMemo(() => {
    let ApprovedByData: any[] = flightReportScreenData;

    const ApprovedBy = new Set<{ id: number | string; name: string }>();

    ApprovedByData.forEach((data: any, index: any) => {
      const superName = data.pilot.firstName + ' ' + data.pilot.lastName;
      if (superName)
        ApprovedBy.add({
          id: data.pilot.id,
          name: superName
        });
    });

    let ApprovedByFiltersArray: { id: string | any; name: any }[] = [
      { id: -1, name: 'All' }
    ];

    ApprovedBy.forEach((data: any, key) => {
      ApprovedByFiltersArray.push({
        id: data.id,
        name: data.name
      });
    });

    return R.uniq(ApprovedByFiltersArray);
  }, [flightReportScreenData]);

  const sortedArr = pilotFiltersArray.sort((a: any, b: any) => {
    if (a.name > b.name) {
      return 1;
    } else {
      return -1;
    }
  });
  return (
    <Field
      name={PILOT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Pilot"
      optionsList={sortedArr}
      required
    />
  );
};

export const AmeFilterComponent = () => {
  const { flightReportScreenData } = useSelector((state: StoreState) => ({
    flightReportScreenData: state.flightReportScreenData.flightReportData
  }));

  const ameFiltersArray = useMemo(() => {
    let ApprovedByData: any[] = flightReportScreenData;

    const ApprovedBy = new Set<{ id: number | string; name: string }>();

    ApprovedByData.forEach((data: any, index: any) => {
      const superName = data.ame?.firstName + ' ' + data.ame?.lastName;
      if (superName !== undefined)
        ApprovedBy.add({
          id: data.ame.id,
          name: superName
        });
    });

    let ApprovedByFiltersArray: { id: string | any; name: any }[] = [
      { id: -1, name: 'All' }
    ];

    ApprovedBy.forEach((data: any, key) => {
      ApprovedByFiltersArray.push({
        id: data.id,
        name: data.name
      });
    });

    return R.uniq(ApprovedByFiltersArray);
  }, [flightReportScreenData]);

  const SuperameFiltersArray = ameFiltersArray.filter((ame) => ame.id);

  const sortedArr = SuperameFiltersArray.sort((a: any, b: any) => {
    if (a.name > b.name) {
      return 1;
    } else {
      return -1;
    }
  });
  return (
    <Field
      name={AME_FILTERS}
      component={ReduxFormAutocomplete}
      label="AME"
      optionsList={sortedArr}
      required
    />
  );
};

// export const StatusFilterComponent = () => {
//   const { statusFiltersArray } = useSelector((state: StoreState) => ({
//     statusFiltersArray: R.pathOr(
//       filtersNotWorkingOptions,
//       ['flightReportScreenFilters', 'statusFiltersArray'],
//       state
//     )
//   }));

//   return (
//     <Field
//       name={STATUS_FILTERS}
//       component={ReduxFormAutocomplete}
//       label="Status"
//       optionsList={statusFiltersArray}
//       required
//     />
//   );
// };

export const StatusFilterComponent = () => {
  const statusFiltersArray = [
    { id: -1, name: 'All' },
    { id: 'Signed', name: 'Signed' },
    { id: 'Signed On Mobile', name: 'Signed On Mobile' },
    { id: 'No Signature', name: 'No Signature' },
    { id: 'Ready to sign', name: 'Ready to sign' },
    { id: 'Not Locked', name: 'Not Locked' },
    { id: 'Signed Portal', name: 'Signed Portal' }
  ];

  return (
    <Field
      name={STATUS_FILTERS}
      component={ReduxFormAutocomplete}
      label="Status"
      optionsList={statusFiltersArray}
      required
    />
  );
};

export const StatusTrackFilterComponent = () => {
  const { StatusTrackFiltersArray } = useSelector((state: StoreState) => ({
    StatusTrackFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['flightReportScreenFilters', 'statusTrackFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={STATUS_TRACK_FILTERS}
      component={ReduxFormAutocomplete}
      label="Status"
      optionsList={StatusTrackFiltersArray}
      required
    />
  );
};

export const ApprovedByFilterComponent = () => {
  const { flightReportScreenData } = useSelector((state: StoreState) => ({
    flightReportScreenData: state.flightReportScreenData.flightReportData
  }));

  const ApprovedByFiltersArray = useMemo(() => {
    let ApprovedByData: any[] = flightReportScreenData;

    const ApprovedBy = new Set<{ id: number | string; name: string }>();

    ApprovedByData.forEach((data, index) => {
      if (data.approverName)
        ApprovedBy.add({ id: data.approverName, name: data.approverName });
    });

    let ApprovedByFiltersArray: { id: string | any; name: any }[] = [
      { id: -1, name: 'All' }
    ];

    ApprovedBy.forEach((data, key) => {
      ApprovedByFiltersArray.push({ id: data.id, name: data.name });
    });

    return R.uniq(ApprovedByFiltersArray);
  }, [flightReportScreenData]);

  const sortedArr = ApprovedByFiltersArray.sort((a: any, b: any) => {
    if (a.name > b.name) {
      return 1;
    } else {
      return -1;
    }
  });

  return (
    <Field
      name={APPROVED_BY}
      component={ReduxFormAutocomplete}
      label="Approved By"
      optionsList={sortedArr}
      required
    />
  );
};

export const InvoiceNumberFilterComponent = () => {
  const { flightReportScreenData } = useSelector((state: StoreState) => ({
    flightReportScreenData: state.flightReportScreenData.flightReportData
  }));

  const invoiceNumberFiltersArray = useMemo(() => {
    let invoiceNumberData: any[] = flightReportScreenData;

    const invoiceNumbers = new Set<{ id: number | string; name: string }>();

    invoiceNumberData.forEach((data, index) => {
      if (data.invoiceNumber)
        invoiceNumbers.add({
          id: data.invoiceNumber,
          name: data.invoiceNumber
        });
    });

    let invoiceNumberFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    invoiceNumbers.forEach((data, key) => {
      invoiceNumberFiltersArray.push({ id: data.id, name: data.name });
    });

    return R.uniq(invoiceNumberFiltersArray);
  }, [flightReportScreenData]);

  return (
    <Field
      name={INVOICE_NUMBER_FILTERS}
      component={ReduxFormAutocomplete}
      label="Invoice"
      optionsList={invoiceNumberFiltersArray}
      required
    />
  );
};

export const StatusNewFilterComponent = () => {
  const statusFiltersArray = [
    { id: -1, name: 'All' },
    { id: 'Approved', name: 'Approved' },
    { id: 'Not Approved', name: 'Not Approved' }
  ];

  return (
    <Field
      name={STATUS_FILTERS_APPROVED}
      component={ReduxFormAutocomplete}
      label="Status"
      optionsList={statusFiltersArray}
      required
    />
  );
};

export const FlightReportFilterComponent = () => {
  const { flightReportScreenData } = useSelector((state: StoreState) => ({
    flightReportScreenData: state.flightReportScreenData.flightReportData
  }));

  const ApprovedByFiltersArray = useMemo(() => {
    let ApprovedByData: any[] = flightReportScreenData;

    const ApprovedBy = new Set<{ id: number | string; name: string }>();

    ApprovedByData.forEach((data, index) => {
      if (data.flightReport)
        ApprovedBy.add({ id: data.flightReport, name: data.flightReport });
    });

    let ApprovedByFiltersArray: { id: string | any; name: any }[] = [
      { id: -1, name: 'All' }
    ];

    ApprovedBy.forEach((data, key) => {
      console.log(data, '@@data@@');
      ApprovedByFiltersArray.push({ id: data.id, name: data.name });
    });

    return R.uniq(ApprovedByFiltersArray);
  }, [flightReportScreenData]);

  const sortedArr = ApprovedByFiltersArray.sort((a: any, b: any) => {
    if (a.name > b.name) {
      return 1;
    } else {
      return -1;
    }
  });
  return (
    <Field
      name={flightreportsFliters}
      component={ReduxFormAutocomplete}
      label="Flight Report"
      optionsList={sortedArr}
      required
    />
  );
};

export const CommentFilterComponent = () => {
  const commentFiltersArray = [
    { id: -1, name: 'All' },
    { id: '0', name: '0' },
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: '5', name: '5' }
  ];

  return (
    <Field
      name={commentFilters}
      component={ReduxFormAutocomplete}
      label="Comment"
      optionsList={commentFiltersArray}
      required
    />
  );
};

export const ApprenticeFilterComponent = () => {
  const { flightReportScreenData } = useSelector((state: StoreState) => ({
    flightReportScreenData: state.flightReportScreenData.flightReportData
  }));

  const ApprovedByFiltersArray = useMemo(() => {
    let ApprovedByData: any[] = flightReportScreenData;

    const ApprovedBy = new Set<{ id: number | string; name: string }>();

    ApprovedByData.forEach((data, index) => {
      const name = data.apprentice.firstName + ' ' + data.apprentice.lastName;
      if (name) {
        ApprovedBy.add({ id: name, name: name });
      }
    });

    let ApprovedByFiltersArray: { id: string | any; name: any }[] = [
      { id: -1, name: 'All' }
    ];

    ApprovedBy.forEach((data, key) => {
      console.log(data, '@@data@@');
      ApprovedByFiltersArray.push({ id: data.id, name: data.name });
    });

    return R.uniq(ApprovedByFiltersArray);
  }, [flightReportScreenData]);

  const sortedArr = ApprovedByFiltersArray.sort((a: any, b: any) => {
    if (a.name > b.name) {
      return 1;
    } else {
      return -1;
    }
  });
  console.log(sortedArr, '@sortedArr@');
  const dataWithName = sortedArr.filter(
    (el) => el.name !== 'undefined undefined'
  );
  return (
    <Field
      name={apprenticesFilters}
      component={ReduxFormAutocomplete}
      label="Apprentice"
      optionsList={dataWithName}
      required
    />
  );
};
