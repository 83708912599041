import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button
} from '@material-ui/core';
import React from 'react';
import {
  //reduxForm,
  Field
  //InjectedFormProps,
  // initialize
} from 'redux-form';
import moment from 'moment';
import ReduxFormInput from '../../components/shared/ReduxFormInput';
import { useSelector } from 'react-redux';
import { CLIENT_PORTAL_FILTERS_FORM } from '../../utils/reduxFormConstants';
import * as R from 'ramda';
import StoreState from '../../redux/utils/interfaces';

function CommentsSection({ data, handleonPostComment }: any) {
  const { formValues } = useSelector((state: StoreState) => {
    return {
      formValues: R.pathOr(
        '',
        ['form', CLIENT_PORTAL_FILTERS_FORM, 'values', 'comment'],
        state
      )
    };
  });
  return (
    <>
      <List>
        {data.flightReportData.clientPortalComments.map((comment: any, index:string) => {
          return (
            <>
              <ListItem alignItems="flex-start" key={index}>
                {/* <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </ListItemAvatar> */}
                <ListItemText
                  primary={
                    <span style={{ display: 'flex' }}>
                      <Typography component="span" className="comment-massage">
                        {comment.user.firstName} {comment.user.lastName}{' '}
                        <span className="comment-timestamp">
                          {moment(comment.utcDate)
                            .local()
                            .format('YYYY-MM-DD HH:mm A')}
                        </span>
                      </Typography>
                    </span>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        //sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        //color="text.primary"
                      >
                        {comment.comment}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider component="li" />
            </>
          );
        })}
      </List>
      <div className="comment-textarea">
        <Field
          name="comment"
          component={ReduxFormInput}
          rows={5}
          label="Enter a Comment"
          variant="outlined"
          multiline
        />
      </div>
      <div className="comment-submit-button">
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            handleonPostComment(formValues, data.flightReportData.id)
          }>
          Post comment
        </Button>
      </div>
    </>
  );
}

export default CommentsSection;
