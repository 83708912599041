import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  bookingFilterStatePath,
  aircraftFilterStatePath,
  pilotFilterStatePath,
  ameFilterStatePath,
  statusFilterStatePath,
  BookingFilterComponent,
  AircraftFilterComponent,
  PilotFilterComponent,
  AmeFilterComponent,
  StatusFilterComponent
} from '../FlightReportTable/FiltersComponents';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

export const columns: Column[] = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    align: 'center',
    sortingProps: [['date']]
  },
  {
    id: 'flightReport',
    label: 'Number',
    minWidth: 120,
    align: 'center',
    sortingProps: [['flightReport']]
  },
  {
    id: 'booking',
    label: 'Booking',
    minWidth: 90,
    align: 'center',
    sortingProps: [['booking', 'bookingNumber']],
    isNumber: true,
    filterComponent: BookingFilterComponent,
    filterStatePath: bookingFilterStatePath
  },
  {
    id: 'aircraft',
    label: 'Aircraft',
    minWidth: 90,
    align: 'center',
    sortingProps: [['aircraft', 'registration']],
    filterComponent: AircraftFilterComponent,
    filterStatePath: aircraftFilterStatePath
  },
  {
    id: 'pilot',
    label: 'Pilot',
    minWidth: 110,
    align: 'center',
    sortingProps: [
      ['pilot', 'firstName'],
      ['pilot', 'lastName']
    ],
    filterComponent: PilotFilterComponent,
    filterStatePath: pilotFilterStatePath
  },
  {
    id: 'ame',
    label: 'AME',
    minWidth: 110,
    align: 'center',
    sortingProps: [
      ['ame', 'firstName'],
      ['ame', 'lastName']
    ],
    filterComponent: AmeFilterComponent,
    filterStatePath: ameFilterStatePath
  },
  {
    id: 'revenue',
    label: 'Revenue',
    minWidth: 110,
    align: 'center',
    sortingProps: [['revenue']],
    isNumber: true
  },
  {
    id: 'nonRevenue',
    label: 'Non Revenue',
    minWidth: 110,
    align: 'center',
    sortingProps: [['nonRevenue']],
    isNumber: true
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 110,
    align: 'center',
    sortingProps: [['status', 'name']],
    filterComponent: StatusFilterComponent,
    filterStatePath: statusFilterStatePath
  }
];

interface AircraftProps {
  id: number;
  registration: string;
}

interface BookingProps {
  id: number;
  name: string;
  bookingNumber: number;
}

interface PilotProps {
  id: number;
  firstName: string;
  lastName: string;
}

interface AmeProps {
  id: number;
  firstName: string;
  lastName: string;
}

interface StatusProps {
  id: number;
  name: string;
}

export interface CustomersFlightReportRow {
  id: string;
  date: string;
  flightReport: string;
  booking: BookingProps;
  aircraft: AircraftProps;
  pilot: PilotProps;
  ame: AmeProps;
  revenue: number;
  nonRevenue: number;
  status: StatusProps;
}

const isAllBookingFilters = R.propEq('bookingFilters', -1);
const isAllAircraftFilters = R.propEq('aircraftFilters', -1);
const isAllPilotFilters = R.propEq('pilotFilters', -1);
const isAllAmeFilters = R.propEq('ameFilters', -1);
const isAllStatusFilters = R.propEq('statusFilters', -1);

const isAllFiltersAppliedEvery = R.allPass([
  isAllBookingFilters,
  isAllAircraftFilters,
  isAllPilotFilters,
  isAllAmeFilters,
  isAllStatusFilters
]);

export const filterFlightReportScreenData = (
  flightReportScreenData: CustomersFlightReportRow[],
  filters: {}
) => {
  console.log(flightReportScreenData, '1flightReportScreenData');
  if (flightReportScreenData === undefined) {
    return [];
  }
  let filteredDataArray = flightReportScreenData;
  const from = R.path(['range', 'fromDate'], filters);
  const to = R.path(['range', 'toDate'], filters);

  filteredDataArray = filteredDataArray.filter(
    (filteredData: CustomersFlightReportRow) => {
      const date = filteredData.date;

      const isSameOrAfter = moment(date).isSameOrAfter(new Date(`${from}`));
      const isSameOrBefore = moment(date).isSameOrBefore(new Date(`${to}`));

      return isSameOrAfter && isSameOrBefore;
    }
  );

  if (isAllFiltersAppliedEvery(filters)) {
    return filteredDataArray;
  }

  const bookingFilters = R.pathOr(-1, ['bookingFilters'], filters);
  const aircraftFilters = R.pathOr(-1, ['aircraftFilters'], filters);
  const pilotFilters = R.pathOr(-1, ['pilotFilters'], filters);
  const ameFilters = R.pathOr(-1, ['ameFilters'], filters);
  const statusFilters = R.pathOr(-1, ['statusFilters'], filters);

  if (!isAllBookingFilters({ bookingFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['booking', 'id'], obj), bookingFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllAircraftFilters({ aircraftFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['aircraft', 'id'], obj), aircraftFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllPilotFilters({ pilotFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['pilot', 'id'], obj), pilotFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllAmeFilters({ ameFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['ame', 'id'], obj), ameFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllStatusFilters({ statusFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['status', 'id'], obj), statusFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (filteredDataArray.length === 0) {
    return flightReportScreenData;
  } else {
    return filteredDataArray;
  }
};
