import { put, takeLatest, select, delay, call } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  fetchFuelExpenseScreenDataSuccess,
  fetchFuelExpenseScreenDataFailure,
  fetchFuelExpenseScreenDataRequest,
  updateFuelExpenseScreenDataSuccess,
  updateFuelExpenseScreenDataFailure,
  // exportRefuelImageRequest,
  exportRefuelImageSuccess,
  exportRefuelImageFailure
} from '../actions/fuelExpenseScreenData';
import {
  setFuelExpenseScreenVendorFilters,
  setFuelExpenseScreenDocumentHashFilters,
  setFuelExpenseScreenInvoiceNumberFilters
} from '../actions/fuelExpenseScreenFilters';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { FUEL_EXPENSE_FILTERS_FORM } from '../../utils/reduxFormConstants';
import StoreState from '../utils/interfaces';
import { isPresent, getDateString } from '../../utils/helper';
import { toast } from 'react-toastify';
import { clearLocalStorage } from '../../services/utils/helper';
import { LOGIN_ROUTE } from '../../utils/routesNavigationConstants';
import { navigateTo } from '../../utils/history';

interface FetchFuelExpenseScreenDataActionType {
  type: String;
  payload: { startDate: Date; endDate: Date };
}

interface UpdateFuelExpenseScreenDataActionType {
  type: String;
  payload: { updateValues: {}[] };
}
interface exportRefuelImageActionType {
  type: String;
  payload: { image_url: String };
}

function* exportRefuelImage(action: exportRefuelImageActionType) {
  try {
    yield put(showSpinner());
    // yield delay(1000);
    const { image_url } = action.payload;
    const body = {
      image_url: image_url,
      isLogbook: false
    };
    const response: any = yield call(
      api.fetchExportData,
      endpoints.EXPORT_REFUEL_IMAGE,
      body
    );
    if (
      response.success === false ||
      response.data === 'Unauthorized' ||
      response.data === 'Access Denied'
    ) {
      clearLocalStorage();
      navigateTo(LOGIN_ROUTE);
      window.location.reload();
    }
    if (!(response.status === 202 || response.status === 200)) {
      throw new Error('Something went wrong');
    }
    yield put(exportRefuelImageSuccess());
    toast.success(`Image exported to Google Drive`);
  } catch (error) {
    console.log(error);
    yield put(exportRefuelImageFailure());
    toast.error(`Unable to export.`);
  } finally {
    yield put(hideSpinner());
  }
}

function* updateFuelExpenseScreenData(
  action: UpdateFuelExpenseScreenDataActionType
) {
  yield put(showSpinner());

  yield delay(1000);

  const { updateValues } = action.payload;

  try {
    const invoiceScreenDataResponse = yield api.updateData(
      endpoints.FUEL,
      {},
      updateValues
    );
    if (
      invoiceScreenDataResponse.success === false ||
      invoiceScreenDataResponse.data === 'Unauthorized' ||
      invoiceScreenDataResponse.data === 'Access Denied'
    ) {
      clearLocalStorage();
      navigateTo(LOGIN_ROUTE);
      window.location.reload();
    }
    if (invoiceScreenDataResponse.success) {
      yield put(updateFuelExpenseScreenDataSuccess());

      const {
        formValues: { range }
      } = yield select((state: StoreState) => {
        return {
          formValues: getFormValues(FUEL_EXPENSE_FILTERS_FORM)(state) || {}
        };
      });

      yield put(fetchFuelExpenseScreenDataRequest(range.from, range.to));
    } else {
      yield put(updateFuelExpenseScreenDataFailure());
    }
  } catch (e) {
    yield put(updateFuelExpenseScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* fetchFuelExpenseScreenData(
  action: FetchFuelExpenseScreenDataActionType
) {
  yield delay(1000);

  const { startDate, endDate } = action.payload;

  const startDateString = getDateString(new Date(startDate));
  const endDateString = getDateString(new Date(endDate));

  try {
    const fuelExpenseScreenDataResponse = yield api.fetchResponse(
      endpoints.FUEL_RANGE(startDateString, endDateString, true)
    );

    if (fuelExpenseScreenDataResponse.success) {
      yield put(
        fetchFuelExpenseScreenDataSuccess(
          fuelExpenseScreenDataResponse.data.data
        )
      );

      const mapInIdName = (filterArray: []) => {
        const filteredArray = filterArray.filter((filter: string) =>
          isPresent(filter)
        );

        return filteredArray.map((filter: string) => {
          return {
            id: filter.toLowerCase(),
            name: filter
          };
        });
      };

      const { vendors, documentNumbers, vendorInvoiceNumbers } =
        fuelExpenseScreenDataResponse.data.metaData;

      yield put(setFuelExpenseScreenVendorFilters(mapInIdName(vendors)));
      yield put(
        setFuelExpenseScreenDocumentHashFilters(mapInIdName(documentNumbers))
      );
      yield put(
        setFuelExpenseScreenInvoiceNumberFilters(
          mapInIdName(vendorInvoiceNumbers)
        )
      );
    } else {
      yield put(fetchFuelExpenseScreenDataFailure());
    }
  } catch (e) {
    yield put(fetchFuelExpenseScreenDataFailure());
  }
}

export default [
  takeLatest(
    types.FETCH_FUEL_EXPENSE_SCREEN_DATA_REQUEST,
    fetchFuelExpenseScreenData
  ),
  takeLatest(
    types.UPDATE_FUEL_EXPENSE_SCREEN_DATA_REQUEST,
    updateFuelExpenseScreenData
  ),
  takeLatest(types.EXPORT_REFUEL_IMAGE_REQUEST, exportRefuelImage)
];
