import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import StoreState from '../../redux/utils/interfaces';
import {
  fetchDetailScreenDataRequest,
  fetchExpenseTypesRequest,
  fetchBookingsRequest
} from '../../redux/actions/detailsScreenData';
import { getCurrentUserIndex } from './helper';
import { isPresent } from '../../utils/helper';
import {
  WEEKLY_PER_EMPLOYEE_RECEIPT_ROUTE,
  WEEKLY_PER_EMPLOYEE_WORK_HOURS_DETAILS
} from '../../utils/routesNavigationConstants';

export const useDetailScreenHook = () => {
  // let { userId }: any = useParams();
  let receiptMatch = useRouteMatch(WEEKLY_PER_EMPLOYEE_RECEIPT_ROUTE);
  let workHoursDetailsMatch = useRouteMatch(
    WEEKLY_PER_EMPLOYEE_WORK_HOURS_DETAILS
  );

  const isReceiptPage = isPresent(receiptMatch);
  const isWorkHoursDetailsPage = isPresent(workHoursDetailsMatch);

  let history = useHistory();
  const dispatch = useDispatch();

  const {
    departmentFilter,
    statusFilter,
    firstNameFilter,
    lastNameFilter,
    supervisorFilter,
    // selectedFirstDate,
    dataArray,
    expenseTypes,
    isLoading,
    currentUser
  } = useSelector((state: StoreState) => {
    return {
      currentUser: state.weeklyScreenData.currentUser,
      dataArray: state.detailsScreenData.dataArray,
      // selectedFirstDate: state.weeklyScreenFilters.selectedFirstDate,
      expenseTypes: state.detailsScreenData.expenseTypes,
      isLoading: state.detailsScreenData.isLoading,
      ...state.detailsScreenSelectedFields
    };
  });
  const { selectedFirstDate } = useSelector((state: StoreState) => {
    return {
      currentUser: state.weeklyScreenData.currentUser,
      selectedFirstDate: state.weeklyScreenFilters.selectedFirstDate
    };
  });
  useEffect(() => {
    dispatch(
      fetchDetailScreenDataRequest(
        selectedFirstDate,
        departmentFilter,
        statusFilter,
        firstNameFilter,
        lastNameFilter,
        supervisorFilter
      )
    );
    dispatch(fetchExpenseTypesRequest());
  }, [
    selectedFirstDate,
    departmentFilter,
    statusFilter,
    firstNameFilter,
    lastNameFilter,
    supervisorFilter,
    dispatch
  ]);

  console.log('selectedFirstDate', selectedFirstDate);
  useEffect(() => {
    dispatch(fetchBookingsRequest(selectedFirstDate));
  }, [currentUser, selectedFirstDate]);

  // const { id } = currentUser;
  const currentUserIndex = getCurrentUserIndex(currentUser.id, dataArray);
  const currentUserData = dataArray[currentUserIndex];

  // console.log(currentUserIndex, 'From---currentUserIndex ');

  console.log(currentUserData?.id, 'currentUserData?.id');
  const goToUserIdRoute = (index: number) =>
    history.push(`/WeeklyPerEmployee/${currentUserData?.id}`);

  const handlePageChange = (changeIndex: number) =>
    goToUserIdRoute(changeIndex);

  const getTitle = () => {
    if (isReceiptPage) return 'Receipt';
    if (isWorkHoursDetailsPage) return 'Description';

    return 'WeeklyPerEmployee';
  };

  const pageTitle = getTitle();
  return {
    isLoading,
    dataArray,
    currentUserIndex,
    isReceiptPage,
    isWorkHoursDetailsPage,
    currentUserData,
    selectedFirstDate,
    expenseTypes,
    pageTitle,
    handlePageChange
  };
};
