import moment from 'moment';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { RowData } from '../../redux/utils/interfaces';
import { isNilOrEmpty, isPresent, getWeekDays } from '../../utils/helper';
import { ExpenseTypes } from '../../redux/utils/interfaces';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface DetailsScreenTableProps {
  currentUserData: RowData;
  selectedFirstDate: Date;
  expenseTypes: ExpenseTypes[];
}

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  maxWidth?: number;
}

export interface TableBodyRow {
  name: string;
  id: string | number;
  day1?: string | number;
  day2?: string | number;
  day3?: string | number;
  day4?: string | number;
  day5?: string | number;
  day6?: string | number;
  day7?: string | number;
  total?: string | number;
  className?: string;
  isExpensesField?: boolean;
}

export interface DailyDataProps {
  dailies: any;
}

export const compareDatesWithCurrentWeekArray = (
  weekDays: string[],
  date: string
) => {
  var didContain = false;

  weekDays.forEach((currentWeekDay: string) => {
    const isSame = moment(currentWeekDay).isSame(date, 'day');

    if (isSame) {
      didContain = true;
    }
  });

  return didContain;
};

export const getColumnTitles = (firstDate: Date) => {
  const columns: Column[] = [
    {
      id: 'labels',
      label: '',
      minWidth: 150
    }
  ];

  const weekDays = getWeekDays(firstDate);

  weekDays.forEach((day: Date, index: number) => {
    const currentDay = moment.utc(day).format('ddd');
    const currentMonthAndDate = moment.utc(day).format('MMM DD');

    const dateFormatString = `${currentDay}\n${currentMonthAndDate}`;
    console.log(moment.utc('2023-01-07T18:30:00.000Z').toLocaleString(), 'f');

    columns.push({
      id: `day${index + 1}`,
      label: dateFormatString,
      minWidth: 140
    });
  });

  columns.push({
    id: 'total',
    label: 'TOTAL',
    minWidth: 70
  });

  return columns;
};

export const rowNamesArray: TableBodyRow[] = [
  {
    name: 'Status',
    id: 'status',
    className: 'details-custom-body-row-yellow-bg'
  },
  {
    name: 'Location',
    id: 'location',
    className: 'details-custom-body-row-yellow-bg'
  },
  {
    name: 'Province',
    id: 'province',
    className: 'details-custom-body-row-yellow-bg'
  },
  { name: 'Duty', id: 'duty', className: 'details-custom-body-row-yellow-bg' },
  {
    name: 'Meals/Rest/Waiting',
    id: 'mealRestWaiting',
    className: 'details-custom-body-row-yellow-bg'
  },
  {
    name: 'Work Start',
    id: 'workStart',
    className: 'details-custom-body-row-yellow-bg'
  },
  {
    name: 'Work End',
    id: 'workEnd',
    className: 'details-custom-body-row-yellow-bg'
  },
  {
    name: 'Work Hours',
    id: 'workHrs',
    className: 'details-custom-body-row-yellow-bg'
  },
  {
    name: 'Accommodation',
    id: 'accommodationStatus',
    className: 'details-custom-body-row-yellow-bg'
  },
  {
    name: 'Bush Pay',
    id: 'bushpay',
    className: 'details-custom-body-row-yellow-bg'
  },
  { name: 'Booking', id: 'booking', className: '' },
  { name: 'Client', id: 'client', className: '' },
  { name: 'Aircraft', id: 'aircraft', className: '' },
  { name: 'Flight Pay', id: 'revenue', className: '' },
  { name: 'Flight Pay - Co-pilot', id: 'revenueHrsCopilot', className: '' },
  { name: 'Flight Pay - Engineer', id: 'revenueHrsEngineer', className: '' },
  {
    name: 'Flight Pay - Apprentice',
    id: 'revenueHrsApprentice',
    className: ''
  },
  { name: 'Non revenue - Pilot', id: 'nonRevenuePilot', className: '' },
  { name: 'Non revenue - Co-pilot', id: 'nonRevenueCoPilot', className: '' },
  { name: 'Comment', id: 'comment', className: '' },
  // { name: 'Air Time', id: 'airTime', className: '' },
  // {
  //   name: 'Air Time/Flight Time %',
  //   id: 'difference',
  //   className: ''
  // },
  {
    name: 'Spécialisé Time',
    id: 'longLine',
    className: ''
  }
];

const extraNewFields = [
  {
    className: 'details-custom-body-row-green-bg details-custom-body-row-total',
    id: 'creditCard',
    name: 'CREDIT CARD'
  },
  {
    className: 'details-custom-body-row-green-bg details-custom-body-row-total',
    id: 'directBill',
    name: 'DIRECT BILL'
  }
];

const ignoreEditArray = ['name', 'id', 'className'];

export const getTotals = (rowDataArray: TableBodyRow[]) => {
  var totalsDataRow: TableBodyRow = {
    name: 'TOTAL',
    id: 'total',
    className: 'details-custom-body-row-green-bg details-custom-body-row-total',
    day1: 0,
    day2: 0,
    day3: 0,
    day4: 0,
    day5: 0,
    day6: 0,
    day7: 0,
    total: 0
  };

  const dayArr = ['day1', 'day2', 'day3', 'day4', 'day5', 'day6', 'day7'];

  rowDataArray.forEach((currentRow: TableBodyRow) => {
    if (currentRow.isExpensesField) {
      dayArr.forEach((day: string) => {
        var currentColumnTotal = R.pathOr('0', [`${day}`], currentRow);

        if (isNilOrEmpty(currentColumnTotal)) {
          currentColumnTotal = '0';
        }

        totalsDataRow[`${day}`] =
          totalsDataRow[`${day}`] + parseFloat(currentColumnTotal);
      });
    }
  });

  dayArr.forEach((day: string) => {
    totalsDataRow['total'] = totalsDataRow['total'] + totalsDataRow[`${day}`];
  });

  const keys = R.keys(totalsDataRow);

  keys.forEach((key: string) => {
    if (R.is(Number, totalsDataRow[`${key}`]))
      totalsDataRow[`${key}`] = totalsDataRow[`${key}`].toFixed(2);
  });

  return totalsDataRow;
};

export const getFilteredRowDataArray = (
  rowDataArray: TableBodyRow[],
  currentUserData: RowData
) => {
  return rowDataArray.filter((rowData: TableBodyRow) => {
    if (
      R.includes(rowData.id, [
        'booking',
        'client',
        'aircraft',
        'revenue',
        'revenueHrsCopilot',
        'revenueHrsEngineer',
        'revenueHrsApprentice',
        'nonRevenuePilot',
        'nonRevenueCoPilot',
        'airTime',
        'difference',
        'longLine'
      ]) &&
      (currentUserData.isAdmin ||
        !(
          currentUserData.isAdmin ||
          currentUserData.isEngineer ||
          currentUserData.isApprentice ||
          currentUserData.isPilot
        ))
    ) {
      return false;
    }

    if (
      R.includes(rowData.id, [
        'revenue',
        'revenueHrsCopilot',
        'nonRevenuePilot',
        'nonRevenueCoPilot',
        'airTime',
        'difference',
        'longLine'
      ]) &&
      (currentUserData.isEngineer || currentUserData.isApprentice) &&
      !currentUserData.isPilot
    ) {
      return false;
    }

    if (
      R.includes(rowData.id, ['revenueHrsEngineer', 'revenueHrsApprentice']) &&
      currentUserData.isPilot &&
      !(currentUserData.isEngineer || currentUserData.isApprentice)
    ) {
      return false;
    }

    if (
      (R.includes(rowData.id, ['revenueHrsEngineer']) &&
        currentUserData.isApprentice &&
        !currentUserData.isEngineer) ||
      (R.includes(rowData.id, ['revenueHrsApprentice']) &&
        currentUserData.isEngineer &&
        !currentUserData.isApprentice)
    ) {
      return false;
    }

    if (!rowData.isExpensesField) {
      if (R.includes(rowData.id, ['duty', 'mealRestWaiting'])) {
        if (R.equals(parseFloat(`${rowData.total}`), 0)) {
          return false;
        }
      }

      if (R.includes(rowData.id, ['bushpay'])) {
        if (
          R.includes(`${rowData.day1}`, ['', '...']) &&
          R.includes(`${rowData.day2}`, ['', '...']) &&
          R.includes(`${rowData.day3}`, ['', '...']) &&
          R.includes(`${rowData.day4}`, ['', '...']) &&
          R.includes(`${rowData.day5}`, ['', '...']) &&
          R.includes(`${rowData.day6}`, ['', '...']) &&
          R.includes(`${rowData.day7}`, ['', '...'])
        ) {
          return false;
        }
      }

      return true;
    }

    if (rowData.isExpensesField && isPresent(rowData.total)) return true;

    return false;
  });
};

export const getFormattedRowDataArray = (
  rowDataArray: TableBodyRow[],
  currentUserData: RowData
) => {
  return rowDataArray.map((rowData: TableBodyRow) => {
    const currentRowKeys = R.keys(rowData);
    let currentRowFormattedData: TableBodyRow = { ...rowData };

    currentRowKeys.forEach((key: string) => {
      const currentRowData: string = rowData[`${key}`];

      if (R.includes(key, ignoreEditArray) || isNilOrEmpty(currentRowData)) {
        currentRowFormattedData[key] = currentRowData;
      } else {
        if (
          R.includes(rowData.id, [
            'revenue',
            'revenueHrsCopilot',
            'revenueHrsEngineer',
            'revenueHrsApprentice',
            'nonRevenuePilot',
            'nonRevenueCoPilot',
            'airTime',
            'longLine'
          ])
        ) {
          currentRowFormattedData[key] = Number(currentRowData).toFixed(1);
        } else if (R.includes(rowData.id, ['workStart', 'workEnd'])) {
          currentRowFormattedData[key] = isPresent(currentRowData)
            ? moment.utc(currentRowData).format('HH:mm')
            : '';
        } else if (R.includes(rowData.id, ['difference'])) {
          const value = parseFloat(currentRowFormattedData[key]);

          if (R.is(Number, value) && `${value}` !== 'NaN') {
            currentRowFormattedData[key] = `${value.toFixed(1)}%`;
          }
        } else {
          currentRowFormattedData[key] = currentRowData;
        }
      }
    });

    if (R.includes(currentRowFormattedData.id, ['difference'])) {
      let count = 0;
      const days = [1, 2, 3, 4, 5, 6, 7];

      days.forEach((n: number) => {
        const value = parseFloat(currentRowFormattedData[`day${n}`]);

        if (R.is(Number, value) && `${value}` !== 'NaN') {
          count = count + 1;
        }
      });

      if (count !== 0) {
        const total = parseFloat(`${currentRowFormattedData.total}`) / count;

        currentRowFormattedData.total = `${total.toFixed(1)}%`;
      }
    }

    //flight pay
    if (currentRowFormattedData.id === 'revenue') {
      if (currentUserData.isPilot) {
        currentRowFormattedData.name = `Flight Pay - Pilot`;
      }
    }

    return currentRowFormattedData;
  });
};

export const getCalculatedDetailsDataArray = (
  currentUserData: RowData,
  selectedFirstDate: Date,
  expenseTypesArray: TableBodyRow[]
) => {
  if (isNilOrEmpty(currentUserData)) {
    return [];
  }

  const weekDays = getWeekDays(selectedFirstDate);
  const { dailies }: DailyDataProps = currentUserData;

  console.log(currentUserData, 'currentUserData');

  let rowNamesDataArray = rowNamesArray.concat(expenseTypesArray);

  rowNamesDataArray = rowNamesDataArray.concat(extraNewFields);

  console.log(rowNamesDataArray, 'rowNamesDataArray');
  const rowDataArray = rowNamesDataArray.map((row: TableBodyRow) => {
    var currentRowObj = { ...row };
    var total = 0;
    var isNumberField = false;

    weekDays.forEach((date: Date, i: number) => {
      var index = -1;
      const convertedDate = moment.utc(date);
      let newdailies = dailies.slice(0, dailies.length);

      newdailies.slice().forEach((dayData: any, idx: number) => {
        const convertedDayDate = moment.utc(dayData.date);
        if (moment(convertedDayDate).isSame(convertedDate, 'day')) {
          index = idx;
        }
      });

      var currentObj = dailies[index];

      if (
        index !== -1 &&
        dailies[index].status.toLowerCase() !== 'off' &&
        isPresent(dailies[index].status)
      ) {
        var data = null;

        if (row.isExpensesField) {
          const totalsArray = currentObj.expenses.totals;
          const currentExpenseId = row.id;

          const expenseIdMatchingObject = R.find(
            R.propEq('id', `${currentExpenseId}`)
          )(totalsArray);

          if (isPresent(expenseIdMatchingObject)) {
            data = R.pathOr(null, ['amount'], expenseIdMatchingObject);
          }
        } else {
          data = currentObj[`${row.id}`];
        }

        if (
          isNilOrEmpty(data) &&
          !row.isExpensesField &&
          !R.includes(row.id, ['workStart', 'workEnd', 'difference'])
        ) {
          currentRowObj[`day${i + 1}`] = '...';
        } else if (isNilOrEmpty(data) && row.isExpensesField) {
          currentRowObj[`day${i + 1}`] = '';
        } else {
          let formattedData = '';

          if (R.is(Array, data)) {
            formattedData = data.join(', ');
          } else if (R.is(Number, data)) {
            total = total + data;
            isNumberField = true;
            formattedData = data.toFixed(2);
          } else {
            formattedData = data;
          }

          currentRowObj[`day${i + 1}`] = formattedData;
        }
      } else {
        if (
          ['status', 'comment', 'creditCard', 'directBill'].includes(
            `${row.id}`
          )
        ) {
          const data = R.pathOr('', [row.id], dailies[index]);

          let formattedData = '';

          if (R.is(Number, data)) {
            const dataTemp = Number(data);

            total = total + dataTemp;
            isNumberField = true;
            formattedData = dataTemp.toFixed(2);
          } else {
            formattedData = data;
          }

          currentRowObj[`day${i + 1}`] = formattedData;
        } else if (row.isExpensesField && currentObj !== undefined) {
          const totalsArray = currentObj.expenses.totals;
          const currentExpenseId = row.id;

          const expenseIdMatchingObject = R.find(
            R.propEq('id', `${currentExpenseId}`)
          )(totalsArray);

          if (isPresent(expenseIdMatchingObject)) {
            const data = R.pathOr(0, ['amount'], expenseIdMatchingObject);

            if (R.is(Number, data)) {
              currentRowObj[`day${i + 1}`] = data.toFixed(2);
              isNumberField = true;

              total = total + data;
            } else {
              currentRowObj[`day${i + 1}`] = data;
            }
          }
        } else {
          currentRowObj[`day${i + 1}`] = '';
        }
      }
    });

    if (isNumberField) {
      currentRowObj['total'] = total.toFixed(2);
    }

    return currentRowObj;
  });
  const totals = getTotals(rowDataArray);

  rowDataArray.splice(rowDataArray.length - extraNewFields.length, 0, totals);

  const filteredRowDataArray = getFilteredRowDataArray(
    rowDataArray,
    currentUserData
  );

  const formatRowDataArray = getFormattedRowDataArray(
    filteredRowDataArray,
    currentUserData
  );

  return formatRowDataArray;
};
