import { WeeklyScreenData, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';
import { initialFilterList } from '../../utils/helper';

const initialState: WeeklyScreenData = {
  isLoading: false,
  userDataArray: [],
  currentUser: {},
  metaData: {
    totalRecords: 0,
    currentPage: 0,
    nextPage: 0
  },
  departmentFiltersArray: initialFilterList
};

const weeklyScreenData = (
  state: WeeklyScreenData = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_WEEKLY_SCREEN_DATA_SUCCESS: {
      return {
        ...state,
        userDataArray: action.payload.data,
        metaData: action.payload.metaData,
        isLoading: false
      };
    }
    case 'FETCH_WEEKLY_CURRENT_DATA': {
      return {
        ...state,
        currentUser: action.payload.data,
        isLoading: false
      };
    }
    case types.FETCH_WEEKLY_SCREEN_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case types.FETCH_WEEKLY_SCREEN_DATA_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }

    case types.FETCH_DEPARTMENT_FILTERS_SUCCESS: {
      const departmentFiltersArray = initialFilterList.concat(
        action.payload.value
      );

      return {
        ...state,
        departmentFiltersArray: departmentFiltersArray
      };
    }

    case types.FETCH_DEPARTMENT_FILTERS_FAILURE:
    default:
      return {
        ...state
      };
  }
};

export default weeklyScreenData;
