import { AnyAction, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import history from '../../utils/history';
import StoreState from '../utils/interfaces';
import actionsTypes from '../utils/actionTypes';

import spinner from './spinner';
import refreshTokens from './refreshTokens';
import weeklyScreenData from './weeklyScreenData';
import weeklyScreenFilters from './weeklyScreenFilters';
import detailsScreenSelectedFields from './detailsScreenSelectedFields';
import detailsScreenData from './detailsScreenData';
import invoiceScreenFilters from './invoiceScreenFilters';
import invoiceScreenData from './invoiceScreenData';
import fuelExpenseScreenFilters from './fuelExpenseScreenFilters';
import fuelExpenseScreenData from './fuelExpenseScreenData';
import engineerJobReviewScreenFilters from './engineerJobReviewScreenFilters';
import engineerJobReviewScreenData from './engineerJobReviewScreenData';
import flightReportScreenFilters from './flightReportScreenFilters';
import flightReportScreenData from './flightReportScreenData';
import customersScreenData from './customersScreenData';
import usersScreenData from './usersScreenData';
import clientUsersScreenData from './clientUsersScreenData';
import bookingsScreenData from './bookingsScreenData';
import bookingsScreenFilters from './bookingsScreenFilters';
import userInformation from './userInfo';
import aircraftScreenData from './aircraftScreenData';
import aircraftScreenFilters from './aircraftScreenFilters';
import logBookScreenData from './logBookScreenData';
import customerFlightReport from './customerFlightReport';
import clientPortalScreenData from './clientPortalScreenData';
import FlightReportRangeData from './FlightReportRangeData';
import FlightReportRangeUpdate from './FlightReportRangeUpdate';

const allReducers = combineReducers({
  spinner,
  refreshTokens,
  weeklyScreenData,
  weeklyScreenFilters,
  detailsScreenSelectedFields,
  FlightReportRangeData,
  detailsScreenData,
  invoiceScreenFilters,
  invoiceScreenData,
  fuelExpenseScreenFilters,
  fuelExpenseScreenData,
  engineerJobReviewScreenFilters,
  engineerJobReviewScreenData,
  flightReportScreenFilters,
  flightReportScreenData,
  customersScreenData,
  usersScreenData,
  clientUsersScreenData,
  bookingsScreenData,
  bookingsScreenFilters,
  userInformation,
  aircraftScreenData,
  aircraftScreenFilters,
  logBookScreenData,
  customerFlightReport,
  FlightReportRangeUpdate,
  clientPortalScreenData,
  form: formReducer,
  router: connectRouter(history)
});

const rootReducer = (state: StoreState | undefined, action: AnyAction) => {
  if (action.type === actionsTypes.USER_LOG_OUT_SUCCESS) {
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
