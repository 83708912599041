export const LOGIN_FORM = 'logInForm';

export const RECEIPT_DETAILS_EDIT_FORM = 'receiptDetailsEditForm';

export const INVOICE_FILTERS_FORM = 'invoiceFiltersForm';

export const FUEL_EXPENSE_FILTERS_FORM = 'fuelExpenseFiltersForm';

export const LOG_BOOK_FILTERS_FORM = 'logBookFiltersForm';

export const LOG_BOOK_DETAILS_EDIT_FORM = 'logBookDetailsEditForm';

export const ENGINEER_JOB_REVIEW_FILTERS_FORM = 'engineerJobReviewFiltersForm';

export const FLIGHT_REPORT_FILTERS_FORM = 'flightReportFiltersForm';

export const CLIENT_PORTAL_FILTERS_FORM = 'clientPortalFiltersForm';

export const CUSTOMERS_ADD_EDIT_FORM = 'customersAddEditForm';

export const CONTACT_ADD_EDIT_FORM = 'contactAddEditForm';

export const PASSENGER_ADD_EDIT_FORM = 'passengerAddEditForm';

export const USERS_ADD_EDIT_FORM = 'userAddEditForm';

export const CLIENT_USERS_ADD_EDIT_FORM = 'clientUserAddEditForm';

export const BOOKINGS_FILTERS_FORM = 'bookingsFiltersForm';

export const BOOKINGS_ADD_EDIT_FORM = 'bookingsAddEditForm';

export const AIRCRAFT_FILTERS_FORM = 'aircraftFiltersForm';

export const USERS_FILTERS_FORM = 'usersFiltersForm';

export const CLIENT_USERS_FILTERS_FORM = 'clientUsersFiltersForm';

export const CUSTOMERS_FILTERS_FORM = 'customersFiltersForm';

export const AIRCRAFT_ADD_EDIT_FORM = 'aircraftAddEditForm';
