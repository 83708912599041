import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';
import { Box } from '@material-ui/core';
// import DoneIcon from '@material-ui/icons/Done';
// import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import LaunchIcon from '@material-ui/icons/LaunchOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import UnlockIcon from '@material-ui/icons/LockOpen';
// import SaveIcon from '@material-ui/icons/SaveOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import Breadcrumbs from '../shared/Breadcrumbs';
import Status from '../shared/Status';
import CustomSpeedDial from '../shared/CustomSpeedDial';
import {
  updateStatusDetailScreenDataRequest
  // resetDetailScreenData,
  // setCommentWeeklyDetailsRequest
} from '../../redux/actions/detailsScreenData';
import { exportWeeklyReportRequest } from '../../redux/actions/pdfExport';
import { RowData } from '../../redux/utils/interfaces';
import { isNilOrEmpty, getWeekRange } from '../../utils/helper';
import StoreState from '../../redux/utils/interfaces';

import './styles.scss';

interface DetailsScreenSecondaryHeaderProps {
  currentUserData: RowData;
  isReceiptPage: boolean;
  isWorkHoursDetailsPage: boolean;
  dataArray: RowData[];
  isLoading: boolean;
  selectedFirstDate: Date;
}

const DetailsWeeklyScreenSecondaryHeaders = ({
  currentUserData,
  isReceiptPage,
  isWorkHoursDetailsPage,
  dataArray,
  isLoading,
  selectedFirstDate
}: DetailsScreenSecondaryHeaderProps) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const {
    userInformation: { canReviewUserIds, isApprovingSupervisor }
  } = useSelector((state: StoreState) => {
    return {
      userInformation: state.userInformation
    };
  });
  console.log(currentUserData, 'fromWorkingData');
  const receiptLink = `/WeeklyPerEmployee/${R.propOr(
    '/',
    'id',
    currentUserData
  )}/receipt`;

  const workHoursDetailsLink = `/WeeklyPerEmployee/${R.propOr(
    '/',
    'id',
    currentUserData
  )}/workHoursDetails`;

  console.log(currentUserData, 'Header currentUserData');
  const status = R.pathOr('', ['status'], currentUserData);
  const userId = R.pathOr('', ['id'], currentUserData);
  const isEngineer = R.pathOr(false, ['isEngineer'], currentUserData);
  const isPilot = R.pathOr(false, ['isPilot'], currentUserData);
  const isAdmin = R.pathOr(false, ['isAdmin'], currentUserData);

  // let dropdownList = useMemo(() => {
  //   if (isWorkHoursDetailsPage) {
  //     return dataArray.filter((listData: RowData) => {
  //       return (
  //         listData.isEngineer ||
  //         listData.isApprentice ||
  //         listData.isPilot ||
  //         listData.isAdmin
  //       );
  //     });
  //   }

  //   return dataArray;
  // }, [dataArray, isWorkHoursDetailsPage]);

  const handleSummaryBreadcrumClick = () => {
    history.push(`/WeeklyPerEmployee`);
    // dispatch(resetDetailScreenData());
  };
  // const handleDetailsBreadcrumClick = () =>
  //   history.push(`/details/${currentUserData.id}`);

  const firstName = R.pathOr('', ['firstName'], currentUserData);
  const lastName = R.pathOr('', ['lastName'], currentUserData);
  const name = `${firstName} ${lastName}`.trim();

  const currentUserName = isLoading
    ? 'Loading...'
    : isNilOrEmpty(name)
    ? 'Select User Here'
    : name;

  // const handleBreadcrumDropdownClick = (userId: string | number) => {
  //   let dropdownButtonClickLink: string = `/details/${userId}`;

  //   if (isReceiptPage) {
  //     dropdownButtonClickLink = `/details/${userId}/receipt`;
  //   }

  //   if (isWorkHoursDetailsPage) {
  //     dropdownButtonClickLink = `/details/${userId}/workHoursDetails`;
  //   }

  //   history.push(dropdownButtonClickLink);
  // };

  const breadcrumbDataArray = [
    {
      id: 'summary_breadcrum',
      label: 'Weekly Per Employee',
      onClick: handleSummaryBreadcrumClick
    },
    // {
    //   id: 'details_breadcrum',
    //   label: 'Details',
    //   onClick: handleDetailsBreadcrumClick
    // },
    {
      id: 'current_user_details_breadcrum',
      label: currentUserName,
      currentSelectedUserId: userId
    }
  ];

  if (isReceiptPage) {
    breadcrumbDataArray.push({
      id: 'current_user_receipts_breadcrum',
      label: 'Receipts',
      onClick: () => {}
    });
  }

  if (isWorkHoursDetailsPage && (isEngineer || isPilot || isAdmin)) {
    breadcrumbDataArray.push({
      id: 'current_user_work_hours_details_breadcrum',
      label: isEngineer || isAdmin ? 'Work Hours Description' : 'Flight Report',
      onClick: () => {}
    });
  }

  const isApproved = status.toLowerCase() === 'approved';
  const isReviewed = status.toLowerCase() === 'reviewed';
  const isPending = status.toLowerCase() === 'pending';
  const reviewButtonClickable =
    (isPending || isReviewed) &&
    (isApprovingSupervisor ||
      (canReviewUserIds.includes(Number(currentUserData.id)) && isPending));
  const unlockButtonClickable =
    (isApproved || isReviewed) && isApprovingSupervisor;
  const isSaveButtonClickable =
    isApprovingSupervisor ||
    canReviewUserIds.includes(Number(currentUserData?.id));

  const handleOnClickReceipt = useCallback(() => {
    history.push(receiptLink);
  }, [history, receiptLink]);

  const handleOnClickWorkHoursDetails = useCallback(() => {
    history.push(workHoursDetailsLink);
  }, [history, workHoursDetailsLink]);

  const handleOnClickReview = useCallback(() => {
    if (reviewButtonClickable) {
      dispatch(updateStatusDetailScreenDataRequest(userId));
    }
  }, [dispatch, userId, reviewButtonClickable]);

  const handleReceiptsDownload = useCallback(() => {
    const { from, to } = getWeekRange(selectedFirstDate);

    dispatch(
      exportWeeklyReportRequest(from, to, [Number(userId)], {
        accpacImport: false,
        weeklySummary: false,
        receipts: true,
        summaryModeImages: false
      })
    );
  }, [dispatch, userId, selectedFirstDate]);

  const handleOnClickUnlock = useCallback(() => {
    if (unlockButtonClickable) {
      dispatch(updateStatusDetailScreenDataRequest(userId, true));
    }
  }, [dispatch, userId, unlockButtonClickable]);

  const handleOnClickSave = useCallback(() => {
    if (isSaveButtonClickable) {
      // dispatch(setCommentWeeklyDetailsRequest(userId));
    }
  }, [dispatch, userId, isSaveButtonClickable]);

  const handleOnClickOpenInNew = useCallback(() => {
    if (isReceiptPage) window.open(receiptLink, '_blank');
    if (isWorkHoursDetailsPage) window.open(workHoursDetailsLink, '_blank');
  }, [
    isReceiptPage,
    isWorkHoursDetailsPage,
    receiptLink,
    workHoursDetailsLink
  ]);

  const actions = useMemo(() => {
    if (isReceiptPage || isWorkHoursDetailsPage) {
      return [
        {
          icon: LaunchIcon,
          name: 'Open',
          onClick: handleOnClickOpenInNew
        }
      ];
    }

    const actionsArray = [
      // {
      //   icon: SaveIcon,
      //   name: 'Save',
      //   disabled: !isSaveButtonClickable,
      //   onClick: handleOnClickSave
      // },
      // {
      //   icon: isReviewed ? DoneAllIcon : isPending ? DoneIcon : DoneIcon,
      //   name: isReviewed ? 'Approve' : isPending ? 'Review' : 'Review',
      //   disabled: !reviewButtonClickable,
      //   onClick: handleOnClickReview,
      //   showToast: false
      // },
      {
        icon: ReceiptIcon,
        name: 'Receipts',
        onClick: handleOnClickReceipt
      },
      {
        icon: AssignmentIcon,
        name: 'Work Details',
        onClick: handleOnClickWorkHoursDetails
      },
      {
        icon: UnlockIcon,
        name: 'Unlock',
        disabled: !unlockButtonClickable,
        onClick: handleOnClickUnlock
      }
    ];

    if (isApprovingSupervisor) {
      actionsArray.push({
        icon: CloudDownloadIcon,
        name: 'Export Receipts',
        disabled: !isApprovingSupervisor,
        onClick: handleReceiptsDownload
      });
    }

    return actionsArray;
  }, [
    handleOnClickOpenInNew,
    handleOnClickReceipt,
    handleOnClickReview,
    handleOnClickWorkHoursDetails,
    handleOnClickUnlock,
    handleOnClickSave,
    isReceiptPage,
    isWorkHoursDetailsPage,
    isReviewed,
    isPending,
    reviewButtonClickable,
    unlockButtonClickable,
    isSaveButtonClickable,
    isApprovingSupervisor,
    handleReceiptsDownload
  ]);

  return (
    <React.Fragment>
      <Box>
        <Breadcrumbs breadcrumbDataArray={breadcrumbDataArray} />
      </Box>
      <Box mx={4}>
        <Status status={status} />
      </Box>
      <Box className="receipt-review-logo-container">
        <CustomSpeedDial actions={actions} />
      </Box>
    </React.Fragment>
  );
};

export default DetailsWeeklyScreenSecondaryHeaders;
