import React, { useEffect } from 'react';
import {
  change,
  Field,
  getFormValues,
  initialize,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import DatePickerDetails from '../shared/DatePickerDetails';
// import DatePicker from '../shared/DatePicker';
import { LOG_BOOK_DETAILS_EDIT_FORM } from '../../utils/reduxFormConstants';
import { getFormattedValues, LogBookRow } from '../LogBookScreenTable/helper';
import { useDispatch, useSelector } from 'react-redux';
import StoreState from '../../redux/utils/interfaces';
import * as R from 'ramda';
import { getDateString } from '../../utils/helper';
import ReduxFormInput from '../shared/ReduxFormInput';

import ColorButton from '../shared/ColorButton';

import {
  fetchLogBookScreenAricraftRequest,
  updateLogBookDetailScreenDataRequest
} from '../../redux/actions/logBookScreenData';
import ReduxFormAutocomplete from '../shared/ReduxFormAutocomplete';
export interface DetailsFormProps {
  currentLogBookData: LogBookRow;
}
function DetailsForm(props: any) {
  const dispatch = useDispatch();
  const { formValues, aircraftsList } = useSelector((state: StoreState) => {
    return {
      formValues: getFormValues(LOG_BOOK_DETAILS_EDIT_FORM)(state) || {},
      aircraftsList: R.pathOr([], ['logBookScreenData', 'aircrafts'], state)
    };
  });
  const {
    id,
    bookNumber,
    pageNumber,
    correctedBookNumber,
    correctedPageNumber,
    aircraft,
    correctedAircraft,
    daily,
    localDate
  } = props.currentLogBookData;
  useEffect(() => {
    dispatch(
      initialize(LOG_BOOK_DETAILS_EDIT_FORM, {
        id: id,
        bookNumber: bookNumber,
        pageNumber: pageNumber,
        correctedBookNumber: correctedBookNumber,
        correctedPageNumber: correctedPageNumber,
        userName: `${daily?.user?.firstName} ${daily.user?.lastName}`,
        aircraft: aircraft?.id,
        correctedAircraft: correctedAircraft?.id,
        date: localDate
      })
    );
    dispatch(fetchLogBookScreenAricraftRequest());
  }, [
    id,
    bookNumber,
    pageNumber,
    correctedBookNumber,
    correctedPageNumber,
    aircraft,
    daily,
    correctedAircraft,
    localDate,
    dispatch
  ]);
  console.log(correctedAircraft, 'correctedAircraft');
  const updateFormField = (key: string, value: any) =>
    dispatch(change(LOG_BOOK_DETAILS_EDIT_FORM, key, value));

  const selectedDate = R.pathOr(new Date(), ['date'], formValues);

  const handleSelectDate = (date: Date) => {
    updateFormField('date', getDateString(date));
  };
  return (
    <form
      onSubmit={(event: any) => {
        event.preventDefault();
        props.handleSubmit(event);
      }}
      noValidate
      autoComplete="off">
      <div className="receipt-details-fields-container">
        <div className="details-field-container">
          <DatePickerDetails
            selectedDate={selectedDate}
            handleDateChange={handleSelectDate}
            disabled
            keyboardIcon={<div></div>}
          />
        </div>
        <Field
          name="bookNumber"
          type="text"
          component={ReduxFormInput}
          label="Book Number"
          disabled
        />
        <Field
          name="pageNumber"
          type="text"
          component={ReduxFormInput}
          label="Page Number"
          disabled
        />
        <Field
          name="userName"
          type="text"
          component={ReduxFormInput}
          label="Uploaded by"
          disabled
        />
        <Field
          name="aircraft"
          component={ReduxFormAutocomplete}
          label="Aircraft"
          optionsList={aircraftsList}
        />
        <Field
          name="correctedBookNumber"
          type="text"
          component={ReduxFormInput}
          label="Corrrected Book Number"
        />
        <Field
          name="correctedPageNumber"
          type="text"
          component={ReduxFormInput}
          label="Corrected Page Number"
        />

        <div className="submit-button-container">
          <ColorButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={props.pristine || props.invalid}>
            Save
          </ColorButton>
        </div>
      </div>
    </form>
  );
}

export default reduxForm<InjectedFormProps, DetailsFormProps>({
  form: LOG_BOOK_DETAILS_EDIT_FORM,
  onSubmit: (values: any, dispatch: any) => {
    const formattedValues = getFormattedValues(values);
    dispatch(updateLogBookDetailScreenDataRequest(formattedValues));
  }
})(DetailsForm);
