import React from 'react';
import {
  Field,
  reduxForm,
  InjectedFormProps,
  WrappedFieldProps
} from 'redux-form';
import {
  CssBaseline,
  Container,
  Input,
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
  Typography
} from '@material-ui/core';
import ColorButton from '../../components/shared/ColorButton';
import AirOpsLogo from '../../assets/airops-logo.svg';
import {
  userLoginRequest,
  userResetPassRequest
} from '../../redux/actions/userAuth';
import { minLength2, email, required } from '../../utils/validation';
import { LOGIN_FORM } from '../../utils/reduxFormConstants';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

interface FieldProps {
  label: string;
  type: string;
  placeHolder: string;
  disabled: boolean;
}

const ReduxFormInput = (field: WrappedFieldProps & FieldProps) => (
  <FormControl className="mui-form-control">
    <InputLabel className="mui-input-label">{field.label}</InputLabel>
    <Input
      className="mui-input"
      {...field.input}
      type={field.type}
      placeholder={field.placeHolder}
      disabled={field.disabled}
    />

    {field.meta.touched && (
      <FormHelperText error={true}>{field.meta.error}</FormHelperText>
    )}
  </FormControl>
);

const Login = (props: InjectedFormProps) => {
  const [forgotPass, toggleForgotPass] = React.useState(false);
  const dispatch = useDispatch();
  const { formValues } = useSelector((state: any) => {
    return { formValues: state.form[LOGIN_FORM] };
  });

  const handleOnForgotPass = (event: any) => {
    event.preventDefault();
    dispatch(userResetPassRequest({ email: formValues.values.email }));
    toggleForgotPass(!forgotPass);
  };
  return (
    <div className="login-container">
      <CssBaseline />
      <Container maxWidth="sm">
        <form
          onSubmit={(event: any) => {
            event.preventDefault();
            props.handleSubmit(event);
          }}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className="login-form-container">
            <div className="logo-container">
              <img src={AirOpsLogo} alt="Logo" height="100%" width="100%" />
            </div>
            <div className="heading-text">
              <Typography variant="body1">
                Flight Operations Made Easy
              </Typography>
            </div>
            {!forgotPass ? (
              <>
                <div className="field-container">
                  <Field
                    name="email"
                    type="email"
                    component={ReduxFormInput}
                    label="Email"
                    placeHolder="Enter email"
                    validate={[required, email]}
                  />
                </div>
                <div className="field-container">
                  <Field
                    name="password"
                    type="password"
                    component={ReduxFormInput}
                    label="Password"
                    placeHolder="Enter password"
                    validate={[required, minLength2]}
                  />
                </div>
                <div className="field-container">
                  <ColorButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={props.pristine || props.invalid}>
                    Go
                  </ColorButton>
                </div>
              </>
            ) : (
              <>
                <div className="field-container">
                  <Field
                    name="email"
                    type="email"
                    component={ReduxFormInput}
                    label="Email"
                    placeHolder="Enter email"
                    validate={[required, email]}
                  />
                </div>
                <div className="field-container">
                  <ColorButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={props.pristine || props.invalid}
                    onClick={handleOnForgotPass}>
                    Reset Password
                  </ColorButton>
                </div>
              </>
            )}
            <div>
              <a
                className="reset-link"
                href="#"
                onClick={() => toggleForgotPass(!forgotPass)}>
                {!forgotPass
                  ? 'Click here to reset your password'
                  : 'Back to login'}
              </a>
              {/* href="https://portal.airops.ca/privacypolicy"> */}
              {/* href="http://localhost:3000/privacypolicy"> */}
              <div style={{ fontSize: '11px', color: '#FFF' }}>
                <p>
                  By clicking Log in, you agree to our{' '}
                  <a
                    target="_blank"
                    style={{
                      fontSize: '13px',
                      color: '#8fb320',
                      textDecoration: 'underline'
                    }}
                    href="https://portal.airops.ca/privacypolicy">
                    <b> Privacy Policy</b>
                  </a>
                </p>
              </div>
            </div>
          </Grid>
        </form>
      </Container>
    </div>
  );
};

export default reduxForm({
  form: LOGIN_FORM,
  onSubmit: (values, dispatch) => dispatch(userLoginRequest(values))
})(Login);
